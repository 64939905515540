/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  DeleteConfirmation,
} from '../../components';
import IngredientCategoryActions from '../../redux/middleware/ingredientCategory';
import { sortedData } from '../../helper/sortData';
import Delete from '../../assets/images/Delete.svg';
import Clock from '../../assets/images/Clock.png';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import './ingredientCategory.scss';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const items1: MenuProps['items'] = [];

const PageDataLength = [6, 10, 20];

const IngredientCategory = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const company = Utils.getCurrentCompany();
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});

  const navigate = useNavigate();

  const { currentBrand } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    pageData();
  }, [currentBrand]);

  const [ingredientId, setIngredientId] = useState('');
  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'serial_no',
      key: 'serial_no',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record.name}</div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>DATE CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          <img src={Clock} alt="" className="icons" />
          {moment(record?.created_at).fromNow()}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items:
              record?.name === 'Sub Recipe' || record?.name === 'Production'
                ? items1
                : items,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Ingredient Category'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (ingredient: any) => {
    setDialogData(ingredient);
    setIngredientId(ingredient?.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', { state: { update: true, id: ingredientId } });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await IngredientCategoryActions.deleteIngredientCategory(ingredientId);
    pageData();
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setRecipeLoader(true);
    const recipeData = await IngredientCategoryActions.getIngredientCategory(
      dataLength
    );

    if (recipeData === undefined) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.ingredient_category_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes =
      await IngredientCategoryActions.searchIngredientCategory(
        company?.id,
        dataLength,
        keyword
      );
    if (searchResultofRecipes === undefined) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.ingredient_category_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Ingredient Category"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.name}?`}
      />
      <Page title="Ingredient Category">
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <ListCount count1={recipeCount} title1="Total Ingredients" />
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox action={onSearch} placeholderText="Search..." />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                items={items}
                dataSource={[...sortData]}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default IngredientCategory;
