import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Input, Select } from 'antd';
import { Page, Breadcrumb } from '../../components';
import './styles.scss';
import ModifiersActions from '../../redux/middleware/modifiers';
import CategoryActions from '../../redux/middleware/category';
import Utils from '../../redux/utils';

const ModifierWizard = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [modifier, setModifier] = React.useState('');
  const [consumption, setconsumption] = React.useState<any>([]);
  const [category, setCategory] = React.useState<any>([]);
  const [menuCategory, setMenuCategory] = React.useState<any>([]);
  const [consumptionCategory, setConsumptionCategory] = React.useState<any>([]);
  const fetchModifiersType = async () => {
    const ModifiersType: any = await ModifiersActions.getModifiersType();
    setConsumptionCategory(ModifiersType.data.items);
    const menucategory: any = await CategoryActions.getMenuCategory();
    setMenuCategory(menucategory.data.items);
  };
  useEffect(() => {
    fetchModifiersType();
  }, []);
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const handleModifier = (data: string) => {
    setModifier(data);
  };
  const handelChangeConsumption = (data: any) => {
    let dataArray: any = consumptionCategory.filter((val: any) => {
      return val.id === data;
    });
    setconsumption(dataArray);
  };
  const handelChangeCategory = (data: any) => {
    let dataArray: any = menuCategory.filter((val: any) => {
      return val.id === data;
    });
    setCategory(dataArray);
  };

  const handleBack = () => {};

  const handleContinue = async () => {
    const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');

    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const company = await Utils.getCurrentCompany();
    if (user) {
      const param = {
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        modifier_name: modifier,
        modifier_type_id: consumption.id,
        modifier_type: consumption.name,
        menu_category: category.name,
        menu_category_id: category.id,
        created_by: 2,
      };
      const response = await ModifiersActions.createModifiers(param);

      if (response) {
        navigate('/app/modifiers');
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Modifier"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/modifiers');
        }}
      />
      <Page title="Add New Modifier">
        <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div>

        <div className="wizardContainer">
          <div className="wizardSubContainer">
            <div className="wizardLabel">
              Modifier Name <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Enter Modifier name"
              size="large"
              value={modifier}
              onChange={(e) => handleModifier(e.target.value)}
              required
            />
          </div>

          <div className="wizardDropdown">
            <div className="wizardLabel">
              Consumption Type <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              placeholder={'Select consumption type'}
              onChange={handelChangeConsumption}
              style={{ width: 560 }}
              optionFilterProp="children"
              value={consumptionCategory?.name}
            >
              {consumptionCategory?.map((data: any, index: number) => (
                <Select.Option value={data?.id} key={index}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="wizardDropdown minimumheight">
            <div className="wizardLabel">
              Category <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              placeholder={'Select consumption type'}
              onChange={handelChangeCategory}
              style={{ width: 560 }}
              optionFilterProp="children"
              value={menuCategory?.name}
            >
              {menuCategory?.map((data: any, index: number) => (
                <Select.Option value={data?.id} key={index}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="butonGroup">
            <div className="wizard-button" onClick={() => handleBack()}>
              Back
            </div>
            <div
              className="continue wizard-button"
              onClick={() => handleContinue()}
            >
              Continue
            </div>
          </div>
        </div>
      </Page>
    </React.Fragment>
  );
};

export default ModifierWizard;
