import { baseURL } from '../../config/constant';
import Utils from '../utils';
const getCombos = async (dataLength) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/combos?company_id=${
        Utils.getCurrentCompany()?.id
      }&limit=${dataLength}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const searchCombo = async (dataLength, keyword) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/combos?company_id=${
        Utils.getCurrentCompany()?.id
      }&limit=${dataLength}&keyword=${keyword}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const deleteCombo = async (id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/combos/${id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'DELETE',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const createCombo = async (params, thumbnail) => {
  const formData = new FormData();
  formData.set('data', JSON.stringify(params));
  formData.append('thumbnail', thumbnail);

  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/combos`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const getRecipesForCombo = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/recipes?company_id=${
        Utils.getCurrentCompany()?.id
      }&limit=1000&parent_variation=true`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const getDetailRecipeforcombo = async (id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/recipes/${id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const updateCombo = async (params, thumbnail, id) => {
  const formData = new FormData();
  formData.set('data', JSON.stringify(params));
  formData.append('_method', 'PUT');
  formData.append('thumbnail', thumbnail == null ? {} : thumbnail);
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/combos/${id}`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const getDetailCombo = async (id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/combos/${id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const ComboActions = {
  getCombos,
  searchCombo,
  deleteCombo,
  createCombo,
  getDetailRecipeforcombo,
  getRecipesForCombo,
  updateCombo,
  getDetailCombo,
};

export default ComboActions;
