/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, ResponseDialog } from '../../components';
import { Row, Col, Alert } from 'antd';
import detailImg1 from '../../assets/images/detail-img1.png';
import detailImg2 from '../../assets/images/detail-img2.png';
import fileIcon from '../../assets/images/excelIcon.png';
import { formatFileSize } from 'react-papaparse';
import Delete from '../../assets/images/Delete.png';
import { RcFile } from 'antd/lib/upload';
import ResourcesActions from '../../redux/middleware/resources';
import Utils from '../../redux/utils';
import './style.scss';

const UploadIngredients = () => {
  const navigate = useNavigate();
  const array = [
    { value: 'csvFile', label: 'CSV File' },
    { value: 'csvMinimal', label: 'CSV Minimal' },
    { value: 'csvFull', label: 'CSV Full' },
  ];
  const fileRef = React.useRef<HTMLInputElement>(null);

  const [stepper, setStepper] = useState(0);
  const [tableData, setTableData] = useState<any>('');
  const [Variation, setVariation] = useState<any>(array[1]);
  const [errorList, setErrorList] = useState('');
  const [visible, setVisible] = useState(false);

  const screenPermission = Utils.getScreenPermission('Inventory List');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleImage = (url: any, data: any) => {
    setTableData(data?.target?.files[0]);
  };
  const handleBack = () => {
    if (stepper > 0) setStepper(stepper - 1);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleContinue = async () => {
    try {
      setVisible(false);
      let responseDialogData = {
        type: 'info',
        messageArray: [`We are uploading your listing. Please Wait...`],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);
      const response = await ResourcesActions.uploadMarketList(tableData);
      if (!response?.success) {
        setErrorList(response.error);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: [
            'Validation Failed - Most Common Issues are Column Sequence or Use of wrong Units. Check our Sample CSV for further guidance.',
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        setErrorList('');
        responseDialogData = {
          OnClick: () => {},
          type: 'success',
          messageArray: [` Your listing has been added`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeUOM = (data: any) => {
    setVariation(data);
  };

  return (
    <div>
      <Breadcrumb
        heading="Upload Ingredients"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        perm_status={screenPermission?.perm_status_id}
      />

      <Row>
        <Col lg={24} md={24}>
          {visible && (
            <Alert
              message="Error"
              description={
                'File format is Invalid. Please Select Correct File!'
              }
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="bulkUploadSubRecipe">
            <div className="wizardSubContainer checkBox">
              <div className="wizardLabel">File Attachment</div>

              {tableData ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <div>
                      <img src={fileIcon} alt="" style={{ width: '100%' }} />
                      <h4> {tableData?.name} </h4>
                      <p>{formatFileSize(tableData?.size)}</p>
                    </div>
                    <div
                      className="deleteButton"
                      onClick={() => handleImage('', [])}
                    >
                      <img src={Delete} width={15} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="formgroup formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      <p className="ant-upload-text ">
                        Drop your csv file here or browse
                      </p>
                      <p className="ant-upload-hint">Minimum file 2 MB</p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".csv"
                        onChange={async (e: any) => {
                          if (e.currentTarget.files[0]?.type == 'text/csv') {
                            const response = await getBase64(
                              e.currentTarget.files[0]
                            );
                            if (response) handleImage(response, e);
                          } else {
                            setVisible(true);
                          }
                        }}
                        disabled={!validateActionPermission()}
                      />
                    </label>
                  </div>
                </div>
              )}
              {/* <div className="dropDown-wrapper">
                <label>
                  CSV File <span>*</span>
                </label>
                <Select
                  size={'large'}
                  value={Variation}
                  placeholder="Select Variation"
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={array}
                  disabled
                />
              </div> */}
              {errorList && (
                <Row>
                  <Col lg={24} md={24}>
                    <div className="ErrorContainerSubRecipe">
                      {Utils.renderErrorItem(errorList).map(
                        (item: any, index: any) => (
                          <p key={index}>{item}</p>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <div className="butonGroup">
                <div className="wizard-button" onClick={handleBack}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} md={24} style={{ display: 'none' }}>
          <div className="fileDetailBlockSubRecipe">
            <ol className="fileDetailListSubRecipe">
              <li>
                <div className="list-content-sub-recipe">
                  <span>1.</span>
                  <p>
                    Download <b>CSV File</b>, you can choose <b>CSV Minimal</b>{' '}
                    or
                    <b>CSV Full</b>
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content-sub-recipe">
                  <span>2.</span>
                  <p>
                    Download <b>Get Marketlist</b> File
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content-sub-recipe">
                  <span>3.</span>
                  <p>
                    Open the <b>CSV File</b> and Input ingredients based on
                    <b>The Marketlist Data</b>. Make sure the ingredients is
                    suitable with the
                    <b>Marketlist Data</b>.
                  </p>
                </div>

                <div className="listImg">
                  <Row>
                    <div className="imgWrapper">
                      <img src={detailImg1} alt="" />
                    </div>
                    <div className="imgWrapper">
                      <img src={detailImg2} alt="" />
                    </div>
                  </Row>
                </div>
              </li>
              <li>
                <div className="list-content-sub-recipe">
                  <span>4.</span>
                  <p>
                    Upload the <b>CSV File</b> on the
                    <b>Upload Recipes’s Menu Download</b>
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UploadIngredients;
