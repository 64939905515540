/* eslint-disable no-unused-vars */
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminLayout from '../layout/adminLayout';
import RecipeBuilder from '../pages/recipeBuilder';
import RDSubRecipe from '../pages/rdSubRecipeList';
import RDSubRecipeWizard from '../pages/rdSubRecipeWizard';
import Recipe from '../pages/recipe';
import Wizard from '../pages/Wizard';
import SubRecipe from '../pages/subRecipe';
import RecipeWizard from '../pages/recipeWizard';
import SubRecipeWizard from '../pages/subRecipeWizard';
import ProductionRecipe from '../pages/productionRecipe';
import ProductionRecipeWizard from '../pages/productionRecipeWizard';
import Brands from '../pages/brands';
import BrandWizard from '../pages/BrandWizard';
import Branch from '../pages/branches';
import BranchWizard from '../pages/branchWizard';
import BranchCopyWizard from '../pages/branchCopy';
import Company from '../pages/company';
// import Menu from '../pages/menu';
// import Variation from '../pages/variation';
import VariationWizard from '../pages/variationWizard';
// import ManageMenu from '../pages/manageMenu';
// import Combos from '../pages/combos';
import ComboWizard from '../pages/comboWizard';
// import Modifiers from '../pages/modifier';
import ModifierWizard from '../pages/modifierWizard';
import CompanyWizard from '../pages/CompanyWizard';
import CompanyCopyWizard from '../pages/companyCopyWizard';

import UploadRecipe from '../pages/uploadRecipe';
import UserListView from '../pages/userList';
import UserWizard from '../pages/userListWizard';
import UploadImages from '../pages/uploadimages';
import UploadSubRecipeWizard from '../pages/uploadSubRecipeWizard';
import Utils from '../redux/utils';
import Dashboard from '../pages/dashboard';
import UploadSubRecipe from '../pages/uploadSubRecipe';
import UploadImageWizard from '../pages/uploadimageswizard';
import UploadMarketList from '../pages/uploadMarketList';
import MenuCategory from '../pages/menuCategory';
import MenuCategoryWizard from '../pages/menuCategoryWizard';
import IngredientCategory from '../pages/ingredientCategory';
import IngredientCategoryWizard from '../pages/ingredientCategoryWizard';
import MenuCategoryBuilder from '../pages/menuCategoryBuilder';
import MenuCategoryBuilderWizard from '../pages/menuCategoryBuilderWizard';
import IngredientCategoryBuilder from '../pages/ingredientCategoryBuilder';
import IngredientCategoryBuilderWizard from '../pages/ingredientCategoryBuilderWizard';
import UploadImagesForRB from '../pages/uploadImagesForRB';
import UploadImageWizardForRB from '../pages/uploadimageswizardforRB';
import UploadRecipeForRB from '../pages/uploadRecipeForRB';
import UploadSubRecipeForRB from '../pages/uploadSubRecipeForRB';
import UploadSubRecipeWizardForRB from '../pages/uploadSubRecipeWizardForRB';
import UploadMarketListForRB from '../pages/uploadMarketListForRB';
import InventoryListPage from '../pages/marketlist';
import UploadIngredients from '../pages/bulkUploadSubRecipe';
import BulkUploadRDSubRecipe from '../pages/bulkUploadR&DSubRecipe';
import BulkUploadRDRecipe from '../pages/bulkUploadR&DRecipe';
import Permission from '../pages/permission';
import Feedback from '../pages/feedback';
import { ComingSoon } from '../components';
// import { Loader } from '../components';
import LeadGeneration from '../pages/leadGeneration';
import Approval from '../pages/approval';
import ApprovalWizard from '../pages/approvalWizard';
import UserAccess from '../pages/userAccess';
import Supply from '../pages/supply';
import SupplyWizard from '../pages/supplyWizard';
import IngredientList from '../pages/Ingredient List';
import BuyingUnit from '../pages/buyingUnit';
import CashPurchase from '../pages/cashPurchase';
import CashPurchaseWizard from '../pages/cashPurchaseWizard';
import PurchaseOrder from '../pages/purchaseOrder';
import PurchaseOrderWizard from '../pages/purchaseOrderWizard';

import BuyingUnitWizard from '../pages/buyingUnitWizard';
import RecipeBuilderMarketList from '../pages/RecipeBuilderMarketList';
import BulkUploadSupplyIngredient from '../pages/bulkUploadSupplyIngredient';
import SupplierIngredients from '../pages/SupplierIngredients';
import Requisitions from '../pages/requisition';
import RequisitionWizard from '../pages/requisitionWizard';
import InventoryTransfer from '../pages/inventoryTransfer';
import StockCount from '../pages/StockCount';
import SubRecipeCategoryWizard from '../pages/subRecipeCategoryWizard';
import ProductionCategoryWizard from '../pages/productionCategoryWizard';
export const AppRoutes = () => {
  const menu = Utils.getCurrentMenu();
  const path = menu[0]?.path || menu[0]?.subs[0].path;

  return (
    <AdminLayout containerClassName="">
      <Routes>
        <Route path="" element={<Navigate to={`/${path}`} replace />} />
        <Route path="/r&d-recipe" element={<RecipeBuilder />} />
        <Route path="/r&d-subrecipe" element={<RDSubRecipe />} />
        <Route path="/research" element={<RecipeBuilder />} />
        <Route path="/recipes" element={<Recipe />} />
        <Route path="/r&d-recipe/wizard" element={<Wizard />} />
        <Route path="/r&d-subrecipe/wizard" element={<RDSubRecipeWizard />} />
        <Route path="/recipes/wizard" element={<RecipeWizard />} />
        <Route path="/subrecipe" element={<SubRecipe />} />
        <Route path="/subrecipe/wizard" element={<SubRecipeWizard />} />
        <Route path="/productionrecipe" element={<ProductionRecipe />} />
        {/* <Route path="/productionInventory" element={<Loader />} />
        <Route path="/productionConsumption" element={<Loader />} />  */}
        <Route path="/brands" element={<Brands />} />
        <Route path="/brands/wizard" element={<BrandWizard />} />
        <Route path="/branchCopy" element={<BranchCopyWizard />} />
        <Route path="/branches" element={<Branch />} />
        <Route path="/branches/wizard" element={<BranchWizard />} />
        <Route path="/company" element={<Company />} />
        <Route path="/company/wizard" element={<CompanyWizard />} />
        <Route
          path="/company/companyCopyWizard"
          element={<CompanyCopyWizard />}
        />
        <Route path="/stockCount" element={<StockCount />} />

        {/* <Route path="/combos" element={<Loader />} /> */}
        <Route path="/combos/wizard" element={<ComboWizard />} />
        {/* <Route path="/modifiers" element={<Loader />} /> */}
        <Route path="/modifiers/wizard" element={<ModifierWizard />} />
        {/* <Route path="/uploadRecipes" element={<UploadRecipe />} /> */}
        <Route path="/uploadRecipesForRB" element={<UploadRecipeForRB />} />
        <Route path="/uploadSubRecipes" element={<UploadSubRecipe />} />
        <Route
          path="/uploadSubRecipes/wizard"
          element={<UploadSubRecipeWizard />}
        />
        <Route
          path="/uploadSubRecipesForRB"
          element={<UploadSubRecipeForRB />}
        />
        <Route
          path="/uploadSubRecipesForRB/wizard"
          element={<UploadSubRecipeWizardForRB />}
        />
        <Route path="/uploadMarketList" element={<UploadMarketList />} />
        <Route
          path="/R&DuploadMarketList"
          element={<UploadMarketListForRB />}
        />
        <Route path="/users" element={<UserListView />} />
        <Route path="/users/wizard" element={<UserWizard />} />
        <Route
          path="/productionrecipe/wizard"
          element={<ProductionRecipeWizard />}
        />
        {/* <Route path="/menu" element={<Loader />} /> */}
        {/* <Route path="/variations" element={<Loader />} /> */}
        <Route path="/variations/wizard" element={<VariationWizard />} />
        {/* <Route path="/manageMenu" element={<Loader />} /> */}
        {/* <Route path="/uploadimage" element={<UploadImages />} /> */}
        <Route path="/uploadimage/wizard" element={<UploadImageWizard />} />
        <Route path="/uploadimageForRB" element={<UploadImagesForRB />} />
        <Route
          path="/uploadimageForRB/wizard"
          element={<UploadImageWizardForRB />}
        />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/menuCategory" element={<MenuCategory />} />
        <Route path="/menuCategory/wizard" element={<MenuCategoryWizard />} />
        <Route
          path="/menuCategory/subRecipeCategoryWizard"
          element={<SubRecipeCategoryWizard />}
        />
        <Route
          path="/menuCategory/productionCategoryWizard"
          element={<ProductionCategoryWizard />}
        />

        <Route path="/ingredientCategory" element={<IngredientCategory />} />
        <Route
          path="/ingredientCategory/wizard"
          element={<IngredientCategoryWizard />}
        />
        <Route path="/rd-menuCategory" element={<MenuCategoryBuilder />} />
        <Route
          path="/rd-menuCategory/wizard"
          element={<MenuCategoryBuilderWizard />}
        />
        <Route
          path="/rd-ingredientCategory"
          element={<IngredientCategoryBuilder />}
        />
        <Route
          path="/rd-ingredientCategory/wizard"
          element={<IngredientCategoryBuilderWizard />}
        />
        <Route path="/inventory" element={<InventoryListPage />} />
        <Route path="/cashPurchase" element={<CashPurchase />} />
        <Route path="/cashPurchase/Wizard" element={<CashPurchaseWizard />} />
        <Route path="/purchaseOrder" element={<PurchaseOrder />} />
        <Route path="/purchaseOrder/Wizard" element={<PurchaseOrderWizard />} />

        <Route path="/screens" element={<Permission />} />
        <Route path="/uploadIngredients" element={<UploadIngredients />} />
        <Route
          path="/bulkUploadR&DSubRecipe"
          element={<BulkUploadRDSubRecipe />}
        />
        <Route path="/bulkUploadR&DRecipe" element={<BulkUploadRDRecipe />} />
        {/* <Route path="/notfound" element={<ComingSoon />} /> */}
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/leadGeneration" element={<LeadGeneration />} />
        <Route path="/approval" element={<Approval />} />
        <Route path="/approval/wizard" element={<ApprovalWizard />} />
        <Route path="/supply" element={<Supply />} />
        <Route path="/supply/wizard" element={<SupplyWizard />} />
        <Route path="/userAccess" element={<UserAccess />} />
        <Route path="/ingredientlist" element={<IngredientList />} />
        <Route path="/ingredientlist/wizard" element={<Wizard />} />
        <Route path="/R&DmarketList" element={<RecipeBuilderMarketList />} />
        <Route path="/buyingUnit" element={<BuyingUnit />} />
        <Route path="/buyingUnit/wizard" element={<BuyingUnitWizard />} />
        <Route path="/requisitions" element={<Requisitions />} />
        <Route path="/requisitions/wizard" element={<RequisitionWizard />} />
        <Route path="/inventorytransfer" element={<InventoryTransfer />} />

        <Route
          path="/bulkUploadSupplyIngredients"
          element={<BulkUploadSupplyIngredient />}
        />
        <Route path="/UntaggedIngredients" element={<SupplierIngredients />} />

        <Route path="*" element={<ComingSoon />} />
      </Routes>
    </AdminLayout>
  );
};
