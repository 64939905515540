import React from 'react';
import { Input, Select } from 'antd';
import './styles.scss';
import Utils from '../../redux/utils';

type Props = {
  categoryData: any;
  roleData: any;
  designationData: any;
  companyData: any;
  brandsData: any;
  branchesData: any;
  handleCompany: any;
  handleBrand: any;
  handleChangeRole: any;
  userRole: any;
  designation: any;
  company: any;
  branchId: any;
  handleBranchId: any;
  handleChangeDesgnation: any;
  name: any;
  handleName: any;
  handlePhone: any;
  phone: any;
  handleEmail: any;
  email: any;
  handlePassword: any;
  password: any;
  brandId: any;
  errorList?: any;
  errorStatus?: boolean;
  countryCode?: any;
  toggleField?: any;
};
const UserWizardStepOne = (props: Props) => {
  let {
    roleData,
    designationData,
    companyData,
    brandsData,
    branchesData,
    handleCompany,
    handleBrand,
    handleChangeRole,
    userRole,
    designation,
    company,
    handleChangeDesgnation,
    branchId,
    brandId,
    handleBranchId,
    handleName,
    name,
    handlePhone,
    phone,
    handleEmail,
    email,
    password,
    handlePassword,
    errorList,
    errorStatus,
    countryCode,
    // toggleField,
  } = props;
  const user = Utils.getCurrentUser();
  const role_id = user?.roles[0]?.id;
  // const country_code = user?.country_code;
  const companyObject = Utils.getCurrentCompany();

  const company_name = user?.company_name;

  // const selectBefore = (
  //   <Select defaultValue="+92">
  //     <Option value="+92">+92</Option>
  //     <Option value="+93">+93</Option>
  //   </Select>
  // );

  return (
    <div className="userWizardStepOne">
      <div className="Ingredient-head">User Role</div>

      <div className="wizardDropdown">
        <div className="wizardLabel">
          User Role <span className="sterik">*</span>
        </div>

        {user?.roles[0]?.id == 3 ? (
          <Select
            size={'large'}
            placeholder={'Select here'}
            onChange={handleChangeRole}
            style={{ width: 560 }}
            options={roleData}
            value={userRole}
          />
        ) : (
          <span className="userRole">
            <span>{userRole?.label}</span>
          </span>
        )}
      </div>
      <div className="wizardDropdown">
        <div className="wizardLabel">
          Designation <span className="sterik">*</span>
        </div>
        {role_id == 1 ? (
          <span className="userRole">
            <span>{designation[0]?.label}</span>
          </span>
        ) : (
          <>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={handleChangeDesgnation}
              options={designationData}
              value={designation}
              className={
                errorList['designation']?.length > 0
                  ? 'dropdownUnitColorRed'
                  : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['designation']}
              </div>
            )}
          </>
        )}
      </div>
      <div className="wizardDropdown">
        <div className="wizardLabel">
          Company <span className="sterik">*</span>
        </div>
        {role_id === 1 ? (
          <>
            <Select
              size={'large'}
              placeholder={'Select company here'}
              onChange={(e: any) => {
                handleCompany(e);
              }}
              style={{ width: 560 }}
              options={companyData}
              value={company}
              className={
                errorList['company_id']?.length > 0
                  ? 'dropdownUnitColorRed'
                  : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['company_id']}
              </div>
            )}
          </>
        ) : (
          <span>
            <Input
              size={'large'}
              placeholder={'Select company here'}
              onChange={handleCompany(companyObject?.id)}
              disabled={true}
              style={{ width: 560 }}
              value={company_name}
            />
          </span>
        )}
      </div>
      {role_id === 2 || role_id === 1 ? null : (
        <div className="wizardDropdown">
          <div className="wizardLabel">
            Branch <span className="sterik">*</span>
          </div>
          {/* <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            value={branchId}
            onChange={handleBranchId}
            options={branchesData}
          /> */}

          <Select
            style={{ width: '100%' }}
            placeholder="Please select"
            value={branchId}
            onChange={handleBranchId}
            options={branchesData}
          />
        </div>
      )}

      {role_id === 1 ? null : (
        <div className="wizardDropdown">
          <div className="wizardLabel">
            Brand <span className="sterik">*</span>
          </div>
          {role_id == 2 || role_id == 3 ? (
            <Select
              mode={'multiple'}
              style={{ width: '100%' }}
              placeholder="Please select"
              value={brandId}
              onChange={handleBrand}
              options={brandsData}
            />
          ) : (
            /* (
            <Select
              style={{ width: '100%' }}
              placeholder="Please select"
              value={brandId}
              onChange={handleBrand}
              options={brandsData}
            /> ) */
            <Input
              size={'large'}
              placeholder={'Select Branch here'}
              disabled
              style={{ width: 560 }}
              value={Utils.getCurrentBrand()?.brand_name}
            />
          )}
        </div>
      )}
      <div className="Ingredient-head">User Information</div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Name <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter name here"
          size="large"
          value={name}
          onChange={(e) => handleName(e.target.value)}
          required
          className={errorList['name']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['name']}</div>
        )}
      </div>

      {/* <div className="wizardSubContainer">
        <div className="wizardLabel">Phone Number</div>
        <Input
          addonBefore={country_code}
          placeholder="Enter Phone Number"
          value={phone}
          onChange={(e) => handlePhone(Number(e.target.value))}
        />
      </div> */}

      <div className="wizardSubContainer">
        <div className="wizardLabel">Phone Number</div>
        <Input
          addonBefore={countryCode}
          placeholder="Enter Phone Number"
          value={phone}
          onChange={(e) => handlePhone(Number(e.target.value))}
          className={errorList['phone']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['phone']}</div>
        )}
      </div>

      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Email <span className="sterik">*</span>{' '}
        </div>
        <Input
          placeholder="Enter email here"
          size="large"
          type="email"
          value={email}
          onChange={(e) => handleEmail(e.target.value)}
          required
          className={errorList['email']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['email']}</div>
        )}
      </div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Password <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter password here"
          size="large"
          value={password}
          onChange={(e) => handlePassword(e.target.value)}
          required
          className={errorList['password']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['password']}</div>
        )}
      </div>
      {password.length >= 10
        ? ''
        : !errorStatus && (
            <div className="passwordValidation">
              Password at least have 10 characters
            </div>
          )}
    </div>
  );
};

export default UserWizardStepOne;
