import React from 'react';
import { Input } from 'antd';
import './menuCategoryStep.scss';

type Props = {
  handleMenuCategory: any;
  menuCategory: string;
  errorList?: any;
  errorStatus?: boolean;
  component?: any;
};

const MenuCategoryStep = (prop: Props) => {
  let { handleMenuCategory, menuCategory, errorList, errorStatus, component } =
    prop;

  return (
    <React.Fragment>
      <div className="parentContainer">
        <div className="wizardSubContainer">
          <div className="wizardLabel">
            {component == 'subRecipe'
              ? 'Sub Recipe Category Name'
              : component == 'production'
              ? 'Production Category Name'
              : 'Menu Category Name'}{' '}
            <span className="sterik">*</span>
          </div>
          <Input
            placeholder="Enter Category Name"
            size="large"
            value={menuCategory}
            onChange={(e) => {
              handleMenuCategory(e.target.value);
            }}
            required
            className={
              errorList['name']?.length > 0 ? 'borderRed size' : 'size'
            }
          />
          {errorStatus && (
            <div className="subRecipeErrorMessages">{errorList['name']}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default MenuCategoryStep;
