import { baseURL } from '../../config/constant';
import Utils from '../utils';

const RD_Bulk_Upload_SubRecipe = async (file, type) => {
  const brand = await Utils.getCurrentBrand();
  const formData = new FormData();
  formData.set('csv', file);
  formData.set('uploadType', type);
  formData.set('brand_id', brand?.brand_id);
  formData.append('uploadMode', 'minimal');
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/rb_bulk_upload_recipes`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const BulkUploadRDSubRecipes = {
  RD_Bulk_Upload_SubRecipe,
};

export default BulkUploadRDSubRecipes;
