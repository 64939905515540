import React from 'react';
import './styles.scss';
import { Col, Modal, Row, Button } from 'antd';
import { Input } from 'antd';
import locompany from '../../assets/images/locompany.png';
import Utils from '../../redux/utils';
import Buttons from '../button';
// import indofoods from '../../assets/images/Indofoods.png';
// import tickIcon from '../../assets/images/tickIcon.png';
// import Subtract from '../../assets/images/Subtract.png';

type Props = {
  open: boolean;
  handleClose: any;
  data: any;
  component: string;
  acceptPO?: Boolean;
  handleAcceptPO?: any;
  handleChangeData?: any;
  handleResponse?: any;
};
const DetailDialog = (props: Props) => {
  let {
    open,
    handleClose,
    data,
    component,
    acceptPO,
    handleAcceptPO,
    handleChangeData,
    handleResponse,
  } = props;

  const renderSupplier = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Supplier Name</p>
              <p>{data?.supplier_name}</p>
            </div>
            <div className="content-text">
              <p>Company</p>
              <p>
                <img src={locompany} alt="Locompany" />
                {data?.contact_name}
              </p>
            </div>
            <div className="content-text">
              <p>Country</p>
              <p>{data?.country_name}</p>
            </div>
            <div className="content-text">
              <p>Email</p>
              <p>{data?.email}</p>
            </div>
            {/* <div className="content-text">
        <p>Designation</p>
        <p>
          {data?.designation
            ?.map((data: any) => data?.designation)
            .toString()}
        </p>
      </div> */}
            <div className="content-text">
              <p>Phone</p>
              <p>{data?.phone}</p>
            </div>
            <div className="content-text">
              <p>Address</p>
              <p>{data?.address}</p>
            </div>
          </div>
        </li>
        {/* <li>
    <div className="title">User Information</div>
    <div className="content">
      <div className="content-text">
        <p>Name</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Phone number</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div>
  </li> */}
        {/* <li>
    <div className="title">User Access (Permitted Screen Access)</div>
    <ul className="content">
      <li className="content-list">
        <Row>
          <Col md={8}>
            <p>Module</p>
          </Col>

          <Col md={8} className="LimitedAccessCol supplier-table-TickCrossIcons">
            <p>Full Access</p>
          </Col>
          <Col md={8} className="LimitedAccessCol supplier-table-TickCrossIcons">
            <p>
              Limited Access{' '}
              <div className="readOnlyTextSize">(Read Only)</div>
            </p>
          </Col>
        </Row>
      </li>
      {data.permissions?.map((data: any, index: number) => (
        <li className="content-list" key={index}>
          <Row>
            <Col md={8}>
              <p>{data?.permission}</p>
            </Col>

            <Col md={8} className="supplier-table-TickCrossIcons">
              <p>
                {data?.perm_status_id == 2 ? (
                  data?.perm_create == 0 ? (
                    <img src={tickIcon} alt="" />
                  ) : (
                    <p>
                      <p>
                        <img src={tickIcon} alt="" />
                      </p>
                      <div className="readOnlyFontSize">Read Only</div>
                    </p>
                  )
                ) : (
                  <img src={Subtract} alt="" />
                )}
              </p>
            </Col>
            <Col md={8} className="supplier-table-TickCrossIcons">
              <p>
                {data?.perm_status_id == 1 ? (
                  <img src={tickIcon} alt="" />
                ) : (
                  <img src={Subtract} alt="" />
                )}
              </p>
            </Col>
          </Row>
        </li>
      ))}
    </ul>
  </li> */}
      </ul>
    );
  };

  const renderRequisition = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Request Type</p>
              <p>{data?.request_type}</p>
            </div>
            <div className="content-text">
              <p>Request Status</p>
              <p>
                {/* <img src={locompany} alt="Locompany" /> */}
                <div
                  className={`requestStatusContainer ${
                    data?.request_status?.toLowerCase() == 'pending'
                      ? 'inventoryTransfer-request-pending'
                      : data?.request_status?.toLowerCase() == 'approved'
                      ? 'inventoryTransfer-request-approve'
                      : 'inventoryTransfer-request-reject'
                  } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div>
            <div className="content-text">
              <p>Brand</p>
              <p>{data?.brand_name}</p>
            </div>
            <div className="content-text">
              <p>Branch</p>
              <p>{data?.shop_name}</p>
            </div>
          </div>
        </li>
        <li>
          {/* <div className="title">Ingredients</div> */}
          {/* <div className="content">
      <div className="content-text">
        <p>Ingredient</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Request Quantity</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div> */}
        </li>
        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={8}>
                  <p>Ingredient</p>
                </Col>

                <Col
                  md={8}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Request Quantity</p>
                </Col>
                <Col
                  md={8}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Transfer Quantity
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={8}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={8} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.request_quantity}</p>
                  </Col>
                  <Col md={8} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.transfer_quantity ?? 0}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderPurchaseOrder = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Request Type</p>
              <p>{data?.request_type}</p>
            </div>
            <div className="content-text">
              <p>Request Status</p>
              <p>
                {/* <img src={locompany} alt="Locompany" /> */}
                <div
                  className={`requestStatusContainer ${
                    data?.request_status?.toLowerCase() == 'pending'
                      ? 'inventoryTransfer-request-pending'
                      : data?.request_status?.toLowerCase() == 'approved'
                      ? 'inventoryTransfer-request-approve'
                      : 'inventoryTransfer-request-reject'
                  } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div>
            <div className="content-text">
              <p>Supplier Name</p>
              <p>{data?.supplier_name}</p>
            </div>
            <div className="content-text">
              <p>PO FROM</p>
              <p>{data?.po_from_name}</p>
            </div>
            {acceptPO ? (
              <div className="stylingAcceptButton">
                <Button
                  className="theme-button recipeBtn"
                  shape="round"
                  size="middle"
                  onClick={handleAcceptPO}
                >
                  Accept
                </Button>
              </div>
            ) : null}
          </div>
        </li>
        <li>
          {/* <div className="title">Ingredients</div> */}
          {/* <div className="content">
      <div className="content-text">
        <p>Ingredient</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Request Quantity</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div> */}
        </li>
        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={8}>
                  <p>Ingredient</p>
                </Col>

                <Col
                  md={8}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Request Quantity</p>
                </Col>
                <Col
                  md={8}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Unit Cost
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={8}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={8} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.request_quantity}</p>
                  </Col>
                  <Col md={8} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.unit_cost ?? 0}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderCashPurchase = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          {/* <div className="title">User Role</div> */}
          <div className="content">
            <div className="content-text">
              <p>Request Type</p>
              <p>{data?.request_type}</p>
            </div>
            <div className="content-text">
              <p>Request Status</p>
              <p>
                {/* <img src={locompany} alt="Locompany" /> */}
                <div
                  className={`requestStatusContainer ${
                    data?.request_status?.toLowerCase() == 'pending'
                      ? 'inventoryTransfer-request-pending'
                      : data?.request_status?.toLowerCase() == 'approved'
                      ? 'inventoryTransfer-request-approve'
                      : 'inventoryTransfer-request-reject'
                  } `}
                >
                  {data?.request_status}
                </div>
              </p>
            </div>
            <div className="content-text">
              <p>Brand</p>
              <p>{Utils.getCurrentBrand()?.brand_name}</p>
            </div>
            <div className="content-text">
              <p>Branch</p>
              <p>{Utils.getCurrentShop()?.shop_name}</p>
            </div>
          </div>
        </li>
        <li>
          {/* <div className="title">Ingredients</div> */}
          {/* <div className="content">
      <div className="content-text">
        <p>Ingredient</p>
        <p>{data?.name}</p>
      </div>
      <div className="content-text">
        <p>Request Quantity</p>
        <p>{data?.phone}</p>
      </div>
      <div className="content-text">
        <p>Email</p>
        <p>{data?.email}</p>
      </div>
    </div> */}
        </li>
        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={6}>
                  <p>Ingredient</p>
                </Col>

                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Quantity</p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Unit Cost
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={6}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Total Cost
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={6}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.request_quantity}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.unit_cost ?? 0}</p>
                  </Col>
                  <Col md={6} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.total_cost ?? 0}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    );
  };

  const renderStockCount = () => {
    return (
      <ul className="SupplierDetailContainer">
        <li>
          <div className="title">Ingredients</div>
          <ul className="content">
            <li className="content-list">
              <Row>
                <Col md={4}>
                  <p>Ingredient Name</p>
                </Col>

                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>Current Balance</p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Unit
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Updated Quantity
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Deficit
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
                <Col
                  md={4}
                  className="LimitedAccessCol supplier-table-TickCrossIcons"
                >
                  <p>
                    Cash Impact
                    {/* <div className="readOnlyTextSize">(Read Only)</div> */}
                  </p>
                </Col>
              </Row>
            </li>
            {data?.ingredients?.map((ingredient: any, index: number) => (
              <li className="content-list" key={index}>
                <Row>
                  <Col md={4}>
                    <p>{ingredient?.ingredient_name}</p>
                  </Col>

                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>
                      {ingredient?.current_balance
                        ? ingredient?.current_balance
                        : 0}
                    </p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>{ingredient?.unit}</p>
                  </Col>
                  <Col
                    md={4}
                    className="supplier-table-TickCrossIcons font-input-field"
                  >
                    <p>
                      <Input
                        type="number"
                        placeholder="0"
                        value={
                          ingredient?.transfer_quantity
                            ? ingredient?.transfer_quantity
                            : ''
                        }
                        onChange={(e) =>
                          handleChangeData(+e.target.value, index)
                        }
                      />
                    </p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>
                      {ingredient?.deficit
                        ? Number(ingredient?.deficit).toFixed(1)
                        : ingredient?.deficit}
                    </p>
                  </Col>
                  <Col md={4} className="supplier-table-TickCrossIcons">
                    <p>
                      {ingredient?.cash_impact
                        ? Number(ingredient?.cash_impact).toFixed(2)
                        : ingredient?.cash_impact}
                    </p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </li>
        <div className="stock-count-submit-button">
          <Buttons
            text="Submit"
            className="theme-button recipeBtn"
            // icon={<PlusOutlined />}
            handleButtonClick={() => {
              // navigate('wizard', { state: { update: false, id: '' } });
              // setCheck(true);
              // handleClose();
              handleResponse(true);
            }}
            shape="round"
            size="middle"
          />
        </div>
      </ul>
    );
  };
  return (
    <div className="DetailDialog">
      <Modal
        title={
          <div className="title font">
            {component == 'supplier'
              ? 'Supplier Detail'
              : component == 'cash_purchase'
              ? 'Cash Purchase Detail'
              : component == 'purchase_order'
              ? 'Purchase Order Detail'
              : component == 'stock_count'
              ? 'Stock Count'
              : 'Requisition Detail'}
          </div>
        }
        centered
        open={open}
        onCancel={handleClose}
        width={608}
        footer={null}
        className={
          component == 'stock_count' ? 'stock-count-modal-container' : ''
        }
      >
        {component == 'supplier'
          ? renderSupplier()
          : component == 'cash_purchase'
          ? renderCashPurchase()
          : component == 'purchase_order'
          ? renderPurchaseOrder()
          : component == 'stock_count'
          ? renderStockCount()
          : renderRequisition()}
      </Modal>
    </div>
  );
};

export default DetailDialog;
