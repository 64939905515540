/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Checkbox, SelectProps } from 'antd';
import {
  IngredientDialog,
  SubRecipeDropdown,
  SubWizardDropdown,
  WizardDropdown,
} from '../index';
import './recipeStepOne.scss';
import IngredientActions from '../../redux/middleware/ingredient';
import useClickListener from '../../helper/outsideClickListener';
import Utils from '../../redux/utils';

type Props = {
  handleChange: any;
  handleRecipe: any;
  category: any;
  recipe: string;
  proposedPrice: any;
  ingredients: any;
  costRatio: any;
  totalCost: any;
  finalPrice: any;
  blocked: any;
  handleCostRatio: any;
  handleFinalPrice: any;
  handleProposedPrice: any;
  handleTotalCost: any;
  addIngredient: any;
  handleRemoveIngredient: any;
  handleIngredientObject: any;
  handleIngredient: any;
  orderVariation: any;
  handleIngredientOrder: any;
  sizeVariation: any;
  blockedForEdit: any;
  handleVariationName: any;
  handleVariation: any;
  variation: any;
  onChangeMenu: any;
  menu: any;
  handleChangeDatabase: any;
  database: any;
  errorList: any;
  errorStatus: boolean;
};

const RecipeStepOne = (prop: Props) => {
  const brand = Utils.getCurrentBrand();
  let {
    handleChange,
    handleRecipe,
    category,
    recipe,
    blocked,
    proposedPrice,
    costRatio,
    totalCost,
    finalPrice,
    handleCostRatio,
    handleFinalPrice,
    ingredients,
    addIngredient,
    handleRemoveIngredient,
    handleIngredientObject,
    handleIngredient,
    orderVariation,
    handleIngredientOrder,
    sizeVariation,
    handleVariationName,
    handleVariation,
    variation,
    handleProposedPrice,
    handleTotalCost,
    onChangeMenu,
    menu,
    handleChangeDatabase,
    blockedForEdit,
    database,
    errorList,
    errorStatus,
  } = prop;
  const [openIngredientDialog, setOpenIngredientDialog] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState(-1); // For manage ingredient index to remove ingredient_name, when we create ingredient from ingredient dropdown
  const [ingredientName, setIngredientName] = useState();
  const [sortVariation, setSortVariation] = useState<any[]>(sizeVariation);
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: '', key: '' },
  ]);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(true);

  useEffect(() => {
    fetchDatabase();
    if (sizeVariation?.length) setSortVariation(sizeVariation);
  }, [sizeVariation]);

  const handleIngredientOnChange = async (
    data: any,
    index: any,
    key: string
  ) => {
    handleIngredient(data, index, key);
    key == 'ingredient_name' && setIngredientName(data);
  };

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleVariationNames = (e: any) => {
    handleVariationName(e.target.value);
    let results;
    if (e.target.value !== '') {
      results = sizeVariation.filter((item: any) => {
        return item.name
          ?.toLowerCase()
          .includes(e.target.value?.toString().toLowerCase());
      });
      setSortVariation(results);
    } else {
      setSortVariation(sizeVariation);
    }
  };

  const fetchDatabase = async () => {
    const data = await IngredientActions.getDatabase();

    const options: SelectProps['options'] = data?.data?.map((country: any) => {
      return {
        value: country?.db_name,
        label: country?.db_name,
        key: country?.id,
      };
    });

    setOption(options);
  };

  const handleSelectOption = (size: any) => {
    handleVariation(size);
    setIsComponentVisible(false);
  };
  const handleDialog = () => {
    handleIngredientOnChange('', ingredientIndex, 'ingredient_name');
    handleChangeDatabase(database?.value, database);
    setOpenIngredientDialog(false);
  };

  return (
    <React.Fragment>
      {openIngredientDialog ? (
        <IngredientDialog
          handleDialog={handleDialog}
          open={openIngredientDialog}
          component={'recipe'}
          name={ingredientName}
        />
      ) : null}
      <div className="parentContainer">
        <div className="Ingredient-head">Ingredients Information</div>
        <div className="wizardSubContainer">
          <div className="wizardLabel">
            Recipe Name <span className="sterik">*</span>
          </div>
          <Input
            placeholder="Enter Recipe Name"
            size="large"
            value={recipe}
            disabled={blocked}
            onChange={(e) => {
              handleRecipe(e.target.value);
            }}
            required
            className={errorList['recipe_name']?.length > 0 ? 'borderRed' : ''}
          />
          {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )}
        </div>
        <div className="wizardSubContainer">
          <span className="category">
            <div className="wizardLabel">
              Category <span className="sterik">*</span>
            </div>
          </span>
          {/* <WizardDropdown
            component="recipe"
            blocked={blocked}
            text={category}
            handleChangeText={handleChange}
            errorList={errorList}
          /> */}
          <SubWizardDropdown
            component={'recipe'}
            text={category}
            handleChangeText={handleChange}
          />
          {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['menu_category_id']}
            </div>
          )}
        </div>
        {/* <div className="wizardDropdown">
          <div className="wizardLabel">
            Select Database <span className="sterik">*</span>
          </div>
          <Select
            size={'large'}
            defaultValue={database?.label}
            onChange={(e: any, data: any) => {
              handleChangeDatabase(e, data);
            }}
            style={{ width: 560 }}
            options={option}
          />
          </div> */}
        <div className="wizardSubContainer">
          <div className="recipewizardIngredientsLabel">
            <span className="recipeIngredientsLabel">
              <span className="wizardLabel">
                Add Ingredients <span className="sterik">*</span>
              </span>
            </span>
            <span className="label">
              <span className="wizardLabel">
                Qty
                <span className="sterik">*</span>
              </span>
            </span>
            <span className="label">
              <span className="wizardLabel">
                Unit <span className="sterik">*</span>
              </span>
            </span>

            <span className="label3">
              <span className="wizardLabel">
                Cost<span className="sterik">*</span>
              </span>
            </span>
            <span className="label">
              <span className="wizardLabel">
                Order Type<span className="sterik">*</span>
              </span>
            </span>
          </div>
          {ingredients?.map((data: any, index: number) => (
            <SubRecipeDropdown
              handleIngredientOrder={(data: any) =>
                handleIngredientOrder(data, index)
              }
              blocked={blocked}
              blockedForEdit={blockedForEdit}
              orderVariation={orderVariation}
              component="recipe"
              key={index}
              data={data}
              index={index}
              openDialog={() => {
                setOpenIngredientDialog(true);
                setIngredientIndex(index);
              }}
              database={database}
              handleIngredientObject={handleIngredientObject}
              handleIngredientOnChange={handleIngredientOnChange}
              deleteIngredient={handleRemoveIngredient}
            />
          ))}
          {/* {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>

        <div
          className={blocked ? 'stepOneDisableaddButton' : 'stepOneaddButton'}
          onClick={addIngredient}
        >
          + Add New Row
        </div>

        <div className="wizardSubContainer spacing">
          <Checkbox onChange={onChangeMenu} value={menu} checked={menu}>
            Is Variations?
          </Checkbox>
        </div>

        {menu ? (
          <div className="wizardSubContainer">
            <span className="category">
              <div className="wizardLabel">
                Variations <span className="sterik">*</span>
              </div>

              <div className="wizard-form-group" ref={ref}>
                <Input
                  type="text"
                  placeholder="Select Variation"
                  onClick={handleClick}
                  onChange={(e) => handleVariationNames(e)}
                  value={variation?.name}
                />

                {isComponentVisible ? (
                  <ul className="dropdown-list">
                    <li>
                      <ul className="subMenu">
                        <li>
                          <h4 className="placeholder">
                            Select an option or create one
                          </h4>
                        </li>

                        {sortVariation?.map((size: any, index: number) => {
                          return (
                            <li
                              key={index}
                              onClick={() => handleSelectOption(size)}
                            >
                              <p>{size?.name} </p>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                ) : null}
              </div>
            </span>
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </div>
        ) : null}

        <div className="recipeIngredient-head-bold">Ingredients Cost</div>

        <div className="wizardSubContainer">
          <div className="row">
            <span className="col">
              <div className="wizardLabel">Total Cost</div>
              <span className="pricegroup">
                <span>{brand?.currency_name}</span>

                <Input
                  placeholder="Enter Final Price"
                  size="large"
                  disabled
                  value={
                    Number.isNaN(totalCost) ? 0 : Number(totalCost.toFixed(2))
                  }
                  onChange={(e) => handleTotalCost(e.target.value)}
                  required
                />
              </span>
              {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['recipe_name']}
                </div>
              )} */}
            </span>
            <span className="col">
              <div className="wizardLabel">Cost Ratio</div>
              <span className="pricegroup">
                <Input
                  placeholder="Enter Cost Ratio"
                  size="large"
                  type="number"
                  onChange={(e) => handleCostRatio(e.target.value)}
                  disabled={blocked}
                  required
                  value={costRatio != 0 ? costRatio : ''}
                />
                <span>%</span>
              </span>
              {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['recipe_name']}
                </div>
              )} */}
            </span>{' '}
          </div>
        </div>

        <div className="wizardSubContainer">
          <div className="row">
            <span className="col">
              <div className="wizardLabel">Proposed Price</div>
              <span className="pricegroup">
                <span>{brand?.currency_name}</span>
                {/* <div> {proposedPrice}</div> */}
                <Input
                  placeholder="Enter Proposed Price"
                  size="large"
                  disabled={blocked}
                  value={Number(proposedPrice.toFixed(2))}
                  onChange={(e) => handleProposedPrice(e.target.value)}
                  required
                />
              </span>
              {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['recipe_name']}
                </div>
              )} */}
            </span>
            <span className="col">
              <div className="wizardLabel">Final Price</div>
              <span className="finalpricegroup">
                <span>{brand?.currency_name}</span>

                <Input
                  placeholder="Enter Final Price"
                  size="large"
                  value={finalPrice !== 0 ? finalPrice : ''}
                  disabled={blocked}
                  onChange={(e) => handleFinalPrice(e.target.value)}
                  required
                />
              </span>
              {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['recipe_name']}
                </div>
              )} */}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RecipeStepOne;
