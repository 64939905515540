import { baseURL } from '../../config/constant';
import Utils from '../../redux/utils';

const getMAtchUnMatch = async (limit, type, current) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/rb_inventory?database_id=3&limit=${limit}&company_id=${
        Utils.getCurrentCompany()?.id
      }&page=${current}&is_matched=${type}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const searchMAtchUnMatch = async (limit, type, keyword) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/rb_inventory?database_id=3&limit=${limit}&company_id=${
        Utils.getCurrentCompany()?.id
      }&is_matched=${type}&keyword=${keyword}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const getUnmatchdropdown = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/inventory?limit=100&distinct_ingredients=1&company_id=${
        Utils.getCurrentCompany()?.id
      }&is_matched=no&brand_id=${Utils.getCurrentBrand()?.brand_id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const updateUnmatch = async (data, id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/rb_matched_ingredient/${id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'PUT',
      data: data,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const RdMatchingApi = {
  getMAtchUnMatch,
  getUnmatchdropdown,
  updateUnmatch,
  searchMAtchUnMatch,
};

export default RdMatchingApi;
