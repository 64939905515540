import React from 'react';
import { Steps } from 'antd';
import './style.scss';

const steps = [
  {
    title: 'GENERAL',
    description: 'Recipe Information',
  },
  {
    title: 'INGREDIENTS',
    description: 'Select Recipe & Quantity',
  },
  {
    title: 'INSTRUCTIONS',
    description: 'Add instruction & details',
  },
];

const steps2 = [
  {
    title: 'INGREDIENTS',
    description: 'Select Recipe & Quantity',
  },
  {
    title: 'INSTRUCTIONS',
    description: 'Add instruction & details',
  },
];

const userSteps = [
  {
    title: 'USER',
    description: 'User Information Detail',
  },
  {
    title: 'USER ACCESS',
    description: 'Granting Access for User',
  },
];
const Uploadimagestep = [
  {
    title: 'UPLOAD IMAGE',
    description: 'Upload Batch Image',
  },
  {
    title: 'SELECT TAG RECIPE',
    description: 'Choose image with the tag',
  },
];

type Props = {
  stepper: number;
  component: string;
};

const Stepper = (props: Props) => {
  let { stepper, component } = props;
  let stepsmapping =
    component === 'recipeBuilder'
      ? steps
      : component === 'UploadImage'
      ? Uploadimagestep
      : component === 'user'
      ? userSteps
      : steps2;
  return (
    <div
      className={
        component == 'recipeBuilder'
          ? 'container wizard'
          : 'recipeWizardStepperContainer wizard'
      }
    >
      <Steps
        current={stepper}
        items={stepsmapping.map((item) => ({
          key: item.title,
          title: item.title,
          description: item.description,
        }))}
      />
      {/* {stepper < steps.length - 1 && (
        <Button
          onClick={() => handleNextStepper(stepper + 1)}
          className="stepper-button"
        >
          Next
        </Button>
      )}
      {stepper === steps.length - 1 && (
        <Button
          className="stepper-button"
          onClick={() => message.success('Processing complete!')}
        >
          Done
        </Button>
      )}
      {stepper > 0 && (
        <Button
          onClick={() => handleBackStepper(stepper - 1)}
          className="stepper-button stepperButon"
        >
          Previous
        </Button>
      )} */}
    </div>
  );
};

export default Stepper;
