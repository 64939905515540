import React from 'react';
import useClickListener from '../../../helper/outsideClickListener';
import ArrowDown from '../../../assets/images/ArrowDown.png';
import Profile from '../../../assets/images/Profile.png';
import SuperAdminProfile from '../../../assets/images/gologo.jpg';
import Logout from '../../../assets/images/Logout.png';
import Utils from '../../../redux/utils';

const ProfileDropdown = () => {
  const user = Utils.getCurrentUser();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);

  const logoutUser = () => {
    Utils.setCurrentUser();
    Utils.setCurrentToken();
    setIsComponentVisible(false);
    const location = window.location;
    window.location.href = location.origin;
  };

  return (
    <div ref={ref} className="headBlock">
      <div className="profileImage">
        <img
          src={
            user?.user_type === 'Super-Admin'
              ? SuperAdminProfile
              : Utils.getCurrentCompany()?.thumbnail || Profile
          }
          alt=""
          className="subHeadImage"
        />
      </div>
      <div
        className="profileHead pointer"
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <div>
          <div className=" headFont">{user?.name}</div>
          <div className="headButton">
            <div className="headButtonText headFont profileSubText">
              {user?.user_type}
            </div>
          </div>
        </div>
        <img src={ArrowDown} alt="" />
      </div>
      {isComponentVisible && (
        <div className="customLogoutDropdown">
          <div className="subcustomLogoutDropdown">
            <div className="subcustomLogoutDropdownTitle font">
              <div>
                <img
                  src={Utils.getCurrentCompany()?.thumbnail}
                  alt=""
                  className="profileIcon"
                />
              </div>
              <div>{Utils.getCurrentCompany()?.company_name}</div>
            </div>
            <div className="brandBlock">
              <div className="brandBlockTitle font">BRAND</div>
              <div className="brandBlockText">
                {Utils.getCurrentBrand()?.brand_name}
              </div>
            </div>
            <div className="brandBlock">
              <div className="brandBlockTitle font">BRANCH</div>
              <div className="brandBlockText">
                {Utils.getCurrentShop()?.shop_name || ''}
              </div>
            </div>
          </div>
          <div className="logoutBlock" onClick={logoutUser}>
            <img src={Logout} alt="" />
            <div>Logout</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
