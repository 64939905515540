/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  // Select,
  Space,
  Dropdown,
  Checkbox,
  Input,
} from 'antd';
import type { MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Page,
  Pagination,
  Breadcrumb,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
  Button,
  DetailDialog,
  Loader,
} from '../../components';
// import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import Edit from '../../assets/images/Edit.png';
import SupplierApi from '../../redux/middleware/supplier';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Utils from '../../redux/utils';
import './StockCount.scss';

const StockCount = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [current, setCurrent] = useState(1);
  const [stockCount, setStockCount] = useState([{}]);
  const [ingredientList, setIngredientList] = useState([]);
  const [dataLength, setDataLength] = useState(8);
  const [total, setTotal] = useState(0);
  const [totalIngredients, setTotalIngredients] = useState(0);
  const [stockLoader, setStockLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const PageDataLength = [6, 10, 20];
  const [loader, setLoader] = useState(false);
  const [ingredients, setIngredients] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const [stockCountRecord, setStockCountRecord] = useState<any>({});
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const { currentCompany } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    pageData();
  }, [currentCompany]);
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Stock Count'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
    fetchStatus();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, check]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  //   {
  //     key: '2',
  //     label: <div className="dotOption">Edit</div>,
  //     icon: <img src={Edit} width={15} />,
  //   },
  // ];
  const itemsForStock: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Update Stock</div>,
      icon: <img src={Edit} width={15} className="iconDropdown" />,
    },
  ];

  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(
        data?.find((type: any) => type?.slug == 'stock_adjustment')
      );

    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setLoader(true);
        fetchDialogData();
        break;
      case 2:
        // setUpdate(true);
        // setOpenIngredientDialog(true);
        break;
      default:
    }
  };

  const fetchDialogData = async () => {
    const { data, success } = await SupplierApi.getInventorySupplyById(
      stockCountRecord
    );
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const handleChangeData = (value: any, index: any) => {
    // console.log(value, index);
    const ingredientsReplicate = dialogData?.ingredients;
    ingredientsReplicate[index] = {
      ...ingredientsReplicate[index],
      transfer_quantity: value,
      deficit:
        +ingredientsReplicate[index]?.current_balance > value
          ? +ingredientsReplicate[index]?.current_balance - +value
          : 0,
      cash_impact:
        +ingredientsReplicate[index]?.current_balance > value
          ? (+ingredientsReplicate[index]?.current_balance - +value) *
            ingredientsReplicate[index]?.unit_cost
          : 0,
    };
    console.log(ingredientsReplicate);
    setDialogData({ ...dialogData, ingredients: ingredientsReplicate });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>STOCK COUNT ID</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">{record?.id}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any, index: number) => (
        <div
          className={`requestStatusContainer ${
            record?.request_status?.toLowerCase() == 'pending'
              ? 'inventoryTransfer-request-pending'
              : record?.request_status?.toLowerCase() == 'approved'
              ? 'inventoryTransfer-request-approve'
              : 'inventoryTransfer-request-reject'
          } `}
        >
          {record?.request_status}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>RAISED BY</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-override-column2-style">
          {record?.po_from}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED AT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: () => (
        <div className="col3 cellSpacing stockCount-override-column2-style">
          1 day ago
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items: itemsForStock,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setStockCountRecord(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const unTaggedColumns = [
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacing">
          {' '}
          <Checkbox
            onChange={(e: any) => onChangeIngredients(e, record)}
            checked={
              ingredients?.findIndex(
                (ingredient: any) => ingredient?.id == record?.id
              ) != -1
            }
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font ">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title stockCount-alignment-left">
                  {record?.ingredient_name}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CURRENT BALANCE</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-storageInput">
          {/* <Input
            placeholder="Enter Cost"
            type="number"
            value={recordIndex == index && totalCost}
            disabled
            onChange={(e) => setTotalCost(+e.target.value)}
          /> */}
          {record?.current_balance}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.storage_unit}</div>
      ),
    },
  ];

  const onChangeIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e.target.checked) {
      setIngredients([...ingredients, data]);
    } else {
      setIngredients(
        ingredients?.filter((ingredient: any) => ingredient?.id != data?.id)
      );
    }
  };

  const pageData = async () => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'stock_adjustment'
      );
    const imagesData = check
      ? await SupplierApi.getInventorySupply(
          request_id?.id,
          dataLength,
          current,
          shop?.shop_id ? shop?.shop_id : '',
          brand?.brand_id ? brand?.brand_id : ''
        )
      : await SubRecipeActions.getAllSubRecipeInventory(
          3,
          current,
          dataLength,
          brand?.brand_id ? brand?.brand_id : '',
          shop?.shop_id ? shop?.shop_id : ''
        );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setStockCount(imagesData?.data?.items);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalIngredients(imagesData?.data?.pagination?.total);
        setIngredientList(imagesData?.data?.items);
      }
    }
    setStockLoader(false);
  };

  const onSearch = async (keyword: string) => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'stock_adjustment'
      );
    const imagesData = check
      ? await SupplierApi.getInventorySupply(
          request_id?.id,
          dataLength,
          current,
          shop?.shop_id ? shop?.shop_id : '',
          brand?.brand_id ? brand?.brand_id : ''
        )
      : await SubRecipeActions.getSubRecipeInventory(3, keyword);
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setStockCount(imagesData?.data?.items);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalIngredients(imagesData?.data?.pagination?.total);
        setIngredientList(imagesData?.data?.items);
      }
    }
    setStockLoader(false);
  };

  const SubmitStockCount = async (update: boolean) => {
    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const role_id = user?.roles[0]?.id;

    const payload = {
      request_type_id: update ? dialogData?.request_type_id : requestType?.id,
      request_status_id: update ? dialogData?.request_status_id : status?.id,
      brand_id: update ? dialogData?.brand_id : brand?.brand_id,
      shop_id: update ? dialogData?.shop_id : shop?.shop_id,
      company_id: update ? dialogData?.company_id : company?.id,
      created_by: user?.id,
      total_cash_impact: update
        ? dialogData?.ingredients
            .map((data: any) => +data?.transfer_quantity * +data?.deficit)
            .reduce((partialSum: any, a: any) => partialSum + a, 0)
        : 0,
      po_from: update
        ? dialogData?.po_from
        : role_id == 4
        ? 'shop'
        : role_id == 5
        ? 'brand'
        : '',
      ingredients: update
        ? dialogData?.ingredients?.map((data: any) => ({
            ingredient_id: data?.ingredient_id || data?.id,
            request_quantity: data?.transfer_quantity,
            deficit: data?.deficit,
            cash_impact: data?.cash_impact,
          }))
        : ingredients?.map((data: any) => ({
            ingredient_id: data?.ingredient_id || data?.id,
            request_quantity: 0,
            deficit: 0,
            cash_impact: 0,
          })),
    };

    try {
      let responseDialogData;
      setLoader(true);
      const response = update
        ? await SupplierApi.updateInventorySupply(payload, stockCountRecord)
        : await SupplierApi.createInventorySupply(payload);
      setLoader(false);
      setIngredients([]);
      if (response == undefined || !response?.success) {
        responseDialogData = {
          OnClick: () => {
            setOpenDialog(false);
          },
          type: 'error',
          messageArray: [
            // `Your Stock Count`,
            // ` has not been ${update ? 'Updated' : 'Created'}`,
            response?.error?.request_status_id,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        let responseDialogData = {
          OnClick: () => {
            if (update) {
              setOpenDialog(false);
              pageData();
            } else setCheck(true);
          },
          type: 'success',
          messageArray: [
            `Your Stock Count`,
            ` has been ${update ? 'Updated' : 'Created'}`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // const actionButton = (
  //   <Button
  //     text="Add New"
  //     className="theme-button recipeBtn"
  //     icon={<PlusOutlined />}
  //     handleButtonClick={() => {
  //       navigate('wizard', { state: { update: false, id: '' } });
  //     }}
  //     shape="round"
  //     size="middle"
  //   />
  // );

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Stock Count"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/inventory');
        }}
        button={() => {}}
        perm_status={permissionStatus}
      />
      {openDialog && (
        <DetailDialog
          open={openDialog}
          handleClose={handleClose}
          data={dialogData}
          handleChangeData={handleChangeData}
          component={'stock_count'}
          handleResponse={SubmitStockCount}
        />
      )}
      <Spin spinning={loader} size="large">
        <Page title="Stock Count">
          {/* <div className="stockCountHeading">
          <p className="heading"> Supplier Name:</p>
          <h1 className="name">{supplier?.supplier_name}</h1>
        </div> */}
          <Row className="listingTopFilter">
            {/* <Col lg={10}></Col> */}

            <Col lg={16}>
              <div className="StockCount-list-menu-container">
                <div className={check === true ? 'menu' : 'menuLight'}>
                  <div
                    onClick={() => {
                      setCheck(true);
                    }}
                    className="text StockCount-list-menu-style"
                  >
                    Stock Count
                  </div>
                </div>

                <div
                  className={!check ? 'menu' : 'menuLight'}
                  onClick={() => {
                    setCheck(false);
                  }}
                >
                  <div className="text StockCount-list-menu-style">
                    Add Stock Count
                  </div>
                  <div
                    className={`StockCount-list-counter ${
                      +totalIngredients >= 10 && 'StockCount-list-large-counter'
                    }`}
                  >
                    {totalIngredients}
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={8}>
              <div className="search-wrapper">
                <SearchBox action={onSearch} placeholderText="Search..." />

                {!check && !!ingredients.length && (
                  <Button
                    text="Submit"
                    className="theme-button recipeBtn"
                    // icon={<PlusOutlined />}
                    handleButtonClick={() => {
                      SubmitStockCount(false);
                    }}
                    shape="round"
                    size="middle"
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={16} className="StockCount-list-wrapper">
              {!stockLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={''}
                  dataSource={check ? [...stockCount] : [...ingredientList]}
                  columns={check ? taggedColumns : unTaggedColumns}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin
                    spinning={stockLoader}
                    className="recipeLoader"
                    size="large"
                  />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};
export default StockCount;
