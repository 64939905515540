/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  UserListDialog,
  UserList,
  DeleteConfirmation,
  DetailDialog,
} from '../../components';
import UsersApi from '../../redux/middleware/users';
import { sortedData } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.png';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import Company from '../../assets/images/Company.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import Delete from '../../assets/images/Delete.svg';
import SupplierApi from '../../redux/middleware/supplier';
import Utils from '../../redux/utils';
import moment from 'moment';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">Detail</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '2',
    label: <div className="dotOption modifierDropdownLabels">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '3',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const CashPurchaseView = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const company = Utils.getCurrentCompany();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [userLoader, setUserLoader] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const navigate = useNavigate();
  const [selectedRecord, setSelectedRecord] = useState('');
  const [id, setid] = useState();
  const [dialogData, setDialogData] = useState({});
  const [statuses, setStatuses] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          Active
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          Non-active
        </div>
      ),
    },
  ];
  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };
  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      UsersApi.disableUser(id, '1');
      pageData();
    } else {
      UsersApi.disableUser(id, '0');
      pageData();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const fetchStatuses = async () => {
    const { data, success } = await SupplierApi.getSupplyStatus('status');
    if (success) {
      setStatuses(data);
    }
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  };

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>RECEIPT NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="id">{record?.receipt_no}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BRAND</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">{Utils?.getCurrentBrand()?.brand_name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BRANCH</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">{Utils.getCurrentShop()?.shop_name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('roles');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="company">{record?.total_cost}</div>;
      },
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>STATUS</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('roles');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'sub_recipe',
    //   key: 'sub_recipe',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => {
    //     return <div className="company">{record?.request_status}</div>;
    //   },
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>Created At</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('roles');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">
            {moment(UTCtoLocal(record?.created_at)).fromNow()}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={{
            // items: permissionStatus && permissionStatus === 2 ? items : [],
            items: checkTodayDate(record?.created_at)
              ? items
              : items.filter((item: any) => item?.key != '2'),
            onClick: handleDropDownClick,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const checkTodayDate = (recordDate: any) => {
    var stillUtc = moment.utc(recordDate).toDate();
    var recordDateCheck = moment(stillUtc).local().format('YYYY-MM-DD');
    let todayDate = moment(Date()).format('YYYY-MM-DD');
    return recordDateCheck == todayDate ? true : false;
  };
  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const fetchDialogData = async () => {
    const { data, success } = await SupplierApi.getInventorySupplyById(
      selectedRecord
    );
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const setSelectedMenu = (recipe: any) => {
    setSelectedRecord(recipe.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Cash Purchase'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1: {
        setLoader(true);
        fetchDialogData();
        break;
      }
      case 2:
        navigate('wizard', { state: { update: true, id: selectedRecord } });
        break;
      case 3:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    setLoader(true);
    const { success } = await SupplierApi.deleteInventorySupply(
      {
        company_id: company?.id,
      },
      selectedRecord
    );
    setLoader(false);
    if (success) pageData();
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setUserLoader(true);
    let request_id;
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'cash_purchase');
    const requisitionData = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );
    if (requisitionData === undefined || !requisitionData?.success) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(requisitionData?.data?.supply_count);
      setTotal(requisitionData?.data?.pagination?.total);
      setSortData(requisitionData?.data?.items);
    }
    setUserLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setUserLoader(true);
    let request_id;
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'cash_purchase');
    const requisitionData = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );
    if (requisitionData === undefined || !requisitionData?.success) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(requisitionData?.data?.supply_count);
      setTotal(requisitionData?.data?.pagination?.total);
      setSortData(requisitionData?.data?.items);
    }
    setUserLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Cash Purchase"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Cash Purchase">
          <DeleteConfirmation
            handleDeleteCancel={handleDeleteCancel}
            handleDeleteContinue={handleDeleteContinue}
            open={openDeleteDialog}
            message={'Are you sure want to delete cash purchase?'}
          />
          <DetailDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
            component={'cash_purchase'}
          />
          <Row className="listingTopFilter">
            <Col lg={10} md={24}>
              <ListCount count1={userCount} title1="Total Cash Purchase " />
            </Col>
            <Col lg={14} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox action={onSearch} placeholderText="Search..." />
                </div>
                {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export CSV</div>
              </div>
              */}
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!userLoader ? (
                <UserList
                  dataSource={[...sortData]}
                  userColumns={columns}
                  limit={dataLength}
                />
              ) : (
                // <ListViewWithoutExpand
                //   items={items}
                //   dataSource={[...sortData]}
                //   columns={columns}
                // />
                <div className="loader-wrapper">
                  <Spin spinning={userLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default CashPurchaseView;
