import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthRoutes } from './authRoutes';
import { AppRoutes } from './appRoutes';
import './style.scss';
import Utils from '../redux/utils';

export const App = () => {
  const checkProtectedRoute = () => {
    const token = Utils.getCurrentToken();
    if (!token) return '/auth';
    else return '/app';
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={checkProtectedRoute()} />}
        />
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route path="/app/*" element={<AppRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
