import React, { useState, useEffect } from 'react';
import { Checkbox, Collapse } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import ArrowDown from '../../assets/svg/arrowDown.svg';
import './Filter.scss';

type Props = {
  action: Function;
  apiCall: Function;
  component?: string;
};

const Filter = (props: Props) => {
  let { component } = props;
  const { Panel } = Collapse;
  const [checkedListCategory, setCheckedListCategory] = useState<
    CheckboxValueType[]
  >([]);
  const [checkedListCalories, setCheckedListCalories] = useState<
    CheckboxValueType[]
  >([]);
  const [checkedListVerified, setCheckedListVerified] = useState<
    CheckboxValueType[]
  >([]);
  const [checkedListAllergen, setCheckedListAllergen] = useState<
    CheckboxValueType[]
  >([]);

  const [showButton, setShowButton] = useState(false);
  const [pillVlues, setPillVlues] = useState([{ label: '', value: '' }]);
  const [category, setCategory] = useState([{ label: '', value: '' }]);
  const [calories, setCalories] = useState([{ label: '', value: '' }]);
  const [verified, setVerified] = useState([{ label: '', value: '' }]);
  const [allergen, setAllergen] = useState([{ label: '', value: '' }]);
  const [allowFilterRequest, setAllowFilterRequest] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState([
    {
      value: '',
      label: '',
    },
  ]);

  const [caloriesOptions, setCalorieOptions] = useState([
    { label: '', value: '' },
  ]);

  const [allergenOptions, setAllergenOptions] = useState([
    { label: '', value: '' },
  ]);

  const [verifiedOptions, setVerifiedOptions] = useState([
    { label: '', value: '' },
  ]);

  const [categoryFilters, setCategoryFilters] = useState([
    { label: '', value: '' },
  ]);
  const [calorieFilters, setCalorieFilters] = useState([
    { label: '', value: '' },
  ]);
  const [allergenFilters, setAllergenFilters] = useState([
    { label: '', value: '' },
  ]);
  const [verifiedFilters, setVerifiedFilters] = useState([
    { label: '', value: '' },
  ]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (component === 'menu') {
      if (calorieFilters?.length > 1) {
        handleResponseFilter();
      }
    } else {
      if (verifiedFilters?.length > 1) {
        handleResponseFilter();
      }
    }
  }, [allergenFilters]);

  const getFilters = async () => {
    const filters = await props.apiCall();
    setCategoryFilters(filters?.data?.menu_category);
    setCalorieFilters(filters?.data?.calorie);
    setVerifiedFilters(filters?.data?.is_verified);
    setAllergenFilters(filters?.data?.allergen_items);
  };

  const handleResponseFilter = () => {
    if (categoryFilters?.length > 3) {
      setCategoryOptions(categoryFilters?.slice(0, 3));
    } else {
      setCategoryOptions(categoryFilters);
    }

    if (calorieFilters?.length > 3) {
      setCalorieOptions(calorieFilters?.slice(0, 3));
    } else {
      setCalorieOptions(calorieFilters);
    }

    if (verifiedFilters?.length > 3) {
      setVerifiedOptions(verifiedFilters?.slice(0, 3));
    } else {
      setVerifiedOptions(verifiedFilters);
    }

    if (allergenFilters?.length > 3) {
      setAllergenOptions(allergenFilters?.slice(0, 3));
    } else {
      setAllergenOptions(allergenFilters);
    }
  };

  const sendAppliedFilterBack = () => {
    const filter = {
      category_id: checkedListCategory?.join(','),
      is_verified: checkedListVerified?.join(','),
      calorie: checkedListCalories?.join(','),
      allergens_id: checkedListAllergen?.join(','),
    };
    props.action(filter);
  };

  useEffect(() => {
    if (pillVlues.length > 3) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    if (allowFilterRequest) {
      sendAppliedFilterBack();
    }
  }, [pillVlues]);

  function onChangeCategoryValues(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListCategory(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = categoryOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setCategory([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...calories, ...verified, ...allergen]);
  }

  function onChangeCaloriesValue(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListCalories(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = caloriesOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setCalories([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...category, ...verified, ...allergen]);
  }

  function onChangeAllergenValue(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListAllergen(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = allergenOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setAllergen([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...category, ...verified, ...calories]);
  }

  function onChangeVerifiedValues(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListVerified(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = verifiedOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setVerified([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...category, ...calories, ...allergen]);
  }

  const handleClear = () => {
    setAllowFilterRequest(true);
    setPillVlues([
      {
        label: '',
        value: '',
      },
    ]);
    setCheckedListCategory([]);
    setCheckedListCalories([]);
    setCheckedListVerified([]);
    setCheckedListAllergen([]);
    setCalories([
      {
        label: '',
        value: '',
      },
    ]);
    setCategory([
      {
        label: '',
        value: '',
      },
    ]);
    setVerified([
      {
        label: '',
        value: '',
      },
    ]);
    setAllergen([
      {
        label: '',
        value: '',
      },
    ]);
  };

  const handleFilter = (pill: any) => {
    setAllowFilterRequest(true);
    setPillVlues((current) =>
      current.filter((item) => {
        return item?.label !== pill.label;
      })
    );
    let categoryFound = category.some(
      (element) => element.label === pill.label
    );
    if (categoryFound) {
      let catArr = [...category];
      catArr = catArr.filter((item) => {
        return item.label !== pill.label;
      });
      setCategory(catArr);
      setCheckedListCategory(catArr.map((c) => c.value));
    }
    let caloryFound = calories.some((element) => element.label === pill.label);
    if (caloryFound) {
      let calArr = [...calories];
      calArr = calArr.filter((item) => {
        return item.label !== pill.label;
      });
      setCalories(calArr);
      setCheckedListCalories(calArr.map((c) => c.value));
    }
    let verifiedFound = verified.some(
      (element) => element.label === pill.label
    );
    if (verifiedFound) {
      let verArr = [...verified];
      verArr = verArr.filter((item) => {
        return item.label !== pill.label;
      });
      setVerified(verArr);
      setCheckedListVerified(verArr.map((c) => c.value));
    }

    let allergenFound = allergen.some(
      (element) => element.label === pill.label
    );

    if (allergenFound) {
      let allergenArr = [...allergen];
      allergenArr = allergenArr.filter((item) => {
        return item.label !== pill.label;
      });
      setAllergen(allergenArr);
      setCheckedListAllergen(allergenArr.map((c) => c.value));
    }
  };

  const handleMore = (option: string) => {
    switch (option) {
      case 'Category':
        setCategoryOptions(categoryFilters);
        break;
      case 'Calories':
        setCalorieOptions(calorieFilters);
        break;
      case 'Verified':
        setVerifiedOptions(verifiedFilters);
        break;
      case 'Allergen':
        setAllergenOptions(allergenFilters);
        break;

      default:
    }
  };

  const handleLess = (option: string) => {
    switch (option) {
      case 'Category':
        setCategoryOptions(categoryFilters.slice(0, 3));
        break;
      case 'Calories':
        setCalorieOptions(calorieFilters.slice(0, 3));
        break;
      case 'Verified':
        setVerifiedOptions(verifiedFilters.slice(0, 3));
        break;
      case 'Allergen':
        setAllergenOptions(allergenFilters.slice(0, 3));
        break;

      default:
    }
  };

  return (
    <div className="container filterContainer" style={{ minHeight: '765px' }}>
      <div className="topHeading">
        {pillVlues?.length > 3 ? 'Selected Filter' : 'Filter'}
      </div>
      {pillVlues?.map((pill, index) =>
        pill.label !== '' ? (
          <div className="pill" key={index}>
            <div className="filterTitle">{pill?.label}</div>
            <div className="tagStyling" onClick={() => handleFilter(pill)}>
              <CloseOutlined twoToneColor={'white'} className="iconStyling" />
            </div>
          </div>
        ) : null
      )}

      {showButton ? (
        <div className="linkText" onClick={handleClear}>
          Clear all
        </div>
      ) : null}
      <Collapse
        className="filterScroll"
        bordered={false}
        defaultActiveKey={['1', '2', '3', '4']}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? <img src={ArrowDown} /> : <img src={ArrowDown} />
        }
      >
        {categoryOptions && (
          <Panel className="panelStyling" key="1" header="Category">
            <Checkbox.Group
              className="checkBoxStyling"
              options={categoryOptions}
              defaultValue={['Apple']}
              value={checkedListCategory}
              onChange={onChangeCategoryValues}
            />
            {categoryOptions.length > 3 ? (
              <div className="btnShow" onClick={() => handleLess('Category')}>
                ShowLess
              </div>
            ) : (
              <div
                className={categoryOptions.length > 2 ? 'btnShow' : 'btnHide'}
                onClick={() => handleMore('Category')}
              >
                ShowMore
              </div>
            )}
          </Panel>
        )}
        {component !== 'menu' && verifiedOptions ? (
          <Panel key="3" header="Calorie Counted">
            <Checkbox.Group
              options={verifiedOptions}
              defaultValue={['Apple']}
              value={checkedListVerified}
              onChange={onChangeVerifiedValues}
            />
            {verifiedOptions.length > 3 ? (
              <div className="btnShow" onClick={() => handleLess('Verified')}>
                ShowLess
              </div>
            ) : (
              <div
                className={verifiedOptions.length > 2 ? 'btnShow' : 'btnHide'}
                onClick={() => handleMore('Verified')}
              >
                ShowMore
              </div>
            )}{' '}
          </Panel>
        ) : null}
        {caloriesOptions && (
          <Panel className="panelStyling" key="2" header="Calories">
            <Checkbox.Group
              options={caloriesOptions}
              defaultValue={['Apple']}
              value={checkedListCalories}
              onChange={onChangeCaloriesValue}
            />
            {caloriesOptions.length > 3 ? (
              <div className="btnShow" onClick={() => handleLess('Calories')}>
                ShowLess
              </div>
            ) : (
              <div
                className={caloriesOptions.length > 2 ? 'btnShow' : 'btnHide'}
                onClick={() => handleMore('Calories')}
              >
                ShowMore
              </div>
            )}{' '}
          </Panel>
        )}

        {allergenOptions && (
          <Panel className="panelStyling" key="4" header="Allergens">
            <Checkbox.Group
              options={allergenOptions}
              defaultValue={['Apple']}
              value={checkedListAllergen}
              onChange={onChangeAllergenValue}
            />
            {allergenOptions.length > 3 ? (
              <div className="btnShow" onClick={() => handleLess('Allergen')}>
                ShowLess
              </div>
            ) : (
              <div
                className={allergenOptions.length > 2 ? 'btnShow' : 'btnHide'}
                onClick={() => handleMore('Allergen')}
              >
                ShowMore
              </div>
            )}{' '}
          </Panel>
        )}
      </Collapse>
      <div className="buttonContainer">
        <button className="resetBtn" onClick={handleClear}>
          Reset Filter
        </button>
      </div>
    </div>
  );
};

export default Filter;
