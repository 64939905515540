import React from 'react';
import './style.scss';
import { Row, Col, Checkbox, Switch } from 'antd';

type Props = {
  toggleSwitch: any;
  checkBox: any;
  menuHandler: any;
  handleCheckbox: any;
  handleSwitch: any;
  permissions: any;
};

const UserWizardStepTwo = (props: Props) => {
  let {
    toggleSwitch,
    menuHandler,
    handleCheckbox,
    handleSwitch,
    permissions,
    checkBox,
  } = props;

  return (
    <div className="userWizardStepTwo">
      <Row>
        <Col lg={24}>
          <div className="userWizardStepTwo-heading">
            <h3>Select user roles for granting access</h3>
            <Checkbox onChange={menuHandler}>Active All</Checkbox>
          </div>

          <div className="userWizardStepTwo-content">
            {permissions.map((val: any) => {
              return (
                <div className="checkBox-wrapper" key={val.id}>
                  <div>
                    <div className="title">{val.name}</div>
                    {val.perm_status_id === 2 ? (
                      <Checkbox
                        onChange={(e) => handleCheckbox(e, val)}
                        checked={
                          checkBox.filter((data: any) => data == val?.id)
                            .length > 0
                            ? true
                            : false
                        }
                      >
                        Read-only
                      </Checkbox>
                    ) : null}
                  </div>
                  <div className="toggle">
                    <Switch
                      checked={
                        toggleSwitch.filter((data: any) => data == val?.id)
                          .length > 0
                          ? true
                          : false
                      }
                      onChange={(e) => handleSwitch(e, val)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UserWizardStepTwo;
