import React from 'react';
import { Divider, Dropdown, Pagination, Space } from 'antd';
import ArrowDown from '../../assets/images/ArrowDown.png';

type Props = {
  menuProps: any;
  onChange: any;
  current: any;
  dataLength: any;
  handleLength: any;
  total: any;
};

const Paginations = (props: Props) => {
  let { menuProps, onChange, current, dataLength, total } = props;
  return (
    <div className="footer">
      <div className="dropdownContainer">
        <div className="showText font">Showing</div>
        <Dropdown menu={menuProps} trigger={['click']} placement="top">
          <Space>
            <div
              className={`dropdown ${dataLength > 9 ? 'dropdownValue' : ''}`}
            >
              <div className="count font">{dataLength}</div>
              <Divider type="vertical" />
              <img src={ArrowDown} alt="" className="dropdownIcon" />
            </div>
          </Space>
        </Dropdown>
        <div className="showText font">of</div>
        <div className="total font">{total}</div>
      </div>

      <Pagination
        current={current}
        onChange={onChange}
        total={total}
        pageSize={dataLength}
        showSizeChanger={false}
      />
    </div>
  );
};

export default Paginations;
