import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Input, Select, Spin } from 'antd';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import { RcFile } from 'antd/lib/upload';
import CompanyActions from '../../redux/middleware/company';
import Delete from '../../assets/images/Delete.svg';
import './styles.scss';
import ResourcesActions from '../../redux/middleware/resources';

const CompanyWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [image, setImage] = React.useState('');
  const [imageObject, setImageObject] = React.useState(null);
  const [companyName, setCompanyName] = React.useState('');
  const [license, setLicense] = React.useState(0);
  const [costRatio, setCostRatio] = React.useState(0);

  // const [branchArray, setBranchArray] = useState<any>([1]);
  const [country, setCountry] = React.useState<any>({});
  const [Consumption, setConsumption] = React.useState<any>({});

  const fileRef = React.useRef<HTMLInputElement>(null);
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const [countryList, setCountryList] = React.useState([]);
  const [consumptionMethod, setConsumptionMethod] = React.useState([]);
  // const [branchLimit, setBranchLimit] = React.useState([]);
  const [branchLimit, setBranchLimit] = React.useState<any>(0);
  const [centralKitchen, setCentralKitchen] = useState<any>(0);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  const getCountryListing = async () => {
    const response = await ResourcesActions.getCountries(500, 1);
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          value: item?.country_name,
          label: item?.country_name,
          key: item?.id,
        }));
      setCountryList(list);
    }
  };

  const getMethodsListing = async () => {
    const response = await ResourcesActions.getConsumptionMethods(10, 1);
    if (response?.success) {
      const { data } = response;
      const list =
        data?.length &&
        data?.map((item: any) => ({
          value: item?.name,
          label: item?.name,
          key: item?.id,
        }));
      setConsumptionMethod(list);
    }
  };

  const getCompanyInfo = async () => {
    const response = await CompanyActions.getCompanyById(id);
    if (response?.success) {
      const { data } = response;
      setCompanyName(data?.company_name);
      setLicense(data?.license);
      setCountry({
        label: data?.country,
        value: data?.country_id,
        key: data?.country_id,
      });
      setConsumption({
        label: data?.name,
        value: data?.consumption_method_id,
        key: data?.consumption_method_id,
      });
      setImage(data?.thumbnail);
      // setBranchArray(
      //   Array.from(
      //     {
      //       length: data?.license,
      //     },
      //     (_, index) => index + 1
      //   )
      // );
      setBranchLimit(data?.branch_limit);
      setCentralKitchen(data?.ck_limit);
      setCostRatio(data?.cost_ratio);
    }
  };

  React.useEffect(() => {
    getCountryListing();
    getMethodsListing();

    if (update) {
      getCompanyInfo();
    }
  }, []);

  // React.useEffect(() => {
  //   if (!update) {
  //     setBranchLimit(branchArray.fill(0));
  //   }
  // }, [branchArray]);

  const handleBack = () => {};

  const handleContinue = async () => {
    setErrorList({});
    setErrorStatus(false);
    if (
      companyName == '' ||
      (Consumption &&
        Object.keys(Consumption).length === 0 &&
        Object.getPrototypeOf(Consumption) === Object.prototype) ||
      (country &&
        Object.keys(country).length === 0 &&
        Object.getPrototypeOf(country) === Object.prototype) ||
      !license ||
      !branchLimit ||
      !centralKitchen
    ) {
      setVisible(true);
      return;
    }
    const payload = {
      country_id: country?.key,
      consumption_method_id: Consumption?.key,
      company_name: companyName,
      ck_limit: centralKitchen,
      branch_limit: branchLimit,
      license: license,
      cost_ratio: costRatio,
    };
    try {
      let responseDialogData;
      setApiLoader(true);
      let response;
      if (update) {
        const thumbnail = imageObject || image;
        response = await CompanyActions.updateCompany(id, payload, thumbnail);
      } else {
        response = await CompanyActions.addCompany(payload, imageObject);
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        // responseDialogData = {
        //   OnClick: () => {},
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/company');
          },
          type: 'success',
          messageArray: [
            ` Your company - ${companyName}`,
            ` has been ${!update ? 'Created' : 'Updated'}`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePickerChange =
    (fieldName: string) => (value: string | string[], data: any) => {
      console.log(data);
      if (fieldName === 'country') setCountry(data);
      if (fieldName === 'consumption') setConsumption(data);
    };

  // const handleChangeBranchLimit = (e: any, index: number) => {
  //   const limits: any = [...branchLimit];
  //   limits[index] = parseFloat(e?.target?.value || 0);
  //   setBranchLimit(limits);
  // };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Company"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/company');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Company">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Company Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter company name"
                size="large"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                className={
                  errorList['company_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['company_name']}
                </div>
              )}
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Country <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select country'}
                onChange={handlePickerChange('country')}
                style={{ width: 560 }}
                value={country?.label}
                options={countryList}
                className={
                  errorList['country_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['country_id']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Brand <span className="sterik">*</span>
              </div>
              <Input
                type="Number"
                placeholder="Enter number of license"
                size="large"
                value={license ? license : ''}
                min={0}
                onChange={(e) => {
                  setLicense(+e.target.value);
                  // setBranchArray(
                  //   Array.from(
                  //     {
                  //       length: +e.target.value,
                  //     },
                  //     (_, index) => index + 1
                  //   )
                  // );
                }}
                required
              />

              {/* {branchArray?.length > 0 &&
                branchArray?.map((data: any, index: number) => ( */}
              {/* <div key={index}> */}
              <div>
                <div className="wizardLabel">
                  {/* Branch Limit (Brand {index + 1}) */}
                  Branch <span className="sterik">*</span>
                </div>
                <Input
                  min={0}
                  type="Number"
                  placeholder="Enter branch Limit"
                  size="large"
                  value={branchLimit ? branchLimit : ''}
                  onChange={(e) => {
                    setBranchLimit(+e.target.value);
                    if (+e.target.value < centralKitchen)
                      setCentralKitchen(+e.target.value);
                  }}
                  // value={branchLimit[index]}
                  // onChange={(e) => handleChangeBranchLimit(e, index)}
                />
              </div>
              {/* ))} */}
            </div>

            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Central Kitchen Limit <span className="sterik">*</span>
              </div>
              <Input
                min={0}
                placeholder="Enter Central Kitchen"
                size="large"
                type="number"
                value={centralKitchen ? centralKitchen : ''}
                onChange={(e) =>
                  setCentralKitchen(
                    +e.target.value <= branchLimit
                      ? +e.target.value
                      : centralKitchen
                  )
                }
                // required
                className={errorList['ck_limit']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ck_limit']}
                </div>
              )}
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Consumption Method <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select consumption method'}
                onChange={handlePickerChange('consumption')}
                style={{ width: 560 }}
                value={Consumption?.label}
                options={consumptionMethod}
                className={
                  errorList['consumption_method_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['consumption_method_id']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Cost Ratio <span className="sterik">*</span>
              </div>
              <Input
                type="Number"
                placeholder="Enter number of license"
                size="large"
                value={costRatio ? costRatio : ''}
                min={0}
                onChange={(e) => {
                  setCostRatio(+e.target.value);
                  // setBranchArray(
                  //   Array.from(
                  //     {
                  //       length: +e.target.value,
                  //     },
                  //     (_, index) => index + 1
                  //   )
                  // );
                }}
                required
              />

              {/* {branchArray?.length > 0 &&
                branchArray?.map((data: any, index: number) => ( */}
              {/* <div key={index}> */}
            </div>

            <div className="wizardSubContainer checkBox">
              <div className="wizardLabel">Upload Image</div>

              {image ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <img src={image} alt="" className="imageHeight" />
                    <div
                      className="deleteButton"
                      onClick={() => handleImage('', {})}
                    >
                      <img src={Delete} width={15} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="formgroup formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      <p className="ant-upload-text ">
                        Drop your image here or browse
                      </p>
                      <p className="ant-upload-hint">Minimum file 2 MB</p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={async (e: any) => {
                          const response = await getBase64(
                            e.currentTarget.files[0]
                          );
                          if (response) handleImage(response, e);
                        }}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default CompanyWizard;
