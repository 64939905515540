/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Modal, Select, SelectProps, Spin } from 'antd';
import IngredientActions from '../../redux/middleware/ingredient';
import CategoryActions from '../../redux/middleware/category';
import useClickListener from '../../helper/outsideClickListener';
import IngredientCategoryActions from '../../redux/middleware/ingredientCategory';
import Utils from '../../redux/utils';
import SubRecipeActions from '../../redux/middleware/subRecipe';
// import ResponseDialog from '../ResopnseDialog';
import './supplyIngredientDialog.scss';

type Props = {
  handleDialog: any;
  open: boolean;
  component?: string;
  name?: any;
  update?: boolean;
  id?: any;
  blocked?: any;
  handleSuccesDialog?: any;
  handleIngredient?: any;
};

const SupplyIngredientDialog = (props: Props) => {
  let {
    handleDialog,
    open,
    component,
    name,
    update,
    id,
    blocked,
    // handleSuccesDialog,
    // handleIngredient,
  } = props;

  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  const shop = Utils.getCurrentShop();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [ingredient, setIngredient] = useState(name);
  const [category, setCategory] = useState('');
  const [categoryObject, setCategoryObject] = useState<any>();
  const [unit, setUnit] = useState<any>({});
  const [allergen, setAllergen] = useState<any[]>([]);
  const [callory, setCallory] = useState<number>();
  const [carb, setCrab] = useState<number>();
  const [fat, setFat] = useState<number>();
  const [protein, setProtein] = useState<number>();
  const [error, setError] = useState(false);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [sortCategory, setSortCategory] = useState<any[]>([]);
  const [waste, setWaste] = useState<number>();
  const [loader, setLoader] = useState(false);
  const [allergenOptions, setAllergensOptions] = useState<
    SelectProps['options']
  >([{ value: '', label: 0, key: 0 }]);
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: 0, key: 0 },
  ]);

  const fetchData = async () => {
    component == 'rb_marketlist';
    const ingredientObject =
      component == 'rb_marketlist'
        ? await IngredientActions.getRBInventoryById(id)
        : await IngredientActions.getInventoryById(id);
    if (component == 'rb_marketlist') {
      setAllergen(
        ingredientObject?.data[0]?.allergens_id
          ?.split(',')
          ?.map((data: any) => +data)
      );
      setIngredient(ingredientObject?.data[0]?.ingredient_name);
      setCategory(ingredientObject?.data[0]?.category_name);
      setCategoryObject({
        id: ingredientObject?.data[0]?.category_id,
        name: ingredientObject?.data[0]?.category_name,
      });
      setUnit({
        value: ingredientObject?.data[0]?.unit_id,
        label: ingredientObject?.data[0]?.unit,
        key: ingredientObject?.data[0]?.unit_id,
      });

      setCallory(ingredientObject?.data[0]?.calorie);
      setCrab(ingredientObject?.data[0]?.carb);
      setFat(ingredientObject?.data[0]?.fat);
      setProtein(ingredientObject?.data[0]?.protein);
      setWaste(ingredientObject?.data[0]?.waste);
    } else {
      setIngredient(ingredientObject?.data?.ingredient_name);
      setCategory(ingredientObject?.data?.ingredient_category);
      setCategoryObject({
        id: ingredientObject?.data?.ingredient_category_id,
        name: ingredientObject?.data?.ingredient_category,
      });
      setUnit({
        value: ingredientObject?.data?.unit_id,
        label: ingredientObject?.data?.unit,
        key: ingredientObject?.data?.unit_id,
      });

      setAllergen(
        ingredientObject?.data?.allergens_id
          ?.split(',')
          ?.map((data: any) => +data)
      );
      setCallory(ingredientObject?.data?.calorie);
      setCrab(ingredientObject?.data?.carb);
      setFat(ingredientObject?.data?.fat);
      setProtein(ingredientObject?.data?.protein);
      setWaste(ingredientObject?.data?.waste);
    }
    setLoader(false);
  };

  const fetchAllergens = async () => {
    const data = await IngredientActions.getAllergens();
    const allergenOptions: SelectProps['options'] = data?.data.map(
      (allergen: any) => {
        return {
          value: allergen.id,
          label: allergen.name,
          key: allergen.id,
        };
      }
    );
    setAllergensOptions(allergenOptions);
  };

  const fetchUnits = async () => {
    const data =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? await IngredientActions.getUnits()
        : await SubRecipeActions.getSubRecipeUnits();
    const option: SelectProps['options'] =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? data?.data.map((unit: any) => {
            return {
              value: unit.id,
              label: unit.unit,
              key: unit.id,
            };
          })
        : data?.data?.items?.map((unit: any) => {
            return {
              value: unit.parent_unit_id,
              label: unit.parent_unit,
              key: unit.parent_unit_id,
            };
          });
    setOption(option);
  };

  const fetchCategory = async () => {
    const company = await Utils.getCurrentCompany();
    const data =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? await CategoryActions.getIngredientCategory(company?.id)
        : await IngredientCategoryActions.getIngredientCategory(1000);
    setCategoryData(
      data?.data?.items?.filter((val: any) => {
        return (
          val?.name?.toLowerCase() !== 'production' &&
          val?.name?.toLowerCase() !== 'sub recipe'
        );
      })
    );

    setSortCategory(
      data?.data?.items?.filter((val: any) => {
        return (
          val?.name?.toLowerCase() !== 'production' &&
          val?.name?.toLowerCase() !== 'sub recipe'
        );
      })
    );
  };

  useEffect(() => {
    fetchUnits();
    fetchAllergens();
    fetchCategory();
    if (update) {
      fetchData();
      setLoader(true);
    }
  }, []);

  const handleIngredientClick = (data: any) => {
    setCategory(data?.category_name || data?.name);
    setCategoryObject(data);
    setIsComponentVisible(!isComponentVisible);
  };

  const handleChangeUOM = (value: string | string[]) => {
    setUnit(option?.filter((data) => data?.value == value)[0] || {});
  };
  // const handleChangeAllergen = (value: any) => {
  //   setAllergen(value);
  // };

  const handleCategoryName = (e: any) => {
    setCategory(e.target.value);

    if (e.target.value != '') {
      let extractCategory =
        component == 'recipe builder' || component == 'rb_marketlist'
          ? categoryData.filter((item: any) => {
              return item.category_name
                ?.toLowerCase()
                .includes(e.target.value?.toString().toLowerCase());
            })
          : categoryData.filter((item: any) => {
              return item.name
                ?.toLowerCase()
                .includes(e.target.value?.toString().toLowerCase());
            });
      setSortCategory(extractCategory);
    } else {
      setSortCategory(categoryData);
    }
  };

  const handleSubmit = async () => {
    // if (ingredient === '' || category === '' || unit === '') {
    //   setError(true);
    //   return;
    // }
    setErrorStatus(false);
    setErrorList({});
    setError(false);
    let param: any =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? {
            category_id: categoryObject?.id,
            fdc_id: '',
            ingredient_name: ingredient,
            brand_id: brand?.brand_id,
            company_id: company?.id,
            shop_id: shop?.id || 0,
            unit_id: +unit?.value || 0,
            calorie: callory ? callory : 0,
            calorie_unit: 1,
            carb: carb ? carb : 0,
            carb_unit: 3,
            protein: protein ? protein : 0,
            protein_unit: 3,
            fat: fat ? fat : 0,
            fat_unit: 3,
            portion: 100,
            portion_unit: 1,
            allergens_id: allergen?.length > 0 ? allergen.toString() : '',
            is_verified:
              callory == null ||
              callory == 0 ||
              carb == null ||
              carb == 0 ||
              fat == null ||
              fat == 0 ||
              protein == null ||
              protein == 0
                ? 0
                : 1,
            verified_by: 'Head Of Culinary',
            created_by: user?.id,
          }
        : {
            unit_id: +unit?.value || null,
            waste: waste || 0,
            ingredient_category_id: +categoryObject?.id,
            ingredient_name: ingredient,
            product_code: '',
            company_id: company?.id,
            brand_id: Utils.getCurrentBrand()?.brand_id,
            calorie: callory ? callory : 0,
            carb: carb ? carb : 0,
            protein: protein ? protein : 0,
            fat: fat ? fat : 0,
            allergens_id: allergen?.length > 0 ? allergen.toString() : '',
            is_verified:
              callory == null ||
              callory == 0 ||
              carb == null ||
              carb == 0 ||
              fat == null ||
              fat == 0 ||
              protein == null ||
              protein == 0
                ? 0
                : 1,
            verified_by: 'Head Of Culinary',
            created_by: user?.id,
          };
    handleDialog();

    // try {
    //   let responseDialogData;
    //   setLoader(true);
    //   let response: any;
    //   if (update) {
    //     response =
    //       component == 'rb_marketlist'
    //         ? await IngredientActions.updateInventory(param, id)
    //         : await IngredientActions.updateNonRbInventory(param, id);
    //   } else {
    //     response =
    //       component == 'recipe builder' || component == 'rb_marketlist'
    //         ? await IngredientActions.postInventory(param)
    //         : await IngredientActions.createNonRbInventory(param);
    //   }
    //   setLoader(false);
    //   if (!response?.success) {
    //     setErrorList(response?.error || { ingredient_name: response?.message });
    //     setErrorStatus(true);
    //   } else {
    //     responseDialogData = {
    //       OnClick: () => {
    //         component == 'marketlist' || component == 'rb_marketlist'
    //           ? handleSuccesDialog()
    //           : handleDialog();
    //       },
    //       type: 'success',
    //       messageArray: [
    //         ` Your item has been ${
    //           !update ? 'Created' : 'Updated'
    //         }`,
    //       ],
    //     };
    //     ResponseDialog(responseDialogData);
    //     if (component == 'recipe builder') {
    //       handleIngredient(response?.data);
    //     }
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };
  return (
    <Modal
      title={<div className="title font">Create Ingredients</div>}
      centered
      open={open}
      onCancel={handleDialog}
      width={608}
      footer={null}
    >
      <Spin spinning={loader} size="large" className="loaderWidthHeight">
        <div className="supplyIngredientDialog">
          <div className="supplyIngredientDialogSpanWidth supplierLabelContainer">
            <div className="supplyIngredientDialogLabel">
              Supplier <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              defaultValue={'Select Supplier'}
              onChange={handleChangeUOM}
              style={{ width: '100%' }}
              options={option}
              value={unit?.label}
              disabled={blocked}
              className={
                errorList['supplier']?.length > 0
                  ? 'createIngredientBorderRed'
                  : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['supplier']}
              </div>
            )}
            <Checkbox
              className="supplierSelectMargin"
              onClick={(e: any) => {
                // if (dirty) { //I'll explain this later
                //   e.stopPropagation();
                // }
                // setDirty(false);
              }}
              // checked={selectedOptions.includes("john")}
            >
              Mark As Default
            </Checkbox>
          </div>

          <div className="supplyIngredientDialogSubContainer">
            <div className="supplyIngredientDialogLabel">
              Ingredient Name <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input ingredient here"
              size="large"
              value={ingredient}
              disabled={blocked}
              onChange={(e) => setIngredient(e.target.value)}
              className={
                errorList['ingredient_name']?.length > 0 ? 'borderRed' : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['ingredient_name']}
              </div>
            )}
          </div>
          <div className="supplyIngredientDialogSubContainer" ref={ref}>
            <div className="supplyIngredientDialogLabel">
              Ingredient Category <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input category here"
              size="large"
              value={category}
              disabled={blocked}
              onChange={handleCategoryName}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
              className={
                errorList['ingredient_category_id']?.length > 0
                  ? 'borderRed'
                  : ''
              }
            />

            {isComponentVisible && (
              <ul className="dropdownIngredientCreate subMenu">
                <li>
                  <h4 className="placeholder">Select an option</h4>
                </li>

                {sortCategory.map((category: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleIngredientClick(category)}
                    >
                      <p>{category?.category_name || category?.name} </p>
                    </li>
                  );
                })}
              </ul>
            )}
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['ingredient_category_id']}
              </div>
            )}
          </div>
          <div className="supplyIngredientDialogSubContainer UOMAllergen">
            <div className="supplyIngredientDialogSpanWidth">
              <div className="supplyIngredientDialogLabel">
                Buying Unit <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                defaultValue={'Select Buying Unit'}
                // onChange={handleChangeUOM}
                style={{ width: '100%' }}
                options={option}
                // value={unit?.label}
                disabled={blocked}
                className={
                  errorList['unit_id']?.length > 0
                    ? 'createIngredientBorderRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['unit_id']}
                </div>
              )}
            </div>
            <div className="supplyIngredientDialogSpanWidth">
              <div className="supplyIngredientDialogLabel">
                Storage Unit <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                defaultValue={'Select Storage Unit'}
                // onChange={handleChangeUOM}
                style={{ width: '100%' }}
                options={option}
                // value={unit?.label}
                disabled={blocked}
                className={
                  errorList['unit_id']?.length > 0
                    ? 'createIngredientBorderRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['unit_id']}
                </div>
              )}
            </div>
          </div>

          <div className="supplyIngredientDialogSubContainer UOMAllergen">
            <div className="supplyIngredientDialogSpanWidth">
              <div className="supplyIngredientDialogLabel">
                Product Code {/* <span className="sterik">*</span> */}
              </div>
              <Input
                placeholder="Input Product Code here"
                size="large"
                type="number"
                value={waste !== 0 ? waste : ''}
                onChange={(e) => setWaste(+e.target.value)}
                disabled={blocked}
              />
              {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ingredient_category_id']}
                </div>
              )} */}
            </div>
            <div className="supplyIngredientDialogSpanWidth">
              <div className="supplyIngredientDialogLabel">
                Storage Factor {/* <span className="sterik">*</span> */}
              </div>
              <Input
                placeholder="Input Storage Factor"
                size="large"
                type="number"
                value={waste !== 0 ? waste : ''}
                onChange={(e) => setWaste(+e.target.value)}
                disabled={blocked}
              />
              {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ingredient_category_id']}
                </div>
              )} */}
            </div>
          </div>
          <div className="supplyIngredientDialogSpanWidth">
            <div className="supplyIngredientDialogLabel">
              Ingredient Unit {/* <span className="sterik">*</span> */}
            </div>
            <Input
              placeholder="Select Ingredient Unit"
              size="large"
              type="number"
              // value={waste !== 0 ? waste : ''}
              // onChange={(e) => setWaste(+e.target.value)}
              disabled
            />
            {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ingredient_category_id']}
                </div>
              )} */}
          </div>

          {error && <div className="error">*Fill All Feilds</div>}

          {blocked ? null : (
            <div className="butonGroup">
              {/* <div className="wizard-button" onClick={() => handleDialog({})}>
            Back
          </div> */}
              <div className="continue wizard-button" onClick={handleSubmit}>
                Continue
              </div>
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default SupplyIngredientDialog;
