import { Col, Row, Spin } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './Page.module.scss';

type Props = {
  children: React.ReactNode;
  title: string;
  className: string;
  appendCommonTitle: boolean;
  loading?: boolean;
};

export function PurePage(props: Props) {
  const { children, title, className, appendCommonTitle, ...otherProps } =
    props;

  const commonTitle = 'ChefAdmin';
  let pageTitle = title ? `${title} - ${commonTitle}` : commonTitle;

  if (!appendCommonTitle) {
    pageTitle = title;
  }

  return (
    <>
      <div {...otherProps} className={`${styles.container} ${className}`}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {props.loading ? (
          <Row className={styles.centerLoader}>
            <Col>
              <p className={`l2 ${styles.placeholderText}`}>Please wait</p>
            </Col>
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        ) : (
          children
        )}
      </div>
    </>
  );
}

PurePage.defaultProps = {
  title: '',
  className: '',
  appendCommonTitle: true,
};

export default PurePage;
