import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import './variationStep.scss';
import VariationActions from '../../redux/middleware/variation';

type Props = {
  handleVariationChange: any;
  handleRecipe: any;
  recipe: string;
  variation: string;
};

const VariationStep = (prop: Props) => {
  let { handleVariationChange, handleRecipe, variation, recipe } = prop;
  const [category, setCategory] = React.useState<any>([]);
  const data: any = category.filter((val: any) => {
    return val.id === variation;
  });
  const fetchVariationType = async () => {
    const VariationType: any = await VariationActions.getVariationType();

    setCategory(VariationType.data.items);
  };
  useEffect(() => {
    fetchVariationType();
  }, []);
  return (
    <React.Fragment>
      <div className="parentContainer">
        <div className="wizardSubContainer">
          <div className="wizardLabel">
            Variation Type <span className="sterik">*</span>
          </div>
          <Select
            size={'large'}
            placeholder={'Select category'}
            onChange={handleVariationChange}
            style={{ width: 560 }}
            optionFilterProp="children"
            value={data[0]?.name}
          >
            {category?.map((data: any, index: number) => (
              <Select.Option value={data?.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="wizardSubContainer wrapper">
          <div className="wizardLabel">
            Variation Name <span className="sterik">*</span>
          </div>
          <Input
            placeholder="Enter Variation Name"
            size="large"
            value={recipe}
            onChange={(e) => {
              handleRecipe(e.target.value);
            }}
            required
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default VariationStep;
