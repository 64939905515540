/* Menu Icons */
import DashboardIcon from '../../assets/icons/sidebar/Dashboard.svg';
import MenuIcon from '../../assets/icons/sidebar/Menu.svg';
import RecipeIcon from '../../assets/icons/sidebar/RecipeBuilder.svg';
import SubRecipeIcon from '../../assets/icons/sidebar/SubMenu.svg';
import ProductionIcon from '../../assets/icons/sidebar/Production.svg';
import SupplyIcon from '../../assets/icons/sidebar/Supply.svg';
import InventoryIcon from '../../assets/icons/sidebar/Inventory.svg';
import WastageIcon from '../../assets/icons/sidebar/Wastage.svg';
import ReportIcon from '../../assets/icons/sidebar/Report.svg';
import SettingsIcon from '../../assets/icons/sidebar/Setting.svg';
import RBIcon from '../../assets/icons/sidebar/RBIcon.jpg';

const appRoute = 'app/';

// export const Menu = [
//   {
//     id: 1,
//     routeName: 'Dashboard',
//     text: ``,
//     icon: DashboardIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Dashboard',
//         path: `${appRoute}Dashboard`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 3,
//     routeName: 'Menu',
//     childRouteName: [
//       {
//         id: 4,
//         routeName: 'Menu',
//         path: `${appRoute}menu`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 5,
//         routeName: 'Manage Menu',
//         path: `${appRoute}manageMenu`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 6,
//         routeName: 'Combos',
//         path: `${appRoute}combos`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 8,
//         routeName: 'Modifiers',
//         path: `${appRoute}modifiers`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//     text: ``,
//     icon: MenuIcon,
//     hasChild: true,
//   },
//   {
//     id: 43,
//     routeName: 'R&D',
//     text: ``,
//     icon: ReportIcon,
//     childRouteName: [
//       {
//         id: 44,
//         routeName: 'R&D Recipe List',
//         path: `${appRoute}rd-recipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D SubRecipe List',
//         path: `${appRoute}rd-subrecipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D MarketList',
//         path: `${appRoute}rd-marketlist`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D Matching',
//         path: `${appRoute}ingredientlist`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 9,
//     routeName: 'Recipe',
//     childRouteName: [
//       {
//         id: 10,
//         routeName: 'Recipe Builder',
//         path: `${appRoute}recipeBuilder`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 11,
//         routeName: 'Recipe',
//         path: `${appRoute}recipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 12,
//         routeName: 'Upload Recipes',
//         path: `${appRoute}uploadRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 13,
//         routeName: 'Upload Images',
//         path: `${appRoute}uploadimage`,
//         text: ``,
//         hasChild: false,
//       },
//       /* {
//         id: 12,
//         routeName: 'Bulk Upload Recipe Builder',
//         path: `${appRoute}uploadRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 13,
//         routeName: 'Upload Recipe Builder Images',
//         path: `${appRoute}uploadimage`,
//         text: ``,
//         hasChild: false,
//       }, */
//     ],
//     icon: RecipeIcon,
//     text: ``,
//     hasChild: true,
//   },
//   {
//     id: 14,
//     routeName: 'Sub Recipe',
//     childRouteName: [
//       {
//         id: 15,
//         routeName: 'Sub Recipe',
//         path: `${appRoute}subrecipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 16,
//         routeName: 'Upload Sub Recipe',
//         path: `${appRoute}uploadbulkSubRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 17,
//         routeName: 'Sub Recipe Images',
//         path: `${appRoute}uploadSubRecipes`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//     text: ``,
//     icon: SubRecipeIcon,
//     hasChild: true,
//   },
//   {
//     id: 18,
//     routeName: 'Production',
//     childRouteName: [
//       {
//         id: 19,
//         routeName: 'Production Recipe',
//         path: `${appRoute}productionrecipe`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 20,
//         routeName: 'Production Inventory',
//         path: `${appRoute}productionInventory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 21,
//         routeName: 'Consumptions',
//         path: `${appRoute}productionConsumption`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//     text: ``,
//     icon: ProductionIcon,
//     hasChild: true,
//   },
//   {
//     id: 22,
//     routeName: 'Supply',
//     text: ``,
//     icon: SupplyIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 23,
//         routeName: 'Supply',
//         path: `${appRoute}supply`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 24,
//         routeName: 'Inventory Transfer',
//         path: `${appRoute}inventorytransfer`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 25,
//         routeName: 'Requisitions',
//         path: `${appRoute}requisitions`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 26,
//         routeName: 'Purchase Orders',
//         path: `${appRoute}purchaseorders`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 27,
//         routeName: 'Purchase Transactions',
//         path: `${appRoute}purchasetransactions`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 28,
//     routeName: 'Inventory',
//     text: ``,
//     icon: InventoryIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 29,
//         routeName: 'Inventory',
//         path: `${appRoute}inventory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 30,
//         routeName: 'Add Stock',
//         path: `${appRoute}addStock`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 31,
//         routeName: 'Stock Count',
//         path: `${appRoute}stockCount`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 32,
//     routeName: 'Wastage',
//     text: ``,
//     icon: WastageIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Wastage',
//         path: `${appRoute}wastage`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 33,
//     routeName: 'Market List',
//     text: ``,
//     icon: InventoryIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Market List',
//         path: `${appRoute}marketList`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 42,
//         routeName: 'Upload Market List',
//         path: `${appRoute}uploadMarketList`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 34,
//     routeName: 'Reports',
//     text: ``,
//     icon: ReportIcon,
//     childRouteName: [
//       {
//         id: 2,
//         routeName: 'Reports',
//         path: `${appRoute}reports`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
//   {
//     id: 35,
//     routeName: 'Settings',
//     text: ``,
//     icon: SettingsIcon,
//     hasChild: true,
//     childRouteName: [
//       {
//         id: 36,
//         routeName: 'Company',
//         path: `${appRoute}company`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 37,
//         routeName: 'Branch',
//         path: `${appRoute}branches`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 38,
//         routeName: 'Brands',
//         path: `${appRoute}brands`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 39,
//         routeName: 'Users',
//         path: `${appRoute}users`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 40,
//         routeName: 'Menu Category',
//         path: `${appRoute}menuCategory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 41,
//         routeName: 'Ingredient Category',
//         path: `${appRoute}ingredientCategory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D Ingredient Category',
//         path: `${appRoute}rd-ingredientCategory`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'R&D Menu Category',
//         path: `${appRoute}rd-menuCategory`,
//         text: ``,
//         hasChild: false,
//       },

//       {
//         id: 7,
//         routeName: 'Variations',
//         path: `${appRoute}variations`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 42,
//         routeName: 'Feedback',
//         path: `${appRoute}feedback`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 43,
//         routeName: 'Screens',
//         path: `${appRoute}screens`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 44,
//         routeName: 'User Access',
//         path: `${appRoute}userAccess`,
//         text: ``,
//         hasChild: false,
//       },
//       {
//         id: 45,
//         routeName: 'Lead Generation',
//         path: `${appRoute}leadGeneration`,
//         text: ``,
//         hasChild: false,
//       },
//     ],
//   },
// ];

export const Menu = [
  {
    id: 1,
    routeName: 'Dashboard',
    text: ``,
    icon: DashboardIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 2,
        routeName: 'Dashboard',
        path: `${appRoute}Dashboard`,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 43,
    routeName: 'R&D',
    text: ``,
    icon: RBIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 44,
        routeName: 'R&D Recipe',
        path: `${appRoute}r&d-recipe`,
        text: ``,
        hasChild: false,
      },
      {
        id: 44,
        routeName: 'R&D Sub Recipe',
        path: `${appRoute}r&d-subrecipe`,
        text: ``,
        hasChild: false,
      },
      {
        id: 44,
        routeName: 'R&D Market List',
        path: `${appRoute}R&DmarketList`,
        text: ``,
        hasChild: false,
      },
      {
        id: 44,
        routeName: 'R&D Matching',
        path: `${appRoute}ingredientlist`,
        text: ``,
        hasChild: false,
      },
    ],
  },

  // {
  //   id: 43,
  //   routeName: 'R&D - Tab',
  //   text: ``,
  //   icon: ReportIcon,
  //   childRouteName: [
  //     {
  //       id: 44,
  //       routeName: 'R&D Recipe List',
  //       path: `${appRoute}rd-recipe`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 44,
  //       routeName: 'R&D SubRecipe List',
  //       path: `${appRoute}rd-subrecipe`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 44,
  //       routeName: 'R&D MarketList',
  //       path: `${appRoute}rd-marketlist`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 44,
  //       routeName: 'R&D Matching',
  //       path: `${appRoute}ingredientlist`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  // },
  {
    id: 9,
    routeName: 'Recipe',
    childRouteName: [
      // {
      //   id: 10,
      //   routeName: 'Recipe Builder',
      //   path: `${appRoute}recipeBuilder`,
      //   text: ``,
      //   hasChild: false,
      // },
      {
        id: 11,
        routeName: 'Recipe',
        path: `${appRoute}recipes`,
        text: ``,
        hasChild: false,
      },
      {
        id: 12,
        routeName: 'Upload Recipes',
        path: `${appRoute}uploadRecipes`,
        text: ``,
        hasChild: false,
      },
      {
        id: 13,
        routeName: 'Recipe Images',
        path: `${appRoute}uploadimage`,
        text: ``,
        hasChild: false,
      },
      /* {
        id: 12,
        routeName: 'Bulk Upload Recipe Builder',
        path: `${appRoute}uploadRecipes`,
        text: ``,
        hasChild: false,
      },
      {
        id: 13,
        routeName: 'Upload Recipe Builder Images',
        path: `${appRoute}uploadimage`,
        text: ``,
        hasChild: false,
      }, */
    ],
    icon: RecipeIcon,
    text: ``,
    hasChild: true,
  },
  {
    id: 14,
    routeName: 'Sub Recipe',
    childRouteName: [
      {
        id: 15,
        routeName: 'Sub Recipe',
        path: `${appRoute}subrecipe`,
        text: ``,
        hasChild: false,
      },
      {
        id: 16,
        routeName: 'Upload Sub Recipe',
        path: `${appRoute}uploadbulkSubRecipes`,
        text: ``,
        hasChild: false,
      },
    ],
    text: ``,
    icon: SubRecipeIcon,
    hasChild: true,
  },
  {
    id: 18,
    routeName: 'Production',
    childRouteName: [
      {
        id: 19,
        routeName: 'Production Recipe',
        path: `${appRoute}productionrecipe`,
        text: ``,
        hasChild: false,
      },
      {
        id: 20,
        routeName: 'Production Inventory',
        path: `${appRoute}productionInventory`,
        text: ``,
        hasChild: false,
      },
      {
        id: 21,
        routeName: 'Consumptions',
        path: `${appRoute}productionConsumption`,
        text: ``,
        hasChild: false,
      },
    ],
    text: ``,
    icon: ProductionIcon,
    hasChild: true,
  },
  {
    id: 22,
    routeName: 'Supply',
    text: ``,
    icon: SupplyIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 25,
        routeName: 'Requisitions',
        path: `${appRoute}requisitions`,
        text: ``,
        hasChild: false,
      },

      {
        id: 24,
        routeName: 'Inventory Transfer',
        path: `${appRoute}inventorytransfer`,
        text: ``,
        hasChild: false,
      },
      {
        id: 26,
        routeName: 'Purchase Orders',
        path: `${appRoute}purchaseOrder`,
        text: ``,
        hasChild: false,
      },
      {
        id: 23,
        routeName: 'Supplier List',
        path: `${appRoute}supply`,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 28,
    routeName: 'Inventory',
    text: ``,
    icon: InventoryIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 29,
        routeName: 'Inventory List',
        path: `${appRoute}inventory`,
        text: ``,
        hasChild: false,
      },
      {
        id: 31,
        routeName: 'Stock Count',
        path: `${appRoute}stockCount`,
        text: ``,
        hasChild: false,
      },
      // {
      //   id: 46,
      //   routeName: 'Upload Ingredients',
      //   path: `${appRoute}uploadIngredients`,
      //   text: ``,
      //   hasChild: false,
      // },
    ],
  },
  {
    id: 3,
    routeName: 'Menu',
    childRouteName: [
      {
        id: 4,
        routeName: 'Menu List',
        path: `${appRoute}menu`,
        text: ``,
        hasChild: false,
      },
      {
        id: 5,
        routeName: 'Manage menu',
        path: `${appRoute}manageMenu`,
        text: ``,
        hasChild: false,
      },
      {
        id: 6,
        routeName: 'Combos',
        path: `${appRoute}combos`,
        text: ``,
        hasChild: false,
      },
      {
        id: 8,
        routeName: 'Modifiers',
        path: `${appRoute}modifiers`,
        text: ``,
        hasChild: false,
      },
    ],
    text: ``,
    icon: MenuIcon,
    hasChild: true,
  },

  {
    id: 32,
    routeName: 'Wastage',
    text: ``,
    icon: WastageIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 2,
        routeName: 'Wastage List',
        path: `${appRoute}wastage`,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 34,
    routeName: 'Reports',
    text: ``,
    icon: ReportIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 2,
        routeName: 'Consumptions',
        path: `${appRoute}reports`,
        text: ``,
        hasChild: false,
      },
    ],
  },
  {
    id: 35,
    routeName: 'Settings',
    text: ``,
    icon: SettingsIcon,
    hasChild: true,
    childRouteName: [
      {
        id: 36,
        routeName: 'Company',
        path: `${appRoute}company`,
        text: ``,
        hasChild: false,
      },
      {
        id: 38,
        routeName: 'Brand',
        path: `${appRoute}brands`,
        text: ``,
        hasChild: false,
      },

      {
        id: 37,
        routeName: 'Branch',
        path: `${appRoute}branches`,
        text: ``,
        hasChild: false,
      },
      {
        id: 39,
        routeName: 'Users',
        path: `${appRoute}users`,
        text: ``,
        hasChild: false,
      },
      // {
      //   id: 40,
      //   routeName: 'Menu Category',
      //   path: `${appRoute}menuCategory`,
      //   text: ``,
      //   hasChild: false,
      // },
      // {
      //   id: 41,
      //   routeName: 'Ingredient Category',
      //   path: `${appRoute}ingredientCategory`,
      //   text: ``,
      //   hasChild: false,
      // },
      {
        id: 44,
        routeName: 'Categories',
        path: `${appRoute}menuCategory`,
        text: ``,
        hasChild: false,
      },
      {
        id: 44,
        routeName: 'R&D Categories',
        path: `${appRoute}rd-menuCategory`,
        text: ``,
        hasChild: false,
      },

      // {
      //   id: 44,
      //   routeName: 'R&D Ingredient Category',
      //   path: `${appRoute}rd-ingredientCategory`,
      //   text: ``,
      //   hasChild: false,
      // },

      {
        id: 7,
        routeName: 'Variations',
        path: `${appRoute}variations`,
        text: ``,
        hasChild: false,
      },
      {
        id: 42,
        routeName: 'Feedback',
        path: `${appRoute}feedback`,
        text: ``,
        hasChild: false,
      },
      {
        id: 45,
        routeName: 'Lead Generation',
        path: `${appRoute}leadGeneration`,
        text: ``,
        hasChild: false,
      },
      {
        id: 44,
        routeName: 'User Access',
        path: `${appRoute}userAccess`,
        text: ``,
        hasChild: false,
      },
      {
        id: 43,
        routeName: 'Screens',
        path: `${appRoute}screens`,
        text: ``,
        hasChild: false,
      },
      {
        id: 43,
        routeName: 'Buying Unit',
        path: `${appRoute}buyingUnit`,
        text: ``,
        hasChild: false,
      },
    ],
  },

  // {
  //   id: 33,
  //   routeName: 'Market List - Tab',
  //   text: ``,
  //   icon: InventoryIcon,
  //   hasChild: true,
  //   childRouteName: [
  //     {
  //       id: 2,
  //       routeName: 'Market List',
  //       path: `${appRoute}marketList`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //     {
  //       id: 42,
  //       routeName: 'Upload Market List',
  //       path: `${appRoute}uploadMarketList`,
  //       text: ``,
  //       hasChild: false,
  //     },
  //   ],
  // },
];

export default Menu;
