import React, { useEffect, useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import Delete from '../../assets/images/Delete.svg';
import './style.scss';
import { Alert } from 'antd';
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
type Props = {
  image: any;
  imageObject: any;
  handleImage: any;
  handleDelete: any;
};
const UploadImageStepOne = (props: Props) => {
  let { image, imageObject, handleImage, handleDelete } = props;
  const [visible, setVisible] = useState(false);
  const fileRef = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    setVisible(false);
  }, [image]);

  return (
    <React.Fragment>
      {visible && (
        <Alert
          message="Error"
          description={'File format is Invalid. Please Select Correct File!'}
          type="error"
          showIcon
          closable
          className="wizardAlert"
          afterClose={() => setVisible(false)}
        />
      )}
      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">
          Upload Image <span className="sterik">*</span>
        </div>
        <div className="formgroup formgroupStepOne">
          <div className="label-wrapper">
            <label>
              <p className="ant-upload-text ">Drop your image here or browse</p>
              <p className="ant-upload-hint">Minimum file 2 MB</p>
              <input
                ref={fileRef}
                type="file"
                multiple
                accept=".png,.jpg,.jpeg"
                onChange={async (e: any) => {
                  const data = e.currentTarget.files;
                  let res = Object.keys(e.currentTarget.files).map(
                    (key: any) => data[key]
                  );
                  const resp = res.map(async (data: any) => {
                    const getDetail = await getBase64(data);
                    return getDetail;
                  });
                  if (
                    res?.some(
                      (item) =>
                        item?.type !== 'image/jpeg' &&
                        item?.type !== 'image/jpg' &&
                        item?.type !== 'image/png'
                    )
                  ) {
                    setVisible(true);
                  } else {
                    Promise.all(resp).then((values) => {
                      handleImage(values, e);
                    });
                  }
                }}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="containerImage">
        {image.length !== 0 &&
          image.map((data: any, index: number) => {
            return (
              <div className="uploadedimage" key={index}>
                <div className="uploadRecipeImgWrapperStepOne">
                  <img src={data} width="52" height="52" />
                  <div className="text">
                    <span>{imageObject[index]?.data?.name}</span>
                    <span>Uploaded</span>
                  </div>
                </div>

                <div className="deletebutton">
                  <img
                    src={Delete}
                    width={15}
                    onClick={() => {
                      handleDelete(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};
export default UploadImageStepOne;
