import { useState, useEffect } from 'react';
import { Dropdown, Button, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import ProfileDropdown from './subComponent/ProfileDropdown';
import ArrowDownBlack from '../../assets/images/ArrowDownBlack.png';
import './style.scss';
import Utils from '../../redux/utils';
import { NotificationOutlined } from '@ant-design/icons';
import { FeedBackDialog } from '../../components';
import { useDispatch } from 'react-redux';
import {
  UpdateBrand,
  UpdateCompany,
  UpdateShop,
} from '../../redux/reducers/authSlice';
// import { FeedBackDialog } from '../../components';

const GlobalHead = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const brandList = Utils.getBrandList();
  const companyList = Utils.getCompanyList();
  const shopList = Utils.getShopList();
  const dispatch = useDispatch();
  // const [feedback, setFeedBack] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState(Utils.getCurrentCompany());
  const [brand, setBrand] = useState(Utils.getCurrentBrand());
  const [branch, setBranch] = useState(Utils.getCurrentShop());
  // const feedbackBtn = () => {
  //   setFeedBack(!feedback);
  // };

  useEffect(() => {
    if (Utils.getCurrentCompany()?.company_name !== company?.company_name) {
      Utils.setCurrentCompany(company);
      Utils.setBrandList(company?.brands);
      setBrand(company?.brands[0]);
      dispatch(UpdateCompany(company));
    }
  }, [company]);

  useEffect(() => {
    if (Utils.getCurrentBrand()?.brand_name !== brand?.brand_name) {
      if (brand?.shops) {
        setBranch(brand?.shops[0]);
      }
      Utils.setCurrentBrand(brand);
      dispatch(UpdateBrand(brand));
    }
  }, [brand]);

  useEffect(() => {
    Utils.setCurrentShop(branch);
    dispatch(UpdateShop(branch));
  }, [branch]);

  const item1: MenuProps['items'] =
    brandList &&
    brandList.map((item: any) => {
      return {
        key: item?.brand_id,
        label: (
          <div className="dotOptionMenu" onClick={() => setBrand(item)}>
            {item?.brand_name}
          </div>
        ),
        icon: <img src={item?.thumbnail} className="menuImage" />,
      };
    });

  const item2: MenuProps['items'] =
    companyList &&
    companyList.map((item: any) => {
      return {
        key: item?.company_id,
        label: (
          <div className="dotOptionMenu" onClick={() => setCompany(item)}>
            {item?.company_name}
          </div>
        ),
        icon: <img src={item?.thumbnail} className="menuImage" />,
      };
    });

  const item3: MenuProps['items'] =
    shopList &&
    shopList.map((item: any) => {
      return {
        key: item?.shop_id,
        label: (
          <div className="dotOptionMenu" onClick={() => setBranch(item)}>
            {item?.shop_name}
          </div>
        ),
      };
    });

  // eslint-disable-next-line no-unused-vars
  const handleMenuClick = async (e: any) => {};

  const menuProps = {
    items: item2,
    onClick: handleMenuClick,
  };

  const menuProps2 = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuProps3 = {
    items: item3,
    onClick: handleMenuClick,
  };

  const [feedback, setFeedBack] = useState(false);
  const feedbackBtn = () => {
    setFeedBack(!feedback);
  };

  const handleClose = () => {
    setFeedBack(false);
  };

  return (
    <div className="headcontainer">
      <div className="subHeadContainer">
        <div
          className={
            user?.user_type === 'Super-Admin'
              ? 'companyBlockWidthHidden'
              : 'companyBlockWidth'
          }
        >
          <div className="headBlock">
            <div className="headDiv headImage">
              <img src={company?.thumbnail} alt="" className="subHeadImage" />
            </div>
            <div>
              <div className="font headTitle">COMPANY</div>
              <Dropdown
                overlayClassName="DropdownHeader"
                menu={menuProps}
                trigger={['click']}
                placement="bottomRight"
                arrow={false}
                disabled={companyList?.length <= 1}
              >
                <div className="headButton">
                  <div className="headButtonText headFont">
                    {company?.company_name}
                  </div>
                  <img src={ArrowDownBlack} alt="" className="pointer" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        {shopList && shopList.length > 0 && (
          <div
            className={
              user?.user_type === 'Super-Admin'
                ? 'branchBlockWidthHidden'
                : 'branchBlockWidth'
            }
          >
            <div className="font headTitle">BRANCH</div>
            <Dropdown
              arrow={false}
              overlayClassName="DropdownHeader"
              menu={menuProps3}
              trigger={['click']}
              placement="bottomRight"
              disabled={shopList?.length <= 1}
            >
              <div className="headButton">
                <div className="headButtonText headFont">
                  {branch?.shop_name}
                </div>{' '}
                <img src={ArrowDownBlack} alt="" className="pointer" />
              </div>
            </Dropdown>
          </div>
        )}
        {brandList && brandList.length > 0 && (
          <div
            className={
              user?.user_type === 'Super-Admin'
                ? 'brandBlockWidthHidden'
                : 'brandBlockWidth'
            }
          >
            <div className="headBlock headImage">
              <img src={brand?.thumbnail} alt="" className="subHeadImage" />
              <div>
                <div className="font headTitle">BRAND</div>
                <Dropdown
                  overlayClassName="DropdownHeader"
                  menu={menuProps2}
                  trigger={['click']}
                  placement="bottomRight"
                  arrow={false}
                  disabled={brandList?.length <= 1}
                >
                  <div className="headButton">
                    <div className="headButtonText headFont">
                      {brand?.brand_name}
                    </div>
                    <img src={ArrowDownBlack} alt="" className="pointer" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="secondSubHeadContainer">
        <div className="feedBackButton">
          <Tooltip
            title={
              <div>
                <div>Give FeedBack</div>
                {/* <div className="tootipText">{record?.description}</div> */}
              </div>
            }
          >
            {' '}
            <Button onClick={() => feedbackBtn()}>
              <NotificationOutlined />
              {/* Give FeedBack */}
            </Button>
          </Tooltip>
          <FeedBackDialog
            feedback={feedback}
            feedbackBtn={feedbackBtn}
            handleClose={handleClose}
          />
        </div>

        <ProfileDropdown />
      </div>
      {/* <FeedBackDialog feedback={feedback} feedbackBtn={feedbackBtn} /> */}
    </div>
  );
};

export default GlobalHead;
