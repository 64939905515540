/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
// import TagOutlined from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  UserList,
  SupplyIngredientDialog,
  DeleteConfirmation,
  ResponseDialog,
  DetailDialog,
} from '../../components';
import UsersApi from '../../redux/middleware/users';
import SupplierApi from '../../redux/middleware/supplier';
import { sortedData } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.png';
import Delete from '../../assets/images/Delete.png';
// import DownRed from '../../assets/images/DownRed.png';
// import DownGreen from '../../assets/images/DownGreen.png';
import Company from '../../assets/images/Company.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import Detail from '../../assets/images/Union.png';
import Duplicate from '../../assets/images/Duplicate.png';
import './supply.scss';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">Detail</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '2',
    label: <div className="dotOption modifierDropdownLabels">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption modifierDropdownLabels">Ingredients</div>,
    icon: <img src={Duplicate} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} className="iconDropdown" />,
  },
];

const itemsForRole3: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">Detail</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '2',
    label: <div className="dotOption modifierDropdownLabels">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} className="iconDropdown" />,
  },
];

const itemsForRole4: MenuProps['items'] = [
  {
    key: '3',
    label: <div className="dotOption modifierDropdownLabels">Ingredients</div>,
    icon: <img src={Duplicate} width={15} />,
  },
];

const itemsForTaggedSupplier: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">Add New</div>,
    icon: <PlusOutlined />,
  },
  {
    key: '2',
    label: (
      <div className="dotOption modifierDropdownLabels">Upload Ingredients</div>
    ),
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '3',
    label: (
      <div className="dotOption modifierDropdownLabels">UnTagged List</div>
    ),

    icon: <img src={Detail} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const SupplyView = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [userLoader, setUserLoader] = useState(false);
  const [supplierCount, setSupplierCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [openIngredientDialog, setOpenIngredientDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const [supplierData, setSupplierData] = useState<any>();
  const [id, setid] = useState();
  const [dialogData, setDialogData] = useState({});
  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          Active
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          Non-active
        </div>
      ),
    },
  ];
  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };
  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      UsersApi.disableUser(id, '1');
      pageData();
    } else {
      UsersApi.disableUser(id, '0');
      pageData();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const supplierColumns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => {
        return <div className="id">{index + 1}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUPPLIER NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('supplier_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="font">
          <div className="name">{record?.supplier_name}</div>
          <div className="email">{record?.email}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUPPLIER COMPANY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('contact_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'contact_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <img src={Company} />
          <div className="company">{record?.contact_name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>COUNTRY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('country_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'country_name',
      key: 'country_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="company">{record?.country_name}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div
          className={
            record?.status === 1
              ? 'statusContainerActive'
              : 'statusContainerNonActive'
          }
        >
          <div className="status">
            {record?.status == 1 ? 'Active' : 'Non-active'}
          </div>
          {/* <img src={record?.status === 1 ? DownGreen : DownRed} alt="" /> */}
        </div>
        // <Dropdown
        //   overlayStyle={{ height: '100px' }}
        //   menu={menuDropdownProps}
        //   trigger={['click']}
        //   placement="bottomRight"
        //   arrow={{ pointAtCenter: true }}
        // >
        //   <a
        //     onClick={(e) => {
        //       setid(record.id);
        //     }}
        //   >
        //     <Space>
        //       <div
        //         className={
        //           record?.status === 1
        //             ? 'statusContainerActive'
        //             : 'statusContainerNonActive'
        //         }
        //       >
        //         <div className="status">
        //           {record?.status == 1 ? 'Active' : 'Non-active'}
        //         </div>
        //         <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
        //       </div>
        //     </Space>
        //   </a>
        // </Dropdown>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (supplier: any) => {
    setSupplierData(supplier);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Supplier List'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1: {
        const response = await SupplierApi.getSupplierById(supplierData?.id);
        if (response?.success) setDialogData(response?.data[0]);
        // const data = await UsersApi.getUserById(supplierData);
        // setDialogData(data?.data);
        setOpenDialog(true);
        break;
      }
      case 2:
        navigate('wizard', { state: { update: true, id: supplierData } });
        break;
      case 3:
        navigate('/app/UntaggedIngredients', {
          state: { supplier: supplierData },
        });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleTaggedDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1: {
        setOpenIngredientDialog(true);
        break;
      }
      // case 2:
      //   // navigate('wizard', { state: { update: true, id: supplierData } });
      //   navigate('/app/bulkUploadSupplyIngredients');
      //   break;
      // case 3:
      //   navigate('/app/UntaggedIngredients', {
      //     state: { id: supplierData?.id },
      //   });
      //   break;
      // case 4:
      //   setOpenDeleteDialog(true);
      //   break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    let responseDialogData;
    const response = await SupplierApi.deleteSupplier(supplierData?.id);
    if (response?.success == false) {
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [`Error!`, ` Your ${response?.message}`],
      };
      ResponseDialog(responseDialogData);
    } else {
      responseDialogData = {
        OnClick: () => {
          pageData();
        },
        type: 'success',
        messageArray: [`Success!`, ` Your ${response?.message}`],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const DropDownmenuProps = {
    items:
      permissionStatus && permissionStatus === 2
        ? user?.roles[0]?.id === 3
          ? itemsForRole3
          : user?.roles[0]?.id === 4
          ? itemsForRole4
          : items
        : [],

    onClick: handleDropDownClick,
  };
  const TaggedDropdown = {
    items: itemsForTaggedSupplier,
    onClick: handleTaggedDropDownClick,
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setUserLoader(true);
    const supplierData = await SupplierApi.getSupplier(dataLength, current);
    if (!supplierData?.success || supplierData === undefined) {
      setSupplierCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setSupplierCount(supplierData?.data?.suppliers_count);
      setTotal(supplierData?.data?.pagination?.total);
      setSortData(supplierData?.data?.items);
    }
    setUserLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setUserLoader(true);
    const searchResultofRecipes = await UsersApi.searchUsers(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes === undefined) {
      setSupplierCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setSupplierCount(searchResultofRecipes?.data?.recipe_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setUserLoader(false);
  };

  const handleDialog = () => {
    setOpenIngredientDialog(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        TaggedDropdown={TaggedDropdown}
        heading="Supplier"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={user?.roles[0]?.id === 4 ? 1 : permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${supplierData?.supplier_name}?`}
      />
      <DetailDialog
        open={openDialog}
        handleClose={handleClose}
        data={dialogData}
        component={'supplier'}
      />
      {openIngredientDialog && (
        <SupplyIngredientDialog
          // handleSuccesDialog={handleSuccesDialog}
          handleDialog={handleDialog}
          open={openIngredientDialog}
          // component={'marketlist'}
          name={''}
          // blocked={blocked}
          // update={update}
          // id={selectedMenu}
        />
      )}
      <Page title="Supplier">
        {/* <UserListDialog
          open={openDialog}
          handleClose={handleClose}
          data={dialogData}
        /> */}
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <ListCount count1={supplierCount} title1="Total Supplier" />
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox action={onSearch} placeholderText="Search..." />
              </div>
              {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export CSV</div>
              </div>
              */}
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!userLoader ? (
              <UserList
                dataSource={[...sortData]}
                userColumns={supplierColumns}
                limit={dataLength}
              />
            ) : (
              // <ListViewWithoutExpand
              //   items={items}
              //   dataSource={[...sortData]}
              //   columns={columns}
              // />
              <div className="loader-wrapper">
                <Spin spinning={userLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default SupplyView;
