/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  DeleteConfirmation,
} from '../../components';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { sortedData, sortNumber } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import subRecipeVerified from '../../assets/images/subRecipeVerified.png';
import subRecipeNotVerified from '../../assets/images/subRecipeNotVerified.png';
import Utils from '../../redux/utils';
import './styles.scss';

const items: MenuProps['items'] = [
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const SubRecipe = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [design, setDesign] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(6);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const [selectedSubRecipeId, setSelectedSubRecipeId] = useState('');
  const { currentBrand } = useSelector((state: any) => state.Auth);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const setSelectedMenu = (subrecipe: any) => {
    setSelectedSubRecipeId(subrecipe.sub_recipe_id);
  };
  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>SUB RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="subrecipecol1 font">
            <img src={record?.thumbnail} width="52px" height="52px" />
            <div className="subrecipesubCol1 font">
              <span
                className="subrecipecol1Text font"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {record?.recipe_name}
              </span>

              <div className="subrecipesubTextCol1 font">
                {record?.is_verified ? (
                  <img
                    src={subRecipeVerified}
                    alt=""
                    className={'subRecipeVerifyIcon'}
                  />
                ) : (
                  <img
                    src={subRecipeNotVerified}
                    alt=""
                    className={'subRecipeVerifyIcon'}
                  />
                )}

                <span className="col1Text font">{record?.calorie}</span>
                <span className="calories font ">kcal</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('menu_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'menu_category',
      key: 'menu_category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="subrecipecol2">
          <div className="subrecipesubCol2">{record?.menu_category}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENTS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('total_ingredients', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'total_ingredients',
      key: 'total_ingredients',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol3">
          {record?.total_ingredients} Ingredients
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNITS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('unit', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="subrecipecol3">{record?.unit}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Sub Recipe'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const brand = await Utils.getCurrentBrand();
    const details = await SubRecipeActions.getIngredientDetail(
      data?.sub_recipe_id,
      brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', {
          state: { update: true, id: selectedSubRecipeId },
        });
        break;

      case 4:
        setOpenDeleteDialog(true);

        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    selectedSubRecipeId.length &&
      (await SubRecipeActions.delSubRecipe(selectedSubRecipeId));
    pageData();
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const SubRecipeData = await SubRecipeActions.getSubRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id
    );

    if (SubRecipeData == undefined || !SubRecipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(SubRecipeData?.data?.recipe_count);
      setIngredientCount(SubRecipeData?.data?.ingredients_count);
      setTotal(SubRecipeData?.data?.pagination?.total);
      setSortData(SubRecipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const subRecipeData = await SubRecipeActions.subRecipesFilters(
      dataLength,
      current,
      filters
    );
    if (subRecipeData == undefined || !subRecipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(subRecipeData?.data?.recipe_count);
      setIngredientCount(subRecipeData?.data?.ingredients_count);
      setTotal(subRecipeData?.data?.pagination?.total);
      setSortData(subRecipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = await SubRecipeActions.searchSubRecipe(
      dataLength,
      current,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleSubDialogOpen = async (id: any) => {
    const brand = await Utils.getCurrentBrand();

    const details = await SubRecipeActions.getIngredientDetail(
      id,
      brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  return (
    <div className="subRecipePage">
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete recipe?'}
      />
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        // printApi={RecipeActions}
        component={'subRecipe'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <Breadcrumb
        heading="Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={12}>
            <ListCount
              count1={recipeCount}
              title1="Total Recipes"
              count2={ingrdientCount}
              title2="Total Ingredients"
            />
          </Col>
          <Col lg={12}>
            <div className="search-wrapper">
              <SearchBox action={onSearch} placeholderText="Search..." />
              <IconSwitcher listViewLayout={design} setListView={setDesign} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={8}>
            <Filter
              component="subRecipe"
              action={pageDataFilter}
              apiCall={SubRecipeActions.getSubRecipesFilters}
            />
          </Col>
          <Col lg={18} md={16}>
            {/* {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'subRecipe'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )} */}

            {/* {design && !recipeLoader ? ( */}
            {!recipeLoader ? (
              <ListView
                limit={dataLength}
                component="subRecipe"
                items={items}
                dataSource={[...sortData]}
                columns={columns}
                handleOpen={(data: any) => {
                  handleSubDialogOpen(data);
                }}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default SubRecipe;
