import React, { useState, useEffect } from 'react';
import { Checkbox, Modal } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import './orderTypeDialog.scss';

type Props = {
  orderVariation: any;
  handleDialogCancel: any;
  open: any;
  orderTypeData: any;
  handleDialog: any;
};

const OrderTypeDialog = (props: Props) => {
  let {
    orderVariation,
    open,
    handleDialogCancel,
    orderTypeData,
    handleDialog,
  } = props;
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [checkedListCategory, setCheckedListCategory] = useState<
    CheckboxValueType[]
  >([]);

  useEffect(() => {
    if (orderVariation?.length) {
      const orderTypes = orderVariation?.map((data: any) => ({
        label: data?.name,
        value: data?.id,
      }));

      setCategoryOptions(
        orderTypes?.filter((data: any) => data?.label != 'All')
      );
    }
  }, [orderVariation]);

  useEffect(() => {
    if (
      !(
        orderTypeData &&
        Object.keys(orderTypeData).length === 0 &&
        Object.getPrototypeOf(orderTypeData) === Object.prototype
      )
    ) {
      const checkedList = orderTypeData?.id?.split(',');
      setCheckedListCategory(checkedList?.map((data: any) => +data));
    }
  }, [orderTypeData?.id]);

  function onChangeCategoryValues(checkedValues: any) {
    let arr: any[] = [];
    checkedValues?.length < 1 ? null : setCheckedListCategory(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = categoryOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
  }

  return (
    <Modal
      title={<div className="title font">Order Type</div>}
      centered
      open={open}
      onCancel={() => {
        const checkedList = orderTypeData?.id?.split(',');
        setCheckedListCategory(checkedList?.map((data: any) => +data));
        handleDialogCancel();
      }}
      width={300}
      footer={null}
    >
      <div className="orderTypeDialogContainer">
        <Checkbox.Group style={{ width: '100%' }}>
          <Checkbox.Group
            className="checkBoxStyling"
            options={categoryOptions}
            defaultValue={['Apple']}
            value={checkedListCategory}
            onChange={onChangeCategoryValues}
          />
        </Checkbox.Group>
        <div className={`butonGroup`}>
          <div className="wizard-button" onClick={handleDialogCancel}>
            Back
          </div>
          <div
            className={`continue wizard-button`}
            onClick={() => {
              handleDialog(
                checkedListCategory?.toString(),
                checkedListCategory?.length == categoryOptions?.length
                  ? 'All'
                  : checkedListCategory?.length == 0
                  ? 'Not Selected'
                  : 'Limited'
              );
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderTypeDialog;
