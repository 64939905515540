import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Select,
  Space,
  Dropdown,
  Checkbox,
  Input,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
} from '../../components';
import { useSelector } from 'react-redux';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import SupplierApi from '../../redux/middleware/supplier';
import IngredientActions from '../../redux/middleware/ingredient';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Utils from '../../redux/utils';
import './SupplierIngredients.scss';

const SupplierIngredients = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { supplier } = state;
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [current, setCurrent] = useState(1);
  const [taggedIngredients, setTaggedIngredients] = useState([{}]);
  const [unTaggedIngredients, setUnTaggedIngredients] = useState([]);
  const [buyingUnitData, setBuyingUnitData] = useState([]);
  const [buyingUnit, setBuyingUnit] = useState<any>({});
  const [dataLength, setDataLength] = useState(8);
  const [total, setTotal] = useState(0);
  const [totalUntaggedImage, setTotalUntaggedImage] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [recordIndex, setRecordIndex] = useState(-1);
  const [storageFactor, setStorageFactor] = useState<any>();
  const [totalCost, setTotalCost] = useState<any>();
  const [markAsDefault, setMarkAsDefault] = useState<any>();
  const PageDataLength = [6, 10, 20];
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [supplierName, setSupplierName] = useState('');
  const { currentCompany } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    pageData();
  }, [currentCompany]);
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Upload Images'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
    fetchBuyingUnits();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, check]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  //   {
  //     key: '2',
  //     label: <div className="dotOption">Edit</div>,
  //     icon: <img src={Edit} width={15} />,
  //   },
  // ];
  const itemsForUntagged: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Details</div>,
      icon: <img src={Eye} width={15} className="iconDropdown" />,
    },
  ];

  const fetchBuyingUnits = async () => {
    const response = await SupplierApi.getBuyingUnits(supplier?.id);
    if (response?.success) setBuyingUnitData(response?.data?.items);
    else setBuyingUnitData([]);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        // handleDialogData();
        break;
      case 2:
        // setUpdate(true);
        // setOpenIngredientDialog(true);
        break;
      default:
    }
  };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">{record?.ingredient_name}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing override-column-2-style">
          {record?.storage_unit}
        </div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>TAGGED SUPPLIER</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: () => (
    //     <div className="col3 cellSpacing override-column-2-style">
    //       {supplier?.supplier_name}
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>MARK AS DEFAULT</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <Checkbox
            disabled={recordIndex == index ? false : true}
            onChange={(e: any) => onChangeMarkAsDefault(e, record)}
            checked={markAsDefault?.id == record?.id}
          />
        ) : (
          <div className="col3 cellSpacing override-column-2-style ">
            {record?.is_default == 1 ? 'YES' : 'NO'}
          </div>
        ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BUYING UNIT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div className="col3 cellSpacing">
            <Select
              size={'large'}
              placeholder={'Select Buying Unit'}
              onChange={(e) => {
                const unit = buyingUnitData.find(
                  (data: any) => data?.id == e
                ) || { buying_unit: '' };
                setBuyingUnit(unit);
                // record.category = unit.buying_unit;
                // handleMenu(e, record);
              }}
              disabled={!(recordIndex == index)}
              style={{ width: 200 }}
              optionFilterProp="children"
              value={buyingUnit?.buying_unit}
            >
              {buyingUnitData?.map((data: any, index: number) => (
                <Select.Option value={data?.id} key={index}>
                  {data.buying_unit}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : (
          <div className="col3 cellSpacing override-column-2-style">
            {record?.buying_unit == null ? '-' : record?.buying_unit}
          </div>
        ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STORAGE FACTOR</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div className="col3 cellSpacing storageFactorInput">
            <Input
              placeholder="Enter Storage"
              type="number"
              disabled={!(recordIndex == index)}
              onChange={(e) => setStorageFactor(+e.target.value)}
              value={storageFactor}
            />
          </div>
        ) : (
          <div className="col3 cellSpacing override-column-2-style">
            {record?.buying_factor}
          </div>
        ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>STORAGE UNIT</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3 cellSpacing">{record?.unit}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div className="col3 cellSpacing storageFactorInput">
            <Input
              placeholder="Enter Cost"
              type="number"
              disabled={!(recordIndex == index)}
              onChange={(e) => setTotalCost(+e.target.value)}
              value={totalCost}
            />
          </div>
        ) : (
          <div className="col3 cellSpacing override-column-2-style">
            {record?.total_cost}
          </div>
        ),
    },

    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div
            className={`${
              loader ? 'loader-enable-supplier' : 'continue'
            } Supplier-ingredient-editButton`}
            onClick={() => {
              submitIngredient(record, 'tagged');
            }}
          >
            <Spin spinning={loader} size="default">
              UPDATE
            </Spin>
          </div>
        ) : (
          <div
            className={`continue Supplier-ingredient-editButton`}
            onClick={() => {
              setMarkAsDefault({});
              setRecordIndex(index);
              setStorageFactor(0);
              setBuyingUnit({
                buying_unit: record?.buying_unit,
                buying_unit_id: record?.buying_unit_id,
              });
              setStorageFactor(record?.buying_factor);
              setTotalCost(record?.total_cost);
              setMarkAsDefault(
                record?.is_default == 1 ? { id: record?.id } : {}
              );
            }}
          >
            EDIT
          </div>
        ),
    },
  ];
  const unTaggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font ">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title alignment-left-column-values">
                  {record?.ingredient_name}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.storage_unit}</div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>UNTAGGED SUPPLIER</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: () => (
    //     <div className="col3 cellSpacing">{supplier?.supplier_name}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>MARK AS DEFAULT</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any, index: number) => (
        <div className="col3 cellSpacing">
          {' '}
          <Checkbox
            disabled={recordIndex == index ? false : true}
            onChange={(e: any) => onChangeMarkAsDefault(e, record)}
            checked={markAsDefault?.id == record?.id}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BUYING UNIT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing">
          <Select
            size={'large'}
            placeholder={'Select Buying Unit'}
            onChange={(e) => {
              const unit = buyingUnitData.find(
                (data: any) => data?.id == e
              ) || { buying_unit: '' };
              setBuyingUnit(unit);
              // record.category = unit.buying_unit;
              // handleMenu(e, record);
            }}
            disabled={!(recordIndex == index)}
            style={{ width: 200 }}
            optionFilterProp="children"
            value={recordIndex == index ? buyingUnit?.buying_unit : null}
          >
            {buyingUnitData?.map((data: any, index: number) => (
              <Select.Option value={data?.id} key={index}>
                {data.buying_unit}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STORAGE FACTOR</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) => (
        <div className="col3 cellSpacing storageFactorInput">
          <Input
            placeholder="Enter Storage"
            type="number"
            value={recordIndex == index && storageFactor}
            disabled={!(recordIndex == index)}
            onChange={(e) => setStorageFactor(+e.target.value)}
          />
        </div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>STORAGE UNIT</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3 cellSpacing alignment-center-column-values">
    //       {record?.unit}
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing storageFactorInput">
          <Input
            placeholder="Enter Cost"
            type="number"
            value={recordIndex == index && totalCost}
            disabled={!(recordIndex == index)}
            onChange={(e) => setTotalCost(+e.target.value)}
          />
        </div>
      ),
    },

    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div
            className={`${
              loader ? 'loader-enable-supplier' : 'continue'
            } Supplier-ingredient-editButton`}
            onClick={() => {
              submitIngredient(record, 'untagged');
            }}
          >
            <Spin spinning={loader} size="default">
              UPDATE
            </Spin>
          </div>
        ) : (
          <div
            className={`continue Supplier-ingredient-editButton`}
            onClick={() => {
              setMarkAsDefault({});
              setRecordIndex(index);
              setStorageFactor('');
              setBuyingUnit({});
              setTotalCost('');
            }}
          >
            EDIT
          </div>
        ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: () => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items: itemsForUntagged,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              // setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const emptyAllStates = () => {
    setBuyingUnit({});
    setMarkAsDefault(null);
    setStorageFactor('');
    setTotalCost('');
    setRecordIndex(-1);
  };

  const onChangeMarkAsDefault = (e: CheckboxChangeEvent, data: any) => {
    if (e.target.checked) setMarkAsDefault(data);
    else setMarkAsDefault({});
  };

  const submitIngredient = async (data: any, type: string) => {
    // const brand = await Utils.getCurrentBrand();
    // const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    // const user = await Utils.getCurrentUser();
    const payload = {
      shop_id: shop?.shop_id ? shop?.shop_id : 0,
      supplier_id: supplier?.id,
      buying_unit_id: buyingUnit?.id || buyingUnit?.buying_unit_id || 0,
      buying_factor: storageFactor || 0,
      total_cost: totalCost,
      is_default: markAsDefault?.id == data?.id ? 1 : 0,
      // ingredient_name: data?.ingredient_name,
      // brand_id: brand?.brand_id,
      // unit_id: data?.unit_id,
      // waste: data?.waste || 0,
      // ingredient_category_id: data?.ingredient_category_id,
      // product_code: data?.product_code,
      // company_id: company?.id,
      // calorie: data?.calorie,
      // carb: data?.carb,
      // protein: data?.protein,
      // fat: data?.fat,
      // allergens_id: data?.allergens_id,
      // is_verified: data?.is_verified || 0,
      // verified_by: data?.verified_by || '',
      // unit_cost: '0.00',
      // created_by: user?.id,
    };

    try {
      setLoader(true);

      const response =
        type == 'tagged'
          ? await IngredientActions.addSuplierToInventory(payload, data?.sku_id)
          : await IngredientActions.addSuplierToInventory(
              payload,
              data?.sku_id
            );
      setLoader(false);
      if (response?.success) {
        let responseDialogData = {
          OnClick: () => {
            setRecordIndex(-1);
            pageData();
          },
          type: 'success',
          messageArray: [` Your ${data?.ingredient_name}`, ` has been Tagged`],
        };
        ResponseDialog(responseDialogData);
      } else if (!response?.success) {
        let responseDialogData = {
          OnClick: () => {
            emptyAllStates();
          },
          type: 'error',
          messageArray: [response?.error?.ingredient_name],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      const responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Validation Failed!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  // const handleMenu = async (value: any, data: any) => {
  //   // const payload = {
  //   //   recipe_id: value,
  //   //   type: 'recipe',
  //   // };
  //   // const assignTag = await UploadRecipeApi.assignUntaggedImage(
  //   //   payload,
  //   //   data?.id
  //   // );
  //   // let responseDialogData;
  //   // if (assignTag === undefined || !assignTag?.success)
  //   //   responseDialogData = {
  //   //     type: 'error',
  //   //     messageArray: [`Validation Failed!`, 'Something Went Wrong'],
  //   //     OnClick: () => {},
  //   //   };
  //   // else
  //   //   responseDialogData = {
  //   //     type: 'success',
  //   //     messageArray: [`Congratulation! ${assignTag?.message}`],
  //   //     OnClick: () => {
  //   //       pageData();
  //   //     },
  //   //   };
  //   // ResponseDialog(responseDialogData);
  // };
  const pageData = async () => {
    setRecipeLoader(true);
    const shop = await Utils.getCurrentShop();
    const imagesData = check
      ? await SupplierApi.getSupplierTaggedIngredients(
          supplier?.id,
          dataLength,
          current,
          shop?.shop_id ? shop?.shop_id : ''
        )
      : await SupplierApi.getSupplierUnTaggedIngredients(
          supplier?.id,
          dataLength,
          current,
          shop?.shop_id ? shop?.shop_id : ''
        );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalUntaggedImage(0);
      setTaggedIngredients([]);
      setUnTaggedIngredients([]);
      setSupplierName('');
    } else {
      if (check) {
        setSupplierName;
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedIngredients(imagesData?.data?.items);
        // setSupplierName(imagesData?.data?.items[0].supplier_name);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUnTaggedIngredients(imagesData?.data?.items);
        // setSupplierName(imagesData?.data?.items[0].supplier_name);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const imagesData = check
      ? await SupplierApi.getSupplierSearchIngredients(
          supplier?.id,
          dataLength,
          keyword,
          'supplier_id'
        )
      : await SupplierApi.getSupplierSearchIngredients(
          supplier?.id,
          dataLength,
          keyword,
          'not_supplier_id'
        );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalUntaggedImage(0);
      setTaggedIngredients([]);
      setUnTaggedIngredients([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedIngredients(imagesData?.data?.items);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUnTaggedIngredients(imagesData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };
  // const actionButton = (
  //   <Button
  //     text="Add New"
  //     className="theme-button recipeBtn"
  //     icon={<PlusOutlined />}
  //     handleButtonClick={() => {
  //       navigate('wizard', { state: { update: false, id: '' } });
  //     }}
  //     shape="round"
  //     size="middle"
  //   />
  // );

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Supplier Ingredients"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/supply');
        }}
        button={() => {}}
        perm_status={permissionStatus}
      />

      <Page title="Supplier Ingredients">
        <div className="supplierHeading">
          <p className="heading"> Supplier Name:</p>
          <h1 className="name">{supplier?.supplier_name}</h1>
        </div>
        <Row className="listingTopFilter">
          {/* <Col lg={10}></Col> */}

          <Col lg={16}>
            <div className="SupplierIngredients-list-menu-container">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div
                  onClick={() => {
                    setCheck(true);
                    setRecordIndex(-1);
                  }}
                  className="text SupplierIngredients-list-menu-style"
                >
                  Tagged Ingredients
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => {
                  setCheck(false);
                  setRecordIndex(-1);
                }}
              >
                <div className="text SupplierIngredients-list-menu-style">
                  UnTagged Ingredients
                </div>
                {totalUntaggedImage != 0 && (
                  <div
                    className={`SupplierIngredients-list-counter ${
                      +totalUntaggedImage >= 10 &&
                      'SupplierIngredients-list-large-counter'
                    }`}
                  >
                    {totalUntaggedImage}
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col lg={8}>
            <SearchBox action={onSearch} placeholderText="Search..." />
          </Col>
        </Row>

        <Row gutter={12} className="listingTopFilter">
          <Col lg={24} md={16} className="SupplierIngredients-list-wrapper">
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={''}
                dataSource={
                  check ? [...taggedIngredients] : [...unTaggedIngredients]
                }
                columns={check ? taggedColumns : unTaggedColumns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};
export default SupplierIngredients;
