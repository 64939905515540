import { baseURL } from '../../config/constant';
import Utils from '../utils';
const id = Utils.getCurrentCompany()?.id;
const getModifiers = async (dataLength) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/modifiers?company_id=${id}&limit=${dataLength}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getModifiersType = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/getModifierTypes?limit=3`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const createModifiers = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/modifiers`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
const ModifiersActions = {
  getModifiers,
  createModifiers,
  getModifiersType,
};

export default ModifiersActions;
