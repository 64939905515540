/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import MenuCategoryBuilderActions from '../../redux/middleware/menuCategoryBuilder';
import IngredientCategoryBuilderActions from '../../redux/middleware/ingredientCategoryBuilder';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  DeleteConfirmation,
  ResponseDialog,
} from '../../components';
import { sortedData } from '../../helper/sortData';
import Delete from '../../assets/images/Delete.svg';
import Clock from '../../assets/images/Clock.png';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import './menuCategoryBuilder.scss';

const items: MenuProps['items'] = [
  {
    key: '3',
    label: <div className="dotOptionBuilder">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOptionBuilder deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const MenuCategoryBuilderPage = () => {
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(6);
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const navigate = useNavigate();
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [VariationId, setVariationId] = useState('');
  const [dialogData, setDialogData] = useState<any>({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [check, setCheck] = useState(true);

  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };

  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Menu Category'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, check]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOptionBuilder">{data}</div>,
    };
  });

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitleBuilder">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrowBuilder"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacingBuilder">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitleBuilder">
          <span>CATEGORY NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrowBuilder"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacingBuilder override-width-column ">
          {record?.category_name}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitleBuilder">
          <span>DATE CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrowBuilder"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableColBuilder col3 col4Builder override-width-column ">
          <img src={Clock} alt="" className="icons" />
          {moment(UTCtoLocal(record?.created_at)).fromNow()}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const ingredientColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing override-width-column">
          {record?.category_name}
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>DATE CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4 override-width-column">
          <img src={Clock} alt="" className="icons" />
          {moment(UTCtoLocal(record?.created_at)).fromNow()}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const setSelectedMenu = (Variation: any) => {
    setDialogData(Variation);
    setVariationId(Variation?.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        check
          ? navigate('wizard', { state: { update: true, id: VariationId } })
          : navigate('/app/ingredientCategory/wizard', {
              state: { update: true, id: VariationId },
            });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    const response = check
      ? await MenuCategoryBuilderActions.deleteCategoryBuilder(VariationId)
      : await IngredientCategoryBuilderActions.deleteIngredientCategoryBuilder(
          VariationId
        );
    if (response?.success) pageData();
    else {
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [response?.message],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        check
          ? navigate('wizard', { state: { update: false, id: '' } })
          : navigate('/app/rd-ingredientCategory/wizard', {
              state: { update: false, id: '' },
            });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setRecipeLoader(true);
    const recipeData = check
      ? await MenuCategoryBuilderActions.getMenuCategoryBuilder(
          dataLength,
          current
        )
      : await IngredientCategoryBuilderActions.getIngredientCategoryBuilder(
          dataLength,
          current
        );
    if (recipeData?.data === undefined || !recipeData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(
        check
          ? recipeData?.data?.rb_menu_category_count
          : recipeData?.data?.ingredient_category_count
      );
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = check
      ? await MenuCategoryBuilderActions.searchCategoryBuilder(
          dataLength,
          keyword
        )
      : await IngredientCategoryBuilderActions.searchIngredientCategoryBuilder(
          dataLength,
          keyword
        );
    if (
      searchResultofRecipes?.data === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(
        check
          ? searchResultofRecipes?.data?.rb_menu_category_count
          : searchResultofRecipes?.data?.ingredient_category_count
      );
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className="R&D Menu Category">
      <Breadcrumb
        heading="R&D Category"
        breadcrumbIcon
        iconAction={() => {}}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.category_name}?`}
      />
      <Page title="R&D Category">
        <Row className="listingTopFilter">
          <Col lg={12} md={24}>
            <ListCount count1={recipeCount} title1="Total R&D Category" />
            <div className="rd-category-list-menu-container">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div
                  onClick={() => setCheck(true)}
                  className="text rd-category-list-menu-style"
                >
                  R&D Menu Category
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => setCheck(false)}
              >
                <div className="text rd-category-list-menu-style">
                  R&D Ingredient Category
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox action={onSearch} placeholderText="Search..." />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={[...sortData]}
                columns={check ? columns : ingredientColumns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default MenuCategoryBuilderPage;
