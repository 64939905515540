import React, { useState, useEffect } from 'react';
import { Select, Input, SelectProps } from 'antd';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import {
  IngredientDialog,
  SubRecipeDropdown,
  SubWizardDropdown,
} from '../index';
import IngredientActions from '../../redux/middleware/ingredient';
import './styles.scss';
import Utils from '../../redux/utils';

type Props = {
  SubRecipeName: any;
  category: any;
  unit: any;
  yeild: any;
  costRatio: any;
  totalPrice: any;
  price: any;
  waste: any;
  finalPrice: any;
  ingredient: any;
  handleRecipe: any;
  handleUnit: any;
  handleYeild: any;
  handleCostRatio: any;
  handleFinalPrice: any;
  handleWaste: any;
  handleIngredient: any;
  deleteIngredient: any;
  addIngredient: any;
  component: String;
  handleIngredientObject: any;
  screenName: String;
  handleChangeDatabase: any;
  database: any;
  errorStatus?: boolean;
  errorList?: any;
  handleSubCategory?: any;
  subCategory?: any;
  parentSubCategory?: any;
};
const SubRecipeStepOne = (props: Props) => {
  const brand = Utils.getCurrentBrand();
  const [units, setUnits] = useState<any[]>();
  const [ingredientIndex, setIngredientIndex] = useState(-1); // For manage ingredient index to remove ingredient_name, when we create ingredient from ingredient dropdown

  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: '', key: '' },
  ]);
  const [ingredientName, setIngredientName] = useState();

  let {
    SubRecipeName,
    // category,
    yeild,
    costRatio,
    totalPrice,
    price,
    waste,
    unit,
    finalPrice,
    ingredient,
    handleRecipe,
    handleUnit,
    handleYeild,
    handleCostRatio,
    handleFinalPrice,
    handleWaste,
    handleIngredient,
    deleteIngredient,
    addIngredient,
    component,
    handleIngredientObject,
    screenName,
    handleChangeDatabase,
    database,
    errorList,
    errorStatus,
    handleSubCategory,
    subCategory,
    // parentSubCategory,
  } = props;
  const [openIngredientDialog, setOpenIngredientDialog] = useState(false);
  const fetchUnits = async () => {
    const data = await SubRecipeActions.getSubRecipeUnits();
    setUnits(data?.data);
  };

  const fetchDatabase = async () => {
    const data = await IngredientActions.getDatabase();

    const options: SelectProps['options'] = data?.data?.map((country: any) => {
      return {
        value: country?.db_name,
        label: country?.db_name,
        key: country?.id,
      };
    });

    setOption(options);
  };

  useEffect(() => {
    fetchDatabase();
    if (!units?.length) fetchUnits();
  }, []);

  const handleIngredientOnChange = (data: any, index: any, key: string) => {
    handleIngredient(data, index, key);
    key == 'ingredient_name' && setIngredientName(data);
  };

  const handleDialog = () => {
    handleIngredientOnChange('', ingredientIndex, 'ingredient_name');
    handleChangeDatabase(database?.value, database);
    setOpenIngredientDialog(false);
  };

  return (
    <React.Fragment>
      {openIngredientDialog ? (
        <IngredientDialog
          handleDialog={handleDialog}
          open={openIngredientDialog}
          component={'sub recipe'}
          name={ingredientName}
        />
      ) : null}
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          {component == 'productionRecipe'
            ? 'Production Recipe Name'
            : 'Sub Recipe Name'}{' '}
          <span className="sterik">*</span>
        </div>
        <Input
          placeholder={`Enter ${
            component == 'productionRecipe'
              ? 'Production Recipe Name'
              : 'Sub Recipe Name'
          }`}
          size="large"
          value={SubRecipeName}
          onChange={(e) => {
            handleRecipe(e.target.value);
          }}
          required
          className={errorList['recipe_name']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_name']}
          </div>
        )}
      </div>
      {/* <div className="wizardSubContainer">
        <span className="category">
          <div className="wizardLabel">
            Category <span className="sterik">*</span>
          </div>

          <span>{category?.name}</span>
        </span>
      </div> */}
      <div className="wizardDropdown">
        <div className="wizardLabel">
          Sub Category <span className="sterik">*</span>
        </div>
        {/* <Select
          size={'large'}
          placeholder={'Select Sub Category'}
          onChange={handleSubCategory}
          style={{ width: 560 }}
          value={subCategory}
          options={parentSubCategory}
        /> */}
        <SubWizardDropdown
          component={component == 'subRecipe' ? 'sub_recipe' : 'production'}
          text={subCategory?.sub_category}
          handleChangeText={handleSubCategory}
        />
      </div>
      <div className="subRecipeIngredient-head">Ingredients Information</div>
      {/* <div className="wizardDropdown">
        <div className="wizardLabel">
          Select Database <span className="sterik">*</span>
        </div>
        <Select
          size={'large'}
          defaultValue={database?.label}
          onChange={(e: any, data: any) => {
            handleChangeDatabase(e, data);
          }}
          style={{ width: 560 }}
          options={option}
        />
        </div> */}
      <div className="wizardSubContainer" style={{ marginTop: '20px' }}>
        <div className="subrecipe-calorie-ingredient-container">
          <div className="wizardIngredientsLabel">
            <span className="IngredientsLabel">
              <span className="wizardLabel">
                Add Ingredients <span className="sterik">*</span>
              </span>
            </span>

            <span className="label">
              <span className="wizardLabel">
                Qty <span className="sterik">*</span>
              </span>
            </span>

            <span className="label">
              <span className="wizardLabel">
                Unit <span className="sterik">*</span>
              </span>
            </span>

            <span className="label">
              <span className="wizardLabel">Waste %</span>
            </span>

            <span className="label">
              <span className="wizardLabel">
                Cost<span className="sterik">*</span>
              </span>
            </span>
          </div>
          <div className="subrecipe-calorie-ingredient-container labels-sub-container">
            <div className="sub-recipe-additional-calorie-label">Calories</div>
            <div className="sub-recipe-additional-calorie-label">
              {/* Total Cost */}
            </div>
          </div>
        </div>
        {ingredient.map((data: any, index: number) => {
          return (
            <SubRecipeDropdown
              key={index}
              data={data}
              index={index}
              database={database}
              handleIngredientObject={handleIngredientObject}
              openDialog={() => {
                setOpenIngredientDialog(true);
                setIngredientIndex(index);
              }}
              handleIngredientOnChange={handleIngredientOnChange}
              deleteIngredient={deleteIngredient}
              component={'subRecipe'}
            />
          );
        })}
        {/* {errorStatus && (
          <div
            className="subRecipeErrorMessages"
            style={{ margin: ' 4px 5px 5px 85px' }}
          >
            {errorList['recipe_name']}
          </div>
        )} */}
      </div>

      <div className="addButton">
        <div
          className="addNewsubDiv"
          onClick={() => {
            addIngredient();
          }}
        >
          + Add New Row
        </div>
      </div>
      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel">
              Unit <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              onChange={(e) => {
                let extractObj =
                  screenName === 'Sub Recipe'
                    ? units?.filter((object: any) => object?.id === e)
                    : units?.filter(
                        (object: any) => object?.storage_unit_id === e
                      );

                handleUnit(extractObj);
              }}
              className={
                errorList['unit_id']?.length > 0 ? 'dropdownUnitColorRed' : ''
              }
              style={{ width: '100%' }}
              optionFilterProp="children"
              placeholder="Select Unit"
              value={
                screenName === 'Sub Recipe' ? unit?.unit : unit?.storage_unit
              }
            >
              {units?.map((data: any, index: number) => (
                <Select.Option
                  value={
                    screenName === 'Sub Recipe'
                      ? data?.id
                      : data?.storage_unit_id
                  }
                  key={index}
                >
                  {screenName === 'Sub Recipe'
                    ? data?.unit
                    : data?.storage_unit}
                </Select.Option>
              ))}
            </Select>
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['unit_id']}
              </div>
            )}
          </span>
          <span className="col">
            <div className="wizardLabel">
              Yield <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Enter yeild"
              size="large"
              type={'number'}
              value={yeild}
              onChange={(e) => handleYeild(e.target.value)}
              required
            />
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </span>
        </div>
      </div>

      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel">
              {component == 'productionRecipe'
                ? 'Production Recipe Waste %'
                : 'Sub Recipe Waste %'}{' '}
              <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Sub Recipe Waste %"
              size="large"
              type="number"
              value={waste}
              onChange={(e) => handleWaste(e.target.value)}
              required
            />
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </span>
          <span className="col">
            <div className="wizardLabel">Total Cost</div>
            <span className="pricegroup">
              <span>{brand?.currency_name}</span>
              <div>{Number(totalPrice.toFixed(2))}</div>
            </span>
          </span>
        </div>
      </div>

      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel">Cost Ratio</div>
            <span className="subRecipeFinalpricegroup">
              <Input
                placeholder="Enter Cost Ratio"
                size="large"
                type="number"
                value={costRatio != 0 && costRatio}
                onChange={(e) => handleCostRatio(e.target.value)}
                required
              />
              <span>%</span>
            </span>
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </span>
          <span className="col">
            <div className="wizardLabel">Proposed Price</div>
            <span className="pricegroup">
              <span>{brand?.currency_name}</span>
              <div> {Number(price.toFixed(2))}</div>
            </span>
          </span>
        </div>
      </div>
      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel">Final Price</div>
            <span className="subRecipeFinalpricegroup">
              <span>{brand?.currency_name}</span>

              <Input
                placeholder="Enter Final Price"
                size="large"
                type="number"
                value={finalPrice}
                onChange={(e) => handleFinalPrice(e.target.value)}
                required
              />
            </span>
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </span>
        </div>
      </div>

      <div className="subRecipewizardSubContainer"></div>
    </React.Fragment>
  );
};
export default SubRecipeStepOne;
