import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import useClickListener from '../../helper/outsideClickListener';
// import Verified from '../../assets/images/Verified.png';
import IngredientActions from '../../redux/middleware/ingredient';
import SubMenu from '../../assets/images/SubMenu.svg';
import {
  setRecipeIngredients,
  setDatabaseIngredient,
} from '../../redux/reducers/recipeSlice';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import './Dropdown.scss';
import Fire from '../../assets/images/Fire.svg';
import Utils from '../../redux/utils';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import SupplierApi from '../../redux/middleware/supplier';

type Props = {
  handleIngredientName: any;
  ingredientName: any;
  openDialog: any;
  ingredientData: any;
  database: any;
  handleIngredient: any;
  component?: string;
  supplier_id?: any;
};

const DropdownComponent = (props: Props) => {
  let {
    handleIngredientName,
    ingredientName,
    openDialog,
    handleIngredient,
    database,
    component,
    supplier_id,
  } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const dispatch = useDispatch();
  const state = store.getState();
  const [ingredients, setIngredients] = useState([]);
  const [search, setSearch] = useState('');
  const [filterOptions] = useState([
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'I', value: 'I' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'M', value: 'M' },
    { key: 'N', value: 'N' },
    { key: 'O', value: 'O' },
    { key: 'P', value: 'P' },
    { key: 'Q', value: 'Q' },
    { key: 'R', value: 'R' },
    { key: 'S', value: 'S' },
    { key: 'T', value: 'T' },
    { key: 'U', value: 'U' },
    { key: 'V', value: 'V' },
    { key: 'W', value: 'W' },
    { key: 'X', value: 'X' },
    { key: 'Y', value: 'Y' },
    { key: 'Z', value: 'Z' },
  ]);

  const [searchResults, setSearchResults] = useState([
    {
      id: 3040,
      database_id: 2,
      category_id: 8,
      fdc_id: 167782,
      ingredient_length: 12,
      ingredient_name: 'Abiyuch, raw',
      brand_id: 0,
      company_id: 0,
      shop_id: 0,
      unit_id: 0,
      calorie: 69,
      calorie_unit: 1,
      calories_per_unit: 0.69,
      carb: 17.6,
      carb_unit: 2,
      carb_per_unit: 0.18,
      protein: 1.5,
      protein_unit: 2,
      protein_per_unit: 0.02,
      fat: 0.1,
      fat_unit: 2,
      fat_per_unit: 0,
      ingredient_type: '',
      portion: 100,
      portion_unit: 1,
      unit_val_portion: 1,
      allergens_id: '',
      is_verified: 1,
      verified_by: 'ChefAdmin',
      created_by: null,
      status: 1,
      created_at: null,
      updated_at: null,
      db_name: 'USA',
      category_name: 'Fruits and Fruit Juices',
      unit: null,
      calorie_unit_name: 'kcal',
      carb_unit_name: 'g',
      protein_unit_name: 'g',
      fat_unit_name: 'g',
      unit_val_portion_name: 'Kg',
      allergens: '',
    },
  ]);

  useEffect(() => {
    const selectedDb = state.Recipe.selectedDatabase;
    handleFilterIngredient('a');
    if (database?.label !== selectedDb) {
      dispatch(setDatabaseIngredient(database?.label));
    }
  }, [database]);

  useEffect(() => {
    setSelectedFilter('A');
    setSearch(ingredientName);
    // handleIngredientsData();
  }, []);

  useEffect(() => {
    if (supplier_id) {
      setSearch(ingredientName);
      handleIngredientsDataForPurchaseOrder();
    }
  }, [supplier_id]);

  // const handleIngredientsData = async () => {
  //   const recipeIngredient = state.Recipe.ingredientsRecipe;
  //   if (recipeIngredient.length === 0) {
  //     handleFilterIngredient('a');
  //   } else {
  //     setIngredients(recipeIngredient);
  //     setSpinner(false);
  //   }
  // };

  const handleIngredientsDataForPurchaseOrder = () => {
    handleFilterIngredient('a');
  };

  const handleFilterIngredient = async (e: string) => {
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const data =
      component == 'purchase order'
        ? await SubRecipeActions.getSubRecipeInventoryForPurchaseOrder(
            database?.key,
            e,
            supplier_id
          )
        : component == 'supply'
        ? await SupplierApi.getInventoryForRequisition(
            database?.key,
            e,
            brand?.brand_id ? brand?.brand_id : '',
            shop?.shop_id ? shop?.shop_id : ''
          )
        : await IngredientActions.getInventory(database?.key, e, company?.id);
    if (data?.success) {
      setIngredients(data?.data?.items);
      dispatch(setRecipeIngredients(data?.data?.items));
    }
    setSpinner(false);
  };

  const handleSearchIngredient = async (e: string) => {
    setSpinner(true);
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const data =
      component == 'supply'
        ? await SupplierApi.getInventoryForRequisition(
            database?.key,
            e,
            brand?.brand_id ? brand?.brand_id : '',
            shop?.shop_id ? shop?.shop_id : ''
          )
        : await IngredientActions.getInventory(database?.key, e, company?.id);
    if (data?.success) setSearchResults(data?.data?.items);
    setSpinner(false);
  };

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
    setShowListViewOne(true);
  };

  const handleChange = (e: any) => {
    handleIngredientName(e);
    if (!isComponentVisible) {
      setIsComponentVisible(true);
    }
    if (e !== '') {
      setShowListViewOne(false);
      handleSearchIngredient(search);
    } else {
      setShowListViewOne(true);
      setSearchResults([]);
    }
  };

  const handleSearchFunction = () => {
    handleChange(search);
  };

  const handleCreateCategory = () => {
    setIsComponentVisible(false);
    openDialog();
  };

  const handleSelectOption = (ingredient: any) => {
    handleIngredientName(ingredient?.ingredient_name);
    setSearch(ingredient?.ingredient_name);
    handleIngredient(ingredient);
    setIsComponentVisible(false);
  };

  const handleFilterOption = (filterOption: any) => {
    setSpinner(true);
    setSelectedFilter(filterOption?.key);
    handleFilterIngredient(filterOption?.key);
    setIngredients(ingredients);
  };
  return (
    <div className="form-group" ref={ref}>
      <input
        type="text"
        placeholder="Enter Ingredients"
        onClick={handleClick}
        onChange={(e) => {
          handleIngredientName(e.target.value);
          setSearch(e.target.value);
        }}
        onKeyUp={() => handleSearchFunction()}
        value={ingredientName}
      />
      {isComponentVisible ? (
        <ul className="dropdown-list">
          {showListViewOne ? (
            <li className="main-list">
              <div className="list-btn">
                {filterOptions.map((filterOption, index) => {
                  return (
                    <>
                      <p
                        key={index}
                        className={
                          selectedFilter === filterOption?.key ? 'active' : ''
                        }
                        onClick={() => handleFilterOption(filterOption)}
                      >
                        {filterOption?.key}
                      </p>
                    </>
                  );
                })}
              </div>
              <ul className="subMenu">
                <li>
                  <h4 className="placeholder">
                    Select an option or create one
                  </h4>
                </li>
                <li>
                  <h3>{selectedFilter ? selectedFilter : 'A'}</h3>
                </li>

                {spinner ? (
                  <Spin
                    spinning={spinner}
                    size="default"
                    className="recipeLoader"
                  />
                ) : (
                  ingredients.map((ingredient: any, index: number) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleSelectOption(ingredient)}
                      >
                        <p>
                          {ingredient?.ingredient_name}{' '}
                          {/* {ingredient?.is_verified ? (
                            <span className="optionIcon">
                              {' '}
                              <img src={Verified} />
                            </span>
                          ) : null} */}
                          {ingredient?.verified_by == 'USA' && (
                            <span className="optionIcon">
                              <img
                                src={Fire}
                                width={5}
                                height={10}
                                alt=""
                                className="iconFireWhite dropdown-verified-icon"
                              />
                            </span>
                          )}
                          {ingredient?.verified_by == 'ChefAdmin' && (
                            <span className="optionIcon">
                              <img
                                src={Fire}
                                width={5}
                                height={10}
                                alt=""
                                className="subCol1Icon dropdown-verified-icon"
                              />
                            </span>
                          )}
                          {ingredient?.verified_by == 'sub_recipe' && (
                            <img
                              src={SubMenu}
                              width={15}
                              alt=""
                              className="subRecipeIcons"
                            />
                          )}
                        </p>
                      </li>
                    );
                  })
                )}
              </ul>
            </li>
          ) : (
            <li>
              <ul className="searchMenu">
                {database.label !== 'USA' ? (
                  <li className="create-category">
                    <button onClick={handleCreateCategory}>
                      Create {ingredientName} as an ingredient
                    </button>
                  </li>
                ) : null}
                {spinner ? (
                  <Spin
                    spinning={spinner}
                    size="default"
                    className="loaderStyle"
                  />
                ) : (
                  searchResults.map((searchResult, index) => {
                    return (
                      <li
                        key={index}
                        style={{ display: 'flex', gap: '5px' }}
                        onClick={() => handleSelectOption(searchResult)}
                      >
                        <p>{searchResult?.ingredient_name}</p>
                        {/* {searchResult?.is_verified ? (
                          <span className="optionIcon">
                            {' '}
                            <img src={Verified} />
                          </span>
                        ) : null} */}

                        {searchResult?.verified_by == 'USA' && (
                          <span className="optionIcon">
                            <img
                              src={Fire}
                              width={5}
                              height={10}
                              alt=""
                              className="iconFireWhite dropdown-verified-icon"
                            />
                          </span>
                        )}
                        {searchResult?.verified_by == 'ChefAdmin' && (
                          <span className="optionIcon">
                            <img
                              src={Fire}
                              width={5}
                              height={10}
                              alt=""
                              className="subCol1Icon dropdown-verified-icon"
                            />
                          </span>
                        )}

                        {searchResult?.ingredient_type == 'sub_recipe' && (
                          <img
                            src={SubMenu}
                            width={15}
                            alt=""
                            className="subRecipeIcons"
                          />
                        )}
                      </li>
                    );
                  })
                )}
              </ul>
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default DropdownComponent;
