import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Page,
  Breadcrumb,
  MenuCategoryStep,
  ResponseDialog,
} from '../../components';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import Utils from '../../redux/utils';
import './menuCategoryWizard.scss';

const menuCategoryWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = React.useState(false);
  const company = Utils.getCurrentCompany();
  const [apiLoader, setApiLoader] = React.useState(false);
  const [errorList, setErrorList] = React.useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { update, id, parent_id } = state;
  const [menuCategory, setMenuCategory] = React.useState('');
  const fetchDataById = async (id: any) => {
    const data = await MenuCategoryActions.getMenuCategoryById(id);
    if (data) setLoader(false);
    setMenuCategory(data?.data?.name);
  };

  useEffect(() => {
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
  }, []);

  const handleMenuCategory = (data: string) => {
    setMenuCategory(data);
  };

  const handleContinue = async () => {
    const brand = await Utils.getCurrentBrand();

    setErrorList({});
    setErrorStatus(false);
    const param = {
      company_id: company?.id,
      sub_category: menuCategory,
      brand_id: brand?.brand_id,
      parent_category_id: parent_id,
    };
    let responseDialogData;
    setApiLoader(true);

    const response = update
      ? await MenuCategoryActions.updateSubCategories(id, param)
      : await MenuCategoryActions.addSubCategories(param);
    setApiLoader(false);
    if (!response?.success) {
      setErrorList(response?.error);
      setErrorStatus(true);
    } else {
      responseDialogData = {
        OnClick: () => {
          navigate('/app/menuCategory');
        },
        type: 'success',
        messageArray: [
          ` Your ${menuCategory}`,
          ` has been ${update ? 'Updated' : 'Created'}`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Menu Category"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/menuCategory');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Menu Category">
          {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="wizardContainer">
              <MenuCategoryStep
                menuCategory={menuCategory}
                handleMenuCategory={handleMenuCategory}
                errorList={errorList}
                errorStatus={errorStatus}
              />
              <div className="butonGroup">
                <div className="wizard-button" onClick={() => handleContinue()}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default menuCategoryWizard;
