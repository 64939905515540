import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  CompanyList,
  DeleteConfirmation,
} from '../../components';
import CompanyActions from '../../redux/middleware/company';
import { sortedData } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import EditSmall from '../../assets/images/EditSmall.png';
// import Delete from '../../assets/images/Delete.svg';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import './styles.scss';
import SettingDialog from '../../components/SettingDialog';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Details</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={EditSmall} className="iconDropdown" />,
  },

  // {
  //   type: 'divider',
  // },
  // {
  //   key: '4',
  //   label: <div className="dotOption deleteText">Delete</div>,
  //   icon: <img src={Delete} width={15} className="iconDropdown" />,
  // },
];

const statusItems: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption activeStatus">Active</div>,
    // icon: <img src={Eye} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption notActiveStatus">Not Active</div>,
    // icon: <img src={EditSmall} className="iconDropdown" />,
  },
];

const PageDataLength = [6, 10, 20];

const Company = () => {
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [info, setInfo] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});

  const navigate = useNavigate();
  const { currentCompany } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    if (currentCompany) {
      pageData();
    }
  }, [currentCompany]);
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState('');
  const handleSort = (column: string) => {
    let arr = sortedData(ascending, sortData, column);
    setSortData(() => arr);
  };

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_',
      key: 'sub_',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="company">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>COMPANY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col1 font">
          <img src={record?.thumbnail} width="52px" height="52px" />
          <div className="subCol1 font">
            <span className="col1Text font">{record?.company_name}</span>
          </div>
        </div>
      ),
    },
    /* {
      title: () => (
        <div className="tableTitle">
          <span>COMPANY OWNER</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('country');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.owner}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>EMAIL</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('city');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="city">{record?.email}</div>;
      },
    }, */
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Dropdown
            menu={StatusDropDownMenuProps}
            overlayStyle={{ height: '100px' }}
            trigger={['click']}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            disabled={!validateActionPermission()}
          >
            <div
              className={
                record?.status === 1
                  ? 'statusContainerActive'
                  : 'statusContainerNonActive'
              }
              onClick={() => {
                setDialogData(record);
                setSelectedId(record?.id);
              }}
            >
              <div className="status">
                {record?.status === 1 ? 'Active' : 'Not Active'}
              </div>
              <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
            </div>
          </Dropdown>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownMenuProps}
          overlayStyle={{ height: '100px' }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedId(record?.id)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setOpenDialog(true);
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedId } });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleStatusDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        await CompanyActions.updateStatus(selectedId, 1).then(() => pageData());
        break;

      case 2:
        await CompanyActions.updateStatus(selectedId, 0).then(() => pageData());
        break;
      default:
    }
  };

  const screenPermission = Utils.getScreenPermission('Company');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  // eslint-disable-next-line no-unused-vars
  const DropDownMenuProps = {
    items: validateActionPermission()
      ? items
      : items.filter((x) => x?.key !== '2' && x?.key !== '4'),
    onClick: handleDropDownClick,
  };

  const StatusDropDownMenuProps = {
    items: statusItems,
    onClick: handleStatusDropDownClick,
  };

  const pageData = async () => {
    setLoader(true);
    const Data = await CompanyActions.getCompany(dataLength, current);
    setCount(Data?.data?.companies_count);
    setTotal(Data?.data?.pagination?.total);
    setSortData(Data?.data?.items);
    setLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  // eslint-disable-next-line no-unused-vars
  const onSearch = async (keyword: string) => {
    setLoader(true);
    const searchResult = await CompanyActions.searchCompany(
      dataLength,
      current,
      keyword
    );
    if (searchResult == undefined || !searchResult?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResult?.data?.companies_count);
      setTotal(searchResult?.data?.pagination?.total);
      setSortData(searchResult?.data?.items);
    }
    setLoader(false);
  };

  const getCompanyInfo = async () => {
    const response = await CompanyActions.getCompanyById(selectedId);
    if (response?.success) {
      const { data } = response;
      setInfo(data);
    }
  };

  useEffect(() => {
    if (openDialog) {
      getCompanyInfo();
    }
  }, [openDialog]);

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await CompanyActions.removeCompany(selectedId).then(() => pageData());
  };

  return (
    <React.Fragment>
      <SettingDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={info}
        component={'company'}
        title={'Company Detail'}
      />
      <Breadcrumb
        heading="Company"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.company_name}?`}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={5}>
            <ListCount count1={count} title1="Total Companies" />
          </Col>
          <Col lg={10} />
          <Col lg={8}>
            <SearchBox action={onSearch} placeholderText="Search..." />
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!Loader ? (
              <CompanyList
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
                limit={dataLength}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={Loader} className="Loader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default Company;
