import React, { useEffect } from 'react';
import { Col, Row, Form, notification } from 'antd';
import SideImage from '../../assets/images/authImage.png';
import Logo from '../../assets/svg/logo.svg';
import './confirmationForEmail.scss';
import { useNavigate } from 'react-router-dom';
import AuthActions from '../../redux/middleware/auth';
import { useSearchParams } from 'react-router-dom';

const ConfirmationEmailPage = () => {
  const [, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeForPassword, setActiveForPassword] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [, setToken] = React.useState<any>('');
  const [, setId] = React.useState<any>('');

  const getDescription = () => (
    <>
      <p>{`Welcome to ChefAdmin!`}</p>
      <p>{`We're excited to have you here. Our platform is designed to simplify your operations and help you manage your business more efficiently. With ChefAdmin, you can track your recipes, manage inventory, monitor costs, and more.`}</p>
      <p>{`We're confident that you'll find our platform intuitive and easy to use. Thank you for choosing ChefAdmin, and we look forward to supporting your business!`}</p>
    </>
  );

  useEffect(() => {
    setToken(searchParams?.get('token'));
    setId(searchParams?.get('user_id'));
    if (searchParams?.get('token')) {
      userVerificationRequest(
        searchParams?.get('user_id'),
        searchParams?.get('token')
      );
    }
  }, []);

  const userVerificationRequest = async (id: any, token: any) => {
    try {
      const apiResponse = await AuthActions.VerifyUser(id, token);
      if (apiResponse?.success) {
        setTimeout(() => {
          navigate('/auth');
        }, 4000);
      } else {
        setActiveForPassword(true);
        setMessage('Failed to verify user. Please Try Again');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    console.log('searchParams', searchParams);
  }, []);

  return (
    <React.Fragment>
      {contextHolder}
      <Row className="authContainer" style={{ height: window.innerHeight }}>
        <Col lg={8} className="leftSide">
          <img src={Logo} alt={'logo'} className="sideLogo" />
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <div className="alignment">
              <label className="b2 upload-label success">
                Your email has been verified.Redirecting to the Login Screen...
              </label>
            </div>

            {activeForPassword ? (
              <div className="alignment error">
                <label className="b upload-label ">{message}</label>
              </div>
            ) : null}
          </Form>
        </Col>
        <Col lg={16} className="rightSide">
          {/* <img src={SideImage} alt={'sideImage'} /> */}
          <div className="login-right-side">
            <div className="title-description-container">
              <div className="login-welcome-title">Welcome</div>
              <div className="login-right-side-description">
                {getDescription()}
              </div>
              <div className="loginsideImage">
                <img src={SideImage} alt="sideImage" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ConfirmationEmailPage;
