import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CategoryActions from '../../redux/middleware/category';
import useClickListener from '../../helper/outsideClickListener';
// import ResponseDialog from '../ResopnseDialog';
import { Input } from 'antd';
import CategoryModal from '../categoryModal';
// import Utils from '../../redux/utils';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import './style.scss';

type Props = {
  text: string;
  handleChangeText: any;
  component: any;
  errorList?: any;
};
type Category = {
  id: number;
  category_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

const SubWizardDropdown = (props: Props) => {
  let { component } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [categoryData, setCategoryData] = useState<any>([]);
  // const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(true);
  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<Category[]>();
  const [parentCategory, setParentCategory] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<any>(props?.text);
  const fetchCategory = async () => {
    // const company = await Utils.getCurrentCompany();

    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      const filterValue = data?.find(
        (x: any) =>
          (x?.slug === 'sub_recipe' && component == 'sub_recipe') ||
          (x?.slug === 'production' && component == 'production') ||
          (x?.slug === 'menu' && component == 'recipe')
      )?.id;
      setParentCategory(filterValue);
      const subCategories = await MenuCategoryActions.getSubCategoriesForWizard(
        filterValue,
        100
      );
      setCategoryData(subCategories?.data?.items);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (props?.text) setSearchQuery(props?.text);
  }, [props?.text]);

  // React.useEffect(() => {
  //   if (categoryData?.length === 1) {
  //     props.handleChangeText({
  //       value: categoryData[0]?.id,
  //       label: categoryData[0]?.sub_category,
  //       key: categoryData[0]?.id,
  //     });
  //   }
  // }, [categoryData]);

  const handleClick = () => {
    setIsComponentVisible(true);
    setShowListViewOne(true);
  };

  const handleChange = (e: any) => {
    // props?.handleChangeText(e.target.value);
    setSearchQuery(e.target.value);
    let results: any[];
    if (e.target.value !== '') {
      setShowListViewOne(false);
      results = categoryData.filter((item: any) => {
        return item?.sub_category
          ?.toLowerCase()
          .includes(e.target.value?.toString().toLowerCase());
      });
      setSearchResults(results);
    } else {
      setShowListViewOne(true);
      setSearchResults([]);
    }
  };

  // const handleCreateCategory = async (text: any) => {
  //   const company = await Utils.getCurrentCompany();
  //   const brand = await Utils.getCurrentBrand();
  //   const params = {
  //     company_id: company?.id,
  //     parent_category_id: parentCategory,
  //     sub_category: text,
  //     brand_id: brand?.brand_id,
  //   };
  //   const data = await MenuCategoryActions.addSubCategories(params);
  //   if (data?.success) {
  //     setShowListViewOne(true);
  //     fetchCategory();
  //   }
  // };
  const handlemodal = (toggle: boolean, data: any) => {
    setOpen(toggle);
    handleSelectOption(data);
    fetchCategory();
  };
  const handleSelectOption = (ingredient: any) => {
    props?.handleChangeText(ingredient);
    setSearchQuery(ingredient?.sub_category);
    setIsComponentVisible(false);
  };

  return (
    <div className="sub-wizard-form-group" ref={ref}>
      {open ? (
        <CategoryModal
          handleDialog={handlemodal}
          open={open}
          component={'sub_recipe'}
          name={searchQuery}
          modalName="Menu"
          parentCategory={parentCategory}
        />
      ) : null}
      <Input
        type="text"
        placeholder="Input category here"
        onClick={handleClick}
        onChange={(e) => handleChange(e)}
        value={searchQuery}
        // className={errorList['menu_category_id']?.length > 0 ? 'borderRed' : ''}
      />

      {isComponentVisible ? (
        <ul className="sub-recipe-dropdown-list">
          {showListViewOne ? (
            <li>
              <ul className="subRecipe-subMenu">
                <li>
                  <h4 className="subRecipe-dropdownPlaceholder">
                    Select an option or create one
                  </h4>
                </li>

                {categoryData.map((category: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleSelectOption(category)}
                    >
                      <p>{category?.sub_category} </p>
                    </li>
                  );
                })}
              </ul>
            </li>
          ) : (
            <li>
              <ul className="subMenu">
                <li className="subRecipe-dropdown-create-category">
                  <button
                    onClick={() => {
                      // handleCreateCategory(searchQuery);
                      setOpen(true);
                    }}
                  >
                    {`Create ${searchQuery} to category`}
                  </button>
                </li>
                {searchResults?.map((searchResult: any, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleSelectOption(searchResult)}
                    >
                      <p>{searchResult?.sub_category}</p>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default SubWizardDropdown;
