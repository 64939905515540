import React from 'react';
import { Input, Modal } from 'antd';
import './inventoryTransferApproval.scss';

type Props = {
  approvalModal: boolean;
  handleCancel: any;
  inventory: any;
  handleInventory: any;
  SubmitApproval: any;
  errorIndex: number;
};

const InventoryTransferApproval = (props: Props) => {
  let {
    approvalModal,
    handleCancel,
    inventory,
    handleInventory,
    SubmitApproval,
    errorIndex,
  } = props;
  return (
    <Modal
      title={<div className="title font">Approval</div>}
      centered
      open={approvalModal}
      onCancel={handleCancel}
      width={480}
      footer={null}
      className="InventoryTransferApprovalContainer"
    >
      {/* <div className="deleteDialogText">{message}</div>
      <div className="butonGroupDialog">
        <div className="wizard-button" onClick={() => handleDeleteCancel()}>
          Cancel
        </div>
        <div
          className="continue wizard-button"
          onClick={() => handleDeleteContinue()}
        >
          Continue
        </div>
      </div> */}
      <div className="font">INGREDIENTS</div>
      <div className="aprroval-inventory-modal-container-label">
        <div>Ingredient Name</div>
        <div>Request Quantity</div>
        <div>Transfer Quantity</div>
      </div>
      {inventory?.ingredients?.map((data: any, index: number) => (
        <div key={index} className="aprroval-inventory-modal-container">
          <div>{data?.ingredient_name}</div>
          <div>{data?.request_quantity}</div>
          <Input
            placeholder="Enter Quantity"
            type="number"
            onChange={(e: any) => {
              const ingredients = inventory?.ingredients;
              ingredients[index].transfer_quantity = +e.target.value;
              handleInventory(ingredients);
            }}
            className={errorIndex == index ? 'borderRed' : ''}
            value={data?.transfer_quantity ? data?.transfer_quantity : ''}
          />
          {errorIndex == index && (
            <span className="subRecipeErrorMessages">
              Quantity must be non zero
            </span>
          )}
        </div>
      ))}
      <div className={`butonGroup `}>
        <div className="wizard-button" onClick={() => handleCancel()}>
          Cancel
        </div>
        <div className={`continue wizard-button`} onClick={SubmitApproval}>
          Continue
        </div>
      </div>
    </Modal>
  );
};

export default InventoryTransferApproval;
