import React from 'react';
import { Modal } from 'antd';
import './confirmation.scss';

type Props = {
  handleCancel: any;
  handleContinue: any;
  open: boolean;
  message: string;
};

const Confirmation = (props: Props) => {
  let { handleCancel, handleContinue, open, message } = props;
  return (
    <Modal
      title={<div className="title font">Confirmation</div>}
      centered
      open={open}
      onCancel={handleCancel}
      width={480}
      footer={null}
      className="confirmationContainer"
    >
      <div className="marginBottomConfirmationText">{message}</div>
      <div className="butonGroupDialog">
        <div className="wizard-button" onClick={() => handleCancel()}>
          No
        </div>
        <div
          className="continue wizard-button"
          onClick={() => handleContinue()}
        >
          Yes
        </div>
      </div>
    </Modal>
  );
};

export default Confirmation;
