/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  ResponseDialog,
  DeleteConfirmation,
  IngendientListDialog,
} from '../../components';
import RecipeActions from '../../redux/middleware/recipe';
import { sortedData, sortNumber } from '../../helper/sortData';
import Fire from '../../assets/images/Fire.svg';
import More from '../../assets/images/More.png';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Export from '../../assets/images/export.png';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import Attributes from '../../redux/middleware/attributes';
import './rdSubRecipe.scss';

const PageDataLength = [6, 10, 20];

const RDSubRecipeList = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  // const [design, setDesign] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(20);
  const [dialogData, setDialogData] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState<any>({});
  const [loader, setLoader] = useState(false);
  // const [pdf, setPdf] = useState('');
  const [id, setId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { currentBrand } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   if (currentBrand) {
  //     pageData();
  //   }
  // }, [currentBrand]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Approval</div>,
      icon: <CheckCircleOutlined className="inventory-transfer-iconDropdown" />,
    },
    {
      key: '2',
      label: <div className="dotOption">Download</div>,
      icon: <img src={Download} width={15} />,
    },
    {
      key: '3',
      label: <div className="dotOption">Edit</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  const itemsForReadOnlyUsers: MenuProps['items'] = [
    {
      key: '2',
      label: <div className="dotOption">Download</div>,
      icon: <img src={Download} width={15} />,
    },
  ];
  const navigate = useNavigate();

  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);
    setSortData(() => arr);
  };
  const setSelectedMenu = (recipe: any) => {
    setDialogData(recipe);
    setSelectedRecipeId(recipe?.rb_id);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={record?.thumbnail} width="52px" height="52px" />
            <div className="subCol1 font">
              <span
                className="col1Text font table-title1-hover"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {record?.recipe_name}
              </span>

              <div className="subTextCol1 font">
                {record?.is_verified ? (
                  <img
                    src={Fire}
                    width={15}
                    alt=""
                    className=" iconFireWhite"
                  />
                ) : (
                  <img src={Fire} width={15} alt="" className="subCol1Icon" />
                )}

                <span className="col1Text font">{record?.calorie}</span>
                <span className="calories font ">kcal</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PREP CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2">
          <div className="subCol2">{record?.category_name}</div>
        </div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>SERVINGS</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('servings', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'servings',
    //   key: 'servings',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3">{record?.servings + ' items'}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUB COUNT</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('sub_recipe_items', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">
          {record?.sub_recipe_items}{' '}
          {+record?.sub_recipe_items > 1 ? 'items' : 'item'}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('servings', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'servings',
      key: 'servings',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div
          className={`requestStatusContainer ${
            record?.status_type?.toLowerCase() == 'pending'
              ? 'inventoryTransfer-request-pending'
              : record?.status_type?.toLowerCase() == 'approved'
              ? 'inventoryTransfer-request-approve'
              : 'inventoryTransfer-request-reject'
          } `}
        >
          {record?.status_type}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">
          {moment(UTCtoLocal(record?.created_at)).fromNow()}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            items:
              permissionStatus && permissionStatus === 2
                ? record?.status == 0
                  ? items
                  : items?.filter((item: any) => +item?.key != 1)
                : itemsForReadOnlyUsers,
            onClick: handleDropDownClick,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'R&D Sub Recipe'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const details = await RecipeActions.getDetailRecipe(data?.rb_id);
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const FetchApprovalDetail = async () => {
    const { data } = await RecipeActions.getApprovalById(selectedRecipeId);
    setData(data);
    if (data) {
      setDialog(true);
    }
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleMarkRecipe = async (payload: any, recipe_id: any) => {
    setLoader(true);
    const response = await RecipeActions.markRecipe(payload, recipe_id);
    setLoader(false);
    if (response?.success) {
      setDialog(false);
      pageData();
    }
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    if (option == 2) {
      setLoader(true);
      const { data, success } = await RecipeActions.getRecipeLink(
        selectedRecipeId
      );
      setLoader(false);
      if (success) {
        // setPdf(data);
        openInNewTab(data);
      }
    }
    switch (option) {
      case 1:
        FetchApprovalDetail();
        break;
      case 3:
        navigate('wizard', { state: { update: true, id: selectedRecipeId } });
        break;
      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    let responseDialogData;

    const response = await RecipeActions.delRecipe(selectedRecipeId);
    if (response?.success == false) {
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [`Error!`, ` Your ${response?.message}`],
      };
      ResponseDialog(responseDialogData);
    } else {
      responseDialogData = {
        OnClick: () => {
          pageData();
        },
        type: 'success',
        messageArray: [`success!`, ` Your ${response?.message}`],
      };
      ResponseDialog(responseDialogData);
    }
  };

  // const DropDownmenuProps = {
  //   items:
  //     permissionStatus && permissionStatus === 2
  //       ? items
  //       : itemsForReadOnlyUsers,
  //   onClick: handleDropDownClick,
  // };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const recipeData = await RecipeActions.getRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      2
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  // const pageDataFilter = async (filters: any) => {
  //   setRecipeLoader(true);
  //   const recipeData = await RecipeActions.recipesFilters(
  //     dataLength,
  //     current,
  //     filters
  //   );

  //   if (recipeData == undefined || !recipeData?.success) {
  //     setRecipeCount(0);
  //     setIngredientCount(0);
  //     setTotal(0);
  //     setSortData([]);
  //   } else {
  //     setRecipeCount(recipeData?.data?.recipe_count);
  //     setIngredientCount(recipeData?.data?.ingredients_count);
  //     setTotal(recipeData?.data?.pagination?.total);
  //     setSortData(recipeData?.data?.items);
  //   }

  //   setRecipeLoader(false);
  // };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = await RecipeActions.searchRecipe(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  const handleCSV = async () => {
    const response = await Attributes.exportCSV('SubRecipes');
    const { data } = response;
    window.open(data?.url);
  };

  return (
    <div className="rdSubRecipeList">
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        printApi={RecipeActions}
        component={'RecipeBuilder'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        component={'RecipeBuilder'}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.recipe_name}?`}
      />

      {dialog && (
        <IngendientListDialog
          open={dialog}
          handleClose={handleClose}
          data={data}
          handleMarkRecipe={handleMarkRecipe}
          loader={loader}
          component={'r&d_subrecipe'}
        />
      )}

      <Breadcrumb
        handleCSV={handleCSV}
        heading="R&D Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="R&D SubRecipe">
          <Row className="listingTopFilter">
            <Col md={24} lg={12}>
              <ListCount
                count1={recipeCount}
                title1="Total RD SubRecipe"
                count2={ingrdientCount}
                title2="Total Ingredients"
              />
            </Col>
            <Col md={24} lg={12}>
              <div className="search-wrapper">
                <SearchBox action={onSearch} placeholderText="Search..." />
                <div className="exportButton" onClick={handleCSV}>
                  <img src={Export} alt="" />
                  <div className="exportText"> Export CSV</div>
                </div>
                {/* <IconSwitcher listViewLayout={design} setListView={setDesign} /> */}
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col lg={6} md={8}>
            <Filter
              action={pageDataFilter}
              apiCall={RecipeActions.getRecipesFilters}
            />
          </Col> 
          */}
            <Col lg={24} md={24}>
              {/* {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData || []}
                component={'recipeBuilder'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )} */}

              {!recipeLoader ? (
                <ListView
                  limit={dataLength}
                  items={items}
                  dataSource={[...sortData] || []}
                  columns={columns}
                  component={'recipe builder'}
                />
              ) : (
                recipeLoader && (
                  <div className="loader-wrapper">
                    <Spin spinning={recipeLoader} className="recipeLoader" />
                  </div>
                )
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </div>
  );
};

export default RDSubRecipeList;
