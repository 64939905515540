/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Page,
  Breadcrumb,
  Stepper,
  SubRecipeStepTwo,
  ResponseDialog,
} from '../../components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SubRecipeStepOne } from '../../components';
import Utils from '../../redux/utils';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import Attributes from '../../redux/middleware/attributes';
import './styles.scss';
import { Alert, Spin } from 'antd';

type additionalInfo = {
  id: number;
  attribute_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type Database = {
  value: any;
  label: any;
  key: any;
};

const SubRecipeWizard = () => {
  const user = Utils.getCurrentUser();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { update, id } = state;
  const [blocked] = useState(true);
  const company = Utils.getCurrentCompany();
  const [errorList, setErrorList] = useState<any>({});
  const [allergenData, setAlergenData] = useState<any[]>();
  const [database, setDatabase] = useState<Database>({
    value: 2,
    label: 'My Market List',
    key: 3,
  });
  const [allergensByDefault, setAllergensByDefault] = useState<any>([]);

  //Step 1
  const [loader, setLoader] = useState(false);
  const [SubRecipeName, setSubRecipeName] = React.useState('');
  const [category, setCategory] = React.useState<any>();
  const [unit, setUnit] = React.useState<any>({});
  const [yeild, setYeild] = React.useState('');
  const [costRatio, setCostRatio] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [waste, setWaste] = React.useState('');
  const [finalPrice, setFinalPrice] = React.useState('');
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [subCategory, setSubCategory] = useState<any>({
    sub_category: '',
    id: -1,
  });
  const [ingredient, setIngredient] = useState<any[]>([
    {
      ingredient_name: '',
      quantity: 0,
      unit_cost: '',
      waste: '',
      small_unit: 'g',
      total_price: 0,
    },
  ]);
  const [messages, setMessages] = useState<any[]>([]);
  const [alerts, setAlerts] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [imageObject, setImageObject] = useState({});
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [steps, setSteps] = useState(['']);
  const [parentCategory, setParentCategory] = useState('');
  const [parentSubCategory, setParentSubCategory] = useState([]);
  const [prep, setPrep] = useState('');
  const [calory, setCalory] = useState<any>();
  const [crab, setCrab] = useState<any>();
  const [fat, setFat] = useState<any>();
  const [protein, setProtein] = useState<any>();
  const [allergens, setAllergens] = useState<any[]>([]);
  const [additonal_info, setAdditonal_info] = useState<any[]>([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepDeleteDialog, setStepDeleteDialog] = useState(false);
  const [recipeNote, setRecipeNote] = useState('');
  const [visible, setVisible] = useState(false);

  const handleRecipe = (data: string) => {
    setSubRecipeName(data);
  };

  const handleUnit = (data: any) => {
    setUnit(data[0]);
  };
  const handleYeild = (data: any) => {
    setYeild(data);
  };

  const handleProposedPrice = (ratio: any) => {
    setPrice((totalPrice * 100) / ratio);
  };

  const handleChangeDatabase = (value: string | string[], data: any) => {
    setDatabase(data);
  };

  const handleSubCategory = (data: any) => {
    setSubCategory(data);
  };

  const handleCostRatio = (data: any) => {
    if (+data <= 100) {
      setCostRatio(data);
      data == '' ? setPrice(0) : handleProposedPrice(+data);
    }
  };

  const handleFinalPrice = (data: any) => {
    setFinalPrice(data);
  };
  const handleWaste = (data: any) => {
    setWaste(data);
  };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    if (key == 'quantity' && data != '')
      dataArray[index]['total_price'] = Number(
        (
          (dataArray[index]['unit_cost'] / dataArray[index]['divided_by']) *
          +data
        ).toFixed(2)
      );
    else
      dataArray[index]['total_price'] = Number(
        (
          dataArray[index]['unit_cost'] / dataArray[index]['divided_by']
        ).toFixed(2)
      );

    return dataArray;
  };

  const handleIngredient = (data: any, index: number, key: any) => {
    let dataArray = ingredient;
    if (key == 'quantity')
      dataArray = handleCostIngredient(dataArray, data, key, index);
    key == 'quantity' && handleTotalPrice(dataArray);
    dataArray[index][key] = data;
    setIngredient([...dataArray]);
  };

  const autoFetchAllergen = (dataSource: any) => {
    let arr = dataSource.map((data: any) => {
      if (data?.ingredient_name != '' || +data?.unit_cost != 0)
        return data.allergens_id;
    });
    arr = arr
      ?.filter(function (element: any) {
        return element !== undefined;
      })
      ?.filter((data: any) => data !== null);
    arr = arr.map((data: any) => data.split(',')).flat();
    let allergenId = [...new Set(arr)];
    const FetchAllergen = allergenId.map((data) => {
      let fetchObject = allergenData?.filter((element) => element?.id == data);
      return fetchObject;
    });
    setAllergensByDefault(FetchAllergen.flat());
    setAllergens(FetchAllergen.flat());
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map((data) => data?.total_price);
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(price);
  };

  const handleIngredientObject = (data: any, index: number) => {
    let dataArray = ingredient;
    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredient(() => [...dataArray]);
      setMessages([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setAlerts(true);
      setVisible(false);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredient([...dataArray]);
    } else {
      dataArray[index] = {
        ...data,
        quantity: 0,
        total_price: 0,
      };
      handleTotalPrice(dataArray);
      autoFetchAllergen(dataArray);
      setIngredient([...dataArray]);
    }
  };

  const calculateNutritionValues = () => {
    let c_carb = 0;
    let c_fat = 0;
    let c_protein = 0;
    let c_calorie = 0;

    for (let index = 0; index < ingredient.length; index += 1) {
      const { quantity } = ingredient[index];
      const {
        protein_per_unit,
        carb_per_unit,
        calories_per_unit,
        fat_per_unit,
      } = ingredient[index];

      c_carb += carb_per_unit * parseFloat(quantity);
      c_fat += fat_per_unit * parseFloat(quantity);
      c_protein += protein_per_unit * parseFloat(quantity);
      c_calorie += calories_per_unit * parseFloat(quantity);
    }

    setCrab(c_carb);
    setProtein(c_protein);
    setFat(c_fat);
    setCalory(c_calorie);
  };

  const handleContinue = () => {
    if (stepper < 3) handleNextStepper();
    if (stepper == 0) calculateNutritionValues();
  };

  const deleteIngredient = (index: number) => {
    let DataArray = ingredient;
    DataArray.splice(index, 1);
    handleTotalPrice(DataArray);
    setIngredient([...DataArray]);
    autoFetchAllergen(DataArray);
  };

  const addIngredient = () => {
    setIngredient((prev) => [
      ...prev,
      {
        id: -1,
        ingredient_name: '',
        quantity: 0,
        unit_cost: 0,
        waste_ingredient: '',
        small_unit: 'g',
        total_price: 0,
      },
    ]);
  };

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      const filterValue = data?.find((x: any) => x.slug === 'sub_recipe');
      setParentCategory(filterValue?.id);
    }
  };

  //Step 2

  const handleIndex = (data: number) => setStepIndex(data);
  const fetchDataById = async (id: any) => {
    const data = await SubRecipeActions.getDetailRecipe(id);

    if (data) {
      setLoader(false);
    }
    setImage(data?.data.general.thumbnail);
    setUnit({
      unit: data?.data.general.unit,
      id: data?.data.general.unit_id,
    });
    setSubRecipeName(data?.data.general.recipe_name);
    setDescription(data?.data.general.description);

    setIngredient(
      data?.data.ingredients.map((data: any) => {
        return {
          ...data,
          id: data?.ingredient_id,
          small_unit: data?.unit,
          waste_ingredient: data?.waste,
          total_price: data?.cost_per_ingredient
            ? data?.cost_per_ingredient
            : 0,
        };
      })
    );
    setSubCategory({
      id: data?.data?.general?.menu_category_id,
      sub_category: data?.data?.general?.menu_category,
    });
    setAllergens(data?.data.general.allergens);
    setYeild(data?.data.general.quantity);
    setWaste(data?.data.general.waste);
    setCostRatio(data?.data.general.fcr);
    setFinalPrice(data?.data.general.final_price);
    setSteps(data?.data.general.steps);
    setCalory(data?.data.general.calorie);
    setFat(data?.data.general.fat);
    setCrab(data?.data.general.carb);
    setProtein(data?.data.general.protein);
    setPrep(data?.data.general.prep_time);
    setRecipeNote(data?.data.general.instruction);
    setAdditonal_info(data?.data.general.additional_attributes);
    setTotalPrice(data?.data?.general?.total_cost);
    setPrice(data?.data?.general?.propose_price);
  };

  // const checkCategory = async () => {
  //   const { data, success } = await MenuCategoryActions.getParentCategories();
  //   if (success) {
  //     const menuCategory_id = data?.find((x: any) => x.slug === 'menu')?.id;
  //     if (menuCategory_id) {
  //       const response = await MenuCategoryActions.getSubCategories(
  //         menuCategory_id,
  //         10,
  //         1
  //       );
  //       if (response?.success == undefined || !response?.success) {
  //         let responseDialogData = {
  //           type: 'error',
  //           messageArray: [`Please create Menu Category, before!`],
  //           OnClick: () => {
  //             navigate('/app/subrecipe');
  //           },
  //         };
  //         ResponseDialog(responseDialogData);
  //       } else {
  //       }
  //     }
  //   }

  //   const menuCategories = await Attributes.getMenuCategory(company?.id);

  //   const getMenuCategory = menuCategories?.data?.items.filter(
  //     (data: any) => data?.name.toLowerCase() == 'sub recipe'
  //   );
  //   setLoader(false);
  //   if (
  //     menuCategories?.data?.items?.length > 0 &&
  //     getMenuCategory?.length > 0
  //   ) {
  //     setCategory(getMenuCategory[0]);
  //   } else {
  //     let responseDialogData = {
  //       type: 'error',
  //       messageArray: [`Please create Menu Category, before!`],
  //       OnClick: () => {
  //         navigate('/app/subrecipe');
  //       },
  //     };
  //     ResponseDialog(responseDialogData);
  //   }
  // };

  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };
  useEffect(() => {
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
    fetchParentCategories();
    fetchAllergen();
    // checkCategory();
  }, []);

  useEffect(() => {
    costRatio != 0 && handleProposedPrice(costRatio);
  }, [ingredient]);

  useEffect(() => {
    if (parentCategory) {
      fetchSubCategories();
    }
  }, [parentCategory]);

  const fetchSubCategories = async () => {
    const subCategories = await MenuCategoryActions.getSubCategoriesForWizard(
      parentCategory,
      100
    );
    const categories = subCategories?.data?.items.map((x: any) => {
      return {
        value: x.id,
        label: x.sub_category,
        key: x.id,
      };
    });
    setParentSubCategory(categories);
  };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const addSteps = () => {
    setSteps((prev) => [...prev, '']);
  };

  const handlePrep = (data: string) => {
    setPrep(data);
  };

  const handleChangeStep = (text: any, index: number) => {
    let DataArray = steps;
    DataArray[index] = text;
    setSteps([...DataArray]);
  };
  const handleDeleteStep = () => {
    let DataArray = steps;
    DataArray.splice(stepIndex, 1);
    setSteps([...DataArray]);
    setStepDeleteDialog(false);
  };

  const handleDeleteDialog = (data: boolean) => setStepDeleteDialog(data);

  const handleRecipeNote = (data: string) => {
    setRecipeNote(data);
  };

  const handleCalory = (data: string) => {
    setCalory(+data);
  };

  const handleCrab = (data: string) => {
    setCrab(+data);
  };

  const handleFat = (data: string) => {
    setFat(+data);
  };

  const handleProtein = (data: string) => {
    setProtein(+data);
  };

  const handleallergenCheck = (e: CheckboxChangeEvent, data: any) => {
    const { checked } = e.target;
    if (
      allergensByDefault.find((val: any) => val?.id == data?.id) == undefined
    ) {
      if (checked) {
        setAllergens((prev) => [...prev, data]);
      } else {
        let dataArray = allergens;
        const updated = dataArray.filter((val) => val?.id != data?.id);
        setAllergens([...updated]);
      }
    }
  };
  const handleaddionalinfocheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: additionalInfo
  ) => {
    const { checked } = e.target;

    if (checked) {
      setAdditonal_info((prev) => [...prev, data]);
    } else {
      let dataArray = additonal_info;
      const updated = dataArray.filter((val) => val?.id !== data?.id);

      setAdditonal_info([...updated]);
    }
  };

  const handleBack = () => {
    if (stepper > 0) {
      setVisible(false);
      setAlerts(false);
      setStepper(stepper - 1);
    } else navigate('/app/subrecipe');
  };

  const checkEmptyIngredientQuantity = () => {
    const quantity = ingredient.filter(
      (data) => data?.quantity != '' || data?.quantity != 0
    );
    return quantity.length > 0 ? false : true;
  };
  const handleNextStepper = async () => {
    const checkIngredientQuantity = checkEmptyIngredientQuantity();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();

    if (
      (stepper == 0 &&
        (SubRecipeName == '' ||
          (Object.keys(unit).length === 0 &&
            (Object.getPrototypeOf(unit) === Object.prototype) == undefined) ||
          !yeild ||
          !waste ||
          ingredient?.length == 0 ||
          totalPrice == 0 ||
          (+price === 0 && +finalPrice === 0) ||
          checkIngredientQuantity ||
          (finalPrice == '' && price == 0))) ||
      (stepper == 1 && (steps.length == 0 || prep == ''))
    ) {
      setVisible(true);
      setAlerts(false);
      window.scrollTo(0, 0);
      return;
    }

    if (stepper == 1 && update) {
      setErrorList({});
      setErrorStatus(false);
      setVisible(false);
      const params = {
        general: {
          thumbnail:
            imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
              ? image
              : null,
          recipe_name: SubRecipeName,
          menu_category_id: subCategory?.id,
          unit_id: unit?.id,
          quantity: Number(yeild),
          company_id: company?.id,
          brand_id: brand?.brand_id,
          shop_id: shop?.id || 0,
          currency_id: brand?.currency_id,
          is_verified: 1,
          verified_by: 'Head of Culinary',
          created_by: user?.id,
        },
        ingredients: ingredient.map((data: any) => {
          return {
            ingredient_name: data?.ingredient_name,
            ingredient_id: data?.id,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            cost_per_ingredient: data?.total_price,
            waste: Number(data?.waste_ingredient),
          };
        }),

        costing: {
          total_cost: totalPrice,
          fcr: Number(costRatio),
          propose_price: JSON.stringify(price),
          final_price: +finalPrice === 0 ? price : finalPrice,
          waste: Number(waste),
        },
        production: {
          instructions: recipeNote,
          description: description,
          prep_time: Number(prep),
          calorie: calory,
          servings: 0,
          protein: protein,
          carb: crab,
          fat: fat,
          allergens_id: allergens.map((data) => data?.id).toString(),
          steps: steps,
          additional_attribute: additonal_info
            .map((data) => data?.attribute_name)
            .toString(),
          additional_attribute_id: additonal_info
            .map((data) => data?.id)
            .toString(),
        },
        inventory: {
          calorie: calory,
          calorie_unit: 1,
          calories_per_unit: 1,
          carb: crab,
          carb_unit: 2,
          carb_per_unit: 1,
          protein: protein,
          protein_unit: 2,
          protein_per_unit: 1,
          fat: fat,
          fat_unit: 2,
          fat_per_unit: 1,
        },
      };

      let responseDialogData;
      setLoader(true);
      const response = await SubRecipeActions.updateSubRecipe(
        params,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? null
          : imageObject,
        id
      );
      setLoader(false);
      if (response != undefined) {
        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
          setStepper(0);
        } else {
          setStepper(stepper + 1);
          responseDialogData = {
            OnClick: () => {
              navigate('/app/subrecipe');
            },
            type: 'success',
            messageArray: [` Your ${SubRecipeName}`, ` has been Updated`],
          };
          ResponseDialog(responseDialogData);
        }
      }
    } else if (stepper < 2) {
      if (stepper == 1) {
        setErrorList({});
        setErrorStatus(false);

        try {
          let responseDialogData;
          setLoader(true);
          const data = {
            general: {
              recipe_name: SubRecipeName,
              menu_category_id: subCategory?.id,
              unit_id: unit?.id || null,
              quantity: Number(yeild),
              company_id: company?.id,
              brand_id: brand?.brand_id,
              shop_id: shop?.id || 0,
              currency_id: brand?.currency_id,
              is_verified: 1,
              verified_by: 'Head of Culinary',
              created_by: user?.id,
            },
            ingredients: ingredient.map((data: any) => {
              return {
                ingredient_name: data?.ingredient_name,
                ingredient_id: data?.id,
                unit_id: data?.unit_id,
                quantity: data?.quantity,
                cost_per_ingredient: data?.total_price,
                waste: Number(data?.waste_ingredient),
              };
            }),

            costing: {
              total_cost: totalPrice,
              fcr: Number(costRatio),
              propose_price: JSON.stringify(price),
              final_price: +finalPrice === 0 ? price : finalPrice,
              waste: Number(waste),
            },
            production: {
              instructions: recipeNote,
              description: description,
              prep_time: Number(prep),
              servings: 0,
              calorie: calory,
              protein: protein,
              carb: crab,
              fat: fat,
              allergens_id: allergens.map((data) => data?.id).toString(),
              steps: steps,
              additional_attribute: additonal_info
                .map((data) => data?.attribute_name)
                .toString(),
              additional_attribute_id: additonal_info
                .map((data) => data?.id)
                .toString(),
            },
            inventory: {
              calorie: calory,
              calorie_unit: 1,
              calories_per_unit: 1,
              carb: crab,
              carb_unit: 2,
              carb_per_unit: 1,
              protein: protein,
              protein_unit: 2,
              protein_per_unit: 1,
              fat: fat,
              fat_unit: 2,
              fat_per_unit: 1,
            },
          };
          const response = await SubRecipeActions.createSubRecipe(
            data,
            imageObject
          );
          setLoader(false);
          if (response != undefined) {
            if (!response?.success) {
              setErrorList(response?.error);
              setErrorStatus(true);
              setStepper(0);
            } else {
              setStepper(stepper + 1);
              responseDialogData = {
                OnClick: () => {
                  navigate('/app/subrecipe');
                },
                type: 'success',
                messageArray: [` Your ${SubRecipeName} has been Created`],
              };
              ResponseDialog(responseDialogData);
            }
          }
        } catch (e) {
          let responseDialogData = {
            OnClick: () => {
              setStepper(0);
            },
            type: 'error',
            messageArray: ['Validation Failed!', 'Server Error'],
          };
          ResponseDialog(responseDialogData);
        }
      } else setStepper(stepper == 2 ? stepper : stepper + 1);
      setVisible(false);
      setAlerts(false);
      window.scrollTo(0, 0);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="AddNew Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/subrecipe');
        }}
      />
      <Page title="AddNew Sub Recipe" className="subRecipeContainer">
        {visible && (
          <Alert
            message="Error"
            description="Please Complete required fields!"
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}
        {alerts &&
          messages?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setAlerts(false)}
              key={index}
            />
          ))}
        <Stepper stepper={stepper} component="subRecipe" />

        <Spin
          spinning={loader}
          size="large"
          className="loadIngredient recipeBuilderloaderHeight"
        >
          <div className="wizardContainer">
            {stepper === 0 ? (
              <SubRecipeStepOne
                component="subRecipe"
                SubRecipeName={SubRecipeName}
                category={category}
                unit={unit}
                yeild={yeild}
                costRatio={costRatio}
                totalPrice={totalPrice}
                price={price}
                waste={waste}
                finalPrice={finalPrice}
                ingredient={ingredient}
                subCategory={subCategory}
                handleRecipe={handleRecipe}
                handleUnit={handleUnit}
                handleYeild={handleYeild}
                parentSubCategory={parentSubCategory}
                handleCostRatio={handleCostRatio}
                handleFinalPrice={handleFinalPrice}
                handleWaste={handleWaste}
                handleIngredient={handleIngredient}
                deleteIngredient={deleteIngredient}
                addIngredient={addIngredient}
                handleIngredientObject={handleIngredientObject}
                screenName={'Sub Recipe'}
                handleChangeDatabase={handleChangeDatabase}
                handleSubCategory={handleSubCategory}
                database={database}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            ) : (
              <SubRecipeStepTwo
                component="subRecipe"
                image={image}
                description={description}
                recipeNote={recipeNote}
                steps={steps}
                prep={prep}
                calory={calory}
                crab={crab}
                fat={fat}
                protein={protein}
                stepDeleteDialog={stepDeleteDialog}
                additonal_info={additonal_info}
                allergens={allergens}
                serving={''}
                handleServing={(e: any) => {
                  e;
                }}
                handleRecipeNote={handleRecipeNote}
                handleIndex={handleIndex}
                handleImage={handleImage}
                handleDescription={handleDescription}
                addSteps={addSteps}
                handleChangeStep={handleChangeStep}
                handleCalory={handleCalory}
                handleCrab={handleCrab}
                handleFat={handleFat}
                handleProtein={handleProtein}
                handleallergenCheck={handleallergenCheck}
                handleaddionalinfocheck={handleaddionalinfocheck}
                handleDeleteStep={handleDeleteStep}
                handleDeleteDialog={handleDeleteDialog}
                handlePrep={handlePrep}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            )}
            <div className="butonGroup">
              <div className="wizard-button" onClick={handleBack}>
                Back
              </div>
              <div className="continue wizard-button" onClick={handleContinue}>
                Continue
              </div>
            </div>
          </div>
        </Spin>
      </Page>
    </React.Fragment>
  );
};
export default SubRecipeWizard;
