import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recipeTypes: [],
  ingredientsRecipe: [],
  selectedDatabase: 'USA',
};

const RecipeSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setRecipeTypes: (state, { payload }) => {
      state.recipeTypes = payload;
    },

    setRecipeIngredients: (state, { payload }) => {
      state.ingredientsRecipe = payload;
    },

    setDatabaseIngredient: (state, { payload }) => {
      state.selectedDatabase = payload;
    },
  },
});

const { reducer, actions } = RecipeSlice;

export const { setRecipeTypes, setRecipeIngredients, setDatabaseIngredient } =
  actions;

export default reducer;
