import { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import './styles.scss';

type Props = {
  action: Function;
  placeholderText?: string;
  additioanlClassName?: string;
};

const SearchBox = (props: Props) => {
  const [keyword, setKeyword] = useState('');

  const applyAction = () => {
    props.action(keyword);
  };

  return (
    <Input
      onPressEnter={applyAction}
      placeholder={props.placeholderText}
      className={`b2 search-box ${props.additioanlClassName}`}
      prefix={<SearchOutlined />}
      onChange={(e) => setKeyword(e.target.value)}
      allowClear={{
        clearIcon: <CloseOutlined onClick={() => props.action('')} />,
      }}
    />
  );
};

export default SearchBox;
