import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Checkbox,
  Collapse,
  Input,
  Select,
  Spin,
  TimePicker,
} from 'antd';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import './supplyWizard.scss';
import SupplierActions from '../../redux/middleware/supplier';
import type { Dayjs } from 'dayjs';
import Utils from '../../redux/utils';

const SupplyWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Panel } = Collapse;
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [company, setCompany] = React.useState('' as any);
  const [address, setAddress] = React.useState('' as any);
  const [city, setCity] = useState<any[]>([]);
  const [phone, handlePhone] = React.useState('' as any);
  const [supplierEmail, setSupplierEmail] = React.useState('');
  const [supplierName, setSupplierName] = React.useState('');
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [blocked] = useState(true);
  const [cityList, setCityList] = useState<any[]>([]);
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  // const company_name = user?.company_name;
  const country_name = user?.country_name;
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [countryCode, setCountryCode] = useState(
    Utils.getCurrentUser()?.country_code
  );
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [deliveryTimes, setDeliveryTimes] = useState<any[]>([]);

  const options = [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },
  ];
  const handleBack = () => {};

  const getCitiesListing = async () => {
    const response = await SupplierActions.getSupplierCity();
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.map((item: any) => ({
          value: item?.id,
          key: item?.shop_id,
          label: item?.shop_name + '  -  ' + item?.city,
        }));
      setCityList(list);
    }
  };

  const handleCityId = (id: any) => {
    const data: any = id.map((data: any) => {
      return cityList.filter((id: any) => {
        if (id.value == data) {
          return id;
        }
      });
    });
    const filteredCities = data.flat();
    setCity(filteredCities);
  };

  const getSupplierInfo = async (id: any) => {
    const response = await SupplierActions.getSupplierById(id);
    if (response?.success) {
      const { data } = response;
      if (data) {
        setApiLoader(false);
      }

      setSupplierEmail(data[0]?.email);
      handlePhone(data[0]?.phone);
      setSupplierName(data[0]?.supplier_name);
      setCompany(data[0]?.contact_name);
      setAddress(data[0]?.address);
      setDeliveryTimes(data[0]?.delivery_timings);
      setCity(
        data[0]?.city_id?.map((data: any) => {
          return {
            ...data,
            label: data?.shop_name + '  -  ' + data?.city,
            value: data?.id,
          };
        })
      );
    }
  };

  React.useEffect(() => {
    getCitiesListing();
    if (update) {
      setApiLoader(true);
      getSupplierInfo(id?.id);
    }
  }, []);

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    setErrorList({});
    setErrorStatus(false);
    const user = await Utils.getCurrentUser();

    if (
      supplierEmail === '' ||
      address === '' ||
      supplierName === '' ||
      company === '' ||
      !phone
    ) {
      setVisible(true);
      return;
    }
    const payload = {
      contact_name: company,
      supplier_name: supplierName,
      phone: phone,
      email: supplierEmail,
      address: address,
      company_id: user?.company_id,
      country_id: user?.country_id,
      shops: city?.map((data: any) => {
        return {
          city_id: data?.value,
          shop_id: data?.key,
        };
      }),
      delivery_timings: deliveryTimes.map((deliveryTime: any) => ({
        shop_id: deliveryTime?.shop_id,
        day: deliveryTime?.day,
        start_time: deliveryTime?.start_time?.format('HH:mm'),
        end_time: deliveryTime?.end_time?.format('HH:mm'),
      })),
    };

    try {
      let responseDialogData;

      setApiLoader(true);
      let response;
      if (update) {
        response = await SupplierActions.updateSupplier(id?.id, payload);
      } else {
        response = await SupplierActions.addSupplier(payload);
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        // responseDialogData = {
        //   OnClick: () => {},
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/supply');
          },
          type: 'success',
          messageArray: [
            ` Your supplier - ${supplierName} has been ${
              !update ? 'Created' : 'Updated'
            }`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Server Error!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const onChange = (
    time: Dayjs,
    timeString: string,
    selectedDay: any,
    label: String,
    data: any
  ) => {
    let dataArray = deliveryTimes;
    const index = deliveryTimes?.findIndex(
      (obj: any) => obj?.day == selectedDay && obj?.shop_id == data?.key
    );
    if (label == 'start_time')
      dataArray[index] = {
        ...dataArray[index],
        start_time: time,
        start_time_string: timeString,
      };
    else
      dataArray[index] = {
        ...dataArray[index],
        end_time: time,
        end_time_string: timeString,
      };
    setDeliveryTimes([...dataArray]);
  };

  const handleDeliveryTimes = (e: any, data: any, day: String) => {
    const isChecked = e.target.checked;
    const values = [...checkedValues];
    if (isChecked) {
      values.push(e.target.value);
      setDeliveryTimes(() => [
        ...deliveryTimes,
        {
          shop_id: data?.key,
          day,
          start_time: '',
          end_time: '',
          start_time_string: '',
          end_time_string: '',
        },
      ]);
    } else {
      const index = deliveryTimes?.findIndex(
        (removeObj: any) =>
          removeObj?.day == day && removeObj?.shop_id == data?.key
      );
      const dataArray = deliveryTimes;
      dataArray.splice(index, 1);
      setDeliveryTimes([...dataArray]);
      if (index !== -1) {
        values.splice(index, 1);
      }
    }
    setCheckedValues(values);
  };

  const renderCheckboxGroup = (data: any) => {
    return options.map((option: any) => {
      return (
        <div key={option.value} className="shopDaysContainer">
          <Checkbox
            checked={
              deliveryTimes?.find(
                (deliveryObj: any) =>
                  deliveryObj?.day == option?.label &&
                  deliveryObj?.shop_id == data?.key
              )?.day == option.label
            }
            value={data?.key + option.value}
            onChange={(e: any) => {
              handleDeliveryTimes(e, data, option.label);
              // console.log(
              //   deliveryTimes?.find(
              //     (time: any) =>
              //       time?.shop_id == data?.key && time?.day == option?.value
              //   )?.start_time
              // );
            }}
          >
            {option.label}
          </Checkbox>
          <div>
            <TimePicker
              // use12Hours
              // defaultValue={Dayjs('12:08:23', 'HH:mm:ss')}
              value={
                deliveryTimes?.find(
                  (time: any) =>
                    time?.shop_id == data?.key && time?.day == option?.value
                )?.start_time
              }
              format="HH:mm"
              onChange={(time: any, timeString: string) =>
                onChange(time, timeString, option?.label, 'start_time', data)
              }
              placeholder="From"
            />
            <TimePicker
              format="HH:mm"
              onChange={(time: any, timeString: string) =>
                onChange(time, timeString, option?.label, 'end_time', data)
              }
              placeholder="To"
              value={
                deliveryTimes?.find(
                  (time: any) =>
                    time?.shop_id == data?.key && time?.day == option?.value
                )?.end_time
              }
            />
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Supplier"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/supply');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Supplier">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer supplierWizard">
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Company Name (Supplier) <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Company name"
                size="large"
                onChange={(e) => setCompany(e.target.value)}
                value={company}
                required
                className={
                  errorList['company_id']?.length > 0 ? 'borderRed' : ''
                }
              />

              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['company_id']}
                </div>
              )}
            </div>{' '}
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Point of Contact Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Company name"
                size="large"
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
                required
                className={
                  errorList['supplier_name']?.length > 0 ? 'borderRed' : ''
                }
              />

              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['supplier_name']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Point of Contact Number <span className="sterik">*</span>
              </div>
              <Input
                addonBefore={countryCode}
                placeholder="Enter Phone Number"
                type="number"
                value={phone}
                onChange={(e) => handlePhone(Number(e.target.value))}
                className={errorList['phone']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['phone']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Point of Contact Email <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Email"
                size="large"
                value={supplierEmail}
                onChange={(e) => setSupplierEmail(e.target.value)}
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Address <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Address"
                size="large"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                required
                className={errorList['address']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['address']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Country <span className="sterik">*</span>
              </div>
              {/* <Select
                size={'large'}
                placeholder={'Select Country'}
                value={country}
                onChange={handlePickerChange('country')}
                style={{ width: 560 }}
                options={countryList}
              /> */}
              <Input
                placeholder="Enter Country name"
                size="large"
                value={country_name}
                disabled={blocked}
                required
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Branch In City <span className="sterik">*</span>
              </div>
              <Select
                showSearch
                size={'large'}
                placeholder={'Select city'}
                mode="multiple"
                value={city}
                onChange={handleCityId}
                style={{ width: 560 }}
                options={cityList}
                className={errorList['city_id']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['city_id']}
                </div>
              )}
            </div>
            <Collapse
              className="supplierCollapseContainer"
              bordered={false}
              accordion
              // defaultActiveKey={[1, 2, 3]}
              ghost
              expandIconPosition="right"
              // expandIcon={({ isActive }) =>
              //   isActive ? <img src={ArrowDown} /> : <img src={ArrowDown} />
              // }
            >
              {city?.map((data: any, index: number) => (
                // <Panel
                //   className="panelStyling"
                //   key={index}
                //   header={data?.label}
                // >
                //   <Checkbox.Group
                //     className="checkBoxStyling"
                //     options={options || []}
                //     defaultValue={['Apple']}
                //     // value={checkedListCategory}
                //     // onChange={onChangeCategoryValues}
                //   >
                //     <div>
                //       <Input placeholder="Enter Value" />
                //     </div>
                //   </Checkbox.Group>
                // </Panel>
                <Panel
                  className="panelStyling"
                  key={index}
                  header={data?.label}
                >
                  <Checkbox.Group value={checkedValues}>
                    {renderCheckboxGroup(data)}
                  </Checkbox.Group>
                </Panel>
              ))}
            </Collapse>
            <div className="butonGroup spacingForButtonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default SupplyWizard;
