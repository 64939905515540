import React from 'react';
import { Input } from 'antd';
import useClickListener from '../../helper/outsideClickListener';
import Delete from '../../assets/images/Delete.svg';
import ArrowDownBlack from '../../assets/images/ArrowDownBlack.png';
import './CookingMethodDropdown.scss';

type Props = {
  CookingVariation: any;
  searchedValue: any;
  showRecipeView: any;
  recipes: any;
  handleChangeSearchedValue: any;
  handleSelectOption: any;
  handleDeleteRecipe: any;
  handleAddItem: any;
  handleAddRecipeStep: any;
  handleDeleteRecipeStep: any;
  handleStepValue: any;
};

const CookingMethodComponent = (props: Props) => {
  let {
    CookingVariation,
    searchedValue,
    showRecipeView,
    recipes,
    handleChangeSearchedValue,
    handleSelectOption,
    handleDeleteRecipe,
    handleAddItem,
    handleAddRecipeStep,
    handleDeleteRecipeStep,
    handleStepValue,
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(true);

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleSelectOptionValue = (ingredient: any) => {
    handleSelectOption(ingredient);
    setIsComponentVisible(false);
  };

  const handleAdd = () => {
    setIsComponentVisible(true);

    handleAddItem();
  };

  return (
    <div className="recipeStepperContainer">
      {/* <div className="wizardSubContainer checkBox">
        <Checkbox onChange={onChangeMenu} value={menu}>
          Multiple Cooking Methods
        </Checkbox>
      </div> */}
      <div className="wizardSubContainer">
        <div className="headingContainer">
          <div className="wizardLabel">
            {' '}
            Cooking Methods <span className="sterik">*</span>{' '}
          </div>
          {/* {menu ? ( */}
          <div className="wizardLabel" onClick={() => handleAdd()}>
            <span className="plus">+</span> Add Item
          </div>
          {/* ) : null}  */}
        </div>

        <div className="Recipe-form-group" ref={ref}>
          <Input
            type="text"
            placeholder="Input category here"
            onClick={handleClick}
            onChange={(e) => handleChangeSearchedValue(e)}
            value={searchedValue}
          />

          {isComponentVisible ? (
            <ul className="dropdown-list">
              <li>
                <ul className="subMenu">
                  <li>
                    <h4 className="placeholder">
                      Select an option or create one
                    </h4>
                  </li>

                  {CookingVariation?.map((method: any, index: number) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleSelectOptionValue(method)}
                      >
                        <p>{method?.name} </p>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          ) : null}
          {showRecipeView
            ? recipes.map((recipe: any, index: number) => {
                return (
                  <div key={index} className="drop-down-wrapper">
                    <div className="drop-down">
                      <div className="drop-down-header">
                        <p>{recipe?.name}</p>
                        <button>
                          <img src={ArrowDownBlack} alt={''} />
                        </button>
                      </div>
                      <div className="drop-down-content">
                        <h4>
                          Steps <span className="sterik">*</span>
                        </h4>
                        {recipe?.steps?.map((step: any, index: number) => {
                          return (
                            <div key={index} className="form-group">
                              <span>{index + 1}</span>
                              <input
                                type="text"
                                value={step}
                                onChange={(e) =>
                                  handleStepValue(e, recipe, index)
                                }
                              />
                              <button
                                onClick={() =>
                                  handleDeleteRecipeStep(recipe, index)
                                }
                              >
                                <img src={Delete} width={15} alt="" />
                              </button>
                            </div>
                          );
                        })}
                        <div onClick={() => handleAddRecipeStep(recipe, index)}>
                          <span className="plus">+</span> Add Item
                        </div>
                      </div>
                    </div>
                    <button
                      className="wrapper-btn"
                      onClick={() => handleDeleteRecipe(recipe)}
                    >
                      <img src={Delete} width={15} alt="" />
                    </button>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default CookingMethodComponent;
