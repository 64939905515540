/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Card, Row, Col, Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from './breadcrumb';
import BreadcrumbIcon from './breadcrumbIcon';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components';
import ArrowDownBlack from '../../assets/images/ArrowDownBlack.png';
import RecipeActions from '../../redux/middleware/recipe';
import MarketListForRB from '../../redux/middleware/marketListForRecipeBuilder';
// import MarketListForRB from '../../redux/middleware/marketListForRecipeBuilder';
import './styles.scss';

type breadcrumbProps = {
  button?: any;
  breadcrumbIcon?: boolean;
  iconAction: () => void;
  heading: string;
  perm_status?: any;
  TaggedDropdown?: any;
  handleCSV?: any;
};

const sampleRecipeCSV =
  'https://admin.sadaynaal.com/sample_csv/Recipe%20Bulk%20Upload%20-%20Sample%20Min.csv';

const sampleSubRecipeCSV =
  'https://admin.sadaynaal.com/sample_csv/Sample%20-%20SubRecipe%20Bulk%20Upload%20-%20Minimal.csv';

const sampleBulkRecipeCSV =
  'https://admin.sadaynaal.com/sample_csv/Sample%20-%20Recipe%20Bulk%20Upload%20-%20Minimal.csv';

const sampleBulkRecipeForMarketlist =
  'https://admin.sadaynaal.com/sample_csv/sample_marketlist.csv';

const sampleRDUploadMArketList =
  'https://admin.sadaynaal.com/sample_csv/R&D_Sample_Marketlist.csv';

const sampleBulkUploadRDSubRecipe =
  'https://admin.sadaynaal.com/sample_csv/R&D_%20Sample_SubRecipe.csv';
const sampleBulkUploadRDRecipe =
  'https://admin.sadaynaal.com/sample_csv/Sample%20-%20RB%20Menu%20Item%20Bulk%20Upload.csv';
export default function breadcrumb(props: breadcrumbProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card>
      <Row className="breadcrumbMainWrapper">
        <div className="breadcrumb-wrapper">
          {location.pathname != '/' && props.breadcrumbIcon && (
            <div className="breadcrumb-button">
              <BreadcrumbIcon
                className="breadcrumb-icon"
                handleClick={props.iconAction}
                shape="circle"
                size="large"
              />
            </div>
          )}
          <div className="breadcrumb">
            <h1 className="breadcrumb-heading">{props?.heading}</h1>
            <BreadCrumb homepath="Dashboard" title={props?.heading} />
          </div>
        </div>
        {props?.heading === 'Upload Market List' && (
          <Col className="recipeBtn-wrapper" lg={15}>
            <Button
              text="Download Sample CSV"
              className="theme-button recipeBtn"
              handleButtonClick={() =>
                openInNewTab(sampleBulkRecipeForMarketlist)
              }
              shape="round"
              size="middle"
            />
          </Col>
        )}
        {props?.heading == 'Upload Recipes' ||
        props?.heading === 'Upload Sub Recipes' ||
        props?.heading === 'RB Upload MarketList' ||
        props?.heading === 'R&D Bulk Upload Sub Recipe' ||
        props?.heading === 'R&D Bulk Upload Recipe' ||
        props?.heading === 'Upload Ingredients' ? (
          <Col className="recipeBtn-wrapper" lg={15}>
            <>
              {/* <Button
                text="Get Marketlist"
                className="theme-button recipeBtn"
                handleButtonClick={() => RecipeActions.getMarketList()}
                shape="round"
                size="middle"
              />
        */}
            </>

            {props?.heading === 'Upload Sub Recipes' && (
              <>
                <Button
                  text="Download Sample CSV"
                  className="theme-button recipeBtn"
                  handleButtonClick={() => openInNewTab(sampleSubRecipeCSV)}
                  shape="round"
                  size="middle"
                />
              </>
            )}

            {props?.heading === 'Upload Recipes' && (
              <>
                <Button
                  text="Download Sample CSV"
                  className="theme-button recipeBtn"
                  handleButtonClick={() => openInNewTab(sampleBulkRecipeCSV)}
                  shape="round"
                  size="middle"
                />
              </>
            )}
            {props?.heading === 'RB Upload MarketList' && (
              <>
                <Button
                  text="Download Sample CSV"
                  className="theme-button recipeBtn"
                  handleButtonClick={() =>
                    openInNewTab(sampleRDUploadMArketList)
                  }
                  shape="round"
                  size="middle"
                />
              </>
            )}
            {props?.heading === 'R&D Bulk Upload Sub Recipe' && (
              <>
                <Button
                  text="Download Sample CSV"
                  className="theme-button recipeBtn"
                  handleButtonClick={() =>
                    openInNewTab(sampleBulkUploadRDSubRecipe)
                  }
                  shape="round"
                  size="middle"
                />
              </>
            )}
            {props?.heading === 'R&D Bulk Upload Recipe' && (
              <>
                <Button
                  text="Download Sample CSV"
                  className="theme-button recipeBtn"
                  handleButtonClick={() =>
                    openInNewTab(sampleBulkUploadRDRecipe)
                  }
                  shape="round"
                  size="middle"
                />
              </>
            )}
            {/* Upload Ingredients */}
            {props?.heading === 'Upload Ingredients' &&
              props?.perm_status === 2 && (
                <>
                  <Button
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() =>
                      openInNewTab(sampleBulkRecipeForMarketlist)
                    }
                    shape="round"
                    size="middle"
                  />
                </>
              )}
          </Col>
        ) : (
          <Col className="breadcrumb-right" lg={7}>
            {props?.heading === 'R&D Sub Recipe' && props?.perm_status === 2 ? (
              <Button
                text="Bulk Upload"
                className="theme-button recipeBtn uploadMarketListButton"
                handleButtonClick={() =>
                  navigate('/app/bulkUploadR&DSubRecipe')
                }
                shape="round"
                size="middle"
              />
            ) : null}
            {props?.heading === 'R&D Recipe List' &&
            props?.perm_status === 2 ? (
              <Button
                text="Bulk Upload"
                className="theme-button recipeBtn uploadMarketListButton"
                handleButtonClick={() => navigate('/app/bulkUploadR&DRecipe')}
                shape="round"
                size="middle"
              />
            ) : null}
            {props?.heading === 'R&D Market List' &&
            props?.perm_status === 2 ? (
              <Button
                text="Upload Marketlist"
                className="theme-button recipeBtn uploadMarketListButton"
                handleButtonClick={() => navigate('/app/R&DuploadMarketList')}
                shape="round"
                size="middle"
              />
            ) : null}
            {/* {props?.heading === 'Inventory List' && props?.perm_status === 2 ? ( */}
            {props?.heading === 'Inventory List' && props?.perm_status !== 2 ? (
              <Button
                text="Stock Count"
                className="theme-button recipeBtn uploadMarketListButton"
                handleButtonClick={() => navigate('/app/stockCount')}
                shape="round"
                size="middle"
              />
            ) : null}
            {props?.heading === 'Inventory List' && props?.perm_status === 2 ? (
              <Button
                text="Upload Ingredients"
                className="theme-button recipeBtn uploadMarketListButton"
                handleButtonClick={() => navigate('/app/uploadIngredients')}
                shape="round"
                size="middle"
              />
            ) : null}
            {/* {props?.heading == 'Supplier' && (
              <Dropdown
                overlayStyle={{ height: '100px' }}
                menu={props?.TaggedDropdown}
                trigger={['click']}
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                className="supplyIngredientBreadcrumbDropdown"
              >
                <Space>
                  <div className="taggedSupplierButton">
                    <div>Tagged Ingredients</div>
                    <img
                      src={ArrowDownBlack}
                      alt=""
                      className="supplierIngredientDownArrow"
                    />
                  </div>
                </Space>
              </Dropdown>
            )} */}
            {props?.perm_status === 2 || !props?.perm_status
              ? props.button
              : null}
          </Col>
        )}
      </Row>
    </Card>
  );
}
