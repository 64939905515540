import React, { useEffect, useState } from 'react';
import { OrderTypeDialog } from '../index';
import { Input, Popover, Spin } from 'antd';
import { Pie } from '@ant-design/plots';
import useClickListener from '../../helper/outsideClickListener';
import Delete from '../../assets/images/Delete.svg';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Utils from '../../redux/utils';
import SubMenu from '../../assets/images/SubMenu.svg';
import Eye from '../../assets/images/Eye.svg';
import Fire from '../../assets/images/Fire.svg';
import './subRecipeDropdown.scss';

type Props = {
  data: any;
  index: any;
  blocked?: any;
  handleIngredientObject: any;
  handleIngredientOnChange: any;
  deleteIngredient: any;
  database?: any;
  component: string;
  blockedForEdit?: any;
  orderVariation?: any;
  handleIngredientOrder?: any;
  openDialog: any;
};

const DemoPie = (props: any) => {
  let { ingredient } = props;

  const data = [
    {
      label: 'Carb',
      type: '分类一',
      value: isNaN(ingredient?.carb_per_unit * parseFloat(ingredient?.quantity))
        ? 0
        : Number(
            (
              ingredient?.carb_per_unit * parseFloat(ingredient?.quantity)
            ).toFixed(2)
          ),
    },
    {
      label: 'Fat',
      type: '分类二',
      value: isNaN(ingredient?.fat_per_unit * parseFloat(ingredient?.quantity))
        ? 0
        : Number(
            (
              ingredient?.fat_per_unit * parseFloat(ingredient?.quantity)
            ).toFixed(2)
          ),
    },
    {
      label: 'Protein',
      type: '分类三',
      value: isNaN(
        ingredient?.protein_per_unit * parseFloat(ingredient?.quantity)
      )
        ? 0
        : Number(
            (
              ingredient?.protein_per_unit * parseFloat(ingredient?.quantity)
            ).toFixed(2)
          ),
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'label',
    // color: ['#d62728', '#2ca02c', '#000000'],
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      content: `{value}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '16px',
        },
        content: `${
          isNaN(
            ingredient?.calories_per_unit * parseFloat(ingredient?.quantity)
          )
            ? 0
            : Number(
                (
                  ingredient?.calories_per_unit *
                  parseFloat(ingredient?.quantity)
                ).toFixed(2)
              )
        } kcal`,
      },
    },
  };
  return <Pie {...config} />;
};

const SubRecipeDropdown = (props: Props) => {
  let {
    data,
    index,
    handleIngredientObject,
    handleIngredientOnChange,
    deleteIngredient,
    blocked,
    component,
    orderVariation,
    handleIngredientOrder,
    database,
    blockedForEdit,
  } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [search, setSearch] = useState(data?.ingredient_name);
  const [sortIngredient, setSortIngredient] = useState<any[]>([]);
  const [spinner, setSpinner] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [blockUnitField] = useState(true);
  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false);
  const [orderTypeData, setOrderTypeData] = useState<any>({});
  const [filterOptions] = useState([
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'I', value: 'I' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'M', value: 'M' },
    { key: 'N', value: 'N' },
    { key: 'O', value: 'O' },
    { key: 'P', value: 'P' },
    { key: 'Q', value: 'Q' },
    { key: 'R', value: 'R' },
    { key: 'S', value: 'S' },
    { key: 'T', value: 'T' },
    { key: 'U', value: 'U' },
    { key: 'V', value: 'V' },
    { key: 'W', value: 'W' },
    { key: 'X', value: 'X' },
    { key: 'Y', value: 'Y' },
    { key: 'Z', value: 'Z' },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [popUp, setPopUp] = useState<any>(null);
  const brand = Utils.getCurrentBrand();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);

  const fetchIngredient = async (key: any, word: any) => {
    const data = await SubRecipeActions.getSubRecipeInventoryByCharacter(
      key,
      word
    );
    setSortIngredient(data?.data?.items);
    setSpinner(false);
  };

  useEffect(() => {
    if (sortIngredient?.length === 0) {
      setSelectedFilter('A');
      fetchIngredient(database?.key, 'a');
    }
    if (data?.ingredient_name == '') {
      setSearch(data?.ingredient_name);
    }
  }, [database, data?.ingredient_name]);

  const handleIngredientClick = (data: any, index: number) => {
    handleIngredientObject(data, index);
    setSearch(data?.ingredient_name);
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    setSearch(data?.ingredient_name);
  }, [data]);

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
    setShowListViewOne(true);
  };

  const handleChangeName = async (e: any) => {
    setSearch(e.target.value);
    handleIngredientOnChange(e.target.value, index, 'ingredient_name');
    setSpinner(true);
    const filterData = await SubRecipeActions.getSubRecipeInventory(
      database?.key,
      e.target.value
    );
    setSortIngredient(filterData?.data?.items);
    setSpinner(false);
  };

  const handleFilterOption = (filterOption: any) => {
    setSpinner(true);

    setSelectedFilter(filterOption?.key);
    fetchIngredient(database?.key, filterOption?.key);
  };

  // const handleSearchIngredient = async (e: string) => {
  //   setSpinner(true);
  //   const data = await SubRecipeActions.getSubRecipeInventory(database?.key, e);
  //   setSearchResults(data?.data?.items);
  //   setSpinner(false);
  // };

  // const handleChange = (e: any) => {
  //   handleIngredientOnChange(e, index, 'ingredient_name');
  //   if (!isComponentVisible) {
  //     setIsComponentVisible(true);
  //   }
  //   if (e !== '') {
  //     setShowListViewOne(false);
  //     handleSearchIngredient(search);
  //   } else {
  //     setShowListViewOne(true);
  //     setSearchResults([]);
  //   }
  // };

  // const handleSearchFunction = () => {
  //   handleChange(search);
  // };

  const handleSelectOption = (ingredient: any) => {
    handleIngredientOnChange(
      ingredient?.ingredient_name,
      index,
      'ingredient_name'
    );
    setSearch(ingredient?.ingredient_name);
    handleIngredientObject(ingredient, index);
    setIsComponentVisible(false);
  };

  const handleOrderTypeDialog = (id: any, name: any) => {
    handleIngredientOrder({
      id,
      name,
      variation_type: 'Order Type',
      variation_type_id: 3,
    });
    setOpenOrderTypeDialog(false);
  };

  const handleCancelOrderDialog = () => {
    setOpenOrderTypeDialog(false);
  };

  return component == 'subRecipe' || component == 'productionRecipe' ? (
    <div className="Ingredient ingredientContainerSpace">
      <div className="IngredientSubContainer" ref={ref}>
        <div className="subgroup" style={{ position: 'relative' }}>
          <span className="Ingredient">
            <Input
              placeholder="Enter Ingredient "
              value={search}
              onClick={handleClick}
              // onKeyUp={() => handleSearchFunction()}
              disabled={blocked}
              onChange={(e) => {
                handleChangeName(e);
              }}
            />
          </span>

          <span className="details">
            <Input
              placeholder="0"
              type={'number'}
              value={data?.quantity ? Number(data?.quantity) : ''}
              onChange={(e) =>
                handleIngredientOnChange(e.target.value, index, 'quantity')
              }
            />
          </span>

          <span className="details">
            <Input
              placeholder="0"
              value={data?.small_unit}
              disabled={blockUnitField}
              onChange={(e) =>
                handleIngredientOnChange(e.target.value, index, 'small_unit')
              }
            />
          </span>

          <span className="details">
            <Input
              placeholder="0"
              type={'number'}
              value={
                data?.waste_ingredient ? Number(data?.waste_ingredient) : ''
              }
              onChange={(e) =>
                handleIngredientOnChange(
                  e.target.value,
                  index,
                  'waste_ingredient'
                )
              }
            />
          </span>

          <span className="subDetails2">
            <span>{brand?.currency_name}</span>

            <div>{Number(data?.quantity) && data?.total_price}</div>
          </span>
          {isComponentVisible && (
            <ul className="dropdownIngredientSubRecipe dropdown-list">
              {showListViewOne ? (
                <li className="main-list">
                  <div className="list-btn">
                    {filterOptions?.map((filterOption, index) => {
                      return (
                        <>
                          <p
                            key={index}
                            className={
                              selectedFilter === filterOption?.key
                                ? 'active'
                                : ''
                            }
                            onClick={() => handleFilterOption(filterOption)}
                          >
                            {filterOption?.key}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <ul className="subMenu">
                    <li>
                      <h4 className="placeholder">
                        Select an option or create one
                      </h4>
                    </li>

                    {spinner ? (
                      <Spin
                        spinning={spinner}
                        size="default"
                        className="recipeLoader"
                      />
                    ) : (
                      sortIngredient?.map(
                        (ingredient: any, innerIndex: number) => {
                          return (
                            <li
                              key={innerIndex}
                              onClick={() =>
                                handleIngredientClick(ingredient, index)
                              }
                            >
                              <p>
                                <span
                                  className={`brandIcons ${
                                    !ingredient?.brand_thumbnail
                                      ? `withPlaceholder`
                                      : ''
                                  }`}
                                >
                                  <img
                                    src={ingredient?.brand_thumbnail}
                                    width={30}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                </span>
                                {ingredient?.ingredient_name}{' '}
                                {ingredient?.ingredient_category?.toLowerCase() ==
                                  'sub recipe' && (
                                  <img
                                    src={SubMenu}
                                    width={15}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                )}
                                {ingredient?.verified_by == 'USA' && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="iconFireWhite dropdown-verified-icon"
                                    />
                                  </span>
                                )}
                                {ingredient?.verified_by == 'ChefAdmin' && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="subCol1Icon dropdown-verified-icon"
                                    />
                                  </span>
                                )}
                              </p>
                            </li>
                          );
                        }
                      )
                    )}
                  </ul>
                </li>
              ) : (
                <li>
                  <ul className="searchMenu">
                    {/* {database.label !== 'USA' ? (
                      <li className="create-category">
                        <button onClick={handleCreateCategory}>
                          Create {data?.ingredient_name} as an ingredient
                        </button>
                      </li>
                    ) : null} */}
                    {spinner ? (
                      <Spin
                        spinning={spinner}
                        size="default"
                        className="loaderStyle"
                      />
                    ) : (
                      searchResults?.length > 0 &&
                      searchResults.map((searchResult, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => handleSelectOption(searchResult)}
                          >
                            <p>
                              {searchResult?.ingredient_name}{' '}
                              {searchResult?.ingredient_category?.toLowerCase() ==
                                'sub recipe' && (
                                <img
                                  src={SubMenu}
                                  width={15}
                                  alt=""
                                  className="subRecipeIcons"
                                />
                              )}
                              {searchResult?.verified_by == 'USA' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="iconFireWhite dropdown-verified-icon"
                                  />
                                </span>
                              )}
                              {searchResult?.verified_by == 'ChefAdmin' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="subCol1Icon dropdown-verified-icon"
                                  />
                                </span>
                              )}
                            </p>
                          </li>
                        );
                      })
                    )}
                  </ul>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>

      <img
        src={Delete}
        width={15}
        alt=""
        className="subRecipeDeleteButon"
        onClick={() => deleteIngredient(index)}
      />

      <div className="subrecipe-unit-container">
        <div className="subrecipe-calorie-value">
          {isNaN(data?.calories_per_unit * parseFloat(data?.quantity))
            ? 0
            : Number(
                (data?.calories_per_unit * parseFloat(data?.quantity)).toFixed(
                  2
                )
              )}
        </div>
      </div>
      <div className="subrecipe-calorie-container">
        <Popover
          content={
            <div className="popUp-recipe-Container">
              <DemoPie ingredient={data} />
              {/* <div className="block block1">
                <div>
                  <span className={`callories-number `}>
                    {isNaN(data?.calories_per_unit * parseFloat(data?.quantity))
                      ? 0
                      : Number(
                          (
                            data?.calories_per_unit * parseFloat(data?.quantity)
                          ).toFixed(2)
                        )}
                  </span>
                  <span className="font callories-unit">{'kcal'}</span>
                </div>
                <div>
                  <span className={`crab-number `}>
                    {isNaN(data?.carb_per_unit * parseFloat(data?.quantity))
                      ? 0
                      : Number(
                          (
                            data?.carb_per_unit * parseFloat(data?.quantity)
                          ).toFixed(2)
                        )}
                  </span>
                  <span className="font crab-unit">{'g'}</span>
                </div>
                <div>
                  <span className={`fat-number `}>
                    {isNaN(data?.fat_per_unit * parseFloat(data?.quantity))
                      ? 0
                      : Number(
                          (
                            data?.fat_per_unit * parseFloat(data?.quantity)
                          ).toFixed(2)
                        )}
                  </span>
                  <span className="font fat-unit">{'g'}</span>
                </div>
                <div>
                  <span className={`font protein-number `}>
                    {isNaN(data?.protein_per_unit * parseFloat(data?.quantity))
                      ? 0
                      : Number(
                          (
                            data?.protein_per_unit * parseFloat(data?.quantity)
                          ).toFixed(2)
                        )}
                  </span>
                  <span className="font protein-unit">{'g'}</span>
                </div>
              </div>
              <div className="block block2">
                <span className={'callories-text'}>calories</span>
                <span className={'callories-text'}>carbs</span>
                <span className={'callories-text'}>fats</span>
                <span className={'callories-text'}>proteins</span>
              </div> */}
              <div className="allergen font">Allergens</div>
              <div className="allergen-text">
                {data?.allergens
                  ?.map((allergen: any) => allergen?.name)
                  ?.toString()}
              </div>
            </div>
          }
          title="Details"
          trigger="click"
          open={popUp == index}
          onOpenChange={() => {
            if (popUp != null) setPopUp(null);
            else setPopUp(index);
          }}
        >
          <img
            src={Eye}
            width={20}
            className="ingredient-unmatach-select-icons"
          />
        </Popover>
      </div>
    </div>
  ) : (
    <div key={index} className="recipeIngredient">
      <OrderTypeDialog
        orderVariation={orderVariation}
        open={openOrderTypeDialog}
        handleDialogCancel={handleCancelOrderDialog}
        orderTypeData={orderTypeData}
        handleDialog={handleOrderTypeDialog}
      />
      <div className="recipeIngredientSubContainer" ref={ref}>
        <div className="group">
          <span className="recipeIngredient">
            <Input
              placeholder="Enter Ingredient "
              value={search}
              onClick={handleClick}
              // onKeyUp={() => handleSearchFunction()}
              disabled={blocked || blockedForEdit}
              onChange={(e) => {
                handleChangeName(e);
              }}
            />
          </span>
          <span className="details">
            <Input
              placeholder="0"
              type={'number'}
              value={data?.quantity ? Number(data?.quantity) : ''}
              onChange={(e) =>
                handleIngredientOnChange(e.target.value, index, 'quantity')
              }
            />
          </span>
          <span className="details">
            <Input
              placeholder="0"
              value={data?.small_unit}
              disabled={blockUnitField}
              onChange={(e) =>
                handleIngredientOnChange(e.target.value, index, 'small_unit')
              }
            />
          </span>

          <span className="details2">
            <span>{brand?.currency_name}</span>
            <div>
              {!Number.isNaN(Number(data?.quantity)) && data?.total_price}
            </div>
          </span>
          <span
            className="details3 orderTypeTextStyle"
            onClick={() => {
              setOrderTypeData(data?.order);
              setOpenOrderTypeDialog(true);
            }}
          >
            <div
            // className={`${
            //   data?.order?.name.toLowerCase() == 'all'
            //     ? ''
            //     : 'orderTypeTextContainer'
            // }`}
            >
              {data?.order?.name}
            </div>
          </span>
        </div>
        {isComponentVisible && (
          <ul className="dropdownIngredientSubRecipe dropdown-list">
            {showListViewOne ? (
              <li className="main-list">
                <div className="list-btn">
                  {filterOptions.map((filterOption, index) => {
                    return (
                      <>
                        <p
                          key={index}
                          className={
                            selectedFilter === filterOption?.key ? 'active' : ''
                          }
                          onClick={() => handleFilterOption(filterOption)}
                        >
                          {filterOption?.key}
                        </p>
                      </>
                    );
                  })}
                </div>
                <ul className="subMenu">
                  <li>
                    <h4 className="placeholder">
                      Select an option or create one
                    </h4>
                  </li>

                  {spinner ? (
                    <Spin
                      spinning={spinner}
                      size="default"
                      className="recipeLoader"
                    />
                  ) : (
                    sortIngredient?.map(
                      (ingredient: any, innerIndex: number) => {
                        return (
                          <li
                            key={innerIndex}
                            onClick={() =>
                              handleIngredientClick(ingredient, index)
                            }
                          >
                            <p>
                              {/* <span
                                className={`brandIcons ${
                                  !ingredient?.brand_thumbnail
                                    ? `withPlaceholder`
                                    : ''
                                }`}
                              >
                                <img
                                  src={ingredient?.brand_thumbnail}
                                  width={15}
                                  alt=""
                                  className="subRecipeIcons"
                                />
                              </span> */}
                              {ingredient?.ingredient_name}
                              {ingredient?.ingredient_category?.toLowerCase() ==
                                'sub recipe' && (
                                <img
                                  src={SubMenu}
                                  width={15}
                                  alt=""
                                  className="subRecipeIcons"
                                />
                              )}
                              {ingredient?.verified_by == 'USA' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="iconFireWhite dropdown-verified-icon"
                                  />
                                </span>
                              )}
                              {ingredient?.verified_by == 'ChefAdmin' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="subCol1Icon dropdown-verified-icon"
                                  />
                                </span>
                              )}
                            </p>
                          </li>
                        );
                      }
                    )
                  )}
                </ul>
              </li>
            ) : (
              <li>
                <ul className="searchMenu">
                  {/* {database.label !== 'USA' ? (
                    <li className="create-category">
                      <button onClick={handleCreateCategory}>
                        Create {data?.ingredient_name} as an ingredient
                      </button>
                    </li>
                  ) : null} */}
                  {spinner ? (
                    <Spin
                      spinning={spinner}
                      size="default"
                      className="loaderStyle"
                    />
                  ) : (
                    searchResults?.length > 0 &&
                    searchResults.map((searchResult, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleSelectOption(searchResult)}
                        >
                          <p>
                            {searchResult?.ingredient_name}{' '}
                            {searchResult?.ingredient_category.toLowerCase() ==
                              'sub recipe' && (
                              <img
                                src={SubMenu}
                                width={15}
                                alt=""
                                className="subRecipeIcons"
                              />
                            )}
                            {searchResult?.verified_by == 'USA' && (
                              <span className="optionIcon">
                                <img
                                  src={Fire}
                                  width={5}
                                  height={10}
                                  alt=""
                                  className="iconFireWhite dropdown-verified-icon"
                                />
                              </span>
                            )}
                            {searchResult?.verified_by == 'ChefAdmin' && (
                              <span className="optionIcon">
                                <img
                                  src={Fire}
                                  width={5}
                                  height={10}
                                  alt=""
                                  className="subCol1Icon dropdown-verified-icon"
                                />
                              </span>
                            )}
                          </p>
                        </li>
                      );
                    })
                  )}
                </ul>
              </li>
            )}
          </ul>
        )}
      </div>
      <img
        src={Delete}
        width={15}
        alt=""
        onClick={() => deleteIngredient(index)}
        className={
          blocked || blockedForEdit
            ? 'hideIngredient'
            : 'deleteIngredientForRecipe'
        }
      />
    </div>
  );
};

export default SubRecipeDropdown;
