/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './style.scss';
import { Breadcrumb, ResponseDialog } from '../../components';
import { Select, Row, Col, Space } from 'antd';
import Stepper from '../../components/Stepper';
import detailImg1 from '../../assets/images/detail-img1.png';
import detailImg2 from '../../assets/images/detail-img2.png';
import fileIcon from '../../assets/images/excelIcon.png';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import UploadRecipeList from '../../components/uploadRecipeList';
import More from '../../assets/images/More.png';
import Delete from '../../assets/images/Delete.svg';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import RecipeActions from '../../redux/middleware/recipe';
import Utils from '../../redux/utils';

const UploadRecipeForRB = () => {
  const items: any = [];
  const array = [
    { value: 'csvFile', label: 'CSV File' },
    { value: 'csvMinimal', label: 'CSV Minimal' },
    { value: 'csvFull', label: 'CSV Full' },
  ];
  const fileRef = React.useRef<HTMLInputElement>(null);

  const { CSVReader } = useCSVReader();
  const [stepper, setStepper] = useState(0);
  const [tableData, setTableData] = useState<any>('');
  const [Variation, setVariation] = useState<any>(array[1]);
  const [errorList, setErrorList] = useState('');
  // const [showRecipeList, setShowRecipeList] = useState(false);
  const [fileInfo, setFileInfo] = useState<any>({});
  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENTS</span>
          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('recipe_name');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            {/* <img src={record?.thumbnail} width="52px" height="52px" /> */}
            <div className="subCol1 font">
              <span
                className="col1Text font"
                onClick={() => {
                  // setDialogData(record);
                  // setOpenDialog(true);
                }}
              >
                {record.ingredients}
              </span>

              <span className="col1Text font">{record?.calorie}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Name</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('category_name');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2">
          <div className="subCol2">{record?.name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>DESCRIPTION</span>
          {/* 
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('servings');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'servings',
      key: 'servings',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">{record?.description}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENTUNIT</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('sub_recipe_items');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">{record?.ingredientUnit}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (record: any) => (
        <div className="col3 col4">{moment(record?.unit).fromNow()}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT QTY</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (record: any) => (
        <div className="col3 col4">{record?.ingredientQty}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>FINAL PRICE</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">{record?.finalPrice}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CALORIES</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">{record?.calorie}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PROTIEN</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">{record?.protien}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CARBS</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">{record?.carbs}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>FAT</span>

          {/* <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              // handleSort('created_at');
              // setAscend(!ascend);
            }}
          /> */}
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">{record?.fats}</div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: () => (
        // <Dropdown
        //   menu={DropDownmenuProps}
        //   trigger={['click']}
        //   placement="bottomRight"
        //   arrow={{ pointAtCenter: true }}
        // >
        //   <a onClick={() => setSelectedMenu(record)}>
        //     <Space>
        //       <div className="dots">
        //         <img src={More} />
        //       </div>
        //     </Space>
        //   </a>
        // </Dropdown>
        <Space>
          <div className="dots">
            <img src={More} />
          </div>
        </Space>
      ),
    },
  ];

  const screenPermission = Utils.getScreenPermission('Bulk Upload Recipe');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleImage = (url: any, data: any) => {
    setTableData(data?.target?.files[0]);
  };
  const handleBack = () => {
    if (stepper > 0) setStepper(stepper - 1);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleContinue = async () => {
    /* if (stepper == 0) setStepper(stepper + 1);
    setShowRecipeList(true); */
    try {
      let responseDialogData = {
        type: 'info',
        messageArray: [`We are uploading your listing. Please Wait...`],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);
      const response = await RecipeActions.uploadRecipes(tableData, 'recipe');
      if (!response?.success) {
        setErrorList(response.error);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: [
            'Validation Failed - Most Common Issues are Column Sequence or Use of wrong Units. Check our Sample CSV for further guidance.',
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        setErrorList('');
        responseDialogData = {
          OnClick: () => {},
          type: 'success',
          messageArray: [` Your listing has been added`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeUOM = (data: any) => {
    setVariation(data);
  };

  return (
    <div>
      <Breadcrumb
        heading="Recipe Builder Upload Recipes"
        breadcrumbIcon
        iconAction={() => {}}
      />
      {/* showRecipeList ? (
        <UploadRecipeList
          items={items}
          dataSource={[...tableData]}
          columns={columns}
        />
      ) : ( */}
      <Row>
        <Col lg={24} md={24}>
          <div className="imgUploadBlockForRB">
            {/* <Stepper stepper={stepper} component="s" /> */}
            <div className="wizardSubContainer checkBox">
              <div className="wizardLabel">File Attachment</div>

              {tableData ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <div>
                      <img src={fileIcon} alt="" style={{ width: '100%' }} />
                      <h4> {tableData?.name} </h4>
                      <p>{formatFileSize(tableData?.size)}</p>
                    </div>
                    <div
                      className="deleteButton"
                      onClick={() => handleImage('', [])}
                    >
                      <img src={Delete} width={15} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="formgroup formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      {/* <CSVReader
                      // onUploadAccepted={(results: any) => {
                      //   setTableData(
                      //     results?.data.map((data: any) => {
                      //       return {
                      //         ingredients: data[0],
                      //         name: data[1],
                      //         description: data[2],
                      //         ingredientUnit: data[3],
                      //         unit: data[3],
                      //         ingredientQty: data[4],
                      //         finalPrice: data[5],
                      //         calories: data[6],
                      //         protien: data[7],
                      //         carbs: data[8],
                      //         fats: data[9],
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        {({ acceptedFile }: any) => {
                          setFileInfo(acceptedFile);
                          return (
                            <>
                              <p className="ant-upload-text ">
                                Drop your csv file here or browse
                              </p>
                              <p className="ant-upload-hint">
                                Minimum file 3mb
                              </p>
                            </>
                          );
                        }}
                      </CSVReader> */}
                      <p className="ant-upload-text ">
                        Drop your image here or browse
                      </p>
                      <p className="ant-upload-hint">Minimum file 2 MB</p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={async (e: any) => {
                          const response = await getBase64(
                            e.currentTarget.files[0]
                          );
                          if (response) handleImage(response, e);
                        }}
                        disabled={!validateActionPermission()}
                      />
                    </label>
                  </div>
                </div>
              )}
              <div className="dropDown-wrapper">
                <label>
                  CSV File <span>*</span>
                </label>
                <Select
                  size={'large'}
                  value={Variation}
                  placeholder="Select Variation"
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={array}
                  disabled
                />
              </div>
              <div className="butonGroup">
                <div className="wizard-button" onClick={handleBack}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} md={24} style={{ display: 'none' }}>
          <div className="fileDetailBlock">
            <ol className="fileDetailList">
              <li>
                <div className="list-content">
                  <span>1.</span>
                  <p>
                    Download <b>CSV File</b>, you can choose <b>CSV Minimal</b>{' '}
                    or
                    <b>CSV Full</b>
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>2.</span>
                  <p>
                    Download <b>Get Marketlist</b> File
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>3.</span>
                  <p>
                    Open the <b>CSV File</b> and Input ingredients based on
                    <b>The Marketlist Data</b>. Make sure the ingredients is
                    suitable with the
                    <b>Marketlist Data</b>.
                  </p>
                </div>

                <div className="listImg">
                  <Row>
                    <div className="imgWrapper">
                      <img src={detailImg1} alt="" />
                    </div>
                    <div className="imgWrapper">
                      <img src={detailImg2} alt="" />
                    </div>
                  </Row>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>4.</span>
                  <p>
                    Upload the <b>CSV File</b> on the
                    <b>Upload Recipes’s Menu Download</b>
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </Col>
      </Row>
      {errorList && (
        <Row>
          <Col lg={24} md={24}>
            <div className="ErrorContainer">
              {Utils.renderErrorItem(errorList).map((item: any, index: any) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default UploadRecipeForRB;
