/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import {
  Page,
  Breadcrumb,
  Stepper,
  UserWizardStepOne,
  UserWizardStepTwo,
  ResponseDialog,
} from '../../components';
import UsersApi from '../../redux/middleware/users';
import './styles.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Utils from '../../redux/utils';
const UserWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  // const [allBrands, setAllBrands] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [roleData, setRoleData] = useState<any[]>([]);
  const [designationData, setDesignationData] = useState<any[]>([]);
  const [companyData, setCompanyData] = useState<any[]>([]);
  const [defaultCompanies, setDefaultCompanies] = useState<any[]>([]);
  const [brandsData, setBrandsData] = useState<any[]>([]);
  const [branchesData, setBranchesData] = useState<any[]>([]);
  const [userRole, setUserRole] = useState<any>({});
  const [designation, setDesignation] = useState<any[]>([]);
  const [company, setCompany] = useState<any>({});
  const [brandId, setBrandId] = useState<any[]>([]);
  const [branchId, setBranchId] = useState<any[]>([]);
  const [branchValue, setBranchValue] = useState<any[]>([]);
  const [name, setName] = useState('');
  const [toggleField, setToggleField] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkBox, setCheckBox] = useState<any[]>([]);
  const [toggleSwitch, setToggleSwitch] = useState<any[]>([]);
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [apiLoader, setApiLoader] = useState(false);
  const [countryCode, setCountryCode] = useState<any>(
    user?.country_code || '+971'
  );
  const companyObject = Utils.getCurrentCompany();
  const role_id = user?.roles[0]?.id;
  const [stepper, setStepper] = useState(0);
  const [loader, setLoader] = useState(false);
  const [categoryData] = useState([
    { value: 'Appetizer', label: 'Appetizer', key: 'Appetizer' },
    { value: 'Hi-Tea', label: 'Hi-Tea', key: 'Hi-Tea' },
    { value: 'Lunch', label: 'Lunch', key: 'Lunch' },
  ]);

  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  React.useEffect(() => {
    if (!update && permissions?.length) {
      const e = {
        target: {
          checked: true,
        },
      };
      menuHandler(e as CheckboxChangeEvent);
    }
  }, [permissions]);

  React.useEffect(() => {
    if (userRole?.label === 'Brand Manager') {
      setToggleField(true);
    }
  }, [userRole]);

  const handleEmail = (data: any) => {
    setEmail(data);
  };

  const handlePassword = (data: any) => {
    setPassword(data);
  };

  const handleName = (data: any) => {
    setName(data);
  };

  const handlePhone = (data: any) => {
    setPhone(data);
  };

  const handleChangeRole = (e: any) => {
    setToggleField(false);
    setUserRole(roleData?.filter((data: any) => data?.value == e)[0]);
    // setUserRole(e);
    fetchUserPermissions(e);
  };

  const handleChangeDesgnation = (e: any) => {
    const data: any = e.map((data: any) => {
      return designationData.filter((id: any) => {
        if (id.value == data) {
          return id;
        }
      });
    });
    setDesignation(data.flat());
  };

  const handleBranchId = (id: any) => {
    // const data: any = id.map((data: any) => {
    //   return branchesData.filter((id: any) => {
    //     if (id.value == data) {
    //       return id;
    //     }
    //   });
    // });

    // setBrandId(
    //   allBrands
    //     ?.find((brand: any) => brand?.id == id[0])
    //     ?.brand_id?.map((obj: any) => ({
    //       label: obj?.brand_name,
    //       value: obj?.brand_id,
    //     }))
    // );
    console.log('data of branch', id);

    setBranchId(id);
    setBranchValue(id);
  };

  const menuHandler = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      let arr = permissions.map((data) => data?.id);
      setToggleSwitch([...arr]);
    } else {
      setToggleSwitch([]);
    }
  };

  const handleCheckbox = (e: CheckboxChangeEvent, val: any) => {
    e.target.checked
      ? setCheckBox((prev: any) => {
          return [...prev, val?.id];
        })
      : setCheckBox((prev) => {
          return prev.filter((data) => data != val?.id);
        });
  };

  const handleSwitch = (checked: boolean, val: any) => {
    checked
      ? setToggleSwitch((prev: any) => {
          return [...prev, val?.id];
        })
      : setToggleSwitch((prev) => {
          return prev.filter((data) => data != val?.id);
        });
  };
  const fetchUserRoles = async () => {
    const userRoleData = await UsersApi.getUserRoles();
    setRoleData(
      userRoleData?.data?.items?.map((data: any) => {
        return {
          label: data?.name,
          value: data?.id,
          key: data?.guard_name,
        };
      })
    );
    setUserRole({
      label: userRoleData?.data?.items[0]?.name,
      value: userRoleData?.data?.items[0]?.id,
      key: userRoleData?.data?.items[0]?.guard_name,
    });

    fetchUserPermissions(userRoleData?.data?.items[0]?.id);
  };
  const fetchUserDesignation = async () => {
    const userDesignationData = await UsersApi.getUserDesignation();
    setDesignationData(
      userDesignationData?.data?.items?.map((data: any) => {
        return {
          label: data?.designation,
          value: data?.id,
        };
      })
    );
  };
  const fetchUserCompany = async () => {
    const userCompanyData = await UsersApi.getUserCompany();
    setDefaultCompanies(userCompanyData?.data?.items);
    setCompanyData(
      userCompanyData?.data?.items?.map((data: any) => {
        return {
          label: data?.company_name,
          value: data?.id,
        };
      })
    );
  };
  const fetchUserBrands = async () => {
    let id = company;
    role_id === 1 ? id : companyObject?.id;
    const userBrandsData = await UsersApi.getUserBrands(id);
    setBrandsData(
      userBrandsData?.data?.items?.map((data: any) => {
        return {
          label: data?.name,
          value: data?.brand_id,
        };
      })
    );
    if (user?.roles[0]?.name == 'Company Owner' && !update) {
      setBrandId(
        userBrandsData?.data?.items?.map((data: any) => {
          return {
            label: data?.name,
            value: data?.brand_id,
          };
        })
      );
    }
  };
  const fetchUserBranches = async () => {
    const userBranchesData = await UsersApi.getUserBranches();
    // setAllBrands(userBranchesData?.data?.items);
    setBranchesData(
      userBranchesData?.data?.items?.map((data: any) => {
        return {
          label: data?.shop_name,
          value: data?.id,
        };
      })
    );
  };

  const fetchUserPermissions = async (role: any) => {
    const userPermissionData = await UsersApi.getUserPermissions(role);
    setPermissions(userPermissionData?.data?.items);
  };

  const fetchShopBrands = async (role: any) => {
    const shopBrands = await UsersApi.getShopBrands(role);
    console.log('shopBrands', shopBrands);
    console.log('shopBrands', branchValue);
    if (branchValue?.length !== 0) {
      setBrandId(
        shopBrands?.data?.map((data: any) => {
          return {
            label: data?.brand_name,
            value: data?.brand_id,
          };
        })
      );
    }
    setBrandsData(
      shopBrands?.data?.map((data: any) => {
        return {
          label: data?.brand_name,
          value: data?.brand_id,
        };
      })
    );
  };

  const fetchDataById = async (id: any) => {
    const data = await UsersApi.getUserById(id);

    if (data) {
      setLoader(false);
    }

    setUserRole(
      data?.data?.roles?.length && {
        label: data?.data?.roles[0]?.name,
        value: data?.data?.roles[0]?.id,
        key: data?.data?.roles[0]?.guard_name,
      }
    );
    fetchUserPermissions(data?.data?.roles && data?.data?.roles[0]?.id);
    setDesignation(
      data?.data?.designation?.map((data: any) => {
        return {
          ...data,
          label: data?.designation,
          value: data?.designation_id,
        };
      })
    );
    setCompany(
      data?.data?.company_tree?.map((data: any) => {
        return {
          ...data,

          label: data?.company_name,
          value: parseInt(data?.id),
        };
      })[0]
    );

    setBrandId(
      data?.data?.company_tree[0]?.brands?.map((data: any) => {
        return {
          ...data,
          label: data?.brand_name,
          value: data?.brand_id,
        };
      })
    );

    setBranchId(
      data?.data?.company_tree[0]?.shops?.map((data: any) => {
        return {
          ...data,
          label: data?.shop_name,
          value: data?.shop_id,
        };
      })
    );
    setEmail(data?.data?.email);
    setName(data?.data?.name);
    setPhone(data?.data?.phone);

    setToggleSwitch(
      data?.data?.permissions.map((data: any) => {
        return data?.permission_id;
      })
    );

    setCheckBox(
      data?.data?.permissions
        .map((data: any) => {
          if (data?.perm_create == 1) return data?.permission_id;
        })
        ?.filter((data: any) => data != null)
    );
  };

  useEffect(() => {
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
    fetchUserRoles();
    fetchUserDesignation();
    fetchUserCompany();
    if (role_id !== 2 && role_id !== 1) {
      fetchUserBranches();
    }
    fetchUserBrands();
  }, []);

  useEffect(() => {
    if (branchId.length !== 0) {
      fetchShopBrands(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (designationData?.length && role_id == 1) {
      setDesignation(
        designationData?.filter(
          (data: any) => data?.label?.toLowerCase() == 'company owner'
        )
      );
    }
  }, [designationData]);

  const handleCompany = (value: any) => {
    setCompany(value);
    setCountryCode(
      defaultCompanies?.find((data: any) => data?.id == value)?.country_code
    );
  };
  const handleBrand = (value: any) => {
    if (role_id == 2) {
      const data: any = brandsData.filter((data: any) => {
        return value?.filter((brand_id: any) => brand_id == data.value)?.length;
      });
      setBrandId(data.flat());
    } else {
      const data: any = brandsData.filter((data: any) => {
        return value?.filter((brand_id: any) => brand_id == data.value)?.length;
      });
      setBrandId(data.flat());
    }
  };
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const handleBack = () => {
    if (stepper > 0) {
      setVisible(false);
      setError(false);
      setStepper(stepper - 1);
    } else {
      setVisible(false);
      setError(false);
      setStepper(stepper);
    }
  };

  const handleContinue = async () => {
    if (update) {
      if (
        (stepper === 0 &&
          (userRole === undefined ||
            company === undefined ||
            designation === undefined ||
            name === '' ||
            email === '')) ||
        (stepper === 1 && toggleSwitch?.length === 0)
      ) {
        setVisible(true);
        setError(false);
        window.scrollTo(0, 0);

        return;
      }
    } else {
      if (
        (stepper === 0 &&
          (userRole === undefined ||
            company === undefined ||
            designation === undefined ||
            (role_id != 1 && role_id != 2 && !branchId?.length) ||
            (role_id == 2 && !brandId?.length) ||
            (role_id != 1 && !designation?.length) ||
            name === '' ||
            email === '' ||
            password === '' ||
            password?.length < 10)) ||
        (stepper === 1 && toggleSwitch?.length === 0)
      ) {
        setVisible(true);
        setError(false);
        window.scrollTo(0, 0);

        return;
      }
    }
    if (stepper < 2) {
      setVisible(false);
      setError(false);
      setStepper(stepper + 1);
    } else {
      setVisible(false);
      setError(false);
      setStepper(stepper);
    }
    if (user && stepper == 1 && !id) {
      setErrorList({});
      setErrorStatus(false);
      const params = {
        user_type: userRole?.value,
        designation: designation.map((data: any) => {
          return data?.value?.toString() || data?.designation_id?.toString();
        }),
        company_id: company,
        brand_id:
          brandId?.length === 0
            ? [0]
            : brandId.map((data: any) => {
                return data.value;
              }),
        shop_id:
          branchId?.length === 0
            ? role_id === 3
              ? [0]
              : []
            : branchId?.map((data: any) => {
                return data.value;
              }),
        name: name,
        phone: phone,
        email: email,
        password: password,
        perm_create: checkBox,
        permissions: toggleSwitch,
      };
      let responseDialogData;
      setApiLoader(true);

      try {
        const response = await UsersApi.addUsers(params);

        setApiLoader(false);
        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
          responseDialogData = {
            OnClick: () => {
              setStepper(0);
            },
            type: 'error',
            messageArray: [
              'Validation Failed!',
              `${
                response?.error?.email ||
                response?.error?.name ||
                response?.error?.phone
              }`,
            ],
          };
          ResponseDialog(responseDialogData);
        } else {
          responseDialogData = {
            OnClick: () => {
              navigate('/app/users');
            },
            type: 'success',
            messageArray: [` Your ${name}`, `  has been Created`],
          };
          ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    } else if (update && stepper == 1) {
      setErrorList({});
      setErrorStatus(false);
      const params: any = {
        user_type: userRole?.value,
        designation: designation.map((data: any) => {
          return data?.value?.toString() || data?.designation_id?.toString();
        }),
        company_id: company?.value || company,
        brand_id:
          brandId?.length === 0
            ? [0]
            : brandId.map((data: any) => {
                return data.value;
              }),
        shop_id:
          branchId?.length === 0
            ? role_id === 3
              ? [0]
              : []
            : branchId?.map((data: any) => {
                return data.value;
              }),
        name: name,
        phone: phone,
        email: email,
        password: password,
        perm_create: checkBox,
        permissions: toggleSwitch,
      };
      let responseDialogData;
      setApiLoader(true);

      const response = await UsersApi.updateUser(params, id);
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        setStepper(0);
        responseDialogData = {
          OnClick: () => {
            setStepper(0);
          },
          type: 'error',
          messageArray: [
            'Validation Failed!',
            `${response?.error?.email || response?.error?.name}`,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/users');
          },
          type: 'success',
          messageArray: [` Your ${name} `, ` has been Update`],
        };
        ResponseDialog(responseDialogData);
      }
    }
  };

  return (
    <div className="userListWizard-block">
      <Breadcrumb
        heading="Add New User"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/users');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New User">
          <Stepper stepper={stepper} component={'user'} />
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>
          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="userListWizardContainer">
              {stepper === 0 ? (
                <UserWizardStepOne
                  categoryData={categoryData}
                  roleData={roleData}
                  designationData={designationData}
                  companyData={companyData}
                  brandsData={brandsData}
                  branchesData={branchesData}
                  userRole={userRole}
                  designation={designation}
                  company={company}
                  branchId={branchId}
                  toggleField={toggleField}
                  name={name}
                  phone={phone}
                  email={email}
                  brandId={brandId}
                  password={password}
                  handlePassword={handlePassword}
                  handleEmail={handleEmail}
                  handlePhone={handlePhone}
                  handleName={handleName}
                  handleBranchId={handleBranchId}
                  handleCompany={handleCompany}
                  handleBrand={handleBrand}
                  handleChangeRole={handleChangeRole}
                  handleChangeDesgnation={handleChangeDesgnation}
                  countryCode={countryCode}
                  errorList={errorList}
                  errorStatus={errorStatus}
                />
              ) : (
                <UserWizardStepTwo
                  toggleSwitch={toggleSwitch}
                  checkBox={checkBox}
                  menuHandler={menuHandler}
                  handleCheckbox={handleCheckbox}
                  handleSwitch={handleSwitch}
                  permissions={permissions}
                />
              )}

              <div
                className="butonGroup"
                style={
                  stepper === 0 ? { margin: 'auto' } : { marginLeft: 'auto' }
                }
              >
                <div className="wizard-button" onClick={() => handleBack()}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={() => handleContinue()}
                >
                  Continue
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </div>
  );
};

export default UserWizard;
