import React, { useState, useEffect } from 'react';
import { Spin, Select, Input, Modal } from 'antd';
import { RcFile } from 'antd/lib/upload';
import FeedbackApi from '../../redux/middleware/feedback';
// import SubtractIcon from '../../assets/images/subtractIcon.png';
import ResponseDialog from '../ResopnseDialog';
import Delete from '../../assets/images/Delete.svg';
import './feedbackDialog.scss';
import Utils from '../../redux/utils';
type Props = {
  feedback: any;
  feedbackBtn: any;
  handleClose: any;
};
const FeedBackDialog = (props: Props) => {
  const fileRef = React.useRef<HTMLInputElement>(null);
  // const [feedback, setFeedBack] = useState(true);
  const { TextArea } = Input;
  const [screenList, setScreenList] = useState<any>([
    { name: 'check', id: 1 },
    { name: 'check2', id: 2 },
    { name: 'check3', id: 3 },
  ]);
  const [feedbackTypeList, setFeedbackTypeList] = useState<any>([
    { name: 'check', id: 1 },
    { name: 'check2', id: 2 },
    { name: 'check3', id: 3 },
  ]);
  const [feedbackType, setFeedbackType] = useState<any>({});
  const [screen, setScreen] = useState<any>({});
  const [description, setDescription] = useState('');
  const [imageObject, setImageObject] = useState({});
  const [image, setImage] = useState('');
  const [disable, setDisable] = useState(true);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [spinner, setSpinner] = useState(false);

  // const feedbackBtn = () => {
  //   setFeedBack(!feedback);
  // };
  let { feedback, feedbackBtn, handleClose } = props;

  const fetchScreens = async () => {
    const screens = await FeedbackApi.getScreens();
    setScreenList(screens?.data?.items || []);
  };

  const fetchFeedbackTypes = async () => {
    const feedbackTypes = await FeedbackApi.getFeedbackTypes();
    setFeedbackTypeList(feedbackTypes?.data || []);
  };

  useEffect(() => {
    fetchScreens();
    fetchFeedbackTypes();
  }, []);

  useEffect(() => {
    if (
      !(
        screen &&
        Object.keys(screen).length === 0 &&
        Object.getPrototypeOf(screen) === Object.prototype
      ) &&
      !(
        feedbackType &&
        Object.keys(feedbackType).length === 0 &&
        Object.getPrototypeOf(feedbackType) === Object.prototype
      ) &&
      image != ''
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [screen, feedbackType, image]);

  const handleDescription = (text: any) => {
    setDescription(text);
  };

  const handleScreen = (value: any) => {
    setScreen(screenList?.filter((screen: any) => screen?.id == value)[0]);
  };

  const handlefeedbackType = (value: any) => {
    setFeedbackType(
      feedbackTypeList?.filter((feedback: any) => feedback?.id == value)[0]
    );
  };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject({});
    }
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const emptyAllFields = () => {
    setScreen({});
    setFeedbackType({});
    setDescription('');
    setImage('');
    setImageObject({});
  };

  const handleSubmitFeedback = async () => {
    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    setErrorList({});
    setErrorStatus(false);
    setSpinner(true);
    const payload = {
      created_by: user?.id,
      brand_id: brand?.id,
      shop_id: shop?.id || 0,
      issue_type_id: feedbackType?.id,
      issue_type: feedbackType?.issue_type,
      screen_id: screen?.id,
      screen: screen?.name,
      feedback: description,
    };

    try {
      let responseDialogData;
      const response = await FeedbackApi.createFeedback(payload, imageObject);
      if (response?.success) {
        feedbackBtn();
        emptyAllFields();
      }
      if (!response?.success) {
        setSpinner(false);
        setErrorList(response?.error);
        setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['FeedBack Failed!', `${response?.error?.feedback}`],
        };
        ResponseDialog(responseDialogData);
      } else {
        setSpinner(false);

        responseDialogData = {
          OnClick: () => {
            feedbackBtn();
            emptyAllFields();
          },
          type: 'success',
          messageArray: [` Your Feed Back`, ` has been Submitted`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <Modal
        title={<div className="title font">Feedback</div>}
        centered
        open={feedback}
        width={600}
        onCancel={handleClose}
        footer={null}
        className="feedBack"
      >
        {/* <div
        className={
          feedback
            ? 'feedBackDrawerContainer feedBackDrawerContainer2'
            : 'feedBackDrawerContainer'
        }
      > */}
        {/* <Button onClick={() => feedbackBtn()}>
        <img src={SubtractIcon} width={18} height={18} />
      </Button> */}

        <Spin spinning={spinner} size="default" className="recipeLoader">
          <div className="feedBackDrawer-body">
            <div className="subContainer">
              <label>
                Selected Screen <span className="sterik">*</span>
              </label>
              {/* <input type="text" placeholder="Select screen" /> */}
              <Select
                size={'large'}
                placeholder={'Select Screen'}
                onChange={handleScreen}
                style={{ width: 430 }}
                value={screen?.name}
              >
                {screenList?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="subContainer">
              <label>
                Feedback Type <span className="sterik">*</span>
              </label>
              <Select
                size={'large'}
                placeholder={'Select Feedback Type'}
                onChange={handlefeedbackType}
                style={{ width: 430 }}
                value={feedbackType?.issue_type}
              >
                {feedbackTypeList?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.issue_type}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="subContainer">
              <label>Description</label>

              <TextArea
                rows={4}
                className="text-Area"
                placeholder="Input description here"
                value={description}
                onChange={(e) => handleDescription(e.target.value)}
              />
            </div>
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['feedback']}
              </div>
            )}
            <div className="subContainer">
              <label>
                Upload image <span className="sterik">*</span>
              </label>

              {image ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <img src={image} alt="" className="imageHeight" />
                    <div className="deleteButton">
                      <img
                        src={Delete}
                        width={15}
                        alt=""
                        onClick={() => {
                          handleImage('', {});
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="feedback-imageUploader formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      <p className="ant-upload-text ">
                        Drop your image here or browse
                      </p>
                      <p className="ant-upload-hint">Minimum file 2 MB</p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={async (e: any) => {
                          const response = await getBase64(
                            e.currentTarget.files[0]
                          );
                          if (response) handleImage(response, e);
                        }}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`${
                !disable ? 'continue' : ''
              }subContainer buttonSubmitSpacing `}
            >
              <button
                disabled={disable}
                className={`${!disable ? 'continue' : ''}`}
                onClick={handleSubmitFeedback}
              >
                Submit Feedback
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default FeedBackDialog;
