import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import IngredientCategoryBuilderActions from '../../redux/middleware/ingredientCategoryBuilder';
import './ingredientCategoryBuilderWizard.scss';
import Utils from '../../redux/utils';
import { Input, Spin } from 'antd';

const IngredientCategoryBuilderWizard = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  // eslint-disable-next-line no-unused-vars
  const [errorList, setErrorList] = React.useState<any>({});
  // eslint-disable-next-line no-unused-vars
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [recipe, setRecipe] = React.useState('');
  const [apiLoader, setApiLoader] = React.useState(false);
  const fetchDataById = async (id: any) => {
    const data =
      await IngredientCategoryBuilderActions.getIngredientCategoryByIdBuilder(
        id
      );
    if (data) {
      setApiLoader(false);
      // ResponseDialog({
      //   OnClick: () => {},
      //   type: 'success',
      //   messageArray: [
      //     ` Your ${data?.data?.category_name}  Ingredient`,
      //     ` has been fetched`,
      //   ],
      // });
    }
    setRecipe(data?.data?.category_name);
  };

  useEffect(() => {
    if (update) {
      setApiLoader(true);
      fetchDataById(id);
    }
  }, []);

  const handleIngredientCategory = (data: string) => {
    setRecipe(data);
  };

  const handleContinue = async () => {
    const company = Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();

    const param = {
      company_id: company?.id,
      category_name: recipe,
      brand_id: brand?.brand_id,
    };

    try {
      let responseDialogData;
      setApiLoader(true);
      let response;
      if (update) {
        response =
          await IngredientCategoryBuilderActions.updateIngredientCategoryBuilder(
            id,
            param
          );
      } else {
        response =
          await IngredientCategoryBuilderActions.addIngredientCategoryBuilder(
            param
          );
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Validation Failed!', 'Something Went Wrong'],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/rd-menuCategory');
          },
          type: 'success',
          messageArray: [
            ` Your ${recipe} Ingredient`,
            ` has been ${!update ? 'Created' : 'Updated'}`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Server Error!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Ingredient Category"
        breadcrumbIcon
        iconAction={() => {}}
      />

      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Ingredient Category">
          {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
          <div className="wizardContainer">
            <div className="ingredientCategoryContainerWidth">
              <div className="wizardSubContainer wrapper">
                <div className="wizardLabel">
                  Ingredient Category Name <span className="sterik">*</span>
                </div>
                <Input
                  placeholder="Enter  Ingredient Category Name"
                  size="large"
                  value={recipe}
                  onChange={(e) => {
                    handleIngredientCategory(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleContinue()}>
                Back
              </div>
              <div className="continue wizard-button" onClick={handleContinue}>
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default IngredientCategoryBuilderWizard;
