import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
// import Print from '../../assets/images/Print.png';
import ArrowLeft from '../../assets/images/ArrowLeft.png';
import './style.scss';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import RecipeActions from '../../redux/middleware/recipe';

type Props = {
  open: boolean;
  handleSubDialog: any;
  handleCloseAll: any;
  data: any;
  component?: any;
};

const SubDialog = (props: Props) => {
  let { open, handleSubDialog, handleCloseAll, data, component } = props;
  const [Recipedata, setRecipedata] = useState<any>([]);
  const [ingredients, setIngredients] = useState<any>([]);

  const fetchDataById = async (id: any) => {
    if (component === 'RecipeBuilder') {
      const details = await RecipeActions.getDetailRecipe(id);
      setRecipedata(details?.data?.general);
      setIngredients(details?.data?.ingredients);
    } else {
      const data = await SubRecipeActions.getDetailRecipe(id);
      setRecipedata(data?.data?.general);
      setIngredients(data?.data?.ingredients);
    }
  };
  useEffect(() => {
    if (data != '') {
      fetchDataById(data);
    }
  }, [data]);
  return (
    <Modal
      title={
        <div className="titleContainer">
          <img
            src={ArrowLeft}
            alt=""
            onClick={handleSubDialog}
            className="arrowBack"
          />
          <div className="title-recipe font">Sub Recipe</div>
        </div>
      }
      centered
      open={open}
      onCancel={handleCloseAll}
      width={480}
      footer={null}
      bodyStyle={{ height: '530px' }}
      className="SubDialogWrapper"
    >
      <div className="subModelBody">
        <div className="cover-photo">
          <img
            src={Recipedata?.thumbnail}
            alt=""
            width={'432px'}
            height="240px"
            className="image"
          />
          <div className="imgtag">{Recipedata?.menu_category}</div>
        </div>
        <div className="subhead-recipe">
          <div className="subHead-left-recipe font">
            {Recipedata?.recipe_name}
          </div>
          {/* <div className="subhead-recipe">
            <img src={Print} alt="" />
            <div className="subHead-right-recipe">Print</div>
          </div> */}
        </div>
        <div className="block block1">
          <div>
            <span className={`callories-number `}>{Recipedata?.calorie}</span>
            <span className="font callories-unit">{'kcal'}</span>
          </div>
          <div>
            <span className={`crab-number `}>{Recipedata?.carb}</span>
            <span className="font crab-unit">{'g'}</span>
          </div>
          <div>
            <span className={`fat-number `}>{Recipedata?.fat}</span>
            <span className="font fat-unit">{'g'}</span>
          </div>
          <div>
            <span className={`font protein-number `}>
              {Recipedata?.protein}
            </span>
            <span className="font protein-unit">{'g'}</span>
          </div>
        </div>
        <div className="block block2">
          <span className={'callories-text'}>calorie</span>
          <span className={'callories-text'}>crab</span>
          <span className={'callories-text'}>fat</span>
          <span className={'callories-text'}>protein</span>
        </div>
        <div className="block-recipe block2-recipe">
          {data?.specs?.map((detail: any, index: any) => (
            <div
              key={index}
              className={detail?.name === 'Protein' ? 'protein-recipe' : ''}
            >
              {detail?.name}
            </div>
          ))}
        </div>

        <div className="block3-recipe">
          <div className="ingredient-recipe">Ingredients</div>
          <div className="ingredient-recipe">QUANTITY</div>
        </div>
        {ingredients?.map((ingredient: any, index: number) => {
          return (
            <div className="block4" key={index}>
              <div className={`block4-text font `}>
                {ingredient?.ingredient_name}
              </div>
              <div className="block4-weight">
                {ingredient?.quantity + ' ' + ingredient?.unit}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default SubDialog;
