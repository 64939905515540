import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  DeleteConfirmation,
} from '../../components';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import { sortedData, sortNumber } from '../../helper/sortData';
import Dish from '../../assets/images/Dish.png';
import Fire from '../../assets/images/Fire.svg';
import More from '../../assets/images/More.png';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Union from '../../assets/images/Union.png';
import moment from 'moment';
import './recipe.scss';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';

const itemsForNotVariation: MenuProps['items'] = [
  {
    key: '2',
    label: <div className="dotOption">Download</div>,
    icon: <img src={Download} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const Recipe = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [design, setDesign] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(6);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const [selectedParentRecipeId, setSelectedParentRecipeId] = useState('');
  const [ingrdientCount, setIngredientCount] = useState(0);
  const PageDataLength = [6, 10, 20];
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pdf, setPdf] = useState('');
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { currentBrand } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    pageData();
  }, [currentBrand]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Add Variation</div>,
      icon: <img src={Union} className=" duplicateIcon" />,
    },
    {
      key: '2',
      label: (
        // <a href={pdf} download>
        <div className="dotOption">Download</div>
        // </a>
      ),
      icon: <img src={Download} width={15} />,
    },
    {
      key: '3',
      label: <div className="dotOption">Edit</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];
  const itemsForReadOnlyUsers: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <a href={pdf} download>
          <div className="dotOption">Download</div>
        </a>
      ),
      icon: <img src={Download} width={15} />,
    },
  ];
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const setSelectedMenu = (recipe: any) => {
    setSelectedParentRecipeId(recipe?.parent_recipe_id);
    setSelectedRecipeId(recipe.recipe_id);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={Dish} width="52px" height="52px" />
            <div className="subCol1 font">
              <span
                className="col1Text font"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {record?.recipe_name}
              </span>

              <div className="subTextCol1 font">
                {record.is_verified ? (
                  <img
                    src={Fire}
                    width={15}
                    alt=""
                    className=" iconFireWhite"
                  />
                ) : (
                  <img src={Fire} width={15} alt="" className="subCol1Icon" />
                )}

                <span className="col1Text font">{record?.calorie}</span>
                <span className="calories font ">kcal</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('menu_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2">
          <div className="subCol2">{record?.menu_category}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SERVINGS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('servings', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'servings',
      key: 'servings',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">{record?.servings + ' items'}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUB RECIPE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('subrecipe_count', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'subrecipe_count',
      key: 'subrecipe_count',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">{record?.subrecipe_count}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 col4">{moment(record?.created_at).fromNow()}</div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Dropdown
            menu={
              record?.is_variation === 'no'
                ? menuPropsNotVariation
                : DropDownmenuProps
            }
            trigger={['click']}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <a onClick={() => setSelectedMenu(record)}>
              <Space>
                <div className="dots">
                  <img src={More} />
                </div>
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Recipe'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const brand = await Utils.getCurrentBrand();
    const details = await RecipeApiActions.getIngredientDetail(
      data?.recipe_id,
      currentBrand?.brand_id || brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        navigate('wizard', {
          state: {
            variationType: true,
            id: selectedRecipeId,
            parentId: selectedParentRecipeId,
          },
        });
        break;
      case 2:
        {
          const data = await RecipeApiActions.downloadRecipe(selectedRecipeId);
          // const file = new Blob([data], { type: 'application/pdf;base64' });
          // const fileURL = URL.createObjectURL(file);
          //window.open(data);
          setPdf(data?.data);
          openInNewTab(data?.data);
          // fetch('document.pdf').then((response) => {
          //   response.blob().then((blob) => {
          //     // Creating new object of PDF file
          //     const fileURL = window.URL.createObjectURL(blob);
          //     // Setting various property values
          //     let alink = document.createElement('a');
          //     alink.href = fileURL;
          //     alink.download = 'index.html';
          //     alink.click();
          //   });
          // });
        }
        break;
      case 3:
        navigate('wizard', {
          state: {
            update: true,
            id: selectedRecipeId,
            parentId: selectedParentRecipeId,
            variationType: false,
          },
        });
        break;

      case 4:
        setOpenDeleteDialog(true);

        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    selectedRecipeId.length &&
      (await RecipeApiActions.delRecipe(selectedRecipeId));
    pageData();
  };
  const DropDownmenuProps = {
    items:
      permissionStatus && permissionStatus === 2
        ? items
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };
  const menuPropsNotVariation = {
    items:
      permissionStatus && permissionStatus === 2
        ? itemsForNotVariation
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const recipeData = await RecipeApiActions.getRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id
    );
    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const recipeData = await RecipeApiActions.recipesFilters(
      dataLength,
      current,
      filters
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', {
          state: { update: false, id: '', variationType: false },
        });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = await RecipeApiActions.searchRecipe(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  const handleSubDialogOpen = async (id: any) => {
    const brand = await Utils.getCurrentBrand();
    const details = await SubRecipeActions.getIngredientDetail(
      id,
      currentBrand?.brand_id || brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  return (
    <div className="recipePage">
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete recipe?'}
      />
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        component={'recipe'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <Breadcrumb
        heading="Recipes"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col md={24} lg={12}>
            <ListCount
              count1={recipeCount}
              title1="Total Recipes"
              count2={ingrdientCount}
              title2="Total Ingredients"
            />
          </Col>
          <Col md={24} lg={12}>
            <div className="search-wrapper">
              <SearchBox action={onSearch} placeholderText="Search..." />
              <IconSwitcher listViewLayout={design} setListView={setDesign} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={8}>
            <Filter
              action={pageDataFilter}
              apiCall={RecipeApiActions.getRecipesFilters}
            />
          </Col>
          <Col lg={18} md={16}>
            {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                menuPropsNotVariation={menuPropsNotVariation}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'recipe'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}

            {design && !recipeLoader ? (
              <ListView
                limit={dataLength}
                component="recipe"
                items={items}
                dataSource={[...sortData]}
                columns={columns}
                handleOpen={handleSubDialogOpen}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default Recipe;
