import React from 'react';
// import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Row, Form, Input, notification } from 'antd';
import menuItems from '../../components/menu';
import { Button, SignUpModal } from '../../components';
// import SideImage from '../../assets/svg/logo.svg';
// import SideImage from '../../assets/svg/authSideImage.svg';
import LoginScreen from '../../assets/images/authSideImage2.png';
import Logo from '../../assets/svg/logo.svg';
import { useDispatch } from 'react-redux';
import {
  loginFailed,
  loginPending,
  loginSuccess,
} from '../../redux/reducers/authSlice';
import './styles.scss';
import AuthActions from '../../redux/middleware/auth';
import Utils from '../../redux/utils';
import { LoginResponse } from './types';
import { useNavigate } from 'react-router-dom';
// import type { NotificationPlacement } from 'antd/es/notification';

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [verify, setVerify] = React.useState(false);
  const [verifyForMessage, setVerifyForMessage] = React.useState(false);
  const [activeForEmail, setActiveForEmail] = React.useState(false);
  const [activeForVerify, setActiveForVerify] = React.useState(false);
  const [activeForPassword, setActiveForPassword] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    localStorage.clear();
  }, []);

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  /* const [description] = React.useState(
    ". "
  ); */

  const getDescription = () => (
    <>
      <p>{'Welcome to ChefAdmin!'}</p>
      <p>{`We're excited to have you here. Our platform is designed to simplify your operations and help you manage your business more efficiently. With ChefAdmin, you can track your recipes, manage inventory, monitor costs, and more.`}</p>
      <p>{`We're confident that you'll find our platform intuitive and easy to use. Thank you for choosing ChefAdmin, and we look forward to supporting your business!`}</p>
    </>
  );

  // const openNotification = (
  //   placement: NotificationPlacement,
  //   type: string,
  //   errorMessage?: any
  // ) => {
  //   if (type === 'success') {
  //     api.success({
  //       message: `Login Successful`,
  //       placement,
  //       icon: <CheckCircleFilled className="successIcon" />,
  //     });
  //   }
  //   if (type === 'error') {
  //     let message;
  //     if (errorMessage?.email) {
  //       message = errorMessage?.email;
  //     }
  //     if (errorMessage?.password) {
  //       message = errorMessage?.password;
  //     }
  //     api.error({
  //       message,
  //       placement,
  //       icon: <ExclamationCircleFilled className="failedIcon" />,
  //     });
  //   }
  // };

  const onSubmit = async () => {
    setActiveForEmail(false);
    setActiveForPassword(false);
    if (!email || !password) {
      return;
    }
    dispatch(loginPending());
    try {
      const apiResponse: any = await AuthActions.UserLogin({ email, password });
      if (apiResponse.success) {
        const { data, token } = apiResponse as LoginResponse;
        // if (data?.is_email_verified && data?.is_email_verified === 0) {
        //   setVerify(true);
        //   return false;
        // }
        // openNotification('bottomRight', 'success');
        dispatch(loginSuccess());
        Utils.setCurrentUser(data);
        const menuPermission = data.permissions;

        const MenuItems: any = [];
        for (let index = 0; index < menuItems.length; index += 1) {
          const filterItem = menuPermission.find(
            (x: any) =>
              x.parent_screen === menuItems[index].routeName.substring(0, -1) ||
              x.parent_screen.includes(menuItems[index].routeName)
          );
          if (filterItem) {
            const filterNav = menuItems[index];
            if (!filterNav.childRouteName) {
              MenuItems.push(filterNav);
            } else {
              const subItems = [];
              for (let i = 0; i < filterNav.childRouteName.length; i += 1) {
                const { routeName } = filterNav.childRouteName[i];
                const filterSubItem = menuPermission.find(
                  (x: any) => x.permission === routeName
                );
                if (filterSubItem) {
                  subItems.push(filterNav.childRouteName[i]);
                }
              }
              MenuItems.push({ ...filterNav, subs: subItems });
            }
          }
        }

        Utils.setCompanyList(data?.company_tree);
        Utils.setCurrentMenu(MenuItems);
        Utils.setCurrentUserPermission(data.permissions);
        Utils.setSideMenu(data.sidemenu);
        Utils.setCurrentToken(token);

        Utils.setCurrentCompany(data.company_tree[0]);
        if (data.company_tree && data.company_tree[0]) {
          if (
            data.company_tree[0].brands &&
            data.company_tree[0].brands.length
          ) {
            Utils.setBrandList(data.company_tree[0].brands);
            Utils.setCurrentBrand(data.company_tree[0].brands[0]);
          }
          if (
            // data.company_tree[0].brands &&
            data?.company_tree[0].shops &&
            data?.company_tree[0].shops.length
          ) {
            Utils.setShopList(data.company_tree[0]?.shops);
            Utils.setCurrentShop(data.company_tree[0].shops[0]);
          }
        }

        navigate('/app');
      } else {
        const payload = {
          message: apiResponse.message,
        };
        if (apiResponse?.error && apiResponse?.error?.password) {
          setActiveForPassword(true);
          setMessage(apiResponse?.error?.password);
        } else if (apiResponse?.error && apiResponse?.error?.email) {
          setActiveForEmail(true);
          setMessage(apiResponse?.error?.email);
        } else {
          setVerify(true);
          setVerifyForMessage(true);
          setMessage(apiResponse?.error?.email);
        }

        // openNotification('bottomRight', 'error', apiResponse?.error);

        dispatch(loginFailed(payload));
      }
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

  const onVerifyEmail = async () => {
    if (!email) {
      return false;
    }
    try {
      const apiResponse = await AuthActions.VerifyEmail(email);
      if (apiResponse?.success) {
        setActiveForVerify(true);
        setVerifyForMessage(false);
        setMessage(apiResponse?.message);
      } else {
        setMessage('Failed to verify email. Please Try Again ');
      }
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

  const handleForgotPasswordNavigation = () => {
    navigate('forgot-password');
  };

  const handleSignUp = () => {
    setOpenDialog(true);
  };

  const handlemodal = () => {
    setOpenDialog(false);
    // pageData();
  };

  return (
    <React.Fragment>
      {openDialog ? (
        <SignUpModal
          handleDialog={handlemodal}
          open={openDialog}
          modalData={[]}
        />
      ) : null}

      {contextHolder}
      <Row className="authContainer" style={{ height: window.innerHeight }}>
        <Col lg={8} className="leftSide">
          <img src={Logo} alt={'logo'} className="sideLogo" />
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <label className="b2 upload-label">
                Email <span className="upload-span">*</span>
              </label>
              <Input
                className="login-input"
                type="email"
                placeholder="Enter email here"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
              {activeForEmail ? (
                <div className="alignment error">
                  <label className="b upload-label ">{message}</label>
                </div>
              ) : null}

              {activeForVerify ? (
                <div className="alignment successForEmail">
                  <label className="b upload-label ">{message}</label>
                </div>
              ) : null}

              {verifyForMessage ? (
                <div className="spacing">
                  <label className="b2 upload-label error">
                    Your email is not verified. Please re-verify email.
                  </label>
                </div>
              ) : null}
            </Form.Item>
            {!verify ? (
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
              >
                <label className="b2 upload-label">
                  Password <span className="upload-span">*</span>
                </label>
                <Input.Password
                  className="login-input"
                  type="password"
                  placeholder="Enter password here"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                />
                {activeForPassword ? (
                  <div className="alignment error">
                    <label className="b upload-label ">{message}</label>
                  </div>
                ) : null}
              </Form.Item>
            ) : null}
            {verify ? null : (
              <Form.Item className="textRight">
                <a
                  className="b2"
                  href=""
                  onClick={handleForgotPasswordNavigation}
                >
                  Forgot password
                </a>
              </Form.Item>
            )}
            {verify ? null : (
              <Form.Item>
                <Button
                  size="large"
                  shape="round"
                  text="Login"
                  handleButtonClick={onSubmit}
                  type="primary"
                  className="theme-button login-btn"
                  focus
                />
              </Form.Item>
            )}
            {verify ? null : (
              <Form.Item className="textRight">
                <div className="b2" onClick={handleSignUp}>
                  Dont have an account?{' '}
                  <span>
                    <a>Click here</a>
                  </span>
                </div>
              </Form.Item>
            )}
            {verify ? (
              <Form.Item>
                <Button
                  size="large"
                  shape="round"
                  text="reverify email"
                  handleButtonClick={onVerifyEmail}
                  type="primary"
                  className="theme-button login-btn"
                  focus
                />
              </Form.Item>
            ) : null}
          </Form>
        </Col>
        <Col lg={16} className="rightSide">
          {/* <img src={SideImage} alt={'sideImage'} /> */}
          <div className="login-right-side">
            <div className="title-description-container">
              <div className="login-welcome-title">Welcome</div>
              <div className="login-right-side-description">
                {getDescription()}
              </div>
              <div className="loginsideImage">
                <img src={LoginScreen} alt="sideImage" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Login;
