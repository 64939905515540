export const sortedData = (order: boolean, list: any, column: string) => {
  if (order) {
    return list.sort(function (a: any, b: any) {
      if (a[column] > b[column]) {
        return -1;
      }
      if (a[column] < b[column]) {
        return 1;
      }
      return 0;
    });
  } else {
    return list.sort(function (a: any, b: any) {
      if (a[column] < b[column]) {
        return -1;
      }
      if (a[column] > b[column]) {
        return 1;
      }
      return 0;
    });
  }
};

export const sortNumber = (order: boolean, list: any, column: string) => {
  if (order) return list.sort((a: any, b: any) => +a[column] - +b[column]);
  else return list.sort((a: any, b: any) => +b[column] - +a[column]);
};
