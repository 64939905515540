/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import Delete from '../../assets/images/Delete.svg';
import './styles.scss';
import ComboActions from '../../redux/middleware/combo';
import Utils from '../../redux/utils';
type Props = {
  stepper: number;
  getBase64: any;
  handleBackStepper: any;
  handleCombo: any;
  handleDescription: any;
  handleImage: any;
  image: string;
  combo: string;
  description: string;
  comboIngredient: any;
  addComboMenu: any;
  deleteComboMenu: any;
  handleChange: any;
  handleChangeValue: any;
  handleServing: any;
  serving: any;
  handleCostRatio: any;
  costRatio: any;
  proposedPrice: any;
  totalCost: any;
  finalPrice: any;
  handleFinalPrice: any;
};
const ComboStep = (props: Props) => {
  const brand = Utils.getCurrentBrand();
  const [menu, setMenu] = React.useState<any>([]);
  const { TextArea } = Input;
  const fetchRecipeData = async () => {
    const recipeData: any = await ComboActions.getRecipesForCombo();
    setMenu(recipeData?.data.items);
  };
  useEffect(() => {
    fetchRecipeData();
  }, []);
  const fileRef = React.useRef<HTMLInputElement>(null);
  let {
    getBase64,
    handleCombo,
    handleDescription,
    handleImage,
    image,
    combo,
    description,
    comboIngredient,
    addComboMenu,
    deleteComboMenu,
    handleChangeValue,
    handleServing,
    serving,
    handleCostRatio,
    costRatio,
    proposedPrice,
    totalCost,
    finalPrice,
    handleChange,
    handleFinalPrice,
  } = props;

  const handleMenu = async (data1: any, index: number) => {
    const data = await ComboActions.getDetailRecipeforcombo(data1);

    handleChange(data?.data, index);
  };
  const handlevariation = (data: any, variationList: any[], index: number) => {
    let getVariation = variationList?.filter(
      (variation) => variation?.variation_id == data
    );

    getVariation?.length > 0 &&
      handleChangeValue(getVariation[0], index, 'variation');
  };
  const handlequantity = (data: any, index: number, key: string) => {
    handleChangeValue(data, index, key);
  };

  return (
    <>
      <div className="step2-head">Combo Information</div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Combo Name <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter combo name"
          size="large"
          value={combo}
          onChange={(e) => handleCombo(e.target.value)}
          required
        />
      </div>

      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">
          Description <span className="sterik">*</span>
        </div>

        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input description here"
          autoSize={{ minRows: 5, maxRows: 6 }}
          value={description}
          onChange={(e) => handleDescription(e.target.value)}
        />
      </div>
      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">Upload Image</div>

        {image ? (
          <div className="imageUpload">
            <div className="imageWrapper">
              <img src={image} alt="" className="imageHeight" />
              <div className="deleteButton" onClick={() => handleImage('', {})}>
                <img src={Delete} width={15} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="formgroup formgroupStepOne">
            <div className="label-wrapper">
              <label>
                <p className="ant-upload-text ">
                  Drop your image here or browse
                </p>
                <p className="ant-upload-hint">Minimum file 2 MB</p>
                <input
                  ref={fileRef}
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={async (e: any) => {
                    const response = await getBase64(e.currentTarget.files[0]);
                    if (response) handleImage(response, e);
                  }}
                />
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="step2-head">Combo Detail</div>
      <div className="ComboLabelContainer wizardSubContainer">
        <div className="menuWidth">
          Menu <span className="sterik">*</span>
        </div>
        <div className="variantWidth">
          Variation <span className="sterik">*</span>
        </div>
        <div className="qtyWidth">
          Qty <span className="sterik">*</span>
        </div>
        <div className="netPriceWidth">Net Price</div>
      </div>
      {comboIngredient.map((data: any, index: number) => (
        <div className="inputComboMenuContainer" key={index}>
          <div
            className="ComboLabelContainer wizardSubContainer inputFieldContainer"
            key={index}
          >
            <div className="menuWidth">
              <Select
                size={'large'}
                placeholder={'Select consumption type'}
                onChange={(e) => {
                  handleMenu(e, index);
                }}
                style={{ width: 240 }}
                optionFilterProp="children"
                value={data.recipe_name}
              >
                {menu?.map((data: any, menuIndex: number) => (
                  <Select.Option value={data?.recipe_id} key={menuIndex}>
                    {data.recipe_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="variantWidth">
              <Select
                size={'large'}
                placeholder={'Select'}
                onChange={(e) => handlevariation(e, data?.variations, index)}
                style={{ width: 110 }}
                disabled={data?.disable}
                value={data?.variation?.variation}
              >
                {data?.variations?.map((data: any, varientIndex: number) => (
                  <Select.Option value={data?.variation_id} key={varientIndex}>
                    {data.variation}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="qtyWidth">
              <Input
                placeholder="0"
                size="large"
                type="number"
                value={data.quantity}
                onChange={(e) =>
                  handlequantity(e.target.value, index, 'quantity')
                }
              />
            </div>
            <div className="netPriceWidth ComboLabelContainer">
              <div className="usdLabel">{brand?.currency_name}</div>
              <div className="usdValueDiv">{data?.total_price}</div>
            </div>
          </div>
          <img
            src={Delete}
            width={15}
            alt=""
            onClick={() => deleteComboMenu(index)}
            className="comboMenuDelete"
          />
        </div>
      ))}
      <div className="addButton" onClick={addComboMenu}>
        + Add New
      </div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Serving <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter Serving"
          size="large"
          type="number"
          value={serving}
          onChange={(e) => handleServing(e.target.value)}
          required
        />
      </div>
      <div className="step2-head">Ingredient Cost</div>

      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="comboPriceFieldLabel">Total Cost</div>
            <span className="pricegroup">
              <span>{brand?.currency_name}</span>
              <div>{Number(totalCost.toFixed(2))}</div>
            </span>
          </span>
          <span className="col">
            <div className="comboPriceFieldLabel">Cost Ratio</div>
            <span className="pricegroup">
              <Input
                placeholder="Enter Cost Ratio"
                size="large"
                type="number"
                value={costRatio != 0 && costRatio}
                onChange={(e) => handleCostRatio(e.target.value)}
                required
              />
              <span>%</span>
            </span>
          </span>
        </div>
      </div>

      <div className="subRecipewizardSubContainer">
        <div className="row bottomFieldComboMargin">
          <span className="col">
            <div className="wizardLabel">Proposed Price</div>
            <span className="pricegroup">
              <span>{brand?.currency_name}</span>
              <div>{Number(proposedPrice.toFixed(2))}</div>
            </span>
          </span>

          <span className="col">
            <div className="wizardLabel">Final Price</div>
            <span className="subRecipeFinalpricegroup">
              <span>{brand?.currency_name}</span>

              <Input
                placeholder="Enter Final Price"
                size="large"
                type="number"
                value={finalPrice}
                onChange={(e) => handleFinalPrice(e.target.value)}
                required
              />
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default ComboStep;
