/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, PaginationProps, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
} from '../../components';
import LeadGenerationApi from '../../redux/middleware/leadGeneration';
import { sortedData } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import moment from 'moment';
import './leadGeneration.scss';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Details</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
];

const PageDataLength = [6, 10, 20];

const LeadGeneration = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(6);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const { currentBrand } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    pageData();
  }, [currentBrand]);
  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>DESCRIPTION</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('description');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'description',
      key: 'description',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 feedbackListViewScreen feedbackMinWidth">
          {record?.description?.length > 40
            ? record?.description?.substring(0, 40) + '...'
            : record?.description}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BRAND</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('brand_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'brand_name',
      key: 'brand_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="col3 cellSpacing">{record?.brand_name}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SCREEN</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('screen');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'screen',
      key: 'screen',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 feedbackListViewScreen">{record?.screen}</div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>DATE CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="feedbackCreatedAt">
          <div className="feedbackUsernameListView">{record?.user_name}</div>
          <div className="feedbackCreatedAtValue">
            {moment(record?.created_at).format('MMMM Do YYYY, h:mm a')}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'FeedBack'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const pageData = async () => {
    setLoader(true);
    const variationData = await LeadGenerationApi.getLeads(dataLength, current);
    if (variationData === undefined || !variationData?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(variationData?.data?.leads_count);
      setTotal(variationData?.data?.pagination?.total);
      setSortData(variationData?.data?.items);
    }
    setLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setLoader(true);
    const searchResultofVariations = await LeadGenerationApi.getSearchLeads(
      dataLength,
      keyword
    );
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResultofVariations?.data?.leads_count);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Lead Generation"
        breadcrumbIcon
        iconAction={() => alert('test')}
        button={''}
        perm_status={permissionStatus}
      />
      <Page title="Lead Generation">
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <div style={{ display: 'flex', marginRight: '100px' }}>
              <ListCount count1={count} title1="Total Leads" />
            </div>
          </Col>
          <Col lg={14} md={24}>
            <div className="lead-search-wrapper">
              <div className="search-box">
                <SearchBox action={onSearch} placeholderText="Search..." />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!loader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={loader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default LeadGeneration;
