import React from 'react';
import { Modal } from 'antd';
import Success from '../../assets/images/succesResponse.png';
import Error from '../../assets/images/errorResponse.png';
import './responseDialog.scss';

type Props = {
  type: string;
  messageArray: any;
  OnClick: any;
};

const ResponseDialog = (props: Props) => {
  let { type, messageArray, OnClick } = props;
  Modal.destroyAll();
  return Modal.info({
    icon: null,
    content: (
      <div className="responseDialogContainer">
        {type == 'error' ? (
          <img src={Error} alt="" className="successImage" />
        ) : type == 'success' ? (
          <img src={Success} alt="" className="successImage" />
        ) : (
          <img
            src={require('../../assets/icons/recipeLoader.gif')}
            alt="loading..."
            className="gifWidth"
          />
        )}
        <div>
          {messageArray?.map((data: string, index: number) => (
            <div
              className={`messageText ${type} responseDialogTextSpacing`}
              key={index}
            >
              {data}
            </div>
          ))}
        </div>
      </div>
    ),

    okText: <div>OK</div>,
    onOk: OnClick,
    okType: 'text',
    okButtonProps: { disabled: type == 'info' ? true : false },
  });
};

export default ResponseDialog;
