import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Input,
  Spin,
  // Dropdown as DropDown,
  // Space,
  // MenuProps,
} from 'antd';
import {
  Page,
  Breadcrumb,
  ResponseDialog,
  Dropdown,
  IngredientDialog,
} from '../../components';
// import UsersApi from '../../redux/middleware/users';
// import BrandActions from '../../redux/middleware/brands';
// import IngredientActions from '../../redux/middleware/ingredient';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Delete from '../../assets/images/Delete.svg';
// import ArrowDown from '../../assets/images/ArrowDown.png';
// import Eye from '../../assets/images/Eye.svg';
import Utils from '../../redux/utils';
import './cashPurchaseWizard.scss';
import SupplierApi from '../../redux/middleware/supplier';

const CashPurchaseWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  // const [image, setImage] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  // const [imageObject, setImageObject] = React.useState({});
  // const [country, setCountry] = React.useState({} as any);
  // const [, setCompany] = React.useState('' as any);
  // const [phone, handlePhone] = React.useState('' as any);
  const [receipt, setReceipt] = React.useState('');
  // const [email, setEmail] = React.useState('');
  // const [unit, setUnit] = useState<any>({});
  // const [, setSubLicense] = React.useState();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [, setCompanyList] = React.useState([]);
  // const [, setCountryList] = React.useState([]);
  // const [branchLimits, setBranchLimits] = useState<any>([]);

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  const [ingredientName, setIngredientName] = useState('');
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  // const [option, setOption] = useState<SelectProps['options']>([
  //   { value: '', label: '', key: '' },
  // ]);
  // const [quantity, setQuantity] = React.useState<number>();
  const [ingredientData, setIngredientData] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>();
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      unit: 'Kg',
      multiplier: 0,
    },
  ]);

  // const [messages, setMessages] = useState<any[]>([]);
  // const [alerts, setAlerts] = useState(false);
  // const [visible, setVisible] = useState(false);
  // const [unit, setUnit] = useState<any>([]);

  // const items: MenuProps['items'] = unit?.map((data: any) => {
  //   return {
  //     key: `${data?.id}`,
  //     label: <div className="dropdownOptions">{data?.unit}</div>,
  //   };
  // });

  // const fetchUnit = async () => {
  //   const data = await IngredientActions.getUnits();
  //   setUnit(data?.data);
  // };

  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(data?.find((type: any) => type?.slug == 'cash_purchase'));

    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  // const handlePickerChange =
  //   (fieldName: string) => (value: string | string[], data: any) => {
  //     if (fieldName === 'country') setCountry(data);
  //     if (fieldName === 'city') {
  //       setCity(data?.value);
  //     }
  //     if (fieldName === 'company') setCompany(data);
  //   };

  // const getCountryListing = async () => {
  //   const response = await ResourcesActions.getCountries(500, 1);
  //   if (response?.success) {
  //     const { data } = response;
  //     const list =
  //       data &&
  //       data.items &&
  //       data.items.map((item: any) => ({
  //         value: item?.country_code,
  //         label: item?.country_name,
  //         key: item?.id,
  //       }));
  //     setCountryList(list);
  //   }
  // };

  // const getBrandInfo = async () => {
  //   const response = await BrandActions.getBrandById(id);
  //   if (response?.success) {
  //     const { data } = response;

  //     setBrand(data?.name);
  //     setSubLicense(data?.sub_license);
  //     handlePhone(data?.phone);
  //     setEmail(data?.email);
  //     setCountry({
  //       label: data?.country,
  //       value: data?.country,
  //       key: data?.country_id,
  //     });
  //     setCompany({
  //       label: data?.company,
  //       value: data?.company,
  //       key: data?.company_id,
  //     });
  //     setImage(data?.thumbnail);
  //   }
  // };

  // const getCompanyById = async () => {
  //   const company = await Utils.getCurrentCompany();
  //   const response = await UsersApi.getUserCompanyById(company?.id);
  //   if (response?.success) {
  //     // setBranchLimits(
  //     //   response?.data?.available_sub_licenses?.map((data: any) => ({
  //     //     label: data,
  //     //     value: data,
  //     //   }))
  //     // );
  //   }
  // };

  const fetchDataById = async () => {
    setApiLoader(true);
    const { data, success } = await SupplierApi.getInventorySupplyById(id);

    if (success) {
      setIngredients(
        data?.ingredients?.map((ingredient: any) => ({
          ...ingredient,
          id: ingredient?.ingredient_id,
          quantity: +ingredient?.request_quantity,
        }))
      );
      setReceipt(data?.receipt_no);
      setTotalPrice(data?.total_cost);
    }
    setApiLoader(false);
  };

  React.useEffect(() => {
    if (update) {
      fetchDataById();
    }
  }, []);

  //   const fetchUnits = async () => {
  //     const data =
  //       component == 'recipe builder' || component == 'rb_marketlist'
  //         ? await IngredientActions.getUnits()
  //         : await SubRecipeActions.getStorageUnits();
  //     const option: SelectProps['options'] =
  //       component == 'recipe builder' || component == 'rb_marketlist'
  //         ? data?.data.map((unit: any) => {
  //             return {
  //               value: unit.id,
  //               label: unit.unit,
  //               key: unit.id,
  //             };
  //           })
  //         : data?.data?.map((unit: any) => {
  //             return {
  //               value: unit.id,
  //               label: unit.storage_unit,
  //               key: unit.id,
  //             };
  //           });
  //     setOption(option);
  //   };

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    setErrorList({});
    setErrorStatus(false);
    const user = await Utils.getCurrentUser();
    const company = await Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    // console.log(user, company, brand, shop);
    const checkEmptyIngredient = ingredients?.some(
      (data: any) =>
        data?.quantity == '' || data?.id == -1 || data?.ingredient_name == ''
    );

    if (brand === '' || !ingredients?.length || checkEmptyIngredient) {
      setVisible(true);
      return;
    }

    const payload = {
      request_type_id: requestType?.id,
      request_status_id: status?.id,
      company_id: company?.id,
      brand_id: brand?.brand_id,
      shop_id: shop?.shop_id,
      created_by: user?.id,
      receipt_no: receipt,
      total_cost: totalPrice,
      tag: user?.roles[0]?.name === 'Branch' ? 'shop' : 'brand',
      ingredients: ingredients?.map((data: any) => ({
        ingredient_id: data?.id,
        request_quantity: data?.quantity,
        unit_cost: data?.unit_cost,
        total_cost: +data.unit_cost * +data?.quantity,
      })),
    };

    let responseDialogData;
    setApiLoader(true);

    try {
      const response = update
        ? await SupplierApi.updateInventorySupply(payload, id)
        : await SupplierApi.createInventorySupply(payload);
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/cashPurchase');
          },
          type: 'success',
          messageArray: [
            `Your ${receipt}`,
            ` has been ${update ? 'Updated' : 'Created'}`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }

    // const payload = {
    //   company_id: user?.company_id,
    //   name: brand,
    //   city_id: 0,
    //   country_id: user?.country_id,
    //   phone: phone.toString(),
    //   email: email,
    //   created_by: user?.id,
    // };

    // try {
    //   let responseDialogData;

    //   setApiLoader(true);
    //   let response;
    //   if (update) {
    //     const thumbnail = imageObject || image;
    //     response = await BrandActions.updateBrand(id, payload, thumbnail);
    //   } else {
    //     response = await BrandActions.addBrand(payload, imageObject);
    //   }
    //   setApiLoader(false);
    //   if (!response?.success) {
    //     setErrorList(response?.error);
    //     setErrorStatus(true);
    //     // responseDialogData = {
    //     //   OnClick: () => {},
    //     //   type: 'error',
    //     //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
    //     // };
    //     // ResponseDialog(responseDialogData);
    //   } else {
    //     responseDialogData = {
    //       OnClick: () => {
    //         navigate('/app/brands');
    //       },
    //       type: 'success',
    //       messageArray: [
    //         ` Your brand - ${brand}`,
    //         ` has been ${!update ? 'Created' : 'Updated'}`,
    //       ],
    //     };
    //     ResponseDialog(responseDialogData);
    //   }
    // } catch (e) {
    //   console.log(e);
    //   let responseDialogData = {
    //     OnClick: () => {},
    //     type: 'error',
    //     messageArray: ['Server Error!', 'Something Went Wrong'],
    //   };
    //   ResponseDialog(responseDialogData);
    // }
  };

  // const checkEmptyIngredients = (index: number) => {
  //   if (index == 2) {
  //     const wrongIngredient = ingredients.filter((data: any) => {
  //       if (
  //         data?.ingredient_id == -1 ||
  //         data?.quantity == '' ||
  //         data?.quantity == '0'
  //       )
  //         return { unit: data?.carb_per_unit, qty: data?.quantity };
  //     });
  //     if (wrongIngredient.length) {
  //       setMessages([
  //         "Please Select correct Ingredient. Quantity shouldn't be zero and please remove unused fields, if any.",
  //       ]);
  //       setAlerts(true);
  //       setVisible(false);
  //       window.scrollTo(0, 0);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  const removeIngredientName = (index: number) => {
    let dataArray = ingredients;
    dataArray[index].ingredient_name = '';
    setIngredients(() => [...dataArray]);
  };

  const handleIngredientName = (data: string, index: number) => {
    let dataArray = ingredients;
    dataArray[index].ingredient_name = data;

    setIngredients(() => [...dataArray]);
  };

  const handleRemoveIngredient = (index: number) => {
    let DataArray = ingredients;
    DataArray.splice(index, 1);
    setIngredients([...DataArray]);
    handleTotalPrice(DataArray);
    // autoFetchAllergen(DataArray);
  };

  const handleIngredientWeight = (data: any, index: number, label: string) => {
    let dataArray = ingredients;
    console.log(dataArray);

    if (data === 0) {
      dataArray[index][label] = '';
      setIngredients(() => [...dataArray]);
    } else {
      dataArray[index][label] = data;
      setIngredients(() => [...dataArray]);
    }
    handleTotalPrice(dataArray);
  };
  // const handleIngredientWeightUnit = (data: any, index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].unit = data?.unit;
  //   dataArray[index].unit_id = data?.id;
  //   dataArray[index].multiplier = data?.multiplier;

  //   setIngredients(() => [...dataArray]);
  // };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map(
      (data) => Number(data?.unit_cost * parseFloat(data?.quantity)) || 0
    );
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(+Number(price)?.toFixed(2) || 0);
  };

  const AddIngredient = () => {
    setIngredients((prevArray) => [
      ...prevArray,
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        unit: 'Kg',
        multiplier: 0,
      },
    ]);
  };

  const handleIngredient = (data: any, index: number) => {
    let dataArray = ingredients;

    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredients(() => [...dataArray]);
      // setMessages([
      //   'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      // ]);
      // setVisible(false);
      // setAlerts(true);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredients([...dataArray]);
    } else {
      // const unitObject = unit.filter(
      //   (element: any) => element?.id == data?.unit_id
      // );
      dataArray[index] = {
        ...data,
        quantity: '',
      };
      // autoFetchAllergen(dataArray);
      setIngredients(() => [...dataArray]);
    }
  };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   const unitObject = unit.filter((data: any) => data?.id == option);
  //   handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  // };

  // const DropDownmenuProps = {
  //   items: items,
  //   onClick: handleDropDownClick,
  // };

  const fetchIngredients = async () => {
    const { data, success } = await SubRecipeActions.getAllSubRecipeInventory(
      3,
      1,
      1000
    );
    if (success) setIngredientData(data?.items);
  };

  const handleDialog = () => {
    setOpen(false);
    fetchIngredients();
    removeIngredientName(ingredientIndex);
  };

  const handleDeleteContinue = (index: number) => {
    handleRemoveIngredient(index);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Cash Purchase"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/cashPurchase');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Cash Purchase">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          {open ? (
            <IngredientDialog
              handleDialog={handleDialog}
              handleSuccesDialog={handleDialog}
              open={open}
              component={'marketlist'}
              name={ingredientName}
              handleIngredient={(data: any) => {
                handleIngredient(data, ingredientIndex);
                setIngredientName(data?.ingredient_name);
              }}
            />
          ) : null}
          <div className="wizardContainer">
            {/* <div className="cash-purchase-wizard-container"> */}
            <div className="wizardDropdown">
              <div className="wizardLabel add-labels-with-ingredient-container">
                <div>
                  Ingredients <span className="sterik">*</span>
                </div>
                <div className="add-labels-with-ingredient-container labels-sub-container">
                  <div className="">Unit Cost</div>
                  <div className="">Total Cost</div>
                </div>
              </div>

              {ingredients?.map((data: any, index: number) => (
                <div className="gramContainer" key={index}>
                  <Dropdown
                    component={'supply'}
                    handleIngredientName={(text: any) => {
                      handleIngredientName(text, index);
                      setIngredientName(text);
                    }}
                    ingredientName={data?.ingredient_name}
                    openDialog={() => {
                      setIngredientIndex(index);
                      setOpen(true);
                    }}
                    ingredientData={ingredientData}
                    database={{ label: 'USA', key: 3 }}
                    handleIngredient={(data: any) => {
                      handleIngredient(data, index);
                    }}
                  />

                  <div className="gramSubContainer">
                    <input
                      className="gramValue"
                      placeholder="0"
                      type={'number'}
                      value={
                        data.quantity ? Number(data?.quantity).toString() : ''
                      }
                      onChange={(e) => {
                        if (+e.target.value <= 999)
                          handleIngredientWeight(
                            +e.target.value,
                            index,
                            'quantity'
                          );
                      }}
                    />
                    <div className="gramBoxWrapper">
                      <div className="gramBox">
                        {/* <DropDown
                          menu={DropDownmenuProps}
                          trigger={['click']}
                          placement="bottomRight"
                          arrow={{ pointAtCenter: true }}
                        >
                          <a onClick={() => setIngredientIndex(index)}>
                            <Space>
                              <div>{data?.unit}</div>{' '}
                              <img src={ArrowDown} alt="" />
                            </Space>
                          </a>
                        </DropDown> */}
                        <div>{data?.unit}</div>
                      </div>
                    </div>
                  </div>

                  <img
                    src={Delete}
                    width={15}
                    alt=""
                    className="deleteButon"
                    onClick={() => {
                      // handleIndex(index);
                      handleDeleteContinue(index);
                      // data?.ingredient_name == ''
                      //   ? handleDeleteContinue(index)
                      //   : setOpenDelete(true);
                    }}
                  />
                  <div className="unit-container-each-ingredient">
                    <div className="cash-purchase-unit-cost-input">
                      {/* {isNaN(+data?.unit_cost)
                        ? 0
                        : Number((+data?.unit_cost || 0).toFixed(2))} */}
                      <input
                        className="gramValue"
                        placeholder="0"
                        type={'number'}
                        value={
                          data.unit_cost
                            ? Number(data?.unit_cost).toString()
                            : ''
                        }
                        onChange={(e) => {
                          if (+e.target.value <= 999)
                            handleIngredientWeight(
                              +e.target.value,
                              index,
                              'unit_cost'
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="calorie-container-each-ingredient">
                    <div className="calorie-each-value-container">
                      {isNaN(+data?.unit_cost * parseFloat(data?.quantity))
                        ? 0
                        : Number(
                            (
                              +data?.unit_cost * parseFloat(data?.quantity)
                            ).toFixed(2)
                          )}
                    </div>
                  </div>
                </div>
              ))}
              {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
            </div>

            <div className="addButton">
              <div onClick={AddIngredient} className="addButton-subContainer">
                + Add New Row
              </div>
            </div>

            {/* <div className="cash-purchase-wizard-container"> */}
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Receipt Number <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Receipt Number"
                size="large"
                value={receipt}
                onChange={(e) => setReceipt(e.target.value)}
                required
                className={
                  errorList['brand_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['brand_name']}
                </div>
              )}
            </div>

            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Total Cost <span className="sterik">*</span>
              </div>
              <Input
                placeholder="0"
                size="large"
                value={totalPrice}
                // onChange={(e) => setEmail(e.target.value)}
                disabled
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div>

            <div className="butonGroup">
              <div
                className="wizard-button"
                onClick={() => navigate('/app/cashPurchase')}
              >
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
            {/* </div> */}
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default CashPurchaseWizard;
