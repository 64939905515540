/* eslint-disable no-unused-vars */
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useState, useEffect } from 'react';
import {
  Page,
  Breadcrumb,
  Stepper,
  SubRecipeStepTwo,
  ResponseDialog,
} from '../../components';
import { SubRecipeStepOne } from '../../components';
import './styles.scss';
import ProductionAction from '../../redux/middleware/production';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import Utils from '../../redux/utils';
import { Alert, Spin } from 'antd';
import Attributes from '../../redux/middleware/attributes';

type additionalInfo = {
  id: number;
  attribute_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type Database = {
  value: any;
  label: any;
  key: any;
};

const ProductionRecipeWizard = () => {
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  const shop = Utils.getCurrentShop();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { update, id } = state;

  //Step 1
  const [loader, setLoader] = useState(false);
  const [allergenData, setAlergenData] = useState<any[]>();
  const [productionRecipe, setProductionRecipe] = React.useState('');
  const [recipeNote, setRecipeNote] = useState('');
  const [category, setCategory] = React.useState<any>();
  const [unit, setUnit] = React.useState<any>({});
  const [yeild, setYeild] = React.useState('');
  const [costRatio, setCostRatio] = React.useState('');
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [waste, setWaste] = React.useState('');
  const [finalPrice, setFinalPrice] = React.useState('');
  const [subCategory, setSubCategory] = useState<any>({});
  const [parentCategory, setParentCategory] = useState('');
  const [parentSubCategory, setParentSubCategory] = useState([]);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [ingredient, setIngredient] = useState<any[]>([
    {
      ingredient_name: '',
      quantity: 0,
      unit_cost: '',
      waste_ingredient: '',
      small_unit: 'g',
      total_price: 0,
    },
  ]);
  const [messages, setMessages] = useState<any[]>([]);
  const [database, setDatabase] = useState<Database>({
    value: 2,
    label: 'My Market List',
    key: 3,
  });

  const [alerts, setAlerts] = useState(false);
  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };

  const handleRecipe = (data: string) => {
    setProductionRecipe(data);
  };

  const handleSubCategory = (data: any) => {
    setSubCategory(data);
  };

  const handleUnit = (data: any) => {
    setUnit(data[0]);
  };
  const handleYeild = (data: any) => {
    setYeild(data);
  };

  const handleProposedPrice = (ratio: any) => {
    setPrice((totalPrice * 100) / ratio);
  };

  const handleChangeDatabase = (value: string | string[], data: any) => {
    setDatabase(data);
  };

  const handleCostRatio = (data: any) => {
    if (+data <= 100) {
      setCostRatio(data);
      data == '' ? setPrice(0) : handleProposedPrice(+data);
    }
  };

  const handleFinalPrice = (data: any) => {
    setFinalPrice(data);
  };
  const handleWaste = (data: any) => {
    setWaste(data);
  };

  const handleRecipeNote = (data: string) => {
    setRecipeNote(data);
  };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    if (key == 'quantity' && data != '')
      dataArray[index]['total_price'] = Number(
        (
          (+dataArray[index]['unit_cost'] / dataArray[index]['divided_by']) *
          +data
        ).toFixed(2)
      );
    else
      dataArray[index]['total_price'] = Number(
        (
          +dataArray[index]['unit_cost'] / dataArray[index]['divided_by']
        ).toFixed(2)
      );

    return dataArray;
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map((data) => data?.total_price);
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(price);
  };

  const handleIngredient = (data: any, index: number, key: any) => {
    let dataArray = ingredient;
    if (key == 'quantity')
      dataArray = handleCostIngredient(dataArray, data, key, index);
    key == 'quantity' && handleTotalPrice(dataArray);
    dataArray[index][key] = data;

    setIngredient([...dataArray]);
  };
  const deleteIngredient = (index: number) => {
    let DataArray = ingredient;
    DataArray.splice(index, 1);
    handleTotalPrice(DataArray);
    setIngredient([...DataArray]);
  };

  const addIngredient = () => {
    setIngredient((prev) => [
      ...prev,
      {
        ingredient_id: -1,
        ingredient_name: '',
        quantity: 0,
        unit_cost: 0,
        waste_ingredient: '',
        small_unit: 'g',
        total_price: 0,
      },
    ]);
  };

  //Step 2
  const [stepper, setStepper] = useState(0);
  const [serving, setServing] = useState<number>();
  const [imageObject, setImageObject] = useState({});
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [steps, setSteps] = useState(['']);
  const [prep, setPrep] = useState<number>();
  const [calory, setCalory] = useState<any>();
  const [crab, setCrab] = useState<any>();
  const [fat, setFat] = useState<any>();
  const [protein, setProtein] = useState<any>();
  const [allergens, setAllergens] = useState<any[]>([]);
  const [additonal_info, setAdditonal_info] = useState<any[]>([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepDeleteDialog, setStepDeleteDialog] = useState(false);

  const handleIndex = (data: number) => setStepIndex(data);

  const fetchDataById = async (id: any) => {
    const data = await ProductionAction.getDetailRecipe(id);
    if (data) {
      setLoader(false);
    }

    setImage(data?.data.general.thumbnail);
    setUnit({
      storage_unit: data?.data.general.unit,
      storage_unit_id: data?.data.general.unit_id,
    });
    setProductionRecipe(data?.data.general.recipe_name);
    setDescription(data?.data.general.description);

    setIngredient(
      data?.data.ingredients.map((data: any) => {
        return {
          ...data,
          id: data?.ingredient_id,
          waste_ingredient: data?.waste,
          total_price: data?.cost_per_ingredient
            ? data?.cost_per_ingredient
            : 0,
        };
      })
    );
    setSubCategory({
      id: data?.data?.general?.menu_category_id,
      sub_category: data?.data?.general?.menu_category,
    });
    setServing(data?.data.general.servings);
    setAllergens(data?.data.general.allergens);
    setYeild(data?.data.general.quantity);
    setWaste(data?.data.general.waste);
    setCostRatio(data?.data.general.fcr);
    setFinalPrice(data?.data.general.final_price);
    setSteps(data?.data.general.steps);
    setCalory(data?.data.general.calorie);
    setFat(data?.data.general.fat);
    setCrab(data?.data.general.carb);
    setProtein(data?.data.general.protein);
    setPrep(data?.data.general.prep_time);
    setRecipeNote(data?.data.general.instruction);
    setAdditonal_info(data?.data.general.additional_attributes);
    setTotalPrice(data?.data?.general?.total_cost);
    setPrice(data?.data?.general?.propose_price);
  };

  // const checkCategory = async () => {
  //   const menuCategories = await Attributes.getMenuCategory(company?.id);
  //   const getMenuCategory = menuCategories?.data?.items.filter(
  //     (data: any) => data?.name.toLowerCase() == 'production'
  //   );
  //   setLoader(false);
  //   if (
  //     menuCategories?.data?.items?.length > 0 &&
  //     getMenuCategory?.length > 0
  //   ) {
  //     setCategory(getMenuCategory[0]);
  //   } else {
  //     let responseDialogData = {
  //       type: 'error',
  //       messageArray: [`Please create Menu Category, before!`],
  //       OnClick: () => {
  //         navigate('/app/productionrecipe');
  //       },
  //     };
  //     ResponseDialog(responseDialogData);
  //   }
  // };

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      const filterValue = data?.filter(
        (x: any) => x.parent_category === 'Production Category'
      );
      setParentCategory(filterValue[0]?.id);
    }
  };

  useEffect(() => {
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
    fetchParentCategories();
    fetchAllergen();
    // checkCategory();
  }, []);

  useEffect(() => {
    if (parentCategory) {
      fetchSubCategories();
    }
  }, [parentCategory]);

  const fetchSubCategories = async () => {
    const subCategories = await MenuCategoryActions.getSubCategoriesForWizard(
      parentCategory,
      100
    );
    const categories = subCategories?.data?.items.map((x: any) => {
      return {
        value: x.id,
        label: x.sub_category,
        key: x.id,
      };
    });
    setParentSubCategory(categories);
  };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const addSteps = () => {
    setSteps((prev) => [...prev, '']);
  };

  const handlePrep = (data: string) => {
    setPrep(Number(data));
  };
  const handleServing = (data: number) => {
    setServing(data);
  };

  const handleChangeStep = (text: any, index: number) => {
    let DataArray = steps;
    DataArray[index] = text;
    setSteps([...DataArray]);
  };
  const handleDeleteStep = () => {
    let DataArray = steps;
    DataArray.splice(stepIndex, 1);
    setSteps([...DataArray]);
    setStepDeleteDialog(false);
  };

  const handleDeleteDialog = (data: boolean) => setStepDeleteDialog(data);

  const handleCalory = (data: string) => {
    setCalory(+data);
  };

  const handleCrab = (data: string) => {
    setCrab(+data);
  };

  const handleFat = (data: string) => {
    setFat(+data);
  };

  const handleProtein = (data: string) => {
    setProtein(+data);
  };

  const handleallergenCheck = (e: CheckboxChangeEvent, data: any) => {
    const { checked } = e.target;

    if (checked) {
      setAllergens((prev) => [...prev, data]);
    } else {
      let dataArray = allergens;
      const updated = dataArray.filter((val) => val !== data);
      setAllergens([...updated]);
    }
  };
  const handleaddionalinfocheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: additionalInfo
  ) => {
    const { checked } = e.target;

    if (checked) {
      setAdditonal_info((prev) => [...prev, data]);
    } else {
      let dataArray = additonal_info;
      const updated = dataArray.filter((val) => val !== data);

      setAdditonal_info([...updated]);
    }
  };

  const handleBack = () => {
    if (stepper > 0) {
      setVisible(false);
      setAlerts(false);
      setStepper(stepper - 1);
    } else navigate('/app/productionrecipe');
  };
  const checkEmptyIngredientQuantity = () => {
    const quantity = ingredient.filter((data) => data?.quantity);
    return ingredient.length === quantity.length ? false : true;
  };
  const handleNextStepper = async () => {
    const checkIngredientQuantity = checkEmptyIngredientQuantity();

    if (
      (stepper == 0 &&
        (productionRecipe == '' ||
          (Object.keys(unit).length === 0 &&
            (Object.getPrototypeOf(unit) === Object.prototype) == undefined) ||
          yeild == '' ||
          waste == '' ||
          ingredient.length == 0 ||
          checkIngredientQuantity)) ||
      (stepper == 1 &&
        (steps.length == 0 ||
          serving == 0 ||
          serving == null ||
          prep == 0 ||
          prep == null))
    ) {
      setVisible(true);
      setAlerts(false);
      window.scrollTo(0, 0);
      return;
    }
    if (finalPrice == '' && price == 0) {
      setVisible(true);
      setAlerts(false);
      window.scrollTo(0, 0);
      return;
    }
    setErrorStatus(false);
    setVisible(false);
    if (stepper == 1 && update) {
      const params = {
        general: {
          recipe_name: productionRecipe,
          thumbnail:
            imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
              ? image
              : null,
          menu_category_id: subCategory?.id,
          unit_id: unit?.storage_unit_id,
          quantity: Number(yeild),
          company_id: company?.id,
          brand_id: brand?.brand_id,
          shop_id: shop?.id || 0,
          currency_id: brand?.currency_id,
          is_verified: 1,
          verified_by: 'Head of Culinary',
          created_by: user?.id,
        },
        ingredients: ingredient.map((data: any) => {
          return {
            ingredient_name: data?.ingredient_name,
            ingredient_id: data?.id,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            cost_per_ingredient: data?.total_price,
            waste: Number(data?.waste_ingredient),
          };
        }),

        costing: {
          total_cost: totalPrice,
          fcr: Number(costRatio),
          propose_price: JSON.stringify(price),
          final_price: finalPrice == '' ? price : Number(+finalPrice),
          waste: Number(waste),
        },
        production: {
          instructions: recipeNote,
          description: description,
          prep_time: Number(prep),
          servings: serving,
          calorie: calory,
          protein: protein,
          carb: crab,
          fat: fat,
          allergens_id: allergens.map((data) => data?.id).toString(),
          steps: steps,
          additional_attribute: additonal_info
            .map((data) => data?.attribute_name)
            .toString(),
          additional_attribute_id: additonal_info
            .map((data) => data?.id)
            .toString(),
        },
        inventory: {
          calorie: calory,
          calorie_unit: 1,
          calories_per_unit: 1,
          carb: crab,
          carb_unit: 2,
          carb_per_unit: 1,
          protein: protein,
          protein_unit: 2,
          protein_per_unit: 1,
          fat: fat,
          fat_unit: 2,
          fat_per_unit: 1,
        },
      };
      setLoader(true);

      const response = await ProductionAction.updateProductionRecipe(
        params,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? null
          : imageObject,

        id
      );
      setLoader(false);
      if (response == undefined || !response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        setStepper(0);
      } else {
        let responseDialogData = {
          OnClick: () => {
            navigate('/app/productionrecipe');
          },
          type: 'success',
          messageArray: [` Your ${productionRecipe} has been Updated`],
        };
        ResponseDialog(responseDialogData);
      }
      setStepper(stepper + 1);
    } else if (stepper == 1) {
      try {
        let responseDialogData;

        const data = {
          general: {
            recipe_name: productionRecipe,
            menu_category_id: subCategory?.id,
            unit_id: unit?.storage_unit_id,
            quantity: Number(yeild),
            company_id: company?.id,
            brand_id: brand?.brand_id,
            shop_id: shop?.id || 0,
            currency_id: brand?.currency_id,
            is_verified: 1,
            verified_by: 'Head of Culinary',
            created_by: user?.id,
          },
          ingredients: ingredient.map((data: any) => {
            return {
              ingredient_name: data?.ingredient_name,
              ingredient_id: data?.id,
              unit_id: data?.unit_id,
              quantity: data?.quantity,
              cost_per_ingredient: data?.total_price,
              waste: Number(data?.waste_ingredient),
            };
          }),

          costing: {
            total_cost: totalPrice,
            fcr: Number(costRatio),
            propose_price: JSON.stringify(price),
            final_price: finalPrice == '' ? price : Number(+finalPrice),
            waste: Number(waste),
          },
          production: {
            instructions: recipeNote,
            description: description,
            prep_time: Number(prep),
            calorie: calory,
            protein: protein,
            carb: crab,
            fat: fat,
            servings: serving,
            allergens_id: allergens.map((data) => data?.id).toString(),
            steps: steps,
            additional_attribute: additonal_info
              .map((data) => data?.attribute_name)
              .toString(),
            additional_attribute_id: additonal_info
              .map((data) => data?.id)
              .toString(),
          },
          inventory: {
            calorie: calory,
            calorie_unit: 1,
            calories_per_unit: 1,
            carb: crab,
            carb_unit: 2,
            carb_per_unit: 1,
            protein: protein,
            protein_unit: 2,
            protein_per_unit: 1,
            fat: fat,
            fat_unit: 2,
            fat_per_unit: 1,
          },
        };
        setLoader(true);
        const response = await ProductionAction.createProductionRecipe(
          data,
          imageObject
        );
        setLoader(false);
        if (response == undefined || !response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
          setStepper(0);
        } else {
          responseDialogData = {
            OnClick: () => {
              navigate('/app/productionrecipe');
            },
            type: 'success',
            messageArray: [` Your ${productionRecipe} has been Created`],
          };
          ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }

      setStepper(stepper + 1);
    } else if (stepper == 0) {
      setVisible(false);
      setAlerts(false);
      setStepper(stepper + 1);
      window.scrollTo(0, 0);
    }
  };

  const calculateNutritionValues = () => {
    let c_carb = 0;
    let c_fat = 0;
    let c_protein = 0;
    let c_calorie = 0;
    for (let index = 0; index < ingredient.length; index += 1) {
      const { quantity } = ingredient[index];
      const {
        protein_per_unit,
        carb_per_unit,
        calories_per_unit,
        fat_per_unit,
      } = ingredient[index];

      c_carb += carb_per_unit * parseFloat(quantity);
      c_fat += fat_per_unit * parseFloat(quantity);
      c_protein += protein_per_unit * parseFloat(quantity);
      c_calorie += calories_per_unit * parseFloat(quantity);
    }

    setCrab(c_carb);
    setProtein(c_protein);
    setFat(c_fat);
    setCalory(c_calorie);
  };

  const handleContinue = () => {
    if (stepper < 2) handleNextStepper();
    if (stepper == 0) calculateNutritionValues();
  };

  const autoFetchAllergen = (dataSource: any) => {
    let arr = dataSource.map((data: any) => {
      if (data?.ingredient_name != '' || +data?.unit_cost != 0)
        return data.allergens_id;
    });
    arr = arr
      .filter(function (element: any) {
        return element !== undefined;
      })
      ?.filter((data: any) => data !== null);
    arr = arr.map((data: any) => data.split(',')).flat();
    let allergenId = [...new Set(arr)];
    const FetchAllergen = allergenId.map((data) => {
      let fetchObject = allergenData?.filter((element) => element?.id == data);
      return fetchObject;
    });
    setAllergens(FetchAllergen.flat());
  };

  const handleIngredientObject = (data: any, index: number) => {
    let dataArray = ingredient;
    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;
    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredient(() => [...dataArray]);
      setMessages([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setAlerts(true);
      setVisible(false);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredient([...dataArray]);
    } else {
      dataArray[index] = {
        ...data,
        quantity: 0,
        total_price: 0,
      };

      handleTotalPrice(dataArray);
      autoFetchAllergen(dataArray);
      setIngredient([...dataArray]);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="AddNew Production Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/productionrecipe');
        }}
      />
      <Page title="AddNew Production Recipe">
        {visible && (
          <Alert
            message="Error"
            description="Please Complete required fields!"
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}
        {alerts &&
          messages?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setAlerts(false)}
              key={index}
            />
          ))}
        <Stepper stepper={stepper} component="productionRecipe" />

        <Spin spinning={loader} size="large">
          <div className="wizardContainer">
            {stepper === 0 ? (
              <SubRecipeStepOne
                component="productionRecipe"
                SubRecipeName={productionRecipe}
                category={category}
                unit={unit}
                yeild={yeild}
                costRatio={costRatio}
                totalPrice={totalPrice}
                price={price}
                waste={waste}
                finalPrice={finalPrice}
                ingredient={ingredient}
                subCategory={subCategory}
                parentSubCategory={parentSubCategory}
                handleRecipe={handleRecipe}
                handleUnit={handleUnit}
                handleYeild={handleYeild}
                handleCostRatio={handleCostRatio}
                handleFinalPrice={handleFinalPrice}
                handleWaste={handleWaste}
                handleIngredient={handleIngredient}
                deleteIngredient={deleteIngredient}
                addIngredient={addIngredient}
                handleIngredientObject={handleIngredientObject}
                screenName={'Production'}
                handleChangeDatabase={handleChangeDatabase}
                handleSubCategory={handleSubCategory}
                database={database}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            ) : (
              <SubRecipeStepTwo
                component="productionRecipe"
                image={image}
                description={description}
                steps={steps}
                prep={prep}
                calory={calory}
                crab={crab}
                fat={fat}
                protein={protein}
                stepDeleteDialog={stepDeleteDialog}
                additonal_info={additonal_info}
                allergens={allergens}
                serving={serving}
                recipeNote={recipeNote}
                handleRecipeNote={handleRecipeNote}
                handleServing={handleServing}
                handleIndex={handleIndex}
                handleImage={handleImage}
                handleDescription={handleDescription}
                addSteps={addSteps}
                handleChangeStep={handleChangeStep}
                handleCalory={handleCalory}
                handleCrab={handleCrab}
                handleFat={handleFat}
                handleProtein={handleProtein}
                handleallergenCheck={handleallergenCheck}
                handleaddionalinfocheck={handleaddionalinfocheck}
                handleDeleteStep={handleDeleteStep}
                handleDeleteDialog={handleDeleteDialog}
                handlePrep={handlePrep}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            )}
            <div className="butonGroup">
              <div className="wizard-button" onClick={handleBack}>
                Back
              </div>
              <div className="continue wizard-button" onClick={handleContinue}>
                Continue
              </div>
            </div>
          </div>
        </Spin>
      </Page>
    </React.Fragment>
  );
};
export default ProductionRecipeWizard;
