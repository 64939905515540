import React from 'react';
import { Card } from 'antd';
import { Row, Divider, Spin, Col } from 'antd';
import { Page, Breadcrumb, ListViewWithoutExpand } from '../../components';
import Dish from '../../assets/images/Dish.png';

import { Pie } from '@ant-design/charts';
import DashBoardActions from '../../redux/middleware/dashboard';
import './style.scss';
import Utils from '../../redux/utils';
const Dashboard = () => {
  const [menuitems, setMenuItems] = React.useState(0);
  const [recipeItems, setRecipeItems] = React.useState(0);
  const [subrecipe_items, setSubRecipeItems] = React.useState(0);
  const [production_items, setProductionItems] = React.useState(0);
  const [stop, setStop] = React.useState(true);
  const [, setSortData] = React.useState([{}]);
  const [sortData2, setSortData2] = React.useState([{}]);
  const [sortData3, setSortData3] = React.useState([{}]);
  const [sortData4, setSortData4] = React.useState([{}]);
  const [recipeLoader, setRecipeLoader] = React.useState(false);
  const company = Utils.getCurrentCompany();
  const [menuCategory, setMenuCategory] = React.useState<any>([
    {
      category_name: '',
      quantity: 0,
      category_items: [
        {
          name: '',
          profitability: 0,
        },
      ],
    },
  ]);
  const [Category, setCategory] = React.useState<any>([
    {
      type: '',
      value: 0,
    },
  ]);
  const [, setCategory1] = React.useState<any>([
    {
      type: '',
      value: 0,
    },
  ]);
  const [, setCategory2] = React.useState<any>([
    {
      type: '',
      value: 0,
    },
  ]);
  const fetchData = async () => {
    setRecipeLoader(true);

    const data: any = await DashBoardActions.getdashboard(company?.id);
    if (data == undefined) {
      setMenuCategory([]);
      setCategory([]);
      setCategory1([]);
      setCategory2([]);
      setSortData([]);
      setSortData2([]);
      setSortData3([]);
      setSortData4([]);
    } else {
      setMenuItems(data?.data?.menu_items);
      setRecipeItems(data?.data?.recipe_items);
      setSubRecipeItems(data?.data?.subrecipe_items);
      setProductionItems(data?.data?.production_items);
      setMenuCategory(
        data?.data.menu_category.map((data: any) => {
          return {
            ...data,
            category_name: data?.category_name,
            quantity: data?.quantity,
            category_items: data.category_items.map((data: any) => {
              return {
                ...data,
                name: data?.name,
                profitability: data?.profitability,
              };
            }),
          };
        })
      );
      setCategory(
        data?.data?.menu_category.map((data: any) => {
          return {
            ...data,
            type: data?.category_name,
            value: data?.quantity,
          };
        })
      );
      setCategory1(
        data?.data?.menu_category[1].category_items.map((data: any) => {
          return {
            ...data,
            type: data?.name,
            value: data?.profitability,
          };
        })
      );
      setCategory2(
        data?.data?.menu_category[2].category_items.map((data: any) => {
          return {
            ...data,
            type: data?.name,
            value: data?.profitability,
          };
        })
      );
      setSortData(data?.data?.ingredient_category);
      setSortData2(data?.data?.menu_category[0]?.category_items);
      setSortData3(data?.data?.menu_category[1]?.category_items);
      setSortData4(data?.data?.menu_category[2]?.category_items);
    }
    setRecipeLoader(false);
  };
  React.useEffect(() => {
    if (stop === true) {
      setStop(false);
      fetchData();
    }
  }, []);

  const columns = [
    {
      dataIndex: 'index',
      key: '*index',
      render: (data: any, record: any, index: any) => <div>{index + 1}</div>,
    },
    {
      dataIndex: 'image',
      key: 'image',

      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={Dish} width="33px" height="33px" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="subCol1 titleContainer font">
                {record?.category_name || record?.name}
              </div>
              <div className="subCol1 titleContainer font">
                {record?.profitability}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="Dashboard">
      <Breadcrumb heading="Dashboard" breadcrumbIcon iconAction={() => {}} />

      <Page title="Dashboard">
        {!recipeLoader ? (
          <div>
            {/* <Row gutter={2}>
              <div className="selector">
                <Select
                  size={'large'}
                  placeholder={'Filter by orders'}
                  // onChange={handelChangeConsumption}

                  optionFilterProp="children"
                  options={[
                    { value: 'Orders', label: 'Orders' },
                    { value: 'Menu Items', label: 'Menu Items' },
                    { value: 'Ingredients', label: 'Ingredients' },
                    { value: 'Wastage', label: 'Wastage' },
                  ]}
                />
                <DatePicker />
              </div>
            </Row> */}
            <Row>
              <Col lg={12}>
                <div className="site-card-border-less-wrapper">
                  <Card>
                    <div className="carditems">
                      <div className="items">
                        {/* <div className="icon">
                          <img src={SubMenu} />
                        </div>
                        */}
                        <div className="details">
                          <div className="title">Menu Items</div>

                          <div className="value">{menuitems} </div>
                        </div>
                      </div>
                      {/* <div className="button">Export details</div> */}
                    </div>
                  </Card>
                  <Card>
                    <div className="carditems">
                      <div className="items">
                        {/* <div className="icon">
                          <img src={SubMenu} />
                        </div>
                        */}
                        <div className="details">
                          <div className="title">Recipe Items</div>

                          <div className="value">{recipeItems} </div>
                        </div>
                      </div>
                      {/* <div className="button">Export details</div> */}
                    </div>
                  </Card>
                  <Card>
                    <div className="carditems">
                      <div className="items">
                        {/* <div className="icon">
                          <img src={SubMenu} />
                        </div>
                        */}
                        <div className="details">
                          <div className="title">Sub Recipe</div>

                          <div className="value">{subrecipe_items} </div>
                        </div>
                      </div>
                      {/* <div className="button">Export details</div> */}
                    </div>
                  </Card>
                  <Card>
                    <div className="carditems">
                      <div className="items">
                        {/* <div className="icon">
                          <img src={SubMenu} />
                        </div>
                        */}
                        <div className="details">
                          <div className="title">Production Recipe</div>

                          <div className="value">{production_items} </div>
                        </div>
                      </div>
                      {/* <div className="button">Export details</div> */}
                    </div>
                  </Card>
                </div>
              </Col>
              <Col lg={12}>
                <div className="Graphs">
                  <Card>
                    <div>
                      <Row gutter={2}>
                        <div className="heading">
                          {/* {menuCategory[0]?.category_name} */}
                          Top 5 Menu
                        </div>
                      </Row>
                      <div className="graphdetails">
                        <Pie
                          color={[
                            '#FEBD2F',
                            '#0B75F5',
                            '#7147F7',
                            '#F97420',
                            '#40B9C3',
                          ]}
                          {...{
                            appendPadding: 5,
                            data: Category,
                            angleField: 'value',
                            colorField: 'type',

                            radius: 1,
                            innerRadius: 0.75,
                            label: {
                              type: 'inner',
                              offset: '-50%',
                              content: '',
                              style: {
                                textAlign: 'center',
                                fontSize: 0,
                              },
                            },
                            interactions: [
                              { type: 'element-selected' },
                              { type: 'element-active' },
                            ],
                            statistic: {
                              title: false as const,
                              content: {
                                style: {
                                  whiteSpace: 'pre-wrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                },

                                formatter: function formatter() {
                                  return ``;
                                },
                              },
                            },
                          }}
                          height={140}
                          width={295}
                        />
                        <div className="value-list">
                          {Category?.map((data: any, index: number) => {
                            return (
                              <div key={index} className="value-text">
                                {' '}
                                {data.value}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Card>
                  {/* <Card>
                  <div>
                    <div className="heading">
                      {menuCategory[1]?.category_name}
                    </div>
                    <div className="graphdetails">
                      <Pie
                        color={[
                          '#FEBD2F',
                          '#997A36',
                          '#E5C275',
                          '#FFEABD',
                          '#FFF5E7',
                        ]}
                        {...{
                          appendPadding: 5,
                          data: Category1,
                          angleField: 'value',
                          colorField: 'type',

                          radius: 1,
                          innerRadius: 0.75,
                          label: {
                            type: 'inner',
                            offset: '-50%',
                            content: '',
                            style: {
                              textAlign: 'center',
                              fontSize: 0,
                            },
                          },
                          interactions: [
                            { type: 'element-selected' },
                            { type: 'element-active' },
                          ],
                          statistic: {
                            title: false as const,
                            content: {
                              style: {
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              },

                              formatter: function formatter() {
                                return ``;
                              },
                            },
                          },
                        }}
                        height={140}
                        width={295}
                      />
                      <div className="value-list">
                        {Category.map((data: any, index: number) => {
                          return (
                            <div key={index} className="value-text">
                              {' '}
                              {data.value}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Card> */}
                  {/* <Card>
                  <div>
                    <div className="heading">
                      {menuCategory[3]?.category_name}
                    </div>
                    <div className="graphdetails">
                      <Pie
                        color={[
                          '#FEBD2F',
                          '#997A36',
                          '#E5C275',
                          '#FFEABD',
                          '#FFF5E7',
                        ]}
                        {...{
                          appendPadding: 5,
                          data: Category2,
                          angleField: 'value',
                          colorField: 'type',

                          radius: 1,
                          innerRadius: 0.75,
                          label: {
                            type: 'inner',
                            offset: '-50%',
                            content: '',
                            style: {
                              textAlign: 'center',
                              fontSize: 0,
                            },
                          },
                          interactions: [
                            { type: 'element-selected' },
                            { type: 'element-active' },
                          ],
                          statistic: {
                            title: false as const,
                            content: {
                              style: {
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              },

                              formatter: function formatter() {
                                return ``;
                              },
                            },
                          },
                        }}
                        height={140}
                        width={295}
                      />

                      <div className="value-list">
                        {Category.map((data: any, index: number) => {
                          return (
                            <div key={index} className="value-text">
                              {' '}
                              {data.value}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Card> */}
                </div>
              </Col>
            </Row>

            <Row>
              <div className="listTable">
                <Card>
                  <div className="header">
                    <div className="heading">
                      {' '}
                      {menuCategory[0]?.category_name}
                    </div>
                    {/* <div className="listbutton">Export details</div> */}
                  </div>
                  <Divider type="horizontal" />
                  <div className="List">
                    <ListViewWithoutExpand
                      items={''}
                      dataSource={[...(sortData2.slice(0, 3) || [])]}
                      columns={columns}
                    />
                  </div>
                </Card>
                <Card>
                  <div className="header">
                    <div className="heading">
                      {' '}
                      {menuCategory[1]?.category_name}
                    </div>
                    {/* <div className="listbutton">Export details</div> */}
                  </div>
                  <Divider type="horizontal" />

                  <div className="List">
                    <ListViewWithoutExpand
                      items={''}
                      dataSource={[...sortData3.slice(0, 3)] || []}
                      columns={columns}
                    />
                  </div>
                </Card>
                <Card>
                  <div className="header">
                    <div className="heading">
                      {' '}
                      {menuCategory[2]?.category_name}
                    </div>
                    {/* <div className="listbutton">Export details</div> */}
                  </div>
                  <Divider type="horizontal" />{' '}
                  <div className="List">
                    <ListViewWithoutExpand
                      items={''}
                      dataSource={[...sortData4.slice(0, 3)] || []}
                      columns={columns}
                    />
                  </div>
                </Card>
              </div>
            </Row>
          </div>
        ) : (
          <div className="loader-wrapper">
            <Spin spinning={recipeLoader} className="recipeLoader" />
          </div>
        )}
      </Page>
    </div>
  );
};

export default Dashboard;
