/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  Button,
  ListViewWithoutExpand,
  IngredientDialog,
} from '../../components';
import Export from '../../assets/images/export.png';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import IngredientActions from '../../redux/middleware/ingredient';
import Attributes from '../../redux/middleware/attributes';
import { sortedData } from '../../helper/sortData';
import Clock from '../../assets/images/Clock.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import SubMenu from '../../assets/images/SubMenu.svg';
import ProductionIcon from '../../assets/icons/sidebar/Production.svg';
import moment from 'moment';
import './marketlist.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Details</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const InventoryListPage = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  // const [database, setDatabase] = useState<any>([]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [openIngredientDialog, setOpenIngredientDialog] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [selectedDbId, setSelectedDbId] = useState(3);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [update, setUpdate] = useState(false);

  const { currentBrand } = useSelector((state: any) => state.Auth);
  const { currentShop } = useSelector((state: any) => state.Auth);

  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>Sku ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{record?.sku_id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">
          {record?.ingredient_name}{' '}
          {record?.ingredient_category?.toLowerCase() == 'sub recipe' && (
            <img src={SubMenu} width={15} alt="" className="subRecipeIcons" />
          )}
          {record?.ingredient_category?.toLowerCase() == 'production' && (
            <img
              src={ProductionIcon}
              width={15}
              alt=""
              className="subRecipeIcons"
            />
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_category');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">{record?.ingredient_category}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Unit Cost</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          <div className="subrecipecol2">{record?.unit_cost}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Unit </span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">{record?.storage_unit}</div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items:
              permissionStatus && permissionStatus === 2
                ? user?.roles[0]?.id === 4 || user?.roles[0]?.id === 5
                  ? []
                  : record?.ingredient_category === 'Sub Recipe' ||
                    record?.ingredient_category === 'Production'
                  ? items.filter((x) => x?.key !== '2')
                  : items
                : [],
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const columnsForUsDb = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.ingredient_name}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_category');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">{record?.ingredient_category}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>DATE CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          <img src={Clock} alt="" className="icons" />
          {moment(record?.created_at).fromNow()}
        </div>
      ),
    },
  ];

  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Inventory List'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
    fetchDatabase();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, selectedDbId, currentBrand, currentShop]);

  const fetchDatabase = async () => {
    const data = await IngredientActions.getDatabase();
    // setDatabase(data?.data);
  };

  // const handleActiveTab = (db: any) => {
  //   setSelectedDbId(db?.id);
  // };

  const handleDialog = () => {
    setOpenIngredientDialog(false);
    setUpdate(false);
    setBlocked(false);
  };

  const handleSuccesDialog = () => {
    setOpenIngredientDialog(false);
    setUpdate(false);
    setBlocked(false);
    pageData();
  };

  const handleDialogData = async () => {
    const ingredientObject = await IngredientActions.getInventoryById(
      selectedMenu
    );
    setUpdate(true);
    setOpenIngredientDialog(true);
    setBlocked(true);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        handleDialogData();
        break;
      case 2:
        setUpdate(true);
        setOpenIngredientDialog(true);
        break;
      default:
    }
  };

  const handleCSV = async () => {
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const response = await Attributes.exportCSVForInventory(
      brand?.brand_id,
      shop?.shop_id ? shop?.shop_id : ''
    );
    console.log('response', response);
    const { data } = response;
    window.open(data?.url);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        setOpenIngredientDialog(true);
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setRecipeLoader(true);
    const id = selectedDbId;
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const variationData = await SubRecipeActions.getAllSubRecipeInventory(
      id,
      current,
      dataLength,
      brand?.brand_id,
      shop?.shop_id ? shop?.shop_id : ''
      // brand?.brand_id?brand?.brand_id:""
    );
    if (variationData === undefined || !variationData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(variationData?.data?.ingredients_count);
      setTotal(variationData?.data?.pagination?.total);
      setSortData(variationData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const id = selectedDbId;
    const searchResultofVariations =
      await SubRecipeActions.getSubRecipeInventory(id, keyword);
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofVariations?.data?.ingredients_count);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setRecipeLoader(false);
  };

  return (
    <React.Fragment>
      {openIngredientDialog ? (
        <IngredientDialog
          handleSuccesDialog={handleSuccesDialog}
          handleDialog={handleDialog}
          open={openIngredientDialog}
          component={'marketlist'}
          name={''}
          blocked={blocked}
          update={update}
          id={selectedMenu}
        />
      ) : null}

      <Breadcrumb
        heading="Inventory List"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={selectedDbId === 3 ? actionButton : ''}
        perm_status={
          user?.roles[0]?.id === 4 || user?.roles[0]?.id === 5
            ? 1
            : permissionStatus
        }
      />
      <Page title="Inventory List">
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <div style={{ display: 'flex', marginRight: '100px' }}>
              <ListCount
                count1={recipeCount}
                title1="Total Ingredients"
                title3="Brand"
                title4="Branch"
                component={'Inventory List'}
              />
              {/* <div className="uploadImageMenuContainer">
                {database?.map((db: any, index: any) => {
                  return (
                    <>
                      <div
                        className={
                          selectedDbId === db?.id ? 'menu' : 'menuLight'
                        }
                      >
                        <div
                          onClick={() => handleActiveTab(db)}
                          className="text menustyle"
                        >
                          {db?.db_name}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div> */}
            </div>
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox action={onSearch} placeholderText="Search..." />
              </div>
              <div className="exportButton" onClick={handleCSV}>
                <img src={Export} alt="" />
                <div className="exportText"> Export CSV</div>
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={[...sortData] || []}
                columns={selectedDbId === 2 ? columnsForUsDb : columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default InventoryListPage;
