import React from 'react';
import './styles.scss';
import { Modal, Row, Col } from 'antd';
import locompany from '../../assets/images/locompany.png';
import indofoods from '../../assets/images/Indofoods.png';
import tickIcon from '../../assets/images/tickIcon.png';
import Subtract from '../../assets/images/Subtract.png';

type Props = {
  open: boolean;
  handleClose: any;
  data: any;
};
const UserListDialog = (props: Props) => {
  let { open, handleClose, data } = props;

  return (
    <div className="UserListDialog">
      <Modal
        title={<div className="title font">User Detail</div>}
        centered
        open={open}
        onCancel={handleClose}
        width={608}
        footer={null}
      >
        <ul className="userListMenu">
          <li>
            <div className="title">User Role</div>
            <div className="content">
              <div className="content-text">
                <p>User Role</p>
                <p>{data?.roles?.map((data: any) => data?.name).toString()}</p>
              </div>
              {/* <div className="content-text">
                <p>Designation</p>
                <p>
                  {data?.designation
                    ?.map((data: any) => data?.designation)
                    .toString()}
                </p>
              </div> */}
              <div className="content-text">
                <p>Company</p>
                <p>
                  <img src={locompany} alt="Locompany" />{' '}
                  {data?.company_tree
                    ?.map((data: any) => data?.company_name)
                    .toString()}
                </p>
              </div>
              <div className="content-text">
                <p>Brand</p>
                <p>
                  <img src={indofoods} alt="Locompany" />
                  {data?.company_tree?.length &&
                    data?.company_tree[0]?.brands
                      ?.map((data: any) => data?.brand_name)
                      .toString()}
                </p>
              </div>
              {/* <div className="content-text">
                <p>Designation</p>
                <p>
                  {data?.designation
                    ?.map((data: any) => data?.designation)
                    .toString()}
                </p>
              </div> */}
            </div>
          </li>
          <li>
            <div className="title">User Information</div>
            <div className="content">
              <div className="content-text">
                <p>Name</p>
                <p>{data?.name}</p>
              </div>
              <div className="content-text">
                <p>Phone number</p>
                <p>{data?.phone}</p>
              </div>
              <div className="content-text">
                <p>Email</p>
                <p>{data?.email}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="title">User Access (Permitted Screen Access)</div>
            <ul className="content">
              <li className="content-list">
                <Row>
                  <Col md={8}>
                    <p>Module</p>
                  </Col>

                  <Col md={8} className="LimitedAccessCol tableTickCrossIcons">
                    <p>Full Access</p>
                  </Col>
                  <Col md={8} className="LimitedAccessCol tableTickCrossIcons">
                    <p>
                      Limited Access{' '}
                      <div className="readOnlyTextSize">(Read Only)</div>
                    </p>
                  </Col>
                </Row>
              </li>
              {data.permissions?.map((data: any, index: number) => (
                <li className="content-list" key={index}>
                  <Row>
                    <Col md={8}>
                      <p>{data?.permission}</p>
                    </Col>

                    <Col md={8} className="tableTickCrossIcons">
                      <p>
                        {data?.perm_status_id == 2 ? (
                          data?.perm_create == 0 ? (
                            <img src={tickIcon} alt="" />
                          ) : (
                            <p>
                              <p>
                                <img src={tickIcon} alt="" />
                              </p>
                              <div className="readOnlyFontSize">Read Only</div>
                            </p>
                          )
                        ) : (
                          <img src={Subtract} alt="" />
                        )}
                      </p>
                    </Col>
                    <Col md={8} className="tableTickCrossIcons">
                      <p>
                        {data?.perm_status_id == 1 ? (
                          <img src={tickIcon} alt="" />
                        ) : (
                          <img src={Subtract} alt="" />
                        )}
                      </p>
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </Modal>
    </div>
  );
};

export default UserListDialog;
