import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  BranchList,
  DeleteConfirmation,
} from '../../components';
import BranchActions from '../../redux/middleware/branches';
import { sortedData } from '../../helper/sortData';
import CompanyLight from '../../assets/images/CompanyLight.png';
// import Delete from '../../assets/images/Delete.svg';
import SettingDialog from '../../components/SettingDialog';
import More from '../../assets/images/More.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import EditSmall from '../../assets/images/EditSmall.png';
import Utils from '../../redux/utils';
import './styles.scss';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Details</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={EditSmall} className="iconDropdown" />,
  },

  {
    type: 'divider',
  },
  // {
  //   key: '4',
  //   label: <div className="dotOption deleteText">Delete</div>,
  //   icon: <img src={Delete} width={15} className="iconDropdown" />,
  // },
];

const PageDataLength = [6, 10, 20];
const Branch = () => {
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [Count, setCount] = useState(0);
  const [info, setInfo] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});

  const handleSort = (column: string) => {
    let arr = sortedData(ascending, sortData, column);
    setSortData(() => arr);
  };

  const { currentBrand } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentBrand]);

  const screenPermission = Utils.getScreenPermission('Branch');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>BRANCH NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('shop');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'shop',
      key: 'shop',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="company">{record?.shop_name}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BRAND NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('brand');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'brand',
      key: 'brand',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col1 font">
          {/* <img src={record?.thumbnail} width="52px" height="52px" /> */}
          <div className="subCol1 font">
            <span className="col1Text font">
              {record?.brand_id
                ?.map((data: any) => data?.brand_name)
                ?.toString()}
            </span>
            <div className="subTextCol1 font">
              <img src={CompanyLight} alt="" className="companyLight" />
              <div className="email">{record?.company_name}</div>
            </div>
          </div>
        </div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>COUNTRY</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('country');
    //           setAscending(!ascending);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'country',
    //   key: 'country',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="country">{record?.country_name}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>CITY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('city');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'city',
      key: 'city',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="city">{record?.city_name}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setDialogData(record);
              setSelectedId(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setOpenDialog(true);
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedId } });
        break;

      case 4:
        setOpenDeleteDialog(true);

        break;
      default:
    }
  };

  const DropDownmenuProps = {
    items: validateActionPermission()
      ? items
      : items.filter((x) => x?.key !== '2' && x?.key !== '4'),
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    setLoader(true);
    const brand = await Utils.getCurrentBrand();
    const Data = await BranchActions.getShops(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id
    );
    if (Data == undefined || !Data?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(Data?.data?.shop_count);
      setTotal(Data?.data?.pagination?.total);
      setSortData(Data?.data?.items);
    }
    setLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  // eslint-disable-next-line no-unused-vars
  const onSearch = async (keyword: string) => {
    setLoader(true);
    const searchResult = await BranchActions.searchShops(
      dataLength,
      current,
      keyword
    );
    if (searchResult == undefined || !searchResult?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResult?.data?.shop_count);
      setTotal(searchResult?.data?.pagination?.total);
      setSortData(searchResult?.data?.items);
    }
    setLoader(false);
  };

  const getBranchInfo = async () => {
    const response = await BranchActions.getBranchById(selectedId);
    if (response?.success) {
      const { data } = response;
      setInfo(data);
    }
  };

  useEffect(() => {
    if (openDialog) getBranchInfo();
  }, [openDialog]);

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await BranchActions.removeBranch(selectedId).then(() => pageData());
  };
  return (
    <React.Fragment>
      <SettingDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={info}
        component={'branch'}
        title={'Branch Detail'}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.shop}?`}
      />
      <Breadcrumb
        heading="Branch"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={validateActionPermission() && actionButton}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={5}>
            <ListCount count1={Count} title1="Total Branches" />
          </Col>
          <Col lg={8} />
          <Col lg={8}>
            <SearchBox action={onSearch} placeholderText="Search..." />
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!Loader ? (
              <BranchList
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
                limit={dataLength}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={Loader} className="Loader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default Branch;
