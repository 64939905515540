import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  MenuProps,
  Select,
  Spin,
  Dropdown as DropDown,
  Space,
} from 'antd';
import { Page, Breadcrumb, ResponseDialog, Dropdown } from '../../components';
import SupplierActions from '../../redux/middleware/supplier';
import Utils from '../../redux/utils';
import Delete from '../../assets/images/Delete.svg';
import ArrowDown from '../../assets/images/ArrowDown.png';
// import Eye from '../../assets/images/Eye.svg';
import './purchaseOrder.scss';

const PurchaseOrderWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [supplierId, setSupplierId] = useState<any>();
  const [selectedSupplierId, setSelectedSupplierId] = useState<any>('');
  // eslint-disable-next-line no-unused-vars
  const [errorList, setErrorList] = useState<any>({});
  // eslint-disable-next-line no-unused-vars
  const [errorStatus, setErrorStatus] = React.useState(false);
  const shop = Utils.getCurrentShop();
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const [ingredientName, setIngredientName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [ingredientData] = useState<any[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [requestType, setRequestType] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState<any>();
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      unit: 'Kg',
      multiplier: 0,
    },
  ]);
  const [totalPrice, setTotalPrice] = useState<number>();

  // const [messages, setMessages] = useState<any[]>([]);
  // const [alerts, setAlerts] = useState(false);
  // const [visible, setVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [unit, setUnit] = useState<any>([]);

  const items: MenuProps['items'] = unit?.map((data: any) => {
    return {
      key: `${data?.id}`,
      label: <div className="dropdownOptions">{data?.unit}</div>,
    };
  });

  const handleBack = () => {};

  const getSupplier = async () => {
    const supplierData = await SupplierActions.getSupplierOfShop(
      500,
      '',
      shop?.shop_id
    );
    setSuppliers(
      supplierData?.data?.items?.map((data: any) => {
        return {
          label: data?.supplier_name,
          value: data?.id,
        };
      })
    );
  };

  const handleSupplier = (value: any) => {
    setSelectedSupplierId(value);
    // const data: any = suppliers.filter((data: any) => {
    //   return data.value === value;
    // });
    //   setBrandId(data.flat());
  };

  React.useEffect(() => {
    fetchStatus();
    getSupplier();
    if (update) {
      setApiLoader(true);
      fetchDataById(id);
    }
  }, []);

  const fetchDataById = async (id: any) => {
    const { data, success } = await SupplierActions.getInventorySupplyById(id);

    if (success) {
      setIngredients(
        data?.ingredients?.map((ingredient: any) => ({
          ...ingredient,
          quantity: ingredient?.request_quantity,
        }))
      );
      setSupplierId({
        label: data?.supplier_name,
        value: data?.po_supplier_id,
      });
      setSelectedSupplierId(data?.po_supplier_id);
      setStatus({ status: data?.request_status, id: data?.request_status_id });
      setRequestType({
        status: data?.request_type_id,
        id: data?.request_type_id,
      });
      setApiLoader(false);
    }
  };

  const fetchStatus = async () => {
    const { success, data } = await SupplierActions.getSupplyStatus(
      'request_type'
    );
    if (success && data?.length > 0)
      setRequestType(data?.find((type: any) => type?.slug == 'purchase_order'));

    const response = await SupplierActions.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    // setErrorList({});
    // setErrorStatus(false);

    const checkIngredient = checkEmptyIngredients(2);
    if (checkIngredient) {
      return false;
    }
    if (
      requestType?.id === undefined ||
      supplierId === '' ||
      ingredients.length == 0 ||
      ingredients[0]?.ingredient_name == ''
    ) {
      setVisible(true);
      return;
    }
    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const company = await Utils.getCurrentCompany();
    const payload = {
      request_type_id: requestType?.id,
      request_status_id: status?.id,
      po_from: user?.roles[0]?.name === 'Branch' ? 'shop' : 'brand',
      po_from_id:
        user?.roles[0]?.name === 'Branch' ? shop?.shop_id : brand?.brand_id,
      po_supplier_id: selectedSupplierId,
      company_id: company?.id,
      brand_id: brand?.brand_id,
      shop_id: shop?.shop_id,
      created_by: user?.id,
      total_cost: totalPrice,
      ingredients: ingredients?.map((data: any) => ({
        ingredient_id: data?.id,
        request_quantity: data?.quantity,
        unit_cost: data?.unit_cost,
        total_cost: data.unit_cost * data?.quantity,
        is_unit_cost_update: 0,
      })),
    };
    try {
      let responseDialogData;

      setApiLoader(true);
      let response;
      if (update) {
        response = await SupplierActions.updateInventorySupply(payload, id);
      } else {
        response = await SupplierActions.createInventorySupply(payload);
      }
      setApiLoader(false);
      if (!response?.success) {
        // responseDialogData = {
        //   OnClick: () => {},
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/purchaseOrder');
          },
          type: 'success',
          messageArray: [
            ` Your Purchase Order`,
            ` has been ${!update ? 'Created' : 'Updated'}`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Server Error!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const checkEmptyIngredients = (index: number) => {
    if (index == 2) {
      const wrongIngredient = ingredients.filter((data: any) => {
        if (
          data?.ingredient_id == -1 ||
          data?.quantity == '' ||
          data?.quantity == '0'
        )
          return { unit: data?.carb_per_unit, qty: data?.quantity };
      });
      console.log('wrongIngredient', wrongIngredient);

      if (wrongIngredient.length) {
        setErrorMessage([
          "Please Select correct Ingredient. Quantity shouldn't be zero and please remove unused fields, if any.",
        ]);
        setError(true);
        setVisible(false);
        window.scrollTo(0, 0);
        return true;
      } else {
        return false;
      }
    }
  };

  //   const removeIngredientName = (index: number) => {
  //     let dataArray = ingredients;
  //     dataArray[index].ingredient_name = '';
  //     setIngredients(() => [...dataArray]);
  //   };

  const handleIngredientName = (data: string, index: number) => {
    let dataArray = ingredients;

    dataArray[index].ingredient_name = data;

    setIngredients(() => [...dataArray]);
  };

  const handleRemoveIngredient = (index: number) => {
    let DataArray = ingredients;
    DataArray.splice(index, 1);
    setIngredients([...DataArray]);
    handleTotalPrice(DataArray);
    // autoFetchAllergen(DataArray);
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map((data) => +data?.quantity);
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(price);
  };

  const handleIngredientWeight = (data: any, index: number) => {
    let dataArray = ingredients;
    if (data === 0) {
      dataArray[index].quantity = '';
      setIngredients(() => [...dataArray]);
    } else {
      dataArray[index].quantity = data;
      setIngredients(() => [...dataArray]);
    }
    handleTotalPrice(dataArray);
  };
  const handleIngredientWeightUnit = (data: any, index: number) => {
    let dataArray = ingredients;
    dataArray[index].unit = data?.unit;
    dataArray[index].unit_id = data?.id;
    dataArray[index].multiplier = data?.multiplier;

    setIngredients(() => [...dataArray]);
  };

  const AddIngredient = () => {
    setIngredients((prevArray) => [
      ...prevArray,
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        unit: 'Kg',
        multiplier: 0,
      },
    ]);
  };

  const handleIngredient = (data: any, index: number) => {
    let dataArray = ingredients;
    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredients(() => [...dataArray]);
      setErrorMessage([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setVisible(false);
      setError(true);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredients([...dataArray]);
    } else {
      // eslint-disable-next-line no-unused-vars
      const unitObject = unit.filter(
        (element: any) => element?.id == data?.unit_id
      );
      dataArray[index] = {
        ...data,
        quantity: '',
      };
      // autoFetchAllergen(dataArray);
      setIngredients(() => [...dataArray]);
    }
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    const unitObject = unit.filter((data: any) => data?.id == option);
    handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  //   const fetchIngredients = async () => {
  //     const { data, success } = await SubRecipeActions.getAllSubRecipeInventory(
  //       3,
  //       1,
  //       1000
  //     );
  //     if (success) setIngredientData(data?.items);
  //   };

  const handleDeleteContinue = (index: number) => {
    handleRemoveIngredient(index);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Purchase Order"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/purchaseOrder');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Purchase Order">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className={'wizardSubContainer'}>
              <div className="wizardDropdown">
                <div className="wizardLabel">
                  Supplier <span className="sterik">*</span>
                </div>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={supplierId}
                  onChange={handleSupplier}
                  options={suppliers}
                  className={
                    errorList['request']?.length > 0
                      ? 'dropdownUnitColorRed'
                      : ''
                  }
                />
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['supplier']}
                  </div>
                )}
              </div>
              <div className="wizardSubContainer">
                <div className="wizardLabel add-labels-with-ingredient-container">
                  <div>
                    Ingredients <span className="sterik">*</span>
                  </div>
                  <div className="add-labels-with-ingredient-container labels-sub-container">
                    <div className="">Unit Cost</div>
                    <div className="">Total Cost</div>
                  </div>
                </div>
                {ingredients?.map((data: any, index: number) => (
                  <div className="gramContainer" key={index}>
                    <Dropdown
                      component={'purchase order'}
                      handleIngredientName={(text: any) => {
                        handleIngredientName(text, index);
                        setIngredientName(text);
                      }}
                      ingredientName={data?.ingredient_name}
                      openDialog={() => {
                        setIngredientIndex(index);
                      }}
                      supplier_id={selectedSupplierId}
                      ingredientData={ingredientData}
                      database={{ label: 'USA', key: 3 }}
                      handleIngredient={(data: any) => {
                        handleIngredient(data, index);
                      }}
                    />

                    <div className="gramSubContainer">
                      <input
                        className="gramValue"
                        placeholder="0"
                        type={'number'}
                        value={
                          data.quantity ? Number(data?.quantity).toString() : ''
                        }
                        onChange={(e) => {
                          if (+e.target.value <= 999)
                            handleIngredientWeight(+e.target.value, index);
                        }}
                      />
                      <div className="gramBoxWrapper">
                        <div className="gramBox">
                          <DropDown
                            menu={DropDownmenuProps}
                            trigger={['click']}
                            placement="bottomRight"
                            arrow={{ pointAtCenter: true }}
                          >
                            <a onClick={() => setIngredientIndex(index)}>
                              <Space>
                                <div>{data?.unit}</div>{' '}
                                <img src={ArrowDown} alt="" />
                              </Space>
                            </a>
                          </DropDown>
                        </div>
                      </div>
                    </div>

                    <img
                      src={Delete}
                      width={15}
                      alt=""
                      className="deleteButon"
                      onClick={() => {
                        // handleIndex(index);
                        handleDeleteContinue(index);
                        // data?.ingredient_name == ''
                        //   ? handleDeleteContinue(index)
                        //   : setOpenDelete(true);
                      }}
                    />
                    <div className="unit-container-each-ingredient">
                      <div className="calorie-each-value-container">
                        {isNaN(data?.unit_cost)
                          ? 0
                          : Number((data?.unit_cost || 0).toFixed(2))}
                      </div>
                    </div>
                    <div className="calorie-container-each-ingredient">
                      <div className="calorie-each-value-container">
                        {isNaN(data?.unit_cost * parseFloat(data?.quantity))
                          ? 0
                          : Number(
                              (
                                data?.unit_cost * parseFloat(data?.quantity)
                              ).toFixed(2)
                            )}
                      </div>

                      {/* <Popover
                  content={
                    <div className="popUp-recipe-Container">
                      <div className="block block1">
                        <div>
                          <span className={`callories-number `}>
                            {isNaN(
                              data?.calories_per_unit *
                                parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.calories_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font callories-unit">{'kcal'}</span>
                        </div>
                        <div>
                          <span className={`crab-number `}>
                            {isNaN(
                              data?.carb_per_unit * parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.carb_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font crab-unit">{'g'}</span>
                        </div>
                        <div>
                          <span className={`fat-number `}>
                            {isNaN(
                              data?.fat_per_unit * parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.fat_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font fat-unit">{'g'}</span>
                        </div>
                        <div>
                          <span className={`font protein-number `}>
                            {isNaN(
                              data?.protein_per_unit *
                                parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.protein_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font protein-unit">{'g'}</span>
                        </div>
                      </div>
                      <div className="block block2">
                        <span className={'callories-text'}>calories</span>
                        <span className={'callories-text'}>carbs</span>
                        <span className={'callories-text'}>fats</span>
                        <span className={'callories-text'}>proteins</span>
                      </div>
                      <div className="allergen font">Allergens</div>
                      <div className="allergen-text">{data?.allergen}</div>
                    </div>
                  }
                  title="Details"
                  trigger="click"
                  open={popUp == index}
                  onOpenChange={() => {
                    console.log(data);
                    if (popUp >= -1) setPopUp(-2);
                    else setPopUp(index);
                  }}
                >
                  <img
                    src={Eye}
                    width={20}
                    className="ingredient-unmatach-select-icons"
                  />
                </Popover> */}
                    </div>
                  </div>
                ))}
                {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
              </div>

              <div className="addButton">
                <div onClick={AddIngredient} className="addButton-subContainer">
                  + Add New Row
                </div>
              </div>
            </div>
            {/* <div className="wizardSubContainer">
              <div className="wizardLabel">
                Created On <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Email"
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div>

            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Brand <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Email"
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div> */}

            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default PurchaseOrderWizard;
