/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Card,
  Col,
  Dropdown,
  Row,
  Space,
  Tooltip,
  Select,
  Divider,
  Switch,
} from 'antd';
import moment from 'moment';
import More from '../../assets/images/More.png';
import Fire from '../../assets/images/Fire.svg';
import Serving from '../../assets/images/Serving.png';
import Clock from '../../assets/images/Clock.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import Circle from '../../assets/images/Circle.png';
import Edit from '../../assets/images/Edit.svg';
import gram from '../../assets/images/gram.svg';
import approved from '../../assets/images/approved.png';
import pending from '../../assets/images/pending.png';
import rejected from '../../assets/images/rejected.png';
import subRecipeNotVerified from '../../assets/images/subRecipeNotVerified.png';
import subRecipeVerified from '../../assets/images/subRecipeVerified.png';
import ComboSubMenu from '../../assets/images/ComboSubMenu.png';
import './style.scss';
import Utils from '../../redux/utils';
type Props = {
  DropDownmenuProps: any;
  dataSource: any;
  handleOpen?: any;
  handleDialog: any;
  setSelectedMenu: any;
  component: string;
  handleToggle?: any;
  menuPropsNotVariation?: any;
  handledata: any;
  perm_status?: any;
  UTCtoLocal?: any;
  DropDownMenuWithoutApprovalProps?: any;
};

function GridView(props: Props) {
  const brand = Utils.getCurrentBrand();
  let {
    dataSource,
    DropDownmenuProps,
    handleOpen,
    setSelectedMenu,
    handleDialog,
    component,
    handleToggle,
    menuPropsNotVariation,
    handledata,
    perm_status,
    UTCtoLocal,
    DropDownMenuWithoutApprovalProps,
  } = props;
  const [hide, setHide] = useState(-1);
  const handleSwitch = (e: any, index: any, data: any) => {
    if (e) {
      handleToggle(e, data);
    } else {
      handleToggle(e, data);
    }
  };

  const handleEdit = (data: any) => {
    handleDialog(true, data);
  };
  const handleChange = (data: any, index: number, list: any) => {
    const dataArray: any = list.filter((val: any) => {
      return val.variation_id === data;
    });
    handledata(dataArray, index);
  };
  return (
    <div className="card-block">
      <Row gutter={[12, 12]}>
        {dataSource?.map((data: any, index: number) => {
          return (
            <Col
              className="gutter-row"
              xl={component == 'combos' || component == 'manageMenu' ? 6 : 8}
              lg={12}
              md={24}
              key={index}
            >
              <Card
                className="card"
                cover={
                  <div
                    className={
                      component !== 'menu' &&
                      component !== 'manageMenu' &&
                      component !== 'combos'
                        ? 'cover'
                        : 'coverWithoutHover'
                    }
                    onMouseEnter={() => {
                      setHide(index);
                    }}
                    onMouseLeave={() => setHide(-1)}
                  >
                    <img
                      alt="example"
                      src={data?.thumbnail}
                      // width="360px"
                      height={'190px'}
                      className="thumbnail"
                    />
                    <div className="imgtag">
                      {component == 'subRecipe' ||
                      component == 'productionRecipe' ||
                      component == 'recipe' ||
                      component == 'menu' ||
                      component == 'manageMenu' ||
                      component == 'combos'
                        ? data?.menu_category
                        : data?.category_name}
                    </div>
                    {/* {component === 'manageMenu' ? (
                      <div className="toggle">
                        <Switch
                        // className={Class[index]}
                        // onChange={(checked) => onChange(checked, index)}
                        />
                      </div>
                    ) : null} */}
                    {component !== 'menu' &&
                    component !== 'manageMenu' &&
                    component !== 'combos' ? (
                      <div className={hide === index ? 'button' : 'buttonHide'}>
                        <span
                          className="buttonText"
                          onClick={() => handleOpen(data)}
                        >
                          View Details
                        </span>
                      </div>
                    ) : null}
                  </div>
                }
              >
                <div className="head">
                  <div className="col1Text font">
                    {component === 'combos'
                      ? data?.combo_name?.length > 16
                        ? data?.combo_name?.slice(0, 16) + '...'
                        : data?.combo_name
                      : data?.recipe_name?.length > 16
                      ? data?.recipe_name.slice(0, 16) + '...'
                      : data?.recipe_name}
                  </div>
                  {component !== 'menu' &&
                  component !== 'manageMenu' &&
                  component !== 'combos' &&
                  component !== 'recipe' ? (
                    <Dropdown
                      onOpenChange={() => setSelectedMenu(data)}
                      menu={
                        component == 'recipeBuilder' && data?.status != 0
                          ? DropDownMenuWithoutApprovalProps
                          : DropDownmenuProps
                      }
                      trigger={['click']}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <div className="dots">
                            <img src={More} />
                          </div>
                        </Space>
                      </a>
                    </Dropdown>
                  ) : null}
                  {component == 'recipe' ? (
                    <Dropdown
                      onOpenChange={() => setSelectedMenu(data)}
                      menu={
                        data?.is_variation == 'no'
                          ? menuPropsNotVariation
                          : DropDownmenuProps
                      }
                      trigger={['click']}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <div className="dots">
                            <img src={More} />
                          </div>
                        </Space>
                      </a>
                    </Dropdown>
                  ) : null}
                </div>
                {component === 'menu' || component === 'manageMenu' ? (
                  <div className="variationTag">
                    <div className="variationText">Variation :</div>
                    {data?.variation_list?.length !== 0 ? (
                      <div className="variationOption">
                        <Select
                          onChange={(e) => {
                            handleChange(e, index, data?.variation_list);
                          }}
                          defaultValue={data?.variation_list?.[0].variation}
                          style={{ width: 120, marginTop: -5 }}
                          bordered={false}
                        >
                          {data?.variation_list?.map(
                            (data: any, innerindex: number) => (
                              <Select.Option
                                value={data?.variation_id}
                                key={innerindex}
                              >
                                {data.variation}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : null}
                {component === 'recipeBuilder' ? (
                  <div className="recipeBuilder-grid-view-calorie">
                    <div className="subTextCol1 font">
                      {data?.is_verified ? (
                        <img
                          src={Fire}
                          width={15}
                          alt=""
                          className=" iconFireWhite"
                        />
                      ) : (
                        <img
                          src={Fire}
                          width={15}
                          alt=""
                          className="subCol1Icon"
                        />
                      )}
                      <span className="col1Text font">{data?.calorie}</span>
                      <span className="calories">kcal</span>
                    </div>
                    <div>
                      {data?.status == 0 ? (
                        <img className="rd-approveIcon" src={pending} />
                      ) : data?.status == 1 ? (
                        <img className="rd-rejectIcon" src={rejected} />
                      ) : (
                        <img className="rd-approveIcon" src={approved} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="subTextCol1 font">
                    {data?.is_verified ? (
                      <img
                        src={subRecipeVerified}
                        alt=""
                        className="subRecipeVerifyIcon"
                      />
                    ) : (
                      <img
                        src={subRecipeNotVerified}
                        alt=""
                        className="subRecipeVerifyIcon"
                      />
                    )}
                    <span className="col1Text font">
                      {component === 'combos' ? data?.calories : data?.calorie}
                    </span>
                    <span className="calories">kcal</span>
                  </div>
                )}
                {component === 'recipeBuilder' ||
                component === 'menu' ||
                component === 'manageMenu' ||
                component === 'combos' ? (
                  <div className="bottom lineHeight gap">
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Servings</div>
                            <div className="tootipText">
                              {data?.servings} person serving
                            </div>
                          </div>
                        }
                      >
                        <img src={Serving} alt="" className="icons" />
                      </Tooltip>

                      <span className="itemGap">{data?.servings}</span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Sub recipe</div>
                            <div className="tootipText">
                              Total{' '}
                              {component === 'combos'
                                ? data?.recipes_count
                                : component === 'recipeBuilder'
                                ? data?.sub_recipe_items
                                : data?.sub_recipe_count}{' '}
                              sub recipes
                            </div>
                          </div>
                        }
                      >
                        {component === 'combos' ? (
                          <img src={ComboSubMenu} alt="" className="icons" />
                        ) : (
                          <img
                            src={SubMenu}
                            width={15}
                            alt=""
                            className="icons"
                          />
                        )}{' '}
                      </Tooltip>
                      <span className="itemGap">
                        {component === 'combos'
                          ? data?.recipes_count
                          : component === 'recipeBuilder'
                          ? data?.sub_recipe_items
                          : data?.sub_recipe_count}
                      </span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div className="tootipText">
                            {component === 'recipeBuilder'
                              ? moment(UTCtoLocal(data?.created_at)).format(
                                  'MMMM Do YYYY, h:mm:ss a'
                                )
                              : moment(data?.created_at).format(
                                  'MMMM Do YYYY, h:mm:ss a'
                                )}

                            {/* Created at Nov 23, 2022 12:23pm */}
                          </div>
                        }
                      >
                        <img src={Clock} alt="" className="icons" />
                      </Tooltip>
                      {component === 'recipeBuilder'
                        ? moment(UTCtoLocal(data?.created_at)).fromNow()
                        : moment(data?.created_at).fromNow()}
                      {/* {moment(data?.created_at).fromNow()} */}
                    </div>
                  </div>
                ) : null}
                {component === 'recipe' ? (
                  <div className="bottom lineHeight gap">
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Sub recipe</div>
                            <div className="tootipText">
                              Total {data?.subrecipe_count} sub recipe
                            </div>
                          </div>
                        }
                      >
                        <img
                          src={SubMenu}
                          width={15}
                          alt=""
                          className="icons"
                        />
                      </Tooltip>
                      <span className="itemGap">{data?.subrecipe_count}</span>
                      <span className="itemGap">sub recipe</span>
                    </div>
                  </div>
                ) : null}
                {component === 'subRecipe' ||
                component == 'productionRecipe' ? (
                  <div className="bottom lineHeight gap">
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Sub recipe</div>
                            <div className="tootipText">
                              Total{' '}
                              {component === 'subRecipe'
                                ? data?.total_ingredients
                                : data?.total_sub_recipe}{' '}
                              ingredients
                            </div>
                          </div>
                        }
                      >
                        <img
                          src={SubMenu}
                          width={15}
                          alt=""
                          className="icons"
                        />
                      </Tooltip>
                      <span className="itemGap">
                        {component === 'subRecipe'
                          ? data?.total_ingredients
                          : data?.total_sub_recipe}{' '}
                        ingredients
                      </span>
                    </div>
                    <img src={Circle} alt="" />
                    <div className="bottom">
                      <Tooltip
                        title={
                          <div className="tootipText">
                            {component === 'productionRecipe'
                              ? 'Production Recipe unit is' + ' ' + data?.unit
                              : ''}
                            {component === 'subRecipe'
                              ? 'Sub Recipe unit is' + ' ' + data?.unit
                              : ''}
                          </div>
                        }
                      >
                        <img
                          src={gram}
                          width={15}
                          alt=""
                          className="icons"
                          style={{ marginRight: '2px' }}
                        />
                      </Tooltip>
                      {data?.unit}
                    </div>
                  </div>
                ) : null}
                {component === 'recipe' ? (
                  <div className="bottomForRecipe lineHeight gap">
                    <div className="bottom"></div>
                    <div className="bottom">
                      <span className="itemGap rupeesTag">
                        {brand?.currency_name}
                      </span>
                      <span className="itemGap rupeesTag">
                        {data?.final_price}
                      </span>
                    </div>
                  </div>
                ) : null}
                {component === 'productionRecipe' ||
                component === 'menu' ||
                component === 'manageMenu' ||
                component === 'combos' ? (
                  <div className="bottomForRecipe lineHeight gap">
                    <div className="bottom"></div>
                    <div className="bottom">
                      <span className="itemGap rupeesTag">
                        {brand?.currency_name}
                        {' ' + component === 'combos'
                          ? data?.costing?.final_price
                          : data?.final_price}
                      </span>
                    </div>
                  </div>
                ) : null}
                {component === 'manageMenu' ? (
                  perm_status === 2 ? (
                    <Divider type="horizontal" />
                  ) : null
                ) : (
                  ''
                )}

                {component === 'manageMenu' ? (
                  perm_status === 2 ? (
                    <div className="eidt" onClick={() => handleEdit(data)}>
                      <img src={Edit} width={15} />
                      Edit
                    </div>
                  ) : null
                ) : null}
              </Card>
              {component === 'manageMenu' ? (
                perm_status === 2 ? (
                  <div className="toggle">
                    <Switch
                      className={data?.status === 1 ? 'green' : 'red'}
                      checked={data?.status === 1 ? true : false}
                      onChange={(checked) => handleSwitch(checked, index, data)}
                    />
                    {/* <Checkbox
                    checked={data.status === 1 ? true : false}
                    onChange={(checked) => handleSwitch(checked, index, data)}
                  /> */}
                  </div>
                ) : null
              ) : (
                ''
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default GridView;
