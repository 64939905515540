/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  SubDialog,
  DeleteConfirmation,
} from '../../components';
import ProductionAction from '../../redux/middleware/production';
import { sortedData, sortNumber } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import subRecipeVerified from '../../assets/images/subRecipeVerified.png';
import subRecipeNotVerified from '../../assets/images/subRecipeNotVerified.png';
import './styles.scss';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    key: '2',
    label: <div className="dotOption">Download</div>,
    icon: <img src={Download} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const ProductionRecipe = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [design, setDesign] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(6);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [productionRecipeId, setProductionRecipeId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { currentBrand } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    pageData();
  }, [currentBrand]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const setSelectedMenu = (recipe: any) => {
    setProductionRecipeId(recipe?.production_id);
  };
  const itemsForReadOnlyUsers: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <a href={''} download>
          <div className="dotOption">Download</div>
        </a>
      ),
      icon: <img src={Download} width={15} />,
    },
  ];
  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>PRODUCTION RECIPE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="subrecipecol1 font">
            <img src={record?.thumbnail} width="52px" height="52px" />
            <div className="subrecipesubCol1 font">
              <span
                className="subrecipecol1Text font"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {record?.recipe_name}
              </span>

              <div className="subrecipesubTextCol1 font">
                {record.is_verified ? (
                  <img
                    src={subRecipeVerified}
                    alt=""
                    className={'subRecipeVerifyIcon'}
                  />
                ) : (
                  <img
                    src={subRecipeNotVerified}
                    alt=""
                    className={'subRecipeVerifyIcon'}
                  />
                )}

                <span className="col1Text font">{record?.calorie}</span>
                <span className="calories font ">kcal</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="subrecipecol2">
          <div className="subrecipesubCol2">{record?.menu_category}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUB RECIPES</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('subrecipe_count', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'subrecipe_count',
      key: 'subrecipe_count',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol3">
          {record?.subrecipe_count}{' '}
          {record?.subrecipe_count < 2 ? `Sub Recipe` : `Sub Recipes`}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNITS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('unit', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol3">{record?.unit}</div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.find(
      (permission: any) => permission?.permission === 'Production Recipe List'
    );
    setPermissionStatus(data?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const details = await ProductionAction.getIngredientDetail(
      data?.production_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', {
          state: { update: true, id: productionRecipeId },
        });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    productionRecipeId.length &&
      (await ProductionAction.delProductionRecipe(productionRecipeId));
    pageData();
  };

  const DropDownmenuProps = {
    items:
      permissionStatus && permissionStatus === 2
        ? items
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    setRecipeLoader(true);
    const ProductionrecipeData = await ProductionAction.getProductionRecipes(
      dataLength,
      current
    );
    if (ProductionrecipeData === undefined || !ProductionrecipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(ProductionrecipeData?.data?.recipe_count);
      setIngredientCount(ProductionrecipeData?.data?.ingredients_count);
      setTotal(ProductionrecipeData?.data?.pagination?.total);
      setSortData(ProductionrecipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const ProductionRecipes = await ProductionAction.ProductionRecipesFilters(
      dataLength,
      current,
      filters
    );
    if (ProductionRecipes === undefined || !ProductionRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(ProductionRecipes?.data?.recipe_count);
      setIngredientCount(ProductionRecipes?.data?.ingredients_count);
      setTotal(ProductionRecipes?.data?.pagination?.total);
      setSortData(ProductionRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = await ProductionAction.searchProductionRecipe(
      dataLength,
      current,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  const handleSubDialogOpen = async (id: any) => {
    const details = await SubRecipeActions.getIngredientDetail(id);
    setDialogData(details);
    setOpenDialog(true);
  };

  return (
    <div className="productionRecipePage">
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete recipe?'}
      />
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        component={'production'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <Breadcrumb
        heading="Production Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={12}>
            <ListCount
              count1={recipeCount}
              title1="Total Recipes"
              count2={ingrdientCount}
              title2="Total Ingredients"
            />
          </Col>
          <Col lg={12}>
            <div className="search-wrapper">
              <SearchBox action={onSearch} placeholderText="Search..." />
              <IconSwitcher listViewLayout={design} setListView={setDesign} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={8}>
            <Filter
              component="production"
              action={pageDataFilter}
              apiCall={ProductionAction.getProductionRecipesFilters}
            />
          </Col>
          <Col lg={18} md={16}>
            {/* {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'productionRecipe'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )} */}

            {/* {design && !recipeLoader ? ( */}
            {!recipeLoader ? (
              <ListView
                limit={dataLength}
                component="production"
                items={items}
                dataSource={[...sortData]}
                columns={columns}
                handleOpen={handleSubDialogOpen}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default ProductionRecipe;
