import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Dropdown, Space, Spin } from 'antd';
import type { MenuProps } from 'antd';
import moment from 'moment';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  DeleteConfirmation,
} from '../../components';
import BrandActions from '../../redux/middleware/brands';
import { sortedData, sortNumber } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import EditSmall from '../../assets/images/EditSmall.png';
import './styles.scss';
import BrandList from '../../components/BrandList';
import CompanyLight from '../../assets/images/CompanyLight.png';
import Delete from '../../assets/images/Delete.svg';
import SettingDialog from '../../components/SettingDialog';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Details</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={EditSmall} className="iconDropdown" />,
  },

  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} className="iconDropdown" />,
  },
];

const PageDataLength = [6, 10, 20];

const Brands = () => {
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [Count, setCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [info, setInfo] = React.useState('');

  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState('');
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const { currentCompany } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentCompany]);

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>BRAND NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col1 font">
          <img src={record?.thumbnail} width="52px" height="52px" />
          <div className="subCol1 font">
            <span className="col1Text font">{record?.name}</span>
            <div className="subTextCol1 font">
              <img src={CompanyLight} alt="" className="companyLight" />
              <div className="email">{record?.company}</div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PHONE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('phone', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'phone',
      key: 'phone',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="company">{record?.phone}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>COUNTRY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('country', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'country',
      key: 'country',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.country}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('city', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'city',
      key: 'city',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="city">{moment(record?.created_at).fromNow()}</div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownMenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setDialogData(record);
              setSelectedId(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (openDialog) {
      getBrandInfo();
    }
  }, [openDialog]);

  useEffect(() => {
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentCompany]);

  const screenPermission = Utils.getScreenPermission('Brand');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await BrandActions.deleteBrands(selectedId).then(() => pageData());
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setOpenDialog(true);
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedId } });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const DropDownMenuProps = {
    items: validateActionPermission()
      ? items
      : items.filter((x) => x?.key !== '2' && x?.key !== '4'),
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    setLoader(true);
    const Data = await BrandActions.getBrands(dataLength, current);
    if (Data == undefined || !Data?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(Data?.data?.brand_count);
      setTotal(Data?.data?.pagination?.total);
      setSortData(Data?.data?.items);
    }
    setLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setLoader(true);
    const searchResult = await BrandActions.searchBrands(
      dataLength,
      current,
      keyword
    );
    if (searchResult == undefined || !searchResult?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResult?.data?.brand_count);
      setTotal(searchResult?.data?.pagination?.total);
      setSortData(searchResult?.data?.items);
    }
    setLoader(false);
  };

  const getBrandInfo = async () => {
    const response = await BrandActions.getBrandById(selectedId);
    if (response?.success) {
      const { data } = response;
      setInfo(data);
    }
  };

  return (
    <React.Fragment>
      <SettingDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={info}
        component={'brand'}
        title={'Brand Detail'}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.name}?`}
      />
      <Breadcrumb
        heading="Brands"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={validateActionPermission() && actionButton}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={5}>
            <ListCount count1={Count} title1="Total Brands" />
          </Col>
          <Col lg={8} />
          <Col lg={8}>
            <SearchBox action={onSearch} placeholderText="Search..." />
          </Col>
          <Col lg={3}>
            {/* <Button
              text="Export CSV"
              shape="round"
              handleButtonClick={() => alert('handle Export')}
              className="ExportBtn"
              icon={<FileExcelOutlined />}
            /> */}
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!Loader ? (
              <BrandList
                items={items}
                dataSource={[...sortData]}
                columns={columns}
                limit={dataLength}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={Loader} className="Loader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default Brands;
