/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Page, ComboStep, Breadcrumb, ResponseDialog } from '../../components';
import ComboActions from '../../redux/middleware/combo';
import Utils from '../../redux/utils';
import Attributes from '../../redux/middleware/attributes';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ComboWizard = () => {
  const company = Utils.getCurrentCompany();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = React.useState('');
  const [category, setCategory] = useState<any>();
  const [imageObject, setImageObject] = React.useState({});
  const [combo, setCombo] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [stepper, setStepper] = React.useState(0);
  const [costRatio, setCostRatio] = useState<any>('');
  const [serving, setServing] = useState<any>();
  const [alertMessage, setAlertMessage] = React.useState('');
  const [comboIngredient, setComboIngredient] = useState<any[]>([
    {
      parent_recipe_id: '',
      recipe_id: '',
      variation: { variation_id: 0, variation_name: '' },
      total_cost: 0,
      quantity: '',
      total_price: 0,
      disable: true,
    },
  ]);

  const [finalPrice, setFinalPrice] = useState<any>();
  const [proposedPrice, setProposedPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const fetchDataById = async () => {
    const data = await ComboActions.getDetailCombo(id);

    setImage(data?.data.thumbnail);
    setCombo(data?.data.combo_name);
    setDescription(data?.data.description);
    setServing(data?.data.servings);
    setFinalPrice(data?.data.final_price);
    setTotalCost(data?.data.total_cost);
    setProposedPrice(data?.data.propose_price);
    setCostRatio(data?.data.fcr);

    setComboIngredient(
      data?.data.recipes.map((data: any) => {
        const getSelectedVariant = data?.variation_list?.filter(
          (variation: any) => variation?.variation_id === data?.variation_id
        )[0];

        return {
          ...data,
          variations: data?.variation_list,
          disable: data?.general?.is_variation == 'no' ? true : false,
          quantity: data?.quantity,
          total_cost:
            data?.general?.is_variation == 'no'
              ? data?.menu_price
              : getSelectedVariant?.menu_price,
          total_price:
            (data?.general?.is_variation == 'no'
              ? +data?.menu_price
              : +getSelectedVariant?.menu_price) * +data?.quantity,
          variation:
            data?.general?.is_variation == 'no' ? [] : getSelectedVariant,
        };
      })
    );
    setLoader(false);
  };

  const checkCategory = async () => {
    const menuCategories = await Attributes.getMenuCategory(company?.id);

    const getMenuCategory = menuCategories?.data?.items.filter(
      (data: any) => data?.name.toLowerCase() == 'combo'
    );
    setLoader(false);
    if (
      menuCategories?.data?.items?.length > 0 &&
      getMenuCategory?.length > 0
    ) {
      setCategory(getMenuCategory[0]);
    } else {
      let responseDialogData = {
        type: 'error',
        messageArray: [`Please create Menu Category, before!`],
        OnClick: () => {
          navigate('/app/combos');
        },
      };
      ResponseDialog(responseDialogData);
    }
  };

  useEffect(() => {
    if (update) {
      setLoader(true);
      fetchDataById();
    }
    checkCategory();
  }, []);

  const handleImage = (url: any, data: any) => {
    setImage(url);
    setImageObject(data?.target?.files[0]);
  };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const handleCombo = (data: string) => {
    setCombo(data);
  };

  const handleBackStepper = (index: number) => {
    if (stepper !== 0) setStepper(index);
  };

  const handleNextStepper = async () => {
    const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    if (comboIngredient?.length < 2) {
      setAlertMessage('You must have atleast 2 Recipes while making a Combo');
      setVisible(true);
      window.scrollTo(0, 0);
      return;
    }
    const checkEmptyComboIngredient = comboIngredient?.filter(
      (data) => data?.parent_recipe_id == '' && data?.recipe_id == ''
    );

    if (proposedPrice === 0 && finalPrice === undefined) {
      setVisible(true);
      window.scrollTo(0, 0);
      return;
    }
    const checkEmptyField =
      combo == '' ||
      description == '' ||
      serving == '' ||
      checkEmptyComboIngredient?.length !== 0;

    if (user && !checkEmptyField && !update) {
      const param = {
        menu_category_id: category?.id,
        menu_category: category?.name,
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        combo_name: combo,
        description: description,
        servings: serving,
        created_by: user?.id,
        recipes: comboIngredient?.map((data) => {
          return {
            parent_recipe_id: data?.parent_recipe_id,
            recipe_id: data?.recipe_id,
            variation_id: data.variation?.variation_id,
            net_price: data?.total_price,
            quantity: data?.quantity,
          };
        }),
        costing: {
          total_cost: totalCost,
          propose_price: proposedPrice,
          final_price: finalPrice == undefined ? proposedPrice : finalPrice,
          fcr: costRatio,
        },
      };
      let responseDialogData = {
        type: 'info',
        messageArray: [`We are building your ${combo}. Kindly Wait !!!`],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);

      const response = await ComboActions.createCombo(
        param,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? null
          : imageObject
      );

      if (!response?.success) {
        responseDialogData = {
          OnClick: () => {
            setStepper(0);
          },
          type: 'error',
          messageArray: ['Validation Failed!', 'Something Went Wrong'],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/combos');
          },
          type: 'success',
          messageArray: [` Your ${combo}`, ` has been created`],
        };
        ResponseDialog(responseDialogData);
      }
    } else if (update && !checkEmptyField) {
      const param = {
        menu_category_id: category?.id,
        menu_category: category?.name,
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        thumbnail:
          imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
            ? image
            : null,
        combo_name: combo,
        description: description,
        servings: serving,
        created_by: user?.id,
        recipes: comboIngredient?.map((data) => {
          return {
            parent_recipe_id: data?.parent_recipe_id,
            recipe_id: data?.recipe_id,
            variation_id: data.variation?.variation_id,
            net_price: data?.total_cost,
            quantity: data?.quantity,
          };
        }),
        costing: {
          total_cost: totalCost,
          propose_price: Number(proposedPrice.toFixed(2)),
          final_price: finalPrice == undefined ? proposedPrice : finalPrice,
          fcr: costRatio,
        },
      };

      let responseDialogData = {
        type: 'info',
        messageArray: [
          `We are building`,
          ` your ${combo}.`,
          ` Kindly Wait !!!`,
        ],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);
      setVisible(false);
      const response = await ComboActions.updateCombo(
        param,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? null
          : imageObject,
        id
      );
      if (!response?.success) {
        responseDialogData = {
          OnClick: () => {
            setStepper(0);
          },
          type: 'error',
          messageArray: ['Validation Failed!', 'Something Went Wrong'],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/combos');
          },
          type: 'success',
          messageArray: [` Your ${combo} has been updated`],
        };
        ResponseDialog(responseDialogData);
      }
    } else {
      setVisible(true);
      window.scrollTo(0, 0);
    }
  };

  const handleChange = (data: any, index: number) => {
    let dataArray = comboIngredient;
    dataArray[index] = {
      ...data?.general,
      variations: data?.variation_list,
      disable: data?.general?.is_variation == 'no' ? true : false,
      quantity: '',
      total_cost:
        data?.general?.is_variation == 'no'
          ? data?.general?.menu_price
          : data?.variation_list[0]?.menu_price,
      total_price: 0,
      variation:
        data?.general?.is_variation == 'no' ? [] : data?.variation_list[0],
    };
    setComboIngredient([...dataArray]);
  };

  const deleteComboMenu = (index: number) => {
    let DataArray = comboIngredient;
    DataArray.splice(index, 1);
    setComboIngredient([...DataArray]);
  };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    if (key == 'quantity' && data != '')
      dataArray[index]['total_price'] = Number(
        (dataArray[index]['total_cost'] * +data).toFixed(2)
      );
    else dataArray[index]['total_price'] = 0;

    return dataArray;
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map((data) => data?.total_price);
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalCost(price);
  };
  const handleChangeValue = (data: any, index: number, key: string) => {
    let dataArray = comboIngredient;

    dataArray = handleCostIngredient(dataArray, data, key, index);

    key == 'quantity' && handleTotalPrice(dataArray);
    key == 'variation'
      ? (dataArray[index] = {
          ...dataArray[index],
          quantity: '',
          total_cost: data?.menu_price,
          total_price: 0,
          variation: data,
        })
      : (dataArray[index][key] = data);
    setComboIngredient([...dataArray]);
  };
  const addComboMenu = () => {
    setComboIngredient((prev) => [
      ...prev,
      {
        parent_recipe_id: '',
        recipe_id: '',
        variation: { variation_id: 0, variation_name: '' },
        total_cost: 0,
        quantity: '',
        total_price: 0,
        disable: true,
      },
    ]);
  };

  const handleServing = (data: any) => {
    setServing(data);
  };

  const handleProposedPrice = (ratio: any) => {
    setProposedPrice((totalCost * 100) / ratio);
  };
  const handleCostRatio = (data: any) => {
    if (+data <= 100) {
      setCostRatio(data);
      data == '' ? setProposedPrice(0) : handleProposedPrice(+data);
    }
  };

  const handleFinalPrice = (data: any) => {
    setFinalPrice(data);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Combo"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/combos');
        }}
      />
      <Page title="Add New Combo">
        <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description={
                alertMessage?.length
                  ? alertMessage
                  : 'Please Complete required fields!'
              }
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div>

        {loader ? (
          <Spin
            spinning={loader}
            size="default"
            className="loadIngredient recipeBuilderloaderHeight"
          />
        ) : (
          <div className="wizardContainer">
            <ComboStep
              handleCostRatio={handleCostRatio}
              handleServing={handleServing}
              handleChangeValue={handleChangeValue}
              addComboMenu={addComboMenu}
              deleteComboMenu={deleteComboMenu}
              handleChange={handleChange}
              handleBackStepper={handleBackStepper}
              handleCombo={handleCombo}
              handleDescription={handleDescription}
              handleImage={handleImage}
              getBase64={getBase64}
              handleFinalPrice={handleFinalPrice}
              stepper={stepper}
              image={image}
              combo={combo}
              description={description}
              comboIngredient={comboIngredient}
              serving={serving}
              costRatio={costRatio}
              proposedPrice={proposedPrice}
              totalCost={totalCost}
              finalPrice={finalPrice}
            />

            <div className="butonGroup">
              <div
                className="wizard-button"
                onClick={() => handleBackStepper(stepper - 1)}
              >
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleNextStepper()}
              >
                Continue
              </div>
            </div>
          </div>
        )}
      </Page>
    </React.Fragment>
  );
};

export default ComboWizard;
