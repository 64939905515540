import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CategoryActions from '../../redux/middleware/category';
import useClickListener from '../../helper/outsideClickListener';
import ResponseDialog from '../ResopnseDialog';
import { Input } from 'antd';
import CategoryModal from '../categoryModal';
import Utils from '../../redux/utils';
import './style.scss';

type Props = {
  text: string;
  blocked: boolean;
  handleChangeText: any;
  component: any;
  errorList?: any;
};
type Category = {
  id: number;
  category_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

const WizardDropdown = (props: Props) => {
  let { blocked, component, errorList } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(true);
  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<Category[]>();
  const [searchQuery, setSearchQuery] = useState<any>(props?.text);
  const fetchCategory = async () => {
    const company = await Utils.getCurrentCompany();
    const data =
      component === 'recipe'
        ? await CategoryActions.getMenuCategory(company?.id)
        : await CategoryActions.getCategory();

    {
      component === 'recipe'
        ? setCategoryData(
            data?.data?.items.filter((val: any) => {
              return (
                val.name.toLowerCase() !== 'production' &&
                val.name.toLowerCase() !== 'sub recipe' &&
                val.name.toLowerCase() !== 'combo'
              );
            })
          )
        : setCategoryData(
            component === 'recipebuilder'
              ? data?.data?.items?.filter(
                  (x: any) => x?.category_name != 'Sub Recipe'
                )
              : data?.data?.items?.filter(
                  (x: any) => x?.category_name == 'Sub Recipe'
                )
          );
    }
    // Set category for R&D Sub Recipe Wizard
    if (component == 'rd-sub-recipe') {
      if (
        !data?.data?.items?.length &&
        data?.data?.items?.find((x: any) => x?.category_name == 'Sub Recipe') ==
          undefined
      ) {
        let responseDialogData = {
          type: 'error',
          messageArray: [`Please create Menu Category, before!`],
          OnClick: () => {
            navigate('/app/r&d-subrecipe');
          },
        };
        ResponseDialog(responseDialogData);
      } else
        props?.handleChangeText(
          data?.data?.items?.find((x: any) => x?.category_name == 'Sub Recipe')
        );
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (props?.text) setSearchQuery(props?.text);
  }, [props?.text]);

  React.useEffect(() => {
    if (categoryData?.length === 1) {
      props.handleChangeText(categoryData[0]);
    }
  }, [categoryData]);

  const handleClick = () => {
    setIsComponentVisible(true);
    setShowListViewOne(true);
  };

  const handleChange = (e: any) => {
    props?.handleChangeText(e.target.value);
    setSearchQuery(e.target.value);
    let results: any[];
    if (e.target.value !== '') {
      setShowListViewOne(false);
      results = categoryData.filter((item: any) => {
        return item.category_name
          ?.toLowerCase()
          .includes(e.target.value?.toString().toLowerCase());
      });
      setSearchResults(results);
    } else {
      setShowListViewOne(true);
      setSearchResults([]);
    }
  };

  const handleCreateCategory = async (text: any) => {
    const data = await CategoryActions.createMenuCategory(text);
    if (data.success === true) {
      setShowListViewOne(true);
      fetchCategory();
    }
  };
  const handlemodal = (toggle: boolean, data: any) => {
    setOpen(toggle);
    handleSelectOption(data);
    fetchCategory();
  };
  const handleSelectOption = (ingredient: any) => {
    props?.handleChangeText(ingredient);
    component == 'recipebuilder'
      ? setSearchQuery(ingredient.category_name)
      : setSearchQuery(ingredient.name);
    setIsComponentVisible(false);
  };
  return (
    <div className="wizard-form-group" ref={ref}>
      {open ? (
        <CategoryModal
          handleDialog={handlemodal}
          open={open}
          component={component}
          name={searchQuery}
          modalName="Menu"
        />
      ) : null}
      <Input
        type="text"
        placeholder="Input category here"
        onClick={handleClick}
        onChange={(e) => handleChange(e)}
        value={searchQuery}
        disabled={blocked}
        className={
          component != 'recipebuilder' &&
          component != 'rd-sub-recipe' &&
          errorList['menu_category_id']?.length > 0
            ? 'borderRed'
            : ''
        }
      />

      {isComponentVisible ? (
        <ul className="dropdown-list">
          {showListViewOne ? (
            <li>
              <ul className="subMenu">
                <li>
                  <h4 className="placeholder">
                    Select an option or create one
                  </h4>
                </li>

                {component === 'recipe'
                  ? categoryData.map((category: any, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleSelectOption(category)}
                        >
                          <p>{category?.name} </p>
                        </li>
                      );
                    })
                  : categoryData.map((category: any, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleSelectOption(category)}
                        >
                          <p>{category?.category_name} </p>
                        </li>
                      );
                    })}
              </ul>
            </li>
          ) : (
            <li>
              <ul className="subMenu">
                <li className="create-category">
                  <button
                    onClick={() => {
                      handleCreateCategory(searchQuery);
                      setOpen(true);
                    }}
                  >
                    {`Create ${searchQuery} to category`}
                  </button>
                </li>
                {searchResults?.map((searchResult: any, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleSelectOption(searchResult)}
                    >
                      <p>
                        {component === 'recipe'
                          ? searchResult?.name
                          : searchResult?.category_name}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default WizardDropdown;
