import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import IngredientCategoryActions from '../../redux/middleware/ingredientCategory';
import './ingredientCategoryWizard.scss';
import Utils from '../../redux/utils';
import { Input, Spin } from 'antd';
import MenuCategoryActions from '../../redux/middleware/menuCategory';

const IngredientCategoryWizard = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { update, id, parent_id } = state;

  const [recipe, setRecipe] = React.useState('');
  const fetchDataById = async (id: any) => {
    const data = await IngredientCategoryActions.getIngredientCategoryById(id);
    if (data) {
      setLoader(false);
    }
    setRecipe(data?.data?.name || data?.data?.category_name);
  };

  useEffect(() => {
    if (update) {
      setLoader(true);

      fetchDataById(id);
    }
  }, []);

  const handleRecipe = (data: string) => {
    setRecipe(data);
  };

  const handleContinue = async () => {
    const company = Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();

    setErrorList({});
    setErrorStatus(false);
    const param = {
      company_id: company?.id,
      sub_category: recipe,
      brand_id: brand?.brand_id,
      parent_category_id: parent_id,
    };
    let responseDialogData;
    setApiLoader(true);

    const response = update
      ? await MenuCategoryActions.updateSubCategories(id, param)
      : await MenuCategoryActions.addSubCategories(param);

    setApiLoader(false);

    if (response?.success == undefined || !response?.success) {
      setErrorList(response?.error);
      setErrorStatus(true);
    } else {
      responseDialogData = {
        OnClick: () => {
          navigate('/app/menuCategory');
        },
        type: 'success',
        messageArray: [
          ` Your ${recipe}`,
          ` has been ${update ? 'Updated' : 'Created'}`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Ingredient"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/menuCategory');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Ingredient">
          {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="wizardContainer">
              <div className="ingredientCategoryContainerWidth">
                <div className="wizardSubContainer wrapper">
                  <div className="wizardLabel">
                    Ingredient Category Name <span className="sterik">*</span>
                  </div>
                  <Input
                    placeholder="Enter Variation Name"
                    size="large"
                    value={recipe}
                    onChange={(e) => {
                      handleRecipe(e.target.value);
                    }}
                    required
                    className={errorList['name']?.length > 0 ? 'borderRed' : ''}
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['name']}
                    </div>
                  )}
                </div>
              </div>
              <div className="butonGroup">
                <div className="wizard-button" onClick={() => handleContinue()}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default IngredientCategoryWizard;
