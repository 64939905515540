import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import SubMenu from '../../assets/images/SubMenu.svg';
import Print from '../../assets/images/Print.png';
import './style.scss';

type Props = {
  open: boolean;
  handleClose: any;
  handleSubDialog: any;
  data: any;
  component: any;
  printApi?: any;
};

const Dialog = (props: Props) => {
  let { open, handleClose, data, component, handleSubDialog, printApi } = props;
  let ingredient = data?.data?.ingredients;
  const [Recipedata, setRecipeData] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (component === 'RecipeBuilder') {
      setRecipeData(data?.data?.general);
    } else {
      setRecipeData(data?.data?.general);
    }
  }, [data]);

  const handleSubRecipeDetail = (ingredient: any) => {
    if (component === 'RecipeBuilder') {
      handleSubDialog(ingredient?.sub_recipe_id);
    } else {
      handleSubDialog(ingredient?.sku);
    }
  };

  const handlePrint = async (id: any) => {
    setLoader(true);
    const { data, success } = await printApi.getRecipeLink(id);
    setLoader(false);
    if (success) {
      // setPdf(data);
      openInNewTab(data);
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      title={<div className="title font">Detail Ingredients</div>}
      centered
      open={open}
      onCancel={() => {
        !loader && handleClose();
      }}
      width={480}
      footer={null}
      className="DialogWrapper"
    >
      <Spin spinning={loader} size="large">
        <div>
          <div className="cover-photo">
            <img
              src={Recipedata?.thumbnail}
              alt=""
              // width={'432px'}
              // height="240px"
              className="image"
            />
            <div className="imgtag">
              {component === 'RecipeBuilder'
                ? Recipedata?.category_name
                : Recipedata?.menu_category}
            </div>
          </div>
          <div className="subhead">
            <div className="subHead-left font">{Recipedata?.recipe_name}</div>
            <div
              onClick={() => handlePrint(Recipedata?.rb_id)}
              className="subhead"
            >
              <img src={Print} alt="" />
              <div className="subHead-right">Print</div>
            </div>
          </div>
          <div className="block block1">
            <div>
              <span className={`callories-number `}>{Recipedata?.calorie}</span>
              <span className="font callories-unit">{'kcal'}</span>
            </div>
            <div>
              <span className={`crab-number `}>{Recipedata?.carb}</span>
              <span className="font crab-unit">{'g'}</span>
            </div>
            <div>
              <span className={`fat-number `}>{Recipedata?.fat}</span>
              <span className="font fat-unit">{'g'}</span>
            </div>
            <div>
              <span className={`font protein-number `}>
                {Recipedata?.protein}
              </span>
              <span className="font protein-unit">{'g'}</span>
            </div>
          </div>
          <div className="block block2">
            <span className={'callories-text'}>calories</span>
            <span className={'callories-text'}>carbs</span>
            <span className={'callories-text'}>fats</span>
            <span className={'callories-text'}>proteins</span>
          </div>
          {component !== 'recipe' ? (
            <div>
              <div className="step">STEP</div>
              {Recipedata?.steps?.map((step: string, index: number) => (
                <div className="step-gap" key={index}>
                  <div className="step-point step-number font">{index + 1}</div>
                  <div className="step-point step-number-text">{step}</div>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
          {component === 'recipe' ? (
            <div>
              <div className="step">Cooking Methods</div>
              {data?.data?.cooking_methods &&
                data?.data?.cooking_methods.map((data: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="step">{data?.cooking_method}</div>
                      {data.steps.map((step: string, index: number) => (
                        <div className="step-gap" key={index}>
                          <div className="step-point step-number font">
                            {index + 1}
                          </div>
                          <div className="step-point step-number-text">
                            {step}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
              {/* {data?.data?.cooking_methods[0].steps.map(
              (step: string, index: number) => (
                <div className="step-gap" key={index}>
                  <div className="step-point step-number font">{index + 1}</div>
                  <div className="step-point step-number-text">{step}</div>
                </div>
              )
            )} */}
            </div>
          ) : (
            ''
          )}
          <div className="block3">
            <div className="ingredient">Ingredients</div>
            <div className="ingredient">QUANTITY</div>
          </div>
          {/* {component === 'RecipeBuilder'
          ? ingredient?.map((ingredient: any, index: number) => {
              return (
                <div className="block4" key={index}>
                  <div className={`block4-text font `}>
                    {ingredient?.ingredient_name}
                  </div>
                  <div className="block4-weight">
                    {ingredient?.quantity + ' ' + ingredient?.unit}
                  </div>
                </div>
              );
            })
          : ''} */}
          {component === 'subRecipe' || component === 'RecipeBuilder'
            ? ingredient?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubRecipeDetail(ingredient)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            width={15}
                            alt=""
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                    </div>
                    <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div>
                  </div>
                );
              })
            : ''}
          {component === 'recipe'
            ? ingredient?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubDialog(ingredient?.sku)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            alt=""
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                    </div>

                    <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div>
                  </div>
                );
              })
            : ''}

          {component === 'production'
            ? ingredient?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubDialog(ingredient?.sku)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            alt=""
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                    </div>

                    <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div>
                  </div>
                );
              })
            : ''}
          {/* {component === 'recipe'
          ? Recipedata.is_variation === 'yes'
            ? ''
            : ''
          : ''} */}
          <div className="allergen font">Allergens</div>
          <div className="allergen-text">
            {component === 'RecipeBuilder'
              ? Recipedata?.allergen
              : Recipedata?.allergen}{' '}
          </div>
          <div className="additional font">Additional Info</div>
          <div className="additional-text">
            {Recipedata?.additional_attribute}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default Dialog;
