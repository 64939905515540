import React, { useEffect } from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
import { Button } from '../../components';
import SideImage from '../../assets/svg/authSideImage.svg';
import Logo from '../../assets/svg/logo.svg';
// import { useDispatch } from 'react-redux';
import AuthActions from '../../redux/middleware/auth';
import { useSearchParams } from 'react-router-dom';
import './forgotPassword.scss';
import { useNavigate } from 'react-router-dom';

const forgotPassword = () => {
  const [, contextHolder] = notification.useNotification();
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = React.useState(false);
  const [message, setMessage] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = React.useState<any>('');
  const [searchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  useEffect(() => {
    setToken(searchParams?.get('token'));
  }, []);

  const onSubmit = async () => {
    if (!password || !email || !confirmPassword) {
      return false;
    }

    try {
      const response = await AuthActions.ResetPassword(
        email,
        password,
        confirmPassword,
        token
      );
      if (response?.success) {
        navigate('/auth/confirm');
      } else {
        setMessage('Failed to update password. Please Try Again ');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      {contextHolder}
      <Row className="authContainer" style={{ height: window.innerHeight }}>
        <Col lg={8} className="leftSide">
          <img src={Logo} alt={'logo'} className="sideLogo" />
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <label className="b2 upload-label">
              Email <span className="upload-span">*</span>
            </label>
            <Input
              className="login-input spacing"
              type="email"
              placeholder="Enter email here"
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
            />
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <label className="b2 upload-label">
                Enter New Password <span className="upload-span">*</span>
              </label>
              <Input.Password
                className="login-input"
                type="password"
                placeholder="Enter password here"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <label className="b2 upload-label">
                Confirm Password <span className="upload-span">*</span>
              </label>
              <Input.Password
                className="login-input"
                type="password"
                placeholder="Enter confirm password here"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                shape="round"
                text="Continue"
                handleButtonClick={onSubmit}
                type="primary"
                className="theme-button login-btn"
                focus
              />
            </Form.Item>

            {active ? (
              <div className="alignment success">
                <label className="b upload-label ">{message}</label>
              </div>
            ) : (
              <div className="alignment error">
                <label className="b upload-label ">{message}</label>
              </div>
            )}
          </Form>
        </Col>
        <Col lg={16} className="rightSide">
          <img src={SideImage} alt={'sideImage'} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default forgotPassword;
