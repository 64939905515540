/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import More from '../../assets/images/More.png';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Eye from '../../assets/images/Eye.svg';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  UserList,
  DetailDialog,
} from '../../components';
// import {
//   Dropdown as DropDown,
//   // Input,
//   MenuProps,
//   Select,
//   // SelectProps,
//   Space,
//   Spin,
// } from 'antd';
import PurchaseApi from '../../redux/middleware/purchaseOrder';
import { sortedData } from '../../helper/sortData';
import Edit from '../../assets/images/Edit.png';
import SupplierApi from '../../redux/middleware/supplier';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Utils from '../../redux/utils';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">Detail</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '2',
    label: <div className="dotOption modifierDropdownLabels">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption modifierDropdownLabels">Accept</div>,
    icon: <img src={Eye} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const PurchaseOrderView = () => {
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [userLoader, setUserLoader] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [id, setid] = useState();
  const [loader, setLoader] = useState(false);
  const [acceptPO, setAcceptPO] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          Active
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          Non-active
        </div>
      ),
    },
  ];
  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };
  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      //   UsersApi.disableUser(id, '1');
      pageData();
    } else {
      //   UsersApi.disableUser(id, '0');
      pageData();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setAcceptPO(false);
  };

  const userColumns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>PO ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => {
        return <div className="id">{record?.id}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PO Name</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">{record?.po_from_name}</div>
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>Supplier</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">{record?.supplier_name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Status</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">{record?.request_status}</div>
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>Created On</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('roles');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">{moment(record?.created_at).fromNow()}</div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items:
              record?.ingredient_category === 'Sub Recipe' ||
              record?.ingredient_category === 'Production'
                ? items.filter((x) => x?.key !== '2')
                : items,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="requisitions-dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (poid: any) => {
    setSelectedUserId(poid);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Purchase Order'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const fetchDialogData = async () => {
    const { data, success } = await SupplierApi.getInventorySupplyById(
      selectedUserId
    );
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const handleAcceptPO = async () => {
    setLoader(true);
    const response = await PurchaseApi.acceptPO(
      {
        request_status_id: 8,
      },
      selectedUserId
    );
    setLoader(false);
    handleClose();
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setLoader(true);
        fetchDialogData();
        break;

      case 2:
        navigate('wizard', { state: { update: true, id: selectedUserId } });
        break;
      case 3:
        setAcceptPO(true);
        setLoader(true);
        fetchDialogData();
        break;

      default:
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setUserLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    let request_id;
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'purchase_order'
      );
    const poData = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );
    if (poData === undefined || !poData?.success) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(poData?.data?.users_count);
      setTotal(poData?.data?.pagination?.total);
      setSortData(poData?.data?.items);
    }
    setUserLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setUserLoader(true);
    const searchResultofRecipes = await PurchaseApi.searchPO(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes === undefined) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(searchResultofRecipes?.data?.recipe_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setUserLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Purchase Order"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Purchase Order">
          <DetailDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
            acceptPO={acceptPO}
            handleAcceptPO={handleAcceptPO}
            component={'purchase_order'}
          />
          <Row className="listingTopFilter">
            <Col lg={10} md={24}>
              <ListCount count1={userCount} title1="Total Purchase Order" />
            </Col>
            <Col lg={14} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox action={onSearch} placeholderText="Search..." />
                </div>
                {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export CSV</div>
              </div>
              */}
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!userLoader ? (
                <UserList
                  dataSource={[...sortData] || []}
                  userColumns={userColumns}
                  limit={dataLength}
                />
              ) : (
                // <ListViewWithoutExpand
                //   items={items}
                //   dataSource={[...sortData]}
                //   columns={columns}
                // />
                <div className="loader-wrapper">
                  <Spin spinning={userLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default PurchaseOrderView;
