import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Input, Spin } from 'antd';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import { RcFile } from 'antd/lib/upload';
import Delete from '../../assets/images/Delete.svg';
import './styles.scss';
import UsersApi from '../../redux/middleware/users';
import BrandActions from '../../redux/middleware/brands';
import Utils from '../../redux/utils';

const BrandWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [image, setImage] = React.useState('');
  const [imageObject, setImageObject] = React.useState({});

  const fileRef = React.useRef<HTMLInputElement>(null);

  const [country, setCountry] = React.useState({} as any);
  const [, setCompany] = React.useState('' as any);
  const [, setCity] = React.useState<any>();
  const [phone, handlePhone] = React.useState('' as any);
  const [brand, setBrand] = React.useState('');
  const [email, setEmail] = React.useState('');

  const [, setSubLicense] = React.useState();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  // const [, setCompanyList] = React.useState([]);
  // const [, setCountryList] = React.useState([]);
  // const [branchLimits, setBranchLimits] = useState<any>([]);
  const [blocked] = useState(true);
  // const [cityList, setCityList] = React.useState([]);
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const company_name = user?.company_name;
  const country_name = user?.country_name;

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [countryCode, setCountryCode] = useState<any>(
    user?.country_code || '+971'
  );
  useEffect(() => {
    country &&
    Object.keys(country).length === 0 &&
    Object.getPrototypeOf(country) === Object.prototype
      ? null
      : setCountryCode(country?.value);
  }, [country]);
  const handleBack = () => {};

  // const handlePickerChange =
  //   (fieldName: string) => (value: string | string[], data: any) => {
  //     if (fieldName === 'country') setCountry(data);
  //     if (fieldName === 'city') {
  //       setCity(data?.value);
  //     }
  //     if (fieldName === 'company') setCompany(data);
  //   };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  // const fetchUserCompany = async () => {
  //   const response = await UsersApi.getUserCompany();
  //   if (response?.success) {
  //     const { data } = response;
  //     setCompanyList(
  //       data?.items?.map((data: any) => {
  //         return {
  //           label: data?.company_name,
  //           value: data?.company_name,
  //           key: data?.id,
  //         };
  //       })
  //     );
  //   }
  // };

  // const getCountryListing = async () => {
  //   const response = await ResourcesActions.getCountries(500, 1);
  //   if (response?.success) {
  //     const { data } = response;
  //     const list =
  //       data &&
  //       data.items &&
  //       data.items.map((item: any) => ({
  //         value: item?.country_code,
  //         label: item?.country_name,
  //         key: item?.id,
  //       }));
  //     setCountryList(list);
  //   }
  // };

  // const getCitiesListing = async () => {
  //   const response = await ResourcesActions.getCities(500, 1, user?.country_id);
  //   if (response?.success) {
  //     const { data } = response;
  //     const list =
  //       data &&
  //       data.items &&
  //       data.items.map((item: any) => ({
  //         value: item?.id,
  //         label: item?.name,
  //       }));
  //     setCityList(list);
  //   }
  // };

  const getBrandInfo = async () => {
    const response = await BrandActions.getBrandById(id);
    if (response?.success) {
      const { data } = response;

      setBrand(data?.name);
      setSubLicense(data?.sub_license);
      handlePhone(data?.phone);
      setEmail(data?.email);
      setCountry({
        label: data?.country,
        value: data?.country,
        key: data?.country_id,
      });
      setCity({
        value: data?.city_id,
        label: data?.city,
      });
      setCompany({
        label: data?.company,
        value: data?.company,
        key: data?.company_id,
      });
      setImage(data?.thumbnail);
    }
  };

  const getCompanyById = async () => {
    const company = await Utils.getCurrentCompany();
    const response = await UsersApi.getUserCompanyById(company?.id);
    if (response?.success) {
      // setBranchLimits(
      //   response?.data?.available_sub_licenses?.map((data: any) => ({
      //     label: data,
      //     value: data,
      //   }))
      // );
    }
  };

  React.useEffect(() => {
    getCompanyById();
    if (update) {
      getBrandInfo();
    }
  }, []);

  // React.useEffect(() => {
  //   console.log('country', country);
  //   if (Object.keys(country)?.length !== 0) {
  //     console.log('in if block of city');
  //     getCitiesListing();
  //   }
  // }, [country]);

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    setErrorList({});
    setErrorStatus(false);
    const user = await Utils.getCurrentUser();

    if (brand === '' || email === '') {
      setVisible(true);
      return;
    }

    const payload = {
      company_id: user?.company_id,
      name: brand,
      city_id: 0,
      country_id: user?.country_id,
      phone: phone.toString(),
      email: email,
      created_by: user?.id,
    };

    try {
      let responseDialogData;
      setApiLoader(true);
      let response;
      if (update) {
        const thumbnail = imageObject || image;
        response = await BrandActions.updateBrand(id, payload, thumbnail);
      } else {
        response = await BrandActions.addBrand(payload, imageObject);
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: [
            'Validation Failed!',
            `${
              response?.error?.email ||
              response?.error?.name ||
              response?.error?.phone ||
              response?.error?.company_id
            }`,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/brands');
          },
          type: 'success',
          messageArray: [
            ` Your brand - ${brand}`,
            ` has been ${!update ? 'Created' : 'Updated'}`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Server Error!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Brand"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/brands');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Modifier">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className="wizardSubContainer checkBox">
              <div className="wizardLabel">Thumbnail</div>

              {image ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <img src={image} alt="" className="imageHeight" />
                    <div
                      className="deleteButton"
                      onClick={() => handleImage('', {})}
                    >
                      <img src={Delete} width={15} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="formgroup formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      <p className="ant-upload-text ">
                        Drop your image here or browse
                      </p>
                      <p className="ant-upload-hint">Minimum file 2 MB</p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".jpeg,jpg,.png"
                        onChange={async (e: any) => {
                          const response = await getBase64(
                            e.currentTarget.files[0]
                          );
                          if (response) handleImage(response, e);
                        }}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Company <span className="sterik">*</span>
              </div>
              {/* <Select
              size={'large'}
              placeholder={'Select brand'}
              value={company}
              onChange={handlePickerChange('company')}
              style={{ width: 560 }}
              options={companyList}
            /> */}
              <Input
                placeholder="Enter Company name"
                size="large"
                value={company_name}
                disabled={blocked}
                required
                className={
                  errorList['company_id']?.length > 0 ? 'borderRed' : ''
                }
              />

              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['company_id']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Brand Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Brand name"
                size="large"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                required
                className={errorList['name']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['name']}
                </div>
              )}
            </div>
            {/* <div className="wizardSubContainer">
              <div className="wizardLabel">
                Branch Limit <span className="sterik">*</span>
              </div>
               <Input
                placeholder="Enter address"
                size="large"
                value={sublicense}
                onChange={(e) => setSubLicense(e.target.value)}
                required
              /> 
              <Select
                size={'large'}
                placeholder={'Select brand'}
                value={sublicense}
                onChange={(value) => setSubLicense(value)}
                style={{ width: 560 }}
                options={branchLimits}
                disabled={update}
                className={
                  errorList['sub_license']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['sub_license']}
                </div>
              )}
            </div>  */}
            <div className="wizardSubContainer">
              <div className="wizardLabel">Country</div>
              {/* <Select
              size={'large'}
              placeholder={'Select brand'}
              value={country}
              onChange={handlePickerChange('country')}
              style={{ width: 560 }}
              options={countryList}
            /> */}
              <Input
                placeholder="Enter Country name"
                size="large"
                value={country_name}
                disabled={blocked}
                required
              />
            </div>
            {/* <div className="wizardDropdown">
              <div className="wizardLabel">
                City <span className="sterik">*</span>
              </div>
              <Select
                showSearch
                size={'large'}
                placeholder={'Select city'}
                value={city}
                onChange={handlePickerChange('city')}
                style={{ width: 560 }}
                options={cityList}
              />
            </div> */}
            <div className="wizardSubContainer">
              <div className="wizardLabel">Phone Number</div>
              <Input
                addonBefore={countryCode}
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => handlePhone(Number(e.target.value))}
                className={errorList['phone']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['phone']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Email <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Email"
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div>
            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default BrandWizard;
