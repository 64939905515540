import React, { useEffect, useState, useRef } from 'react';
import { Input, Select, Spin } from 'antd';
import Attributes from '../../redux/middleware/attributes';
import Delete from '../../assets/images/Delete.svg';
import './style.scss';

type Props = {
  component: any;
  steps: any;
  addinfo: any;
  allergen: any;
  handleallergenCheck: any;
  handleaddionalinfocheck: any;
  handleDeleteStep: any;
  addSteps: any;
  // stepDeleteDialog: any;
  stepIndex: any;
  // handleDeleteDialog: any;
  handleIndex: any;
  handleChangeStep: any;
  calory: any;
  fat: any;
  crab: any;
  protein: any;
  recipeNote: any;
  video: any;
  handlecalory: any;
  handlecrab: any;
  handlefat: any;
  handleprotein: any;
  handleNote: any;
  handlevideo: any;
  prep: any;
  serving: any;
  handleprep: any;
  handleserving: any;
  errorList?: any;
  errorStatus?: boolean;
  handleYeild?: any;
  handleUnit?: any;
  subRecipeUnit?: any;
  yeild?: any;
  unit?: any;
};

type additionalInfo = {
  id: number;
  attribute_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

const StepThree = (props: Props) => {
  const [spinner, setSpinner] = useState(true);
  const [allergenData, setAlergenData] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);

  useEffect(() => {
    fetchAllergen();
    fetchAdditionalData();
  }, []);

  useEffect(() => {
    if (attributes.length && allergenData.length) setSpinner(false);
  }, [allergenData, attributes]);

  const fetchAdditionalData = async () => {
    const data = await Attributes.getAdditionalInfo();
    setAttributes(data?.data);
  };

  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };
  let {
    steps,
    handleallergenCheck,
    handleaddionalinfocheck,
    handleDeleteStep,
    addSteps,
    // stepDeleteDialog,
    // handleDeleteDialog,
    handleIndex,
    handleChangeStep,
    calory,
    fat,
    crab,
    protein,
    recipeNote,
    video,
    handlecalory,
    handlecrab,
    handlefat,
    handleprotein,
    handleNote,
    handlevideo,
    prep,
    serving,
    handleprep,
    handleserving,
    allergen,
    addinfo,
    handleYeild,
    handleUnit,
    subRecipeUnit,
    yeild,
    component,
    unit,
  } = props;
  const { TextArea } = Input;

  const inputRefs = useRef<any>(steps.map(() => React.createRef()));

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === 'Enter') {
      // Set focus to next text input
      addSteps();
      setTimeout(() => inputRefs.current[index + 1].focus(), 250);
      // inputRefs.current[index + 1].focus();
    }
  };

  return spinner ? (
    <Spin className="loadIngredient recipeBuilderloaderHeight" />
  ) : (
    <>
      {/* <Modal
        title={<div className="title font">Confirmation</div>}
        centered
        open={stepDeleteDialog}
        onCancel={() => handleDeleteDialog(false)}
        width={480}
        footer={null}
        className="DialogHeight"
      >
        <div className="deleteDialogText">
          Are you sure want to delete “Bacon” ingredient?
        </div>
        <div className="butonGroupDialog">
          <div
            className="wizard-button"
            onClick={() => handleDeleteDialog(false)}
          >
            Cancel
          </div>
          <div className="continue wizard-button" onClick={handleDeleteStep}>
            Continue
          </div>
        </div>
      </Modal> */}
      <div className="step3-head">Cooking instruction</div>
      <div className="wizardDropdownStep3 ">
        <div className="wizardLabel">
          Steps <span className="sterik">*</span>
        </div>

        {steps.map((data: any, index: number) => (
          <div className="stepThreeInput" key={index}>
            <div>{index + 1}</div>
            <Input
              placeholder="Enter field name"
              value={data}
              onChange={(e: any) => handleChangeStep(e.target.value, index)}
              // ref={inputRefs.current[index]}
              ref={(el) => (inputRefs.current[index] = el)}
              // onKeyDown={handleKeyDown}
              onKeyDown={(e: any) => handleKeyDown(e, index)}
            />

            <img
              src={Delete}
              width={15}
              alt=""
              onClick={() => {
                handleIndex(index);
                handleDeleteStep(index);
                // handleDeleteDialog(true);
              }}
            />
          </div>
        ))}
        {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
        <div className="addButton" onClick={addSteps}>
          + Add New
        </div>
      </div>
      <div className="wizardDropdownStep3 stepThreeTextArea">
        <div className="wizardLabel">Recipe Notes</div>

        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input recipe notes here"
          autoSize={{ minRows: 5, maxRows: 5 }}
          value={recipeNote}
          onChange={(e) => handleNote(e.target.value)}
        />
      </div>
      <div className="step3-head">Advanced</div>
      <div className="wizardDropdownStep3 stepThreeTextArea">
        <div className="wizardLabelAdvance">Training Video (URL)</div>

        <Input
          placeholder="Enter training video"
          value={video}
          onChange={(e) => handlevideo(e.target.value)}
        />
        {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
      </div>
      <div className="PracticeContainer PracticeContainerJustify">
        <div className="subContainerPractice stepThreeTextArea">
          <div className="PracticeLabel ">
            Prep Time (Mins) <span className="sterik">*</span>
          </div>
          <Input
            placeholder="Enter prep time"
            value={prep}
            type="number"
            onChange={(e) => handleprep(e.target.value)}
          />
          {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
        </div>
        {component == 'R&D Sub Recipe' ? null : (
          <div className="subContainerPractice stepThreeTextArea">
            <div className="PracticeLabel">
              Serving <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Enter serving"
              value={serving}
              type="number"
              onChange={(e) => handleserving(e.target.value)}
            />
            {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
          </div>
        )}
      </div>
      {component == 'R&D Sub Recipe' && (
        <>
          <div className="PracticeContainer PracticeContainerJustify">
            <div className="subContainerPractice stepThreeTextArea">
              <div className="PracticeLabel">
                Yield <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter yield"
                value={yeild}
                type="number"
                onChange={(e) => handleYeild(e.target.value)}
              />
              {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
            </div>

            <div className="subContainerPractice stepThreeTextArea BottomSpacing">
              <div className="PracticeLabel ">
                UOM <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select'}
                onChange={(e) => handleUnit(e)}
                style={{ width: 300 }}
                value={subRecipeUnit?.unit}
              >
                {unit?.map((data: any, unitIndex: number) => (
                  <Select.Option value={data?.id} key={unitIndex}>
                    {data.unit}
                  </Select.Option>
                ))}
              </Select>
              {/* <Input
            placeholder="Enter prep time"
            value={prep}
            type="number"
            onChange={(e) => handleprep(e.target.value)}
          /> */}
              {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
            </div>
          </div>
        </>
      )}
      <div className="step3-subHead">Nutritions Facts</div>
      <div className="stepThreeSubContainer">
        <div className="stepThreeadditonal">
          <div>Calories</div>
          <div>Carbs</div>
          <div>Fat</div>
          <div className="stepThreeproteinText">Protein</div>
        </div>
        <div className="stepThreeunitContainer">
          <div className="stepThreeborderContainer">
            <div className="stepThreeunitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                value={calory ? Number(calory?.toFixed(2)) : ''}
                onChange={(e) => handlecalory(e.target.value)}
              />
              <div className="unitColor">kcal</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                value={crab ? Number(crab?.toFixed(2)) : ''}
                onChange={(e) => handlecrab(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                value={fat ? Number(fat?.toFixed(2)) : ''}
                onChange={(e) => handlefat(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer ">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                value={protein ? Number(protein?.toFixed(2)) : ''}
                onChange={(e) => handleprotein(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
        </div>
      </div>

      <div className="wizardLabelAdvance">
        <div className="wizardDropdownStep3 allergens">Allergens</div>
        <ul className="allergens-content">
          {allergenData.map((val, index) => {
            return (
              <li key={index}>
                <input
                  type="checkbox"
                  checked={
                    allergen.filter((data: any) => data?.id == val?.id).length
                      ? true
                      : false
                  }
                  onChange={(e) => handleallergenCheck(e, val)}
                  className="checkboxIngredient"
                />
                <label>{val?.name}</label>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="wizardDropdownStep3">
        <div className=" wizardLabelAdvance allergens">Additional Info</div>
        <ul className="allergens-content">
          {attributes.map((val, index) => {
            return (
              <li key={index}>
                <input
                  type="checkbox"
                  onChange={(e) => handleaddionalinfocheck(e, val)}
                  className="checkboxIngredient"
                  checked={
                    addinfo.filter(
                      (data: additionalInfo) => data?.id === val?.id
                    ).length
                      ? true
                      : false
                  }
                />
                <label className="addtionalAttributeLabel">
                  {val?.attribute_name}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default StepThree;
