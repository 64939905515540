import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Page,
  Breadcrumb,
  VariationStep,
  ResponseDialog,
} from '../../components';
import VariationActions from '../../redux/middleware/variation';
import './variationWizard.scss';
import Utils from '../../redux/utils';

const variationWizard = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const company = Utils.getCurrentCompany();
  const [category, setCategory] = React.useState<any>([]);
  const [recipe, setRecipe] = React.useState('');
  const fetchDataById = async (id: any) => {
    const data = await VariationActions.getDetailvariations(id);
    if (data) {
      ResponseDialog({
        OnClick: () => {},
        type: 'success',
        messageArray: [`Your ${data?.data?.name}  Variation has been fetched`],
      });
    }
    setCategory(data?.data?.variation_type_id);
    setRecipe(data?.data?.name);
  };

  useEffect(() => {
    if (update) {
      let responseDialogData = {
        type: 'info',
        messageArray: [
          `We are fetching you Variation details . Kindly Wait !!!`,
        ],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);
      fetchDataById(id);
    }
  }, []);

  const handleRecipe = (data: string) => {
    setRecipe(data);
  };

  const handleVariationChange = (data: any) => {
    setCategory(data);
  };

  const handleContinue = async () => {
    let user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();

    if (update === true) {
      const param = {
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        variation_type_id: category,
        name: recipe,
        created_by: user?.id,
      };
      let responseDialogData = {
        type: 'info',
        messageArray: [`We are building your ${recipe} . Kindly Wait !!!`],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);

      const response = await VariationActions.updateVariation(param, id);

      if (!response?.success) {
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Validation Failed!', 'Something Went Wrong'],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/variations');
          },
          type: 'success',
          messageArray: [` Your ${recipe}- has been Updated`],
        };
        ResponseDialog(responseDialogData);
      }
    } else {
      const param = {
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        variation_type_id: category,
        name: recipe,
        created_by: user?.id,
      };
      let responseDialogData = {
        type: 'info',
        messageArray: [`We are building your ${recipe}  . Kindly Wait !!!`],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);

      try {
        const response = await VariationActions.createVariation(param);

        if (!response?.success) {
          responseDialogData = {
            OnClick: () => {},
            type: 'error',
            messageArray: ['Validation Failed!', 'Something Went Wrong'],
          };
          ResponseDialog(responseDialogData);
        } else {
          responseDialogData = {
            OnClick: () => {
              navigate('/app/variations');
            },
            type: 'success',
            messageArray: [`Your ${recipe} Variation has been Created`],
          };
          ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Variation"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/variations');
        }}
      />

      <Page title="Add New Variation">
        {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
        {/* <Stepper stepper={stepper} component={'recipe'} /> */}
        <div className="wizardContainer">
          <VariationStep
            handleVariationChange={handleVariationChange}
            recipe={recipe}
            variation={category}
            handleRecipe={handleRecipe}
          />
          <div className="butonGroup">
            <div className="wizard-button" onClick={() => handleContinue()}>
              Back
            </div>
            <div className="continue wizard-button" onClick={handleContinue}>
              Continue
            </div>
          </div>
        </div>
      </Page>
    </React.Fragment>
  );
};

export default variationWizard;
