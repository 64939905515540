import React, { useEffect, useState } from 'react';
import { Input, Modal, Select, Spin } from 'antd';
import './permissionModal.scss';
import ResponseDialog from '../ResopnseDialog';
import PermissionApi from '../../redux/middleware/permission';

type Props = {
  handleDialog: any;
  open: boolean;
  component?: string;
  name: any;
  update?: boolean;
  id?: any;
  blocked?: any;
  handleSuccessDialog: any;
};

const PermissionDialog = (props: Props) => {
  let { handleDialog, open, update, id, blocked, handleSuccessDialog } = props;
  const [permissionName, setPermissionName] = useState('');
  const [parentScreen, setParentScreen] = useState<any>([]);
  const [routeList, setRouteList] = useState<any>([]);
  const [selectedRoute, setSelectedRoute] = useState<any>();
  const [parentScreenObj, setParentScreenObj] = useState<any>({});
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  const fetchData = async () => {
    const detailData = await PermissionApi.getPermissionById(id);
    setPermissionName(detailData?.data[0]?.name);
    setParentScreenObj({
      id: detailData?.data[0]?.parent_screen_id,
      parent_screen: detailData?.data[0]?.parent_screen,
      route: detailData?.data[0]?.route,
      route_id: detailData?.data[0]?.route_id,
    });

    setSelectedRoute({
      id: detailData?.data[0]?.route_id,
      route: detailData?.data[0]?.route,
    });

    setLoader(false);
  };

  const fetchScreens = async () => {
    const screens = await PermissionApi.getParentPermission();
    setParentScreen(screens?.data);
    const { data } = await PermissionApi.getScreenRoutes();
    setRouteList(data?.items);
    setLoader(false);
  };

  useEffect(() => {
    fetchScreens();
    if (update) {
      fetchData();
      setLoader(true);
    }
  }, []);

  const handleParentScreen = (value: string | string[]) => {
    setParentScreenObj(
      parentScreen?.filter((data: any) => data?.id == value)[0]
    );
  };

  const handleScreenRoute = (e: any) => {
    const item = routeList && routeList.find((x: any) => x?.id === e);
    console.log(item);
    setSelectedRoute(item);
  };

  const handleSubmit = async () => {
    if (permissionName === '' || parentScreenObj?.id === '' || !selectedRoute) {
      setError(true);
      return;
    }
    setErrorList({});
    setErrorStatus(false);
    setError(false);

    console.log(selectedRoute);
    let param: any = {
      parent_screen_id: parentScreenObj?.id,
      name: permissionName,
      route_id: selectedRoute?.id,
    };

    try {
      let responseDialogData;
      let response: any;
      if (update) {
        response = await PermissionApi.updatePermission(param, id);
      } else {
        response = await PermissionApi.addPermission(param);
      }
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        responseDialogData = {
          OnClick: () => {},
          type: 'success',
          messageArray: [
            ` Your item has been ${!update ? 'Created' : 'Updated'}`,
          ],
        };
        ResponseDialog(responseDialogData);
        handleSuccessDialog();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      title={<div className="title font">Add Permission</div>}
      centered
      open={open}
      onCancel={handleDialog}
      width={600}
      footer={null}
      className="permissionModal"
    >
      {loader ? (
        <Spin spinning={loader} size="large" className="loaderWidthHeight" />
      ) : (
        <div className="ingredientDialog">
          <div className="ingredientSubContainer">
            <div className="ingredientLabel">
              Permission Name <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input permission name here"
              size="large"
              value={permissionName}
              onChange={(e) => setPermissionName(e.target.value)}
              className={errorList['name']?.length > 0 ? 'borderRed' : ''}
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">{errorList['name']}</div>
            )}
          </div>

          <div className="ingredientSubContainer UOMAllergen">
            <div className="spanWidth">
              <div className="ingredientLabel">
                Parent Screen <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                defaultValue={'Select Parent Screen'}
                onChange={handleParentScreen}
                style={{ width: '100%' }}
                optionFilterProp="children"
                value={parentScreenObj?.parent_screen}
                className={
                  errorList['parent_screen_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              >
                {parentScreen?.map((data: any, menuIndex: number) => (
                  <Select.Option value={data?.id} key={menuIndex}>
                    {data.parent_screen}
                  </Select.Option>
                ))}
              </Select>
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['parent_screen_id']}
                </div>
              )}
            </div>
          </div>

          <div className="ingredientSubContainer UOMAllergen">
            <div className="spanWidth">
              <div className="ingredientLabel">
                Screen Route <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                defaultValue={'Select Screen Route'}
                onChange={handleScreenRoute}
                style={{ width: '100%' }}
                optionFilterProp="children"
                value={selectedRoute?.route}
                className={
                  errorList['route_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              >
                {routeList?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.route}
                  </Select.Option>
                ))}
              </Select>
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['route_id']}
                </div>
              )}
            </div>
          </div>

          {error && <div className="error">*Fill All Feilds</div>}

          {blocked ? null : (
            <div className="butonGroup">
              <div className="continue wizard-button" onClick={handleSubmit}>
                Continue
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default PermissionDialog;
