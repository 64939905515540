import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Spin, Select, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  // Button,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
  IngendientListDialog,
} from '../../components';
import RdMatchingApi from '../../redux/middleware/rdmatching';
// import moment from 'moment';
import { useSelector } from 'react-redux';
// import Edit from '../../assets/images/Edit.svg';
// import UpDownArrow from '../../assets/images/UpDownArrow.png';
// import More from '../../assets/images/More.png';
// import Eye from '../../assets/images/Eye.svg';
import './ingredientlist.scss';

const IngredientList = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const data = {
    permissions: [
      {
        permission: 'CAL',
        perm_status_id: 2,
        perm_create: 0,
        value: 23.4,
        value_tbh: 65.3,
      },
      {
        permission: 'PRO',
        perm_status_id: 1,
        perm_create: 0,
        value: 50.32,
        value_tbh: 23.3,
      },
      {
        permission: 'FATS',
        perm_status_id: 2,
        perm_create: 1,
        value: 21.9,
        value_tbh: 32.5,
      },
      {
        permission: 'CARB',
        perm_status_id: 2,
        perm_create: 1,
        value: 43.02,
        value_tbh: 32.67,
      },
    ],
    allergens: [
      {
        name: 'Milk',
        own_created: 1,
        verified: 0,
      },
      {
        name: 'Egg',
        own_created: 0,
        verified: 1,
      },
      {
        name: 'Fish',
        own_created: 1,
        verified: 1,
      },
      {
        name: 'ShellFish',
        own_created: 0,
        verified: 1,
      },
      {
        name: 'Treenuts',
        own_created: 1,
        verified: 1,
      },
      {
        name: 'Peanuts',
        own_created: 1,
        verified: 1,
      },
      {
        name: 'Soybean',
        own_created: 1,
        verified: 1,
      },
      {
        name: 'Gluten',
        own_created: 0,
        verified: 1,
      },
    ],
  };
  const [current, setCurrent] = useState(1);
  const [taggedImageData, setTaggedImageData] = useState([{}]);
  const [dialog, setDialog] = useState(false);
  const [untaggedImageData, setUntaggedImageData] = useState([]);
  const [untaggedRecipeData, setUntaggedRecipeData] = useState<any[]>([]);
  const [dataLength, setDataLength] = useState(8);
  const [total, setTotal] = useState(0);
  const [totalUntaggedImage, setTotalUntaggedImage] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const PageDataLength = [6, 10, 20];
  const [recordIndex, setRecordIndex] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [ingredient, setIngredient] = useState<any>('');
  const [error, setError] = useState<any>({});
  const { currentCompany } = useSelector((state: any) => state.Auth);
  React.useEffect(() => {
    pageData();
  }, [currentCompany]);
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Upload Images'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
      fetchUntaggedDataCount();
    }
  }, [dataLength, current, check]);
  useEffect(() => {
    setCurrent(1);
    getUntaggedRecipes();
  }, [check]);

  const fetchUntaggedDataCount = async () => {
    const { data, success } = await RdMatchingApi.getMAtchUnMatch(
      dataLength,
      'no',
      current
    );
    if (success) setTotalUntaggedImage(data?.pagination?.total);
  };

  const getUntaggedRecipes = async () => {
    const untaggedRecipes = await RdMatchingApi.getUnmatchdropdown();
    if (untaggedRecipes == undefined || !untaggedRecipes?.success)
      setUntaggedRecipeData([]);
    else {
      // const dataArray =
      //   [
      //     { ingredient_name: 'None', ingredient_category: '', id: 0 },
      //     ...untaggedRecipes.data.items,
      //   ] || [];
      // console.log([...dataArray]);
      setUntaggedRecipeData([
        { ingredient_name: 'None', ingredient_category: '', id: 0 },
        ...untaggedRecipes.data.items,
      ]);
    }
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  //   {
  //     key: '2',
  //     label: <div className="dotOption">Edit</div>,
  //     icon: <img src={Edit} width={15} />,
  //   },
  // ];
  // const itemsForUntagged: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  // ];

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setCurrent(1);
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   switch (option) {
  //     case 1:
  //       // handleDialogData();
  //       setDialog(true);
  //       break;
  //     case 2:
  //       // setUpdate(true);
  //       // setOpenIngredientDialog(true);
  //       break;
  //     default:
  //   }
  // };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>R&D INGREDIENT</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col3 cellSpacing cellSpacingIngredientTable ">
            {record?.ingredient_name}
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.category_name}</div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>INVENTORY INGREDIENT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">
          {recordIndex === record?.id ? (
            <Select
              size={'large'}
              showSearch
              placeholder={'-'}
              value={record?.matched_ingredient_name}
              disabled={recordIndex == record?.id ? false : true}
              onChange={(e) => {
                const getIngredient = untaggedRecipeData.find(
                  (data: any) => data?.id == e
                ) || { ingredient_name: '' };
                setIngredient(getIngredient);
                record.matched_ingredient_name = getIngredient?.ingredient_name;
                record.matched_ingredient_id = e;
              }}
              style={{ width: 200 }}
              optionFilterProp="children"
            >
              {untaggedRecipeData?.map((data: any, menuIndex: number) => (
                <Select.Option value={data?.id} key={menuIndex}>
                  {data?.ingredient_name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            record?.matched_ingredient_name
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">
          {recordIndex == record?.id ? (
            <Tooltip
              title={
                <div className="font">
                  {ingredient?.ingredient_category ||
                    ingredient?.matched_ingredient_category}
                </div>
              }
            >
              {
                // ingredient?.ingredient_category != undefined &&
                ingredient?.ingredient_category?.length > 12
                  ? ingredient?.ingredient_category?.substring(0, 10) + '...'
                  : ingredient?.ingredient_category
                // ||
                //   (ingredient?.matched_ingredient_category != undefined &&
                //     ingredient?.matched_ingredient_category?.length > 12)
                // ? ingredient?.matched_ingredient_category?.substring(0, 10) +
                //   '...'
                // : ingredient?.matched_ingredient_category
              }
            </Tooltip>
          ) : record?.matched_ingredient_category?.length > 12 ? (
            <Tooltip
              title={
                <div className="font">
                  {record?.matched_ingredient_category}
                </div>
              }
            >
              {record?.matched_ingredient_category?.substring(0, 10) + '...'}
            </Tooltip>
          ) : (
            record?.matched_ingredient_category
          )}
        </div>
      ),
    },

    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],

      render: (_: any, record: any) =>
        recordIndex == record?.id ? (
          <div
            className={`${
              loader ? 'loader-enable-supplier' : 'continue'
            } Supplier-ingredient-editButton`}
            onClick={() => {
              handleMenu(record?.matched_ingredient_id, record);
            }}
          >
            <Spin spinning={loader} size="default">
              UPDATE
            </Spin>
          </div>
        ) : (
          <div
            className={`continue Supplier-ingredient-editButton`}
            onClick={() => {
              setRecordIndex(record?.id);
              setIngredient({
                ...record,
                ingredient_category: record?.matched_ingredient_category,
              });
            }}
          >
            EDIT
          </div>
        ),
    },
  ];
  const unTaggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>R&D INGREDIENT</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],

      render: (data: any, record: any) => {
        return (
          <div className="col3 cellSpacing cellSpacingIngredientTable ">
            {record?.ingredient_name}
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>R&D CATEGORY</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col3 cellSpacing cellSpacingIngredientTable ">
            {record?.category_name}
          </div>
        );
      },
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>INVENTORY INGREDIENT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing unMatch-ingredient-empty">
          <Select
            size={'large'}
            placeholder={'-'}
            value={
              recordIndex == record?.id ? ingredient?.ingredient_name : null
            }
            disabled={recordIndex == record?.id ? false : true}
            onChange={(e) => {
              const getIngredient = untaggedRecipeData.find(
                (data: any) => data?.id == e
              ) || { ingredient_name: '', ingredient_category: '' };
              setIngredient(getIngredient);
              record.matched_ingredient_name = getIngredient?.ingredient_name;
              record.matched_ingredient_id = e;
            }}
            style={{ width: 200 }}
            optionFilterProp="children"
            showSearch
            className={
              error['ingredient']?.length > 0 && recordIndex == record?.id
                ? 'dropdownUnitColorRed'
                : ''
            }
          >
            {untaggedRecipeData
              ?.filter(
                (filteredObj: any) => filteredObj?.ingredient_name != 'None'
              )
              ?.map((data: any, menuIndex: number) => (
                <Select.Option value={data?.id} key={menuIndex}>
                  {data?.ingredient_name}
                </Select.Option>
              ))}
          </Select>
          {error['ingredient']?.length > 0 && recordIndex == record?.id && (
            <div className="subRecipeErrorMessages">{error['ingredient']}</div>
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return recordIndex == record?.id ? (
          <div className="col3 cellSpacing cellSpacingIngredientTable ">
            {ingredient?.ingredient_category?.length > 12 ? (
              <Tooltip
                title={
                  <div className="font">{ingredient?.ingredient_category}</div>
                }
              >
                {ingredient?.ingredient_category?.substring(0, 10) + '...'}
              </Tooltip>
            ) : (
              ingredient?.ingredient_category
            )}
          </div>
        ) : null;
      },
    },

    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],

      render: (_: any, record: any) =>
        recordIndex == record?.id ? (
          <div
            className={`${
              loader ? 'loader-enable-supplier' : 'continue'
            } Supplier-ingredient-editButton`}
            onClick={() => {
              handleMenu(record?.matched_ingredient_id, record);
            }}
          >
            <Spin spinning={loader} size="default">
              UPDATE
            </Spin>
          </div>
        ) : (
          <div
            className={`continue Supplier-ingredient-editButton`}
            onClick={() => {
              setRecordIndex(record?.id);
              setIngredient('');
              setError({});
            }}
          >
            EDIT
          </div>
        ),
    },
  ];

  const handleMenu = async (value: any, data: any) => {
    if (value == null) {
      setError({ ingredient: 'Ingredient is required for matching' });
      return;
    }

    setLoader(true);
    const payload = {
      matched_ingredient_id: value,
      is_matched: value == 0 ? 0 : 1,
    };
    const assignTag = await RdMatchingApi.updateUnmatch(payload, data?.id);
    let responseDialogData;
    setLoader(false);
    if (assignTag === undefined || !assignTag?.success) {
      setRecordIndex(-1);

      responseDialogData = {
        type: 'error',
        messageArray: [`Validation Failed!`, 'Something Went Wrong'],
        OnClick: () => {},
      };
    } else {
      responseDialogData = {
        type: 'success',
        messageArray: [`${assignTag?.message}`],
        OnClick: () => {
          getUntaggedRecipes();
          setRecordIndex(-1);
          setIngredient('');
          pageData();
        },
      };
    }
    ResponseDialog(responseDialogData);
  };
  const pageData = async () => {
    setRecipeLoader(true);
    const imagesData = check
      ? await RdMatchingApi.getMAtchUnMatch(dataLength, 'yes', current)
      : await RdMatchingApi.getMAtchUnMatch(dataLength, 'no', current);
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalUntaggedImage(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedImageData(imagesData?.data?.items);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUntaggedImageData(imagesData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = check
      ? await RdMatchingApi.searchMAtchUnMatch(dataLength, 'yes', keyword)
      : await RdMatchingApi.searchMAtchUnMatch(dataLength, 'no', keyword);
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setTotal(0);
      setTotalUntaggedImage(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
    } else {
      if (check) {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTaggedImageData(searchResultofRecipes?.data?.items);
      } else {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTotalUntaggedImage(searchResultofRecipes?.data?.pagination?.total);
        setUntaggedImageData(searchResultofRecipes?.data?.items);
      }
    }
    setRecipeLoader(false);
  };
  // const actionButton = (
  //   <Button
  //     text="Add New"
  //     className="theme-button recipeBtn"
  //     icon={<PlusOutlined />}
  //     handleButtonClick={() => {
  //       navigate('wizard', { state: { update: false, id: '' } });
  //     }}
  //     shape="round"
  //     size="middle"
  //   />
  // );
  const handleClose = () => {
    setDialog(false);
  };
  return (
    <React.Fragment>
      <Breadcrumb
        heading="R&D Matching"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        perm_status={permissionStatus}
      />
      <IngendientListDialog
        open={dialog}
        handleClose={handleClose}
        data={data}
        handleMarkRecipe={() => {}}
      />
      <Page title="Matching Ingredient List">
        <Row className="listingTopFilter">
          <Col lg={10}></Col>
          <Col lg={8} />
          <Col lg={6}>
            <SearchBox action={onSearch} placeholderText="Search..." />
          </Col>
        </Row>

        <Row gutter={12} className="listingTopFilter">
          <Col lg={24} md={16} className="ingredient-list-wrapper">
            <div className="ingredient-list-menu-container">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div
                  onClick={() => setCheck(true)}
                  className="text ingredient-list-menu-style"
                >
                  Matched
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => setCheck(false)}
              >
                <div className="text ingredient-list-menu-style">UnMatched</div>
                <div
                  className={`ingredient-list-counter ${
                    +totalUntaggedImage >= 10 && 'ingredient-list-large-counter'
                  }`}
                >
                  {totalUntaggedImage}
                </div>
              </div>
            </div>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={''}
                dataSource={
                  check ? [...taggedImageData] : [...untaggedImageData]
                }
                columns={check ? taggedColumns : unTaggedColumns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};
export default IngredientList;
