import React from 'react';
import { Table } from 'antd';
import './listViewWithoutExpand.scss';

type Props = {
  dataSource: any;
  columns: any;
  items: any;
  limit?: any;
};

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

const ListViewWithoutExpand = (props: Props) => {
  return (
    <Table
      rowKey={(record: any) => record.id}
      dataSource={props?.dataSource}
      columns={props?.columns}
      pagination={{
        pageSize: props?.limit || 20,
        position: [
          'none' as TablePaginationPosition,
          'none' as TablePaginationPosition,
        ],
      }}
    />
  );
};

export default ListViewWithoutExpand;
