import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import './style.scss';

type Props = {
  title: string;
  open: boolean;
  handleClose: any;
  data: any;
  component: any;
};

const SettingDialog = (props: Props) => {
  let { open, handleClose, component, data, title } = props;
  const [Info, setInfo] = useState<any>([]);

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const renderBrandInfo = () => {
    return (
      <Row>
        <Col md={14}>
          <div className="additional font">Brand</div>
          <div className="additional-text">{Info?.name}</div>

          <div className="additional font">Company</div>
          <div className="additional-text">{Info?.company}</div>

          <div className="additional font spacingDiv">Phone</div>
          <div className="additional-text">{Info?.phone}</div>
        </Col>
        <Col md={10}>
          <div className="additional font">Country</div>
          <div className="additional-text">{Info?.country}</div>

          <div className="additional font">Branch Limit</div>
          <div className="additional-text">{Info?.sub_license}</div>

          <div className="additional font spacingDiv">Created</div>
          <div className="additional-text">
            {moment(Info?.created_at).fromNow()}
          </div>
        </Col>
      </Row>
    );
  };

  const renderBranchInfo = () => {
    return (
      <Row>
        <Col md={14}>
          <div className="additional font additional-remove-margin-top ">
            Branch
          </div>
          <div className="additional-text additional-text-height">
            {Info?.shop_name}
          </div>

          <div className="additional font">Brand</div>
          <div className="additional-text additional-text-height">
            {Info?.brand_id?.map((data: any) => data?.brand_name)?.toString()}
          </div>
        </Col>
        <Col md={10}>
          <div className="additional font additional-remove-margin-top ">
            Address
          </div>
          <div className="additional-text additional-text-height">
            {Info?.address}
          </div>

          <div className="additional font">City</div>
          <div className="additional-text additional-text-height">
            {Info?.city_name}
          </div>
        </Col>
      </Row>
    );
  };

  const renderCompanyInfo = () => {
    return (
      <Row>
        <Col md={14}>
          <div className="additional font">Company</div>
          <div className="additional-text">{Info?.company_name}</div>

          <div className="additional font">Consumption Method</div>
          <div className="additional-text">{Info?.consumption_method}</div>
        </Col>
        <Col md={10}>
          <div className="additional font">Country</div>
          <div className="additional-text">{Info?.country}</div>

          <div className="additional font">License</div>
          <div className="additional-text">{Info?.license}</div>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={<div className="title font">{title}</div>}
      centered
      open={open}
      onCancel={handleClose}
      width={480}
      footer={null}
      className="SettingWrapper"
    >
      <div>
        {component != 'branch' && (
          <div className="cover-photo">
            <img
              src={Info?.thumbnail}
              alt=""
              width={'432px'}
              height="240px"
              className="image"
            />
          </div>
        )}
        {component === 'brand' && renderBrandInfo()}
        {component === 'branch' && renderBranchInfo()}
        {component === 'company' && renderCompanyInfo()}
      </div>
    </Modal>
  );
};

export default SettingDialog;
