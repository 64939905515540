import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import SupplierActions from '../../redux/middleware/supplier';
import BuyingUnitActions from '../../redux/middleware/buyingUnit';
import './buyingUnitWizard.scss';
import Utils from '../../redux/utils';
import { Input, Spin, Select } from 'antd';

const BuyingUnitWizard = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [supplierId, setSupplierId] = useState<any[]>([]);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { update, id } = state;
  const [recipe, setRecipe] = React.useState('');
  const fetchDataById = async (id: any) => {
    const data = await BuyingUnitActions.getBuyingUnitById(id);
    if (data) {
      setLoader(false);
    }
    setRecipe(data?.data[0]?.buying_unit);
    setSupplierId({
      ...data,
      label: data?.data[0]?.supplier_name,
      value: data?.data[0]?.supplier_id,
    });
  };

  useEffect(() => {
    getSupplier();
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
  }, []);

  const handleRecipe = (data: string) => {
    setRecipe(data);
  };

  const getSupplier = async () => {
    const supplierData = await SupplierActions.getSupplier(500);
    setSuppliers(
      supplierData?.data?.items?.map((data: any) => {
        return {
          label: data?.supplier_name,
          value: data?.id,
        };
      })
    );
  };

  const handleSupplier = (value: any) => {
    setSupplierId(value);
    // const data: any = suppliers.filter((data: any) => {
    //   return data.value === value;
    // });
    //   setBrandId(data.flat());
  };

  const handleContinue = async () => {
    const company = Utils.getCurrentCompany();
    setErrorList({});
    setErrorStatus(false);
    if (update === true) {
      const param = {
        company_id: company?.id,
        buying_unit: recipe,
        supplier_id: supplierId,
      };
      let responseDialogData;
      setApiLoader(true);

      const response = await BuyingUnitActions.updateBuyingUnit(id, param);

      setApiLoader(false);

      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        responseDialogData = {
          OnClick: () => {
            navigate('/app/buyingUnit');
          },
          type: 'success',
          messageArray: [` Your ${recipe}`, ` has been Updated`],
        };
        ResponseDialog(responseDialogData);
      }
    } else {
      const param = {
        company_id: company?.id,
        buying_unit: recipe,
        supplier_id: supplierId,
      };
      let responseDialogData;
      setApiLoader(true);

      try {
        const response = await BuyingUnitActions.addBuyingUnit(param);

        setApiLoader(false);

        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
        } else {
          responseDialogData = {
            OnClick: () => {
              navigate('/app/buyingUnit');
            },
            type: 'success',
            messageArray: [` Your ${recipe} buying unit`, ` has been Created`],
          };
          ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Buying Unit"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/buyingUnit');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Buying Unit">
          {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="wizardContainer">
              <div className="ingredientCategoryContainerWidth">
                <div className="wizardSubContainer">
                  <div className="wizardLabel">
                    Supplier <span className="sterik">*</span>
                  </div>
                  {/* <Input
                    placeholder="Enter Variation Name"
                    size="large"
                    value={recipe}
                    onChange={(e) => {
                      handleRecipe(e.target.value);
                    }}
                    required
                    className={errorList['name']?.length > 0 ? 'borderRed' : ''}
                  /> */}
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={supplierId}
                    onChange={handleSupplier}
                    options={suppliers}
                  />

                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['name']}
                    </div>
                  )}
                </div>
                <div className="wizardSubContainer wrapper">
                  <div className="wizardLabel">
                    Buying Unit Name <span className="sterik">*</span>
                  </div>
                  <Input
                    placeholder="Enter Variation Name"
                    size="large"
                    value={recipe}
                    onChange={(e) => {
                      handleRecipe(e.target.value);
                    }}
                    required
                    className={errorList['name']?.length > 0 ? 'borderRed' : ''}
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['name']}
                    </div>
                  )}
                </div>
              </div>
              <div className="butonGroup">
                <div className="wizard-button" onClick={() => handleContinue()}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default BuyingUnitWizard;
