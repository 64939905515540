/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  Button,
  ListViewWithoutExpand,
  DetailDialog,
  DeleteConfirmation,
} from '../../components';
// import SubRecipeActions from '../../redux/middleware/subRecipe';
// import IngredientActions from '../../redux/middleware/ingredient';
import { sortedData } from '../../helper/sortData';
import Clock from '../../assets/images/Clock.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import Delete from '../../assets/images/Delete.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './requisitions.scss';
import SupplierApi from '../../redux/middleware/supplier';
import Utils from '../../redux/utils';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="requisitions-dot-option">Details</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="requisitions-dot-option">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '4',
    label: <div className="requisitions-dot-option">Approve</div>,
    icon: <CheckCircleOutlined className="requisition-iconDropdown" />,
  },
  {
    type: 'divider',
  },
  {
    key: '3',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20];

const Requisitions = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('__chefAdminUser') || '');
  const company = Utils.getCurrentCompany();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  // const [database, setDatabase] = useState<any>([]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [selectedDbId] = useState(3);
  const [requisitionLoader, setRequisitionLoader] = useState(false);
  const [requisitionCount, setRequisitionCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);

  const { currentBrand } = useSelector((state: any) => state.Auth);
  const { currentShop } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentBrand]);

  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  };

  const fetchStatuses = async () => {
    const { data, success } = await SupplierApi.getSupplyStatus('status');
    if (success) {
      setStatuses(data);
    }
  };

  const columns = [
    {
      title: () => (
        <div className="requisitions-table-title requisition-table-serial-no">
          <span>REQUISITION ID</span>
          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="requisitions-column requisitions-cell-spacing">
          {record?.id}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>RAISED BY BRAND</span>
          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('ingredient_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="requisitions-column requisitions-cell-spacing">
          {record?.brand_name}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>REQUEST TO BRANCH</span>

          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('ingredient_category');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">{record?.shop_name}</div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>STATUS </span>

          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        // <div className="requisitions-table-col requisitions-column requisitions-col4">
        //   {record?.request_status}
        // </div>
        <div
          className={`requestStatusContainer ${
            record?.request_status?.toLowerCase() == 'pending'
              ? 'inventoryTransfer-request-pending'
              : record?.request_status?.toLowerCase() == 'approved'
              ? 'inventoryTransfer-request-approve'
              : 'inventoryTransfer-request-reject'
          } `}
        >
          {record?.request_status}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>CREATED AT</span>

          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="requisitions-table-col requisitions-column ">
          <div className="subrecipecol2">
            {moment(UTCtoLocal(record?.created_at)).fromNow()}
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items:
              record?.request_status?.toLowerCase() === 'approved'
                ? items
                : items.filter((x) => x?.key !== '4'),
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="requisitions-dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    fetchStatuses();
    let permissions = user?.permissions;
    const data = permissions.filter(
      (permission: any) => permission?.permission === 'Requisitions'
    );
    setPermissionStatus(data[0]?.perm_status_id);
    // fetchDatabase();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand, currentShop]);

  // const fetchDatabase = async () => {
  //   const data = await IngredientActions.getDatabase();
  //   // setDatabase(data?.data);
  // };

  const fetchDialogData = async () => {
    const { data, success } = await SupplierApi.getInventorySupplyById(
      selectedMenu
    );
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const SubmitAcceptStatus = async () => {
    const statusId =
      statuses?.find((status: any) => status?.slug == 'closed')?.id || '';
    const { success } = await SupplierApi.acceptInventorySupply(
      { request_status_id: statusId },
      selectedMenu
    );
    setLoader(false);
    if (success) pageData();
  };
  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setLoader(true);
        fetchDialogData();
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedMenu } });
        break;
      case 3:
        setOpenDeleteDialog(true);
        break;
      case 4:
        setLoader(true);
        SubmitAcceptStatus();
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    setLoader(true);
    const { success } = await SupplierApi.deleteInventorySupply(
      {
        company_id: company?.id,
      },
      selectedMenu
    );
    setLoader(false);
    if (success) pageData();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: (
        <div className="requisitions-font requisitions-dot-option">{data}</div>
      ),
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    let request_id;
    setRequisitionLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'requisition');
    const requisitionData = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );
    if (requisitionData === undefined || !requisitionData?.success) {
      setRequisitionCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRequisitionCount(requisitionData?.data?.supply_count);
      setTotal(requisitionData?.data?.pagination?.total);
      setSortData(requisitionData?.data?.items);
    }
    setRequisitionLoader(false);
  };

  const onSearch = async (keyword: string) => {
    let request_id;
    setRequisitionLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const id = selectedDbId;
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'requisition');
    // const id = selectedDbId;
    const requisitionSearchResult = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );
    if (
      requisitionSearchResult === undefined ||
      !requisitionSearchResult?.success
    ) {
      setRequisitionCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRequisitionCount(requisitionSearchResult?.data?.supply_count);
      setTotal(requisitionSearchResult?.data?.pagination?.total);
      setSortData(requisitionSearchResult?.data?.items);
    }
    setRequisitionLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Requisitions"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        // perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Requisitions">
          <DeleteConfirmation
            handleDeleteCancel={handleDeleteCancel}
            handleDeleteContinue={handleDeleteContinue}
            open={openDeleteDialog}
            message={'Are you sure want to delete requisition?'}
          />
          <DetailDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
            component={'requisition'}
          />
          <Row className="requisitions-listingTopFilter">
            <Col lg={10} md={24}>
              <div style={{ display: 'flex', marginRight: '100px' }}>
                <ListCount
                  count1={requisitionCount}
                  title1="Total Requisitions"
                  title3="Brand"
                  title4="Branch"
                  component={'Requisitions'}
                />
                {/* <div className="uploadImageMenuContainer">
                {database?.map((db: any, index: any) => {
                  return (
                    <>
                      <div
                        className={
                          selectedDbId === db?.id ? 'menu' : 'menuLight'
                        }
                      >
                        <div
                          onClick={() => handleActiveTab(db)}
                          className="text menustyle"
                        >
                          {db?.db_name}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div> */}
              </div>
            </Col>
            <Col lg={14} md={24}>
              <div className="requisitions-search-wrapper">
                <div className="search-box">
                  <SearchBox action={onSearch} placeholderText="Search..." />
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!requisitionLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={items}
                  dataSource={[...sortData] || []}
                  columns={columns}
                />
              ) : (
                <div className="requisitions-loader-wrapper">
                  <Spin spinning={requisitionLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default Requisitions;
